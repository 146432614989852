/**
* UserSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file UserSlice.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { getApplications, getUsersByAppIdApi } from '../Services/ApplicationApi';
import { getRolesDropdown, getRolesFromServer, getUsersByRoleId } from '../Services/RolesApi';
import {
  createUserApi,
  deleteUsersFromServer,
  getUserByUUID,
  getUsers,
  removeProfilePicApi,
  resetPasswordApi,
  toggleStatusApi,
  updateUserApi,
  uploadProfilePicApi,
  deleteUserFromServer,
  verifyUserAPI,
  getTotalUsersCount,
  setUserThemeModeAPI,
  setUserLanguageSettingsModeAPI,
  getUserProfileInformation
} from '../Services/UserApi';
import i18n from '../Services/I18n';
import { Reducer } from 'react';
import { logoutAction } from './AuthSlice';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  IMessageResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IUser,
  IUserList,
  IUserProfile
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import {
  IDeleteUserResponse,
  IDeleteUsersResponse,
  IGetUserByIDResponse,
  IImageUploadResponse,
  IRemoveUserProfilePictureResponse
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { getApplicationsByUserId } from './ApplicationSlice';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const userFeatureKey: string = 'users';

/**
 * Interface userInitialState
 */
export interface IUserState {
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  isPasswordReset: boolean;
  createError: any;
  updateThemeModeStateError: any;
  updateError: any;
  deleteError: any;
  fetchError: any;
  fetchUserByIdError: any;
  application: any;
  role: any;
  applications: any;
  editUser: any;
  expandRow: any;
  usersList: any[];
  first: number;
  totalRecords: number;
  isLoading: boolean;
  resetPasswordError: any;
  isFormLoad: boolean;
  toggleUserStatus: boolean;
  toggleUserStatusSuccess: any;
  toggleUserStatusFail: any;
  isUpdating: boolean;
  profilePicUploadResponse: any;
  profilePicUploadError: any;
  profilePicUploadStatus: boolean;
  isFetchingRoles: boolean;
  userCount: number | null;
  allRoles: IRole[] /**< All roles. */;
  toggleVerifiedStatus: boolean /**< Verify Email Status. */;
  toggleVerifiedStatusSuccess: string | null /**< Verify Email Status Success. */;
  toggleVerifiedStatusError: string | null /**< Verify Email Status Error. */;
  updateLanguageSettingsModeStateError: any /**< Language settings mode error state. */;
  userProfileCardInformation: IUserProfile | null /**< Information used in the Profile card of Sidebar and hover effects. */;
  isLoadingUserProfileCardInformation:
    | boolean
    | null /**< State to manage when requesting information to use in the Profile card. */;
}

export const userInitialState: IUserState = {
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  deleteError: null,
  fetchError: null,
  fetchUserByIdError: null,
  createError: null,
  updateThemeModeStateError: null,
  updateError: null,
  usersList: null,
  editUser: null,
  expandRow: null,
  isPasswordReset: false,
  first: 0,
  totalRecords: 0,
  isLoading: false,
  resetPasswordError: null,
  isFormLoad: false,
  toggleUserStatus: false,
  toggleUserStatusSuccess: null,
  toggleUserStatusFail: null,
  isUpdating: false,
  profilePicUploadResponse: null,
  profilePicUploadError: null,
  profilePicUploadStatus: false,
  role: [],
  applications: [],
  application: null,
  isFetchingRoles: false,
  userCount: null,
  allRoles: [],
  toggleVerifiedStatus: false,
  toggleVerifiedStatusSuccess: null,
  toggleVerifiedStatusError: null,
  updateLanguageSettingsModeStateError: null,
  userProfileCardInformation: null,
  isLoadingUserProfileCardInformation: null
};

export const userAdapter: EntityAdapter<IUserState> = createEntityAdapter();
export const initialUserState: EntityState<IUserState> & IUserState =
  userAdapter.getInitialState(userInitialState);

export const getUsersListAction = createAsyncThunk(
  'users/get/list',
  async (payload: any, thunkAPI) => {
    await asyncErrorHandler(thunkAPI.dispatch(initializeUserStateAction({})));
    const response: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(getUsers(payload));
    return response;
  }
);

export const updateUserThemeModeStateAction = createAsyncThunk(
  'user/update/themeMode',
  async (payload: { userUUID: string; themeMode: string }, thunkAPI) => {
    await asyncErrorHandler(thunkAPI.dispatch(initializeUserStateAction({})));
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(
      setUserThemeModeAPI(payload.userUUID, payload.themeMode)
    );
    return response;
  }
);

export const updateExpandedUserAction = createAsyncThunk(
  'users/expand/update',
  async (payload: any) => {
    return payload;
  }
);

export const createUserAction = createAsyncThunk('users/create', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  if (payload.imageName) {
    const imageResponse: { payload: IAPIEntityResponse<IImageUploadResponse> } =
      await asyncErrorHandler(
        dispatch(
          uploadProfilePictureAction({ file: payload.imageName, userUUID: payload.userUUID })
        )
      );
    if (imageResponse.payload.status === 200) {
      payload['imageName'] = imageResponse.payload.data.imageName;
    } else {
      return imageResponse.payload;
    }
  }
  const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(createUserApi(payload));
  return response;
});

export const updateUserAction = createAsyncThunk('users/update', async (payload: any, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { resetUploadStatus } = userActions;
  dispatch(resetUploadStatus());

  if (payload.imageName) {
    const imageResponse: { payload: IAPIEntityResponse<IImageUploadResponse> } =
      await asyncErrorHandler(
        dispatch(
          uploadProfilePictureAction({ file: payload.imageName, userUUID: payload.userUUID })
        )
      );
    if (imageResponse.payload.status === 200) {
      payload['imageName'] = imageResponse.payload.data.imageName;
    } else {
      return imageResponse.payload;
    }
  }
  const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(
    updateUserApi(payload, payload.userUUID)
  );

  // Check if applications changed for logged in user
  if (
    response !== undefined &&
    response.status === 200 &&
    response.data !== undefined &&
    response.data.userUUID === LocalStorage.getXUserUUID()
  ) {
    const userState: any = getState();
    const currentUserApplications: IApplications[] = userState.users.editUser.applications;
    const responseUserApplications: IApplications[] = response.data.applications;

    //Note: Get the bigger user applications array to use as reference for the first loop iteration.
    //Note: This avoid to first loop in a smaller array in case the second array has more length.
    const firstUserApplicationsToLoop: IApplications[] =
      currentUserApplications.length >= responseUserApplications.length
        ? currentUserApplications
        : responseUserApplications;

    //Note: Get the smallest user applications array to use as reference for the second loop iteration.
    const secondUserApplicationsToLoop: IApplications[] = !(
      currentUserApplications.length >= responseUserApplications.length
    )
      ? currentUserApplications
      : responseUserApplications;

    const isUserApplicationsChanged = (
      firstUserApplications: IApplications[],
      secondUserApplications: IApplications[]
    ): boolean => {
      const firstUserApplicationsUUID: string[] = firstUserApplications.map(
        (application: IApplications) => application.applicationUUID
      );
      const secondUserApplicationsUUID: string[] = secondUserApplications.map(
        (application: IApplications) => application.applicationUUID
      );

      for (let i = 0; i < firstUserApplicationsUUID.length; i++) {
        if (firstUserApplicationsUUID[i] !== secondUserApplicationsUUID[i]) {
          return true;
        }
      }

      return false;
    };

    //Note: Only request user applications in case any linked application changed
    if (isUserApplicationsChanged(firstUserApplicationsToLoop, secondUserApplicationsToLoop)) {
      dispatch(getApplicationsByUserId({}));
    }
  }
  return response;
});

export const getRolesAction = createAsyncThunk('users/roles/get', async (payload: any) => {
  return getRolesDropdown(payload.selectedApplicationIDs);
});

export const getTotalUsersCountAction = createAsyncThunk('users/count', async () => {
  return getTotalUsersCount();
});

export const getApplicationsAction = createAsyncThunk('users/applications/get', async () => {
  const response: PaginatedAPIEntityResponse<IApplications> = await asyncErrorHandler(
    getApplications({ limit: 0 })
  );
  return response;
});

export const getUserByIdAction = createAsyncThunk('users/get/id', async (payload: any) => {
  const response: IAPIEntityResponse<IGetUserByIDResponse> = await asyncErrorHandler(
    getUserByUUID(payload.userUUID)
  );
  return response;
});

export const deleteUsersAction = createAsyncThunk('users/delete', async (payload: any) => {
  const response: IAPIEntityResponse<IDeleteUsersResponse> = await asyncErrorHandler(
    deleteUsersFromServer(payload)
  );
  return response;
});

export const deleteUserAction = createAsyncThunk(
  'users/delete/one',
  async (payload: any, thunkAPI) => {
    const dispatch: any = thunkAPI.dispatch;
    const response: IAPIEntityResponse<IDeleteUserResponse> = await asyncErrorHandler(
      deleteUserFromServer(payload)
    );
    await asyncErrorHandler(dispatch(logoutAction()));
    return response;
  }
);

export const getUsersByAppIdAction = createAsyncThunk('users/app/id/list', async (payload: any) => {
  const applicationUUID: string = payload.applicationUUID;
  const response: Promise<PaginatedAPIEntityResponse<IUser>> = getUsersByAppIdApi(
    payload,
    applicationUUID
  );
  return response;
});

export const getUsersByRoleIdAction = createAsyncThunk(
  'users/role/id/list',
  async (payload: any) => {
    const response: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(
      getUsersByRoleId(payload)
    );
    return response;
  }
);

export const removeProfilePicAction = createAsyncThunk(
  'users/profilepic/delete',
  async (payload: string) => {
    const response: IAPIEntityResponse<IRemoveUserProfilePictureResponse> = await asyncErrorHandler(
      removeProfilePicApi(payload)
    );
    return response;
  }
);

export const resetPasswordAction = createAsyncThunk(
  'users/password/reset',
  async (payload: any) => {
    const response: IAPIEntityResponse<IMessageResponse> = await asyncErrorHandler(
      resetPasswordApi(payload)
    );
    return response;
  }
);

export const initializeUserStateAction = createAsyncThunk(
  'users/initialize',
  async (payload: any) => {
    return payload;
  }
);

export const toggleUserStatusAction = createAsyncThunk(
  'user/status/toggle',
  async (payload: any, thunkAPI: any) => {
    const dispatch: any = thunkAPI.dispatch;
    await asyncErrorHandler(dispatch(initializeUserStateAction({})));
    const response: IAPIEntityResponse<IMessageResponse> = await asyncErrorHandler(
      toggleStatusApi(payload.userUUID, payload)
    );
    return response;
  }
);

export const uploadProfilePictureAction = createAsyncThunk(
  'user/profilepic/upload',
  async (payload: { file: Blob; userUUID: string }) => {
    const response: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
      uploadProfilePicApi(payload)
    );
    return response;
  }
);

export const verifyUserAction = createAsyncThunk(
  'user/userUUID/verify',
  async (userUUID: string) => {
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(verifyUserAPI(userUUID));
    return response;
  }
);

/**
 * Get All Roles.
 */
export const getAllRolesAction = createAsyncThunk('users/allRoles/get', async () => {
  const response: PaginatedAPIEntityResponse<IRole> = await asyncErrorHandler(
    getRolesFromServer({ limit: 0 })
  );
  return response;
});

/**
 * Get user information for the Profile card.
 */
export const getUserProfileInformationAction = createAsyncThunk(
  'users/profile',
  async (userUUID: string) => {
    const response: IAPIEntityResponse<IUserProfile> = await getUserProfileInformation(userUUID);
    return response;
  }
);

/**
 * Update user language preference
 */
export const updateUserLanguageSettingsModeStateAction = createAsyncThunk(
  'user/update/languageSettingsMode',
  async (payload: { userUUID: string; languageSettingsMode: string }, thunkAPI) => {
    await asyncErrorHandler(thunkAPI.dispatch(initializeUserStateAction({})));
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(
      setUserLanguageSettingsModeAPI(payload.userUUID, payload.languageSettingsMode)
    );
    return response;
  }
);

const clearErrors = (state: IUserState) => {
  state.isCreated = false;
  state.isUpdated = false;
  state.isDeleted = false;

  state.createError = null;
  state.updateError = null;
  state.deleteError = null;
  state.fetchError = null;
  state.updateThemeModeStateError = null;
  state.updateLanguageSettingsModeStateError = null;
};

/**
 * Creates Slice - All User related state will be stored here
 */
export const userSlice = createSlice({
  name: userFeatureKey,
  initialState: initialUserState,
  reducers: {
    reset: (state: IUserState) => clearErrors(state),
    resetUploadStatus: (state: IUserState) => {
      state.profilePicUploadResponse = null;
      state.profilePicUploadError = null;
      state.profilePicUploadStatus = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalUsersCountAction.pending, (state: IUserState) => {
        state.isLoading = true;
        state.fetchError = null;
      })
      .addCase(
        getTotalUsersCountAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IUserList>>) => {
          state.userCount = action.payload.data.userCount;
        }
      )
      .addCase(getTotalUsersCountAction.rejected, (state: IUserState) => {
        state.isLoading = false;
        state.userCount = null;
        state.fetchError = i18n.t('I18N.error_messages.get_total_users_count_error');
      })
      .addCase(getUsersListAction.pending, (state: IUserState) => {
        state.isLoading = true;
        state.fetchError = null;
      })
      .addCase(
        getUsersListAction.fulfilled,
        (state: IUserState, action: IReducerAction<PaginatedAPIEntityResponse<IUser>>) => {
          if (action && action.payload && action.payload.status === 200) {
            state.totalRecords = action && action.payload && action.payload.data?.totalRecords;
            state.usersList = action && action.payload && action.payload.data?.records;
          } else {
            state.fetchError =
              (action && action.payload && action.payload.error) ||
              i18n.t('I18N.error_messages.fetch_userslist_fail');
          }
          state.isLoading = false;
        }
      )
      .addCase(
        getUsersListAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.usersList = [];
          state.fetchError =
            (action && action.error && action.error.message) ||
            i18n.t('I18N.error_messages.fetch_userslist_fail');
        }
      )
      .addCase(createUserAction.pending, (state: IUserState) => {
        state.isFormLoad = true;
        state.createError = null;
        state.isCreated = false;
      })
      .addCase(
        createUserAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isFormLoad = false;
          if (action && action.payload && action.payload.status === 200) {
            state.isCreated = true;
            state.editUser = null;
          } else {
            state.isCreated = false;
            state.createError =
              (action && action.payload && action.payload.error) ||
              i18n.t('I18N.error_messages.create_user_error');
          }
        }
      )
      .addCase(
        createUserAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isCreated = false;
          state.isFormLoad = false;
          state.createError =
            (action && action.error) || i18n.t('I18N.error_messages.create_user_error');
        }
      )
      .addCase(getRolesAction.pending, (state: IUserState) => {
        state.isFetchingRoles = true;
      })
      .addCase(
        getRolesAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IRole[]>>) => {
          if (action.payload && action.payload.status === 200) {
            state.role = action.payload.data;
          }
          state.isFetchingRoles = false;
        }
      )
      .addCase(
        getApplicationsAction.fulfilled,
        (state: IUserState, action: IReducerAction<PaginatedAPIEntityResponse<IApplications>>) => {
          if (action.payload && action.payload.status === 200) {
            state.applications = action.payload.data.records;
          }
        }
      )
      .addCase(updateUserAction.pending, (state: IUserState) => {
        state.updateError = null;
        state.isUpdating = true;
        state.isUpdated = false;
        state.isFormLoad = true;
      })
      .addCase(
        updateUserAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isFormLoad = false;
          state.isUpdating = false;
          if (action.payload && action.payload.status === 200) {
            state.isUpdated = true;
            // TODO: If refresh is needed on every edit, code needs restructuring in user dialog first.
            // state.application = null;
            // state.role = null;
            state.editUser = null;
          } else {
            state.updateError =
              (action && action.payload && action.payload.error) ||
              i18n.t('I18N.error_messages.update_user_error');
            state.isUpdated = false;
          }
        }
      )
      .addCase(
        updateUserAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isUpdating = false;
          state.isFormLoad = false;
          state.updateError =
            (action && action.error) || i18n.t('I18N.error_messages.update_user_error');
          state.isUpdated = false;
        }
      )
      .addCase(getUserByIdAction.pending, (state: IUserState) => {
        state.isLoading = true;
        state.fetchUserByIdError = null;
      })
      .addCase(
        getUserByIdAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IGetUserByIDResponse>>) => {
          if (action.payload && action.payload.status === 200) {
            state.editUser = action.payload.data;
          } else {
            state.fetchUserByIdError =
              (action && action.payload && action.payload.error) ||
              i18n.t('I18N.error_messages.load_user_error');
          }
          state.isLoading = false;
        }
      )
      .addCase(
        getUserByIdAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.fetchUserByIdError =
            (action && action.error) || i18n.t('I18N.error_messages.load_user_error');
        }
      )
      .addCase(updateExpandedUserAction.fulfilled, (state: IUserState) => {
        state.expandRow = null;
      })
      .addCase(deleteUsersAction.pending, (state: IUserState) => {
        state.deleteError = null;
        state.isDeleted = false;
      })
      .addCase(
        deleteUsersAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IDeleteUsersResponse>>) => {
          if (action.payload && action.payload.status === 200) {
            state.isDeleted = true;
          } else {
            state.isDeleted = false;
            state.deleteError =
              action.payload.error || i18n.t('I18N.error_messages.delete_user_error');
          }
        }
      )
      .addCase(
        deleteUsersAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isDeleted = false;
          state.deleteError = action.error || i18n.t('I18N.error_messages.delete_user_error');
        }
      )
      .addCase(deleteUserAction.pending, (state: IUserState) => {
        state.deleteError = null;
        state.isDeleted = false;
      })
      .addCase(
        deleteUserAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IDeleteUserResponse>>) => {
          if (action && action.payload && action.payload.status === 200) {
            state.isDeleted = true;
          } else {
            state.isDeleted = false;
            state.deleteError =
              (action && action.payload && action.payload.error) ||
              i18n.t('I18N.error_messages.delete_user_error');
          }
        }
      )
      .addCase(
        deleteUserAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isDeleted = false;
          state.deleteError =
            (action && action.error) || i18n.t('I18N.error_messages.delete_user_error');
        }
      )
      .addCase(getUsersByAppIdAction.pending, (state: IUserState) => {
        state.isLoading = true;
      })
      .addCase(
        getUsersByAppIdAction.fulfilled,
        (state: IUserState, action: IReducerAction<PaginatedAPIEntityResponse<IUser>>) => {
          if (action && action.payload && action.payload.status === 200) {
            state.totalRecords =
              action && action.payload && action.payload.data && action.payload.data.totalRecords;
            state.usersList =
              action && action.payload && action.payload.data && action.payload.data.records;
          }
          state.isLoading = false;
        }
      )
      .addCase(getUsersByRoleIdAction.pending, (state: IUserState) => {
        state.isLoading = true;
      })
      .addCase(
        getUsersByRoleIdAction.fulfilled,
        (state: IUserState, action: IReducerAction<PaginatedAPIEntityResponse<IUser>>) => {
          if (action && action.payload && action.payload.status === 200) {
            state.totalRecords = action && action.payload && action.payload.data?.totalRecords;
            state.usersList = action && action.payload && action.payload.data?.records;
          }
          state.isLoading = false;
        }
      )
      .addCase(resetPasswordAction.pending, (state: IUserState) => {
        state.isLoading = true;
        state.resetPasswordError = null;
        state.isPasswordReset = false;
      })
      .addCase(
        resetPasswordAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IMessageResponse>>) => {
          if (action && action.payload && action.payload.status === 200) {
            state.isPasswordReset = true;
          } else {
            state.isPasswordReset = false;
            state.resetPasswordError =
              (action && action.payload && action.payload.error) ||
              i18n.t('I18N.error_messages.initiate_password_reset_failed');
          }
          state.isLoading = false;
        }
      )
      .addCase(
        resetPasswordAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isPasswordReset = false;
          state.resetPasswordError =
            (action && action.error) ||
            i18n.t('I18N.error_messages.initiate_password_reset_failed');
          state.isLoading = false;
        }
      )
      .addCase(toggleUserStatusAction.pending, (state: IUserState) => {
        state.isLoading = true;
        state.toggleUserStatus = true;
        state.toggleUserStatusSuccess = null;
        state.toggleUserStatusFail = null;
      })
      .addCase(
        toggleUserStatusAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IMessageResponse>>) => {
          state.isLoading = false;
          state.toggleUserStatus = false;
          if (action.payload.status === 200) {
            state.toggleUserStatusSuccess = action.payload && action.payload.data.message;
            state.toggleUserStatusFail = null;
          } else {
            state.toggleUserStatusSuccess = null;
            state.toggleUserStatusFail = action.payload && action.payload.error;
          }
        }
      )
      .addCase(
        toggleUserStatusAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.toggleUserStatus = false;
          state.toggleUserStatusSuccess = null;
          state.toggleUserStatusFail = action.error;
        }
      )
      .addCase(uploadProfilePictureAction.pending, (state: IUserState) => {
        state.profilePicUploadResponse = null;
        state.profilePicUploadError = null;
        state.profilePicUploadStatus = true;
      })
      .addCase(
        uploadProfilePictureAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IImageUploadResponse>>) => {
          state.profilePicUploadStatus = false;
          if (action.payload.status === 200) {
            state.profilePicUploadResponse = action.payload.data;
            state.profilePicUploadError = null;
          } else {
            state.profilePicUploadError = (action.payload && action.payload.message) || '';
            state.profilePicUploadResponse = null;
          }
        }
      )
      .addCase(
        uploadProfilePictureAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.profilePicUploadStatus = false;
          state.profilePicUploadResponse = null;
          state.profilePicUploadError = action.error.message || '';
        }
      )
      .addCase(initializeUserStateAction.fulfilled, (state: IUserState) => {
        state.isCreated = false;
        state.isUpdated = false;
        state.isDeleted = false;
        state.createError = null;
        state.updateError = null;
        state.deleteError = null;
        state.editUser = null;
        state.toggleUserStatus = false;
        state.toggleUserStatusFail = null;
        state.toggleUserStatusSuccess = null;
        state.profilePicUploadError = null;
        state.profilePicUploadResponse = null;
        state.profilePicUploadStatus = false;
        state.toggleVerifiedStatus = false;
        state.toggleVerifiedStatusSuccess = null;
        state.toggleVerifiedStatusError = null;
      })
      .addCase(updateUserThemeModeStateAction.pending, (state) => {
        state.updateThemeModeStateError = null;
      })
      .addCase(updateUserThemeModeStateAction.fulfilled, (state, action: any) => {
        if (action.payload.status !== 200) {
          state.updateThemeModeStateError =
            action.error || i18n.t('I18N.error_messages.update_user_theme_mode_state');
        } else {
          state.updateThemeModeStateError = null;
        }
      })
      .addCase(verifyUserAction.pending, (state: IUserState) => {
        state.isLoading = true;
        state.toggleVerifiedStatusError = null;
        state.toggleVerifiedStatus = false;
      })
      .addCase(
        verifyUserAction.fulfilled,
        (state: IUserState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isLoading = false;
          if (action.payload.status === 200) {
            state.toggleVerifiedStatus = true;
            state.toggleVerifiedStatusSuccess = i18n.t('I18N.success_messages.verified_success');
          } else {
            state.toggleVerifiedStatus = false;
            state.toggleVerifiedStatusError =
              action.payload?.message || i18n.t('I18N.error_messages.verify_user_error');
          }
        }
      )
      .addCase(
        verifyUserAction.rejected,
        (state: IUserState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.toggleVerifiedStatus = false;
          state.toggleVerifiedStatusError =
            action.error?.message || i18n.t('I18N.error_messages.verify_user_error');
        }
      )
      .addCase(
        getAllRolesAction.fulfilled,
        (state: IUserState, action: IReducerAction<PaginatedAPIEntityResponse<IRole>>) => {
          if (action.payload && action.payload.status === 200) {
            state.allRoles = action.payload.data.records; /**< Fetched roles. */
          }
        }
      )
      .addCase(updateUserLanguageSettingsModeStateAction.pending, (state) => {
        state.updateLanguageSettingsModeStateError = null;
      })
      .addCase(updateUserLanguageSettingsModeStateAction.fulfilled, (state, action: any) => {
        if (action.payload.status !== 200) {
          state.updateLanguageSettingsModeStateError =
            action.error || i18n.t('I18N.error_messages.update_user_language_settings_mode_state');
        } else {
          state.updateLanguageSettingsModeStateError = null;
        }
      })
      .addCase(getUserProfileInformationAction.pending, (state: IUserState) => {
        state.isLoadingUserProfileCardInformation = true;
      })
      .addCase(
        getUserProfileInformationAction.fulfilled,
        (state: IUserState, action: IAPIEntityResponse<IUserProfile>) => {
          state.isLoadingUserProfileCardInformation = false;
          state.userProfileCardInformation = action.payload.data;
        }
      )
      .addCase(getUserProfileInformationAction.rejected, (state: IUserState) => {
        state.isLoadingUserProfileCardInformation = false;
      });
  }
});

export const userReducer: Reducer<EntityState<IUserState> & IUserState, AnyAction> =
  userSlice.reducer;

export const userActions: any = userSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = userAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getUserState = (rootState: Record<string, any>): any => rootState[userFeatureKey];
export const selectAllUser = createSelector(getUserState, selectAll);
export const selectUserEntities = createSelector(getUserState, selectEntities);
