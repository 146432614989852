/**
* UserForm.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file UserForm.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { getAuthState, IAuthState } from '../../../Store/AuthSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { TFunction } from 'i18next';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { Dialog } from 'primereact/dialog';
import { generatePassword } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { handleCheckboxIconClick, handleHeaderCheckboxClick } from '../../../Utils/helpers';
import {
  maximumCharactersAllowedInPassword,
  minimumCharactersAllowedInPassword
} from '@abstract/abstractwebcommon-shared/constants';

/**
 * Interface for UserForm properties.
 */
interface IUserFormProperties {
  editUser: IUser /**< The user that needs to be edited. */;
  onCreateUser?: (payload: IUser) => Promise<void> /**< Handles user create button clicks.*/;
  onUpdateUser: (payload: IUser) => Promise<void> /**< Handles user update button clicks.*/;
  hideDialog?: () => void /**< Hide dialog function. */;
  deleteUsers: (payload: string[]) => void /**< To delete users. */;
  roles: IRole[] /**< Roles of the user. */;
  reloadRoles: (
    selectedApplicationIDs: number[]
  ) => Promise<void> /**< Handles the reloading of roles. */;
  applications: IApplications[] /**< Applications of the user. */;
  isLoading: boolean /**< Check if information is still loading. */;
  setIsProfilePicUpdated?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Check profile picture is updated or not. */;
  profile?: boolean /**< Check profile is present or not. */;
  isFetchingRoles?: boolean /**< Check roles is fetched or not. */;
  isImageUploadAllowed?: boolean /**< Check image upload is allowed or not. */;
  onDelete?: (isDeleted: boolean) => void /**< Handles user delete button clicks. */;
}

const UserForm = ({
  onUpdateUser,
  onCreateUser,
  deleteUsers,
  reloadRoles,
  isFetchingRoles,
  isImageUploadAllowed = false,
  ...properties
}: IUserFormProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;

  const authState: IAuthState = useSelector(getAuthState);

  const initialApplications: IApplications[] = properties.editUser?.applications || [];
  const initialRoles: any = properties.editUser?.role;
  const [generatedPassword, setGeneratedPassword] = useState<string>('');
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [isEmailNeedsVerification, setIsEmailNeedsVerification] = useState<boolean>(false);
  const [showEmailConfirmationPopup, setShowEmailConfirmationPopup] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isInputTypePassword, setIsInputTypePassword] = useState<boolean>(true);
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false);

  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const isAdmin: boolean = authState.isAdmin;

  const passwordValidation: Yup.StringSchema = Yup.string()
    .min(
      minimumCharactersAllowedInPassword,
      t('/validations.min', { field: minimumCharactersAllowedInPassword })
    )
    .max(
      maximumCharactersAllowedInPassword,
      t('/validations.max', { field: maximumCharactersAllowedInPassword })
    )
    .matches(validators.PASSWORD, t('I18N.change_password.password_regex'));
  const passwordValidationConditional: Yup.StringSchema = properties.editUser
    ? passwordValidation.notRequired()
    : passwordValidation.required(
        t('/validations.required', { field: t('I18N.change_password.password') })
      );

  useEffect(() => {
    if (
      properties.applications.length > 0 &&
      isFetchingRoles !== undefined &&
      !isFetchingRoles &&
      isAdmin
    ) {
      const values: IRole[] = formik.values.role || [];
      const options: IRole[] = properties.roles || [];

      // if values not in selected applications, filter out
      const filteredValues: IRole[] = [];
      values.forEach((eachRole: IRole) => {
        const selectedRolesIDs: number[] = options.map(
          (eachOptionRole: IRole) => eachOptionRole.id || 0
        );
        if (eachRole.id && selectedRolesIDs.indexOf(eachRole.id) !== -1) {
          filteredValues.push(eachRole);
        }
      });

      formik.setFieldValue('role', filteredValues);
    }
  }, [isFetchingRoles]);

  useEffect(() => {
    // on new user, auto generate password
    if (!properties.editUser) {
      setGeneratedPassword(generatePassword());
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: properties.editUser ? properties.editUser.firstName : '',
      lastName: properties.editUser ? properties.editUser.lastName : '',
      email: properties.editUser ? properties.editUser.email : '',
      username: properties.editUser ? properties.editUser.username : '',
      password: properties.editUser ? '' : generatedPassword,
      about: properties.editUser ? properties.editUser.about : '',
      company: properties.editUser ? properties.editUser.company : '',
      website: properties.editUser ? properties.editUser.website : '',
      location: properties.editUser ? properties.editUser.location : '',
      notes: properties.editUser ? properties.editUser.notes : '',
      applications: initialApplications,
      role: initialRoles,
      imageName: []
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(50, t('/validations.max', { field: '50' }))
        .matches(/(?!^\d+$)^.+$/, t('/validations.notOnlyNumbers'))
        .required(t('/validations.required', { field: t('I18N.user_table.firstName') })),
      lastName: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(50, t('/validations.max', { field: '50' }))
        .matches(/(?!^\d+$)^.+$/, t('/validations.notOnlyNumbers'))
        .required(t('/validations.required', { field: t('I18N.user_table.lastName') })),
      username: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(50, t('/validations.max', { field: '50' }))
        .matches(validators.USERNAME, t('/validations.alphaNumeric'))
        .required(t('/validations.required', { field: t('I18N.user_table.username') })),
      email: Yup.string()
        .email(t('/validations.valid_email'))
        .min(2, t('/validations.min', { field: '2' }))
        .max(200, t('/validations.max', { field: '200' }))
        .required(t('/validations.required', { field: t('I18N.user_table.email') })),
      password: passwordValidationConditional,
      about: Yup.string()
        .max(100, t('/validations.max', { field: '100' }))
        .nullable()
        .notRequired(),
      company: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(500, t('/validations.max', { field: '500' }))
        .nullable()
        .notRequired(),
      website: Yup.string()
        .max(500, t('/validations.max', { field: '250' }))
        .nullable()
        .notRequired(),
      location: Yup.string()
        .max(300, t('/validations.max', { field: '250' }))
        .nullable()
        .notRequired(),
      notes: Yup.string()
        .max(500, t('/validations.max', { field: '500' }))
        .nullable()
        .notRequired(),
      applications: Yup.object().nullable().notRequired(),
      role: Yup.object().nullable().notRequired(),
      imageName: Yup.object().nullable().notRequired()
    }),
    onSubmit: (data) => {
      const payload: any = {};
      formik.setStatus({ message: '' });
      Object.keys(data).forEach(async (key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }

        if (i === Object.keys(data).length - 1) {
          const keys = Object.keys(payload);
          if (keys.includes('applications')) {
            const application = payload['applications'] ?? null;
            if (application && application.length === 0) {
              payload['applications'] = null;
            }
          }
          if (keys.includes('role') && initialRoles) {
            const initialRolesIds = initialRoles.map((eachRole: any) => eachRole.id).sort();
            const updatedRolesIds = payload['role'].map((eachRole: any) => eachRole.id).sort();
            if (JSON.stringify(initialRolesIds) === JSON.stringify(updatedRolesIds)) {
              delete payload['role'];
            }
          }
          if (uploadedFile !== null) {
            if (isImageDelete) {
              payload['imageName'] = '';
            } else {
              payload['imageName'] = uploadedFile;
            }
            setUploadedFile(null);
          }
          if (
            properties.editUser !== null &&
            payload['imageName'] === properties.editUser.imageName
          ) {
            delete payload['imageName'];
          }

          if (properties && properties.editUser && properties.editUser.userUUID) {
            payload['userUUID'] = properties.editUser.userUUID;
            if (Object.keys(payload).length > 1) {
              payload.verify = isEmailNeedsVerification;
              payload.applications && data.applications;
              if (!properties.profile) {
                payload.applications = data.applications;
              }

              onUpdateUser(payload);
              if (isEmailNeedsVerification) {
                formik.values.email = formik.initialValues.email;
              }
            }
          } else {
            payload['password'] = data['password'];
            onCreateUser(payload);
          }
        }
      });
    },
    validateOnMount: false,
    validateOnChange: true
  });

  /// Calls confirmationpopup on delete Button click
  const deleteBtnClick = (event: any): void => {
    setShowConfirmPopup(true);
    setConfirmPopupTarget(event?.target);
  };

  /// Calls deleteUsers on accept
  const onAccept = (): void => {
    setShowConfirmPopup(false);
    deleteUsers([properties?.editUser?.userUUID]);
  };

  /// Close confirmPopup on reject
  const onReject = (): void => {
    setShowConfirmPopup(false);
  };
  /// Update application in Formik on selection
  const setApplicationValue = (event: any): void => {
    const mappedApplicationIDs: number[] = event.value.map(
      (eachApplication: IApplications) => eachApplication.id
    );
    if (mappedApplicationIDs.length > 0) {
      reloadRoles(mappedApplicationIDs);
    } else {
      formik.setFieldValue('role', []);
    }
    formik.setFieldValue('applications', event.value);
  };

  /// Update role in Formik on role selection
  const setRoleValue = (event: any): void => {
    formik.setFieldValue('role', event.value);
  };

  /// Get the application auto complete
  const getApplicationDiv = (): JSX.Element => {
    let values: IApplications[] = formik.values.applications || [];
    let options: IApplications[] = properties.applications || [];

    values = values.map((eachApplication: IApplications) => {
      return {
        id: eachApplication.id,
        applicationUUID: eachApplication.applicationUUID,
        applicationName: eachApplication.applicationName
      };
    });
    options = options.map((eachApplication: IApplications) => {
      return {
        id: eachApplication.id,
        applicationUUID: eachApplication.applicationUUID,
        applicationName: eachApplication.applicationName
      };
    });

    return (
      <MultiSelect
        optionLabel="applicationName"
        name="applications"
        dataKey="id"
        inputId="applications"
        value={values}
        options={isAdmin ? options : values} // If the user is an admin, show all applications as options, otherwise only show user applications as options
        placeholder={t('/admin/application-management.apps_fieldset.dropdown.placeholder')}
        onChange={setApplicationValue}
        className={
          formik.touched.applications && formik.errors.applications
            ? 'p-invalid multi-select'
            : 'multi-select'
        }
        onBlur={formik.handleBlur}
        disabled={!isAdmin}
        pt={{
          checkboxIcon: {
            onClick: (event: any) => handleCheckboxIconClick(event)
          },
          headerCheckbox: {
            onClick: (event: any) => handleHeaderCheckboxClick(event)
          }
        }}
      />
    );
  };

  /// Gets the role drop down
  const getRoleDropDown = (): JSX.Element | null => {
    if (!isAdmin) {
      return null;
    }

    let values: IRole[] = formik.values.role || [];
    let options: IRole[] = properties.roles || [];

    // clean options and values
    // primereact/MultiSelect will fail / show null values if the options & values contain a isDeleted subobject property.
    values = values.map((eachRole: IRole) => {
      return {
        id: eachRole.id,
        roleUUID: eachRole.roleUUID,
        name: eachRole.name
      };
    });
    options = options.map((eachRole: any) => {
      return {
        id: eachRole.id,
        roleUUID: eachRole.roleUUID,
        name: eachRole.name
      };
    });
    //clean up the values on change. This way we can avoid the null options and all.
    const selectedValue: IRole[] = values.filter((eachRole: IRole) =>
      options.find((option: IRole) => option.id === eachRole.id)
    );

    return (
      <Col xs={6}>
        <label htmlFor="role">{t('/admin.users_fieldset.datatable.columns.role')}</label>
        <MultiSelect
          optionLabel="name"
          name="role"
          dataKey="roleUUID"
          inputId="role"
          value={selectedValue}
          options={options}
          placeholder={t('/admin/role-management.roles_fieldset.dropdown.placeholder')}
          onChange={setRoleValue}
          className={formik.touched.role && formik.errors.role ? 'p-invalid' : ''}
          onBlur={formik.handleBlur}
          pt={{
            checkboxIcon: {
              onClick: (event: any) => handleCheckboxIconClick(event)
            },
            headerCheckbox: {
              onClick: (event: any) => handleHeaderCheckboxClick(event)
            }
          }}
        />
        {formik.touched.role && formik.errors.role ? (
          <small id="email-invalid" className="p-invalid">
            {formik.errors.role}
          </small>
        ) : null}
      </Col>
    );
  };

  /// Combines role and application into a common div.
  const getApplicationAndRole = (): JSX.Element => {
    return (
      <Row>
        {isAdmin && (
          <Col xs={!isAdmin ? 12 : 6}>
            <label htmlFor="applications">
              {t('/admin.users_fieldset.datatable.columns.application')}
            </label>
            {getApplicationDiv()}
          </Col>
        )}
        {getRoleDropDown()}
      </Row>
    );
  };

  /// Triggers on profile pic upload success. Sets the imageName to display image
  const onUpload = async (files: any[]): Promise<void> => {
    const file = files ? files[0] : null;
    setIsFileChanged(true);
    setUploadedFile(file);
    setIsImageDelete(false);
    // dispatch(uploadProfilePictureAction(file));
  };

  /// Dispatches deleteAPI to delete the profile picture
  const clearProfileImage = (): void => {
    setIsImageDelete(true);
    setUploadedFile({});
  };

  const errorHandler = (error: any): void => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: t('I18N.settings.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  const isSameUser: boolean =
    properties &&
    properties.editUser &&
    LocalStorage.getXUserUUID() === properties.editUser.userUUID;

  /// Gets the fileUpload component.
  const getFileUpload = (): JSX.Element => {
    return (
      <InstaImageUpload
        showLegend={false}
        imageUrl={properties?.editUser?.imageUrl || ''}
        showDelete={true}
        deleteHandler={clearProfileImage}
        imgContainerClass={'px-3'}
        onChange={onUpload}
        croppedImage={croppedImage}
        showUploadBtn={false}
        errorHandler={errorHandler}
        imgClass={'imageUrl rounded'}
        altText={t('I18N.settings.logo_alt')}
        isPlainBtn={true}
        plainBtnLabel={t('I18N.user_profile.choose_profile_pic')}
      />
    );
  };

  // toggles password visibility
  const toggleViewPassword = (): void => {
    setIsInputTypePassword(!isInputTypePassword);
  };

  const handleImageCropComplete = (image: any): void => {
    setCroppedImage(image);
    setUploadedFile(image);
  };

  /// Renders the email confirmation dialog Footer
  const renderEmailConfirmationDialogFooter = (): JSX.Element => {
    return (
      <div className="d-flex justify-content-end">
        {!properties.profile && !isSameUser && (
          <Button
            onClick={() => {
              setIsEmailNeedsVerification(false);
              formik.handleSubmit();
              setShowEmailConfirmationPopup(false);
            }}
            autoFocus
            variant="warning"
            className="d-flex align-items-center">
            <i className="bi bi-check2-circle btn-icon"></i>
            {t('/admin.users_fieldset.edit_user_dialog.emailConfirmationNo')}
          </Button>
        )}
        <Button
          onClick={() => {
            setIsEmailNeedsVerification(true);
            formik.handleSubmit();
            setShowEmailConfirmationPopup(false);
          }}
          autoFocus
          className="d-flex align-items-center">
          <i className="bi bi-check2-circle btn-icon"></i>
          {t('/admin.users_fieldset.edit_user_dialog.emailConfirmationYes')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <FormWrapper
        onSubmit={formik.handleSubmit}
        controlButtonLabel={properties.editUser && properties.editUser.id && !properties.isLoading}
        isLoading={properties.isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) => deleteBtnClick(event)}
        handleSubmitButton={() =>
          authState.isAdmin
            ? formik.handleSubmit()
            : properties.editUser &&
              properties.editUser.email &&
              properties.editUser.email !== formik.values.email
            ? setShowEmailConfirmationPopup(true)
            : formik.handleSubmit()
        }>
        {!isImageUploadAllowed && (
          <Col xs={12} className="bg-dark text-center px-4 py-2 mb-3 primary-border-radius">
            {getFileUpload()}
          </Col>
        )}
        <Row>
          <Col xs={12} sm={6}>
            <label htmlFor="firstName" className="required">
              {t('/admin.users_fieldset.datatable.columns.firstName')}
            </label>
            <InputText
              id="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              className={formik.touched.firstName && formik.errors.firstName ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors.firstName}
              </small>
            ) : null}
          </Col>
          <Col xs={12} sm={6}>
            <label htmlFor="lastName" className="required">
              {t('/admin.users_fieldset.datatable.columns.lastName')}
            </label>
            <InputText
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.lastName && formik.errors.lastName ? 'p-invalid' : ''}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors.lastName}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <label htmlFor="username" className="required">
              {t('/admin.users_fieldset.datatable.columns.username')}
            </label>
            <InputText
              id="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.username && formik.errors.username ? 'p-invalid' : ''}
            />
            {formik.touched.username && formik.errors.username ? (
              <small id="username-invalid" className="p-invalid">
                {formik.errors.username}
              </small>
            ) : null}
          </Col>
          <Col xs={12} sm={6}>
            <label htmlFor="email" className="required">
              {t('/admin.users_fieldset.datatable.columns.email')}
            </label>
            <InputText
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.email && formik.errors.email ? 'p-invalid' : ''}
            />
            {formik.touched.email && formik.errors.email ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors.email}
              </small>
            ) : null}
          </Col>
        </Row>
        {getApplicationAndRole()}
        {!properties.profile && (
          <Row>
            <Col xs={12}>
              <label htmlFor="password">
                {t('/admin.users_fieldset.datatable.columns.password')}
              </label>
              <span className="p-input-icon-right w-100">
                <InputText
                  type={isInputTypePassword ? 'password' : 'text'}
                  autoComplete="new-password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.company && formik.errors.company ? 'p-invalid' : ''}
                />
                <i
                  className={isInputTypePassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                  style={{ cursor: 'pointer' }}
                  onClick={toggleViewPassword}></i>
              </span>
              {formik.touched.password && formik.errors.password ? (
                <small id="password-invalid" className="p-invalid">
                  {formik.errors.password}
                </small>
              ) : null}
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} sm={6}>
            <label htmlFor="company">{t('/admin.users_fieldset.datatable.columns.company')}</label>
            <InputText
              id="company"
              value={formik.values.company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.company && formik.errors.company ? 'p-invalid' : ''}
            />
            {formik.touched.company && formik.errors.company ? (
              <small id="company-invalid" className="p-invalid">
                {formik.errors.company}
              </small>
            ) : null}
          </Col>
          <Col xs={12} sm={6}>
            <label htmlFor="location">
              {t('/admin.users_fieldset.datatable.columns.location')}
            </label>
            <InputText
              id="location"
              placeholder={t('/admin.users_fieldset.datatable.columns.location_placeholder')}
              value={formik.values.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.location && formik.errors.location ? 'p-invalid' : ''}
            />
            {formik.touched.location && formik.errors.location ? (
              <small id="location-invalid" className="p-invalid">
                {formik.errors.location}
              </small>
            ) : null}
          </Col>
          <Col xs={12}>
            <label htmlFor="website">{t('/admin.users_fieldset.datatable.columns.website')}</label>
            <InputText
              id="website"
              value={formik.values.website}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.website && formik.errors.website ? 'p-invalid' : ''}
            />
            {formik.touched.website && formik.errors.website ? (
              <small id="website-invalid" className="p-invalid">
                {formik.errors.website}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="about">{t('/admin.users_fieldset.datatable.columns.about')}</label>
            <InputTextarea
              id="about"
              value={formik.values.about}
              onChange={formik.handleChange}
              className={formik.touched.about && formik.errors.about ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
              autoResize
            />
            {formik.touched.about && formik.errors.about ? (
              <small id="about-invalid" className="p-invalid">
                {formik.errors.about}
              </small>
            ) : null}
          </Col>
        </Row>

        {isAdmin && (
          <Row>
            <Col xs={12}>
              <label htmlFor="notes">{t('/admin.users_fieldset.datatable.columns.notes')}</label>
              <InputTextarea
                id="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                className={formik.touched.notes && formik.errors.notes ? 'p-invalid' : ''}
                onBlur={formik.handleBlur}
                autoResize
              />
              {formik.touched.notes && formik.errors.notes ? (
                <small id="notes-invalid" className="p-invalid">
                  {formik.errors.notes}
                </small>
              ) : null}
            </Col>
          </Row>
        )}
      </FormWrapper>
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmPopup}
        title={
          properties.profile
            ? t('/confirm_messages.delete_account')
            : t('/confirm_messages.delete_record')
        }
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
      <Dialog
        header={t('/admin.users_fieldset.edit_user_dialog.emailConfirmationHeader')}
        visible={showEmailConfirmationPopup && !authState.isAdmin}
        footer={renderEmailConfirmationDialogFooter()}
        onHide={() => setShowEmailConfirmationPopup(false)}
        className="custom-dialog-container"
        draggable={false}>
        {t('/admin.users_fieldset.edit_user_dialog.emailConfirmationMessage')}
      </Dialog>

      <CropDialog
        isVisible={isFileChanged}
        setVisible={setIsFileChanged}
        file={uploadedFile}
        onImageCropComplete={handleImageCropComplete}
        isCircular={true}
        isCropOptionsVisible={false}
      />
    </>
  );
};

export default UserForm;
