/*
 * UserTokenTable.tsx (InstaLOD GmbH)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file UserTokenTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import {
  IPageEvent,
  IPaginationResponse,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IUserTokenTransaction } from '@abstract/abstractwebcommon-shared/interfaces/user/UserTokenTransaction';
import { Column } from 'primereact/column';
import React, { useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import UserTokenTransactionForm from './UserTokenTransactionForm';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * @interface IUserTokenTransactionTableProperties
 */
interface IUserTokenTransactionTableProperties {
  onSort: (payload: ITablePayload) => void /**< Handle sort update. */;
  onPage: (event: IPageEvent) => void /**< Handle page update. */;
  onFilter: (searchTerm: string) => void /**< Handle filter update. */;
  tablePayload: ITablePayload /**  Table payload. */;
  userTokenList: IPaginationResponse<IUserTokenTransaction> | null /**< UserToken list. */;
  isLoading: boolean /**< True if loading and false otherwise.*/;
  handleAddOrEditUserToken: (
    payload: Partial<IUserTokenTransaction>,
    userTokenUUID?: string
  ) => void /**< Add userToken. */;
  isDialogVisible: boolean /***< Dialog visibility */;
  setDialogVisible: React.Dispatch<React.SetStateAction<boolean>> /**< To set dialog visibility */;
}

const UserTokenTransactionTable = (
  properties: IUserTokenTransactionTableProperties
): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const [expandedRows, setExpandedRows] = useState<Partial<IUserTokenTransaction>>({});
  const [editUserToken, setEditUserToken] = useState<Partial<IUserTokenTransaction>>({});
  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tablePayload.
  properties.tablePayload?.sort &&
    Object.keys(properties.tablePayload?.sort).forEach((key) => {
      defaultSortField = key; // Sortfield
      defaultSortOrder = properties.tablePayload.sort[key]; //Sortorder
    });
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.uuid]: true });
    }
  };

  /**
   * Table header element
   */
  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center row-direction wrap-header">
      <div className="headerTableContainer margin-bottom-on-wrap">
        <ActionButton onClick={() => properties.setDialogVisible(true)} /> {/* To Add userToken. */}
      </div>
      <div className="d-flex">
        <SearchBar onSearchTermChanged={(data: string) => properties.onFilter(data)} />
      </div>
    </div>
  );

  // Returns the userToken dialog
  const getUserTokenDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={properties.isDialogVisible}
        onHide={() => {
          setEditUserToken({});
          properties.setDialogVisible(false);
        }}
        headerTitle={
          editUserToken.id
            ? translation('/admin/userToken-management.edit_header')
            : translation('/admin/userToken-management.add_header')
        }>
        <UserTokenTransactionForm
          handleSubmit={properties.handleAddOrEditUserToken}
          isLoading={properties.isLoading}
          editUserToken={editUserToken}
        />
      </DialogWrapper>
    );
  };

  /**
   * Template for row expansion
   * @param values IUserTokenTransaction
   * @returns JSX.Element
   */
  const UserTokenRowExpansionTemplate = (values: {
    rowData: IUserTokenTransaction;
  }): JSX.Element => (
    <>
      <tr>
        <th>{translation('/admin/userToken-management.datatable.columns.user')}</th>
        <td>{values.rowData.user ? values.rowData.user.username : ''}</td>
      </tr>
      <tr>
        <th>{translation('/admin/userToken-management.datatable.columns.description')}</th>
        <td>{values.rowData.description}</td>
      </tr>
      <tr>
        <th>{translation('/admin/userToken-management.datatable.columns.amount')}</th>
        {/* Amount is in decimal format, let's only display the integer value. */}
        <td>{String(values.rowData.amount).slice(0, -3)}</td>
      </tr>
      <tr>
        <th>{translation('/admin/userToken-management.datatable.columns.application')}</th>
        <td>{values.rowData.application ? values.rowData.application.applicationName : ''}</td>
      </tr>
      <tr>
        <th>{translation('/admin/userToken-management.datatable.columns.creationDate')}</th>
        <td>{values.rowData.creationDate ? formatDate(values.rowData.creationDate) : ''}</td>
      </tr>
      <tr>
        <th>{translation('/admin/userToken-management.datatable.columns.updateDate')}</th>
        <td>{values.rowData.updateDate ? formatDate(values.rowData.updateDate) : ''}</td>
      </tr>
    </>
  );

  /**
   * To render expansion rows
   * @param rowData IUserTokenTransaction
   * @returns JSX.Element
   */
  const renderExpansionRows = (rowData: IUserTokenTransaction): JSX.Element => (
    <>
      <ExpansionRow>
        <UserTokenRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <UserTokenRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /**
   * Handler for sort update
   * @param event ISortEvent
   */
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = properties.tablePayload; /**< Updated Payload. */
    updatedPayload.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedPayload.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }
    setMultiSortMeta(event.multiSortMeta);
    properties.onSort(updatedPayload);
  };

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={properties?.userTokenList?.records}
        totalRecords={properties?.userTokenList?.totalRecords}
        isLoading={!properties?.userTokenList?.records}
        header={header}
        parentClass="userTokenDataTable"
        first={properties.tablePayload.skip}
        emptyMessage={translation('/admin/userToken-management.datatable.empty_msg')}
        rows={properties.tablePayload.limit}
        onPage={properties.onPage}
        onSort={handleSortUpdate}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        onFilter={properties.onFilter}
        rowExpansionTemplate={renderExpansionRows}
        bodyStyle={'text-center'}
        responsive
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        expandedRows={expandedRows}
        dataKey="uuid">
        <Column expander className="p-0 col-width-3" headerClassName="p-0 col-width-3" />
        <Column selectionMode="multiple" className="d-none" />
        <Column
          sortable
          field="username"
          body={(rowData: IUserTokenTransaction) => (
            <DatatableColumn
              title={translation('/admin/userToken-management.datatable.columns.user')}
              data={rowData.user ? rowData.user.username : ''}
            />
          )}
          header={translation('/admin/userToken-management.datatable.columns.user')}
        />
        <Column
          sortable
          field="description"
          className="d-table-cell d-sm-none d-md-table-cell"
          headerClassName="d-table-cell d-sm-none d-md-table-cell"
          body={(rowData: IUserTokenTransaction) => (
            <DatatableColumn
              title={translation('/admin/userToken-management.datatable.columns.description')}
              data={rowData.description}
            />
          )}
          header={translation('/admin/userToken-management.datatable.columns.description')}
        />
        <Column
          sortable
          field="amount"
          body={(rowData: IUserTokenTransaction) => (
            <DatatableColumn
              title={translation('/admin/userToken-management.datatable.columns.amount')}
              data={String(rowData.amount).slice(0, -3)} // Amount is in decimal format, let's only display the integer value.
            />
          )}
          header={translation('/admin/userToken-management.datatable.columns.amount')}
        />
        <Column
          sortable
          field="applicationName"
          body={(rowData: IUserTokenTransaction) => (
            <DatatableColumn
              title={translation('/admin/userToken-management.datatable.columns.application')}
              data={rowData.application ? rowData.application.applicationName : ''}
            />
          )}
          header={translation('/admin/userToken-management.datatable.columns.application')}
        />
        <Column
          field="creationDate"
          header={translation('/admin/userToken-management.datatable.columns.creationDate')}
          sortable
          className="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
          body={(rowData: IUserTokenTransaction) => (
            <DatatableColumn
              title={translation('/admin/userToken-management.datatable.columns.creationDate')}
              data={rowData.creationDate ? formatDate(rowData.creationDate) : ''}
            />
          )}
        />
        <Column
          field="updateDate"
          header={translation('/admin/userToken-management.datatable.columns.updateDate')}
          sortable
          className="d-table-cell d-sm-none d-xxl-table-cell updatedDateCol"
          headerClassName="d-table-cell d-sm-none d-xxl-table-cell updatedDateCol"
          body={(rowData: IUserTokenTransaction) => (
            <DatatableColumn
              title={translation('/admin/userToken-management.datatable.columns.updateDate')}
              data={rowData.updateDate ? formatDate(rowData.updateDate) : ''}
            />
          )}
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getUserTokenDialog()}
    </>
  );
};

export default UserTokenTransactionTable;
