/*
 * ProfileHeader.tsx (AbstractUser)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2022
 *
 * @file ProfileHeader.tsx
 * @author Timothy Fadayini
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import './ProfileHeader.css';
import { Col, Row } from 'react-bootstrap';
import ImagePreview from '../js/IdentityCard/ImagePreview';
import '../cssLoader.css';
import ProfileHeaderSkeleton from '../SkeletonLoader/ProfileHeaderSkeleton/ProfileHeaderSkeleton';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

/**
 * @interface IProfileHeaderProperties
 */
export interface IProfileHeaderProperties {
  firstName: string; /**< user firstname */
  lastName: string;  /**< user lastname */
  profilePictureURL: string | null;  /**< user profile image url */
  isLoading: boolean;  /**< Boolean for when the information is still loading */
  onProfileChange: () => void;  /**< Function to handle when profile image change is initiated/clicked */
}

/**
 * Renders the profile picture using the image preview component or the intials of the firstname and lastname
 * 
 * @param profilePictureURL The user's profile picture URL
 * @param firstName The user's firstname
 * @param lastName The user's lastname
 * @returns JSX.Element
 */
const renderProfilePicture = (profilePictureURL: string | null, firstName: string, lastName: string): JSX.Element => {
  if (profilePictureURL) {
    return (
      <ImagePreview
        showDelete={false}
        imageUrl={profilePictureURL}
        isLogo={true}
        altText={''}
        imgClass={'profile'}
      />
    );
  } else {
    return (
      <div
        data-initials={`${firstName.charAt(0)}${lastName.charAt(
          0
        )}`}
      ></div>
    );
  }
};

/**
 * Renders the profile header component
 */
const ProfileHeader = ({
  firstName, /**< user firstname */
  lastName, /**< user lastname */
  profilePictureURL, /**< user profile image url */
  isLoading, /**< Boolean for when the information is still loading */
  onProfileChange /**< Function to handle when profile image change is initiated/clicked */
}: IProfileHeaderProperties) => {
  const translation: TFunction = useTranslation().t;

  if (isLoading) {
    return <ProfileHeaderSkeleton />;
  }
  
  return (
    <div className="example-warper">
      <div className="card profile-card profile-card-dark-mode border-0 mb-2">
          {firstName && lastName && (
            <Row className="flex-nowrap align-items-center mb-2">
              <div className="ml-3 mr-3">{renderProfilePicture(profilePictureURL, firstName, lastName)}</div>
              <div className="my-auto">
                <h3 className="card-title mb-0 ">
                  {firstName} {lastName}
                </h3>
                <span className="image-change-text font-weight-light">
                  <a href="#" onClick={onProfileChange}>{translation('I18N.user_profile.profile_header')}</a>
                </span>
              </div>
            </Row>
          )}
      </div>
    </div>
  );
};

export default ProfileHeader;
