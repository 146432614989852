/**
* PasswordComponent.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2022 
* @file PasswordComponent.tsx
* @author Timothy Fadayini
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import ChangePassword, { IPasswordFormValues } from '../Shared/ChangePassword';
import { Card } from 'primereact/card';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

/**
 * @interface IProfilePasswordProperties
 */
export interface IProfilePasswordProperties {
  isLoading: boolean /**< check if information is still loading */;
  handleSubmitPassword: (
    data: IPasswordFormValues | any
  ) => void /**< handles password form submission */;
}
export const PasswordComponent = ({
  isLoading,
  handleSubmitPassword
}: IProfilePasswordProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;

  return (
    <Card className="profile-home-cards" title={t('I18N.profile.update_password')}>
      <ChangePassword handleSubmitPassword={handleSubmitPassword} isLoading={isLoading} />
    </Card>
  );
};

export default PasswordComponent;
