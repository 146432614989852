/*
 * VerifyAccount.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file VerifyAccount.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

interface IVerifyAccountProperties {
  onCancel: () => void;
  onVerify: (code: any) => void;
}

const VerifyAccount = ({
  onCancel,
  onVerify,
}: IVerifyAccountProperties): ReactElement => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {},
    onSubmit: (data) => {
      onVerify(data);
    },
  });

  return (
    <>
      <Col xs={12} className="p-0">
        <p className="custom-margin"> {t("awc:/.forgot_password.verify.header")}</p>
        <p className="p-0 text-break custom-margin">{t("awc:/.forgot_password.verify.subtitle")}</p>
      </Col>
      <form onSubmit={formik.handleSubmit}>
      <Col xs={12} className="p-0 justify-content-start">
        <Button onClick={onCancel} className="d-flex align-items-center action-buttons-container justify-content-center button-without-icon" type="button">
          {t("awc:/.forgot_password.button.sign-in")}
      </Button>
      </Col>
      </form>
    </>
  );
};

export default VerifyAccount;
