/**
* Config.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file Config.ts
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

export const BASE_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/user-backend';
export const CURRENT_VERSION = process.env.REACT_APP_CURRENT_VERSION;
export const CURRENT_AWC_VERSION = process.env.REACT_APP_CURRENT_AWC_VERSION;
