/**
* TemplateApi.ts (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022 
* @file TemplateApi.ts
* @author Etienne Daher
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ITemplates } from '@abstract/abstractwebcommon-shared/interfaces/user/templates';
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

export const getAllTemplatesApi = (): Promise<IAPIEntityResponse<ITemplates[]>> => {
  return httpClient.get<ITemplates[]>(`/templates/all`);
};

/**
 * Service to get all public template
 * @returns Promise
 */
export const getAllPublicTemplatesApi = (): Promise<IAPIEntityResponse<ITemplates[]>> => {
  return httpClient.get<ITemplates[]>(`/templates/public`, {}, false);
};

/**
 * Service to update template
 * @returns Promise
 */
export const getAllCSSTemplatesApi = (): Promise<IAPIEntityResponse<ITemplates[]>> => {
  return httpClient.get<ITemplates[]>(`/templates/css/all`);
};

/**
 * Service to update template
 * @param templateUUID templateUUID of the template
 * @param template
 * @returns Promise
 */
export const updateTemplateApi = (
  templateUUID: string,
  template: Record<string, any>
): Promise<IAPIEntityResponse<ITemplates>> => {
  return httpClient.post<ITemplates>(`/template/${templateUUID}/update`, template);
};

/**
 * Service to fetch css template
 * @param templateUUID templateUUID of the template to be fetched
 * @returns Promise
 */
export const getCSSApi = async (templateUUID: string): Promise<IAPIEntityResponse<string>> => {
  return httpClient.get<string>(`/template/${templateUUID}/css`);
};

/**
 * Service to update css
 * @param templateUUID templateUUID of the template
 * @param template CSS string to update
 * @returns Promise
 */
export const updateCSSApi = async (
  templateUUID: string,
  template: string
): Promise<IAPIEntityResponse<ITemplates>> => {
  return httpClient.post<ITemplates>(`/template/${templateUUID}/css/update`, { css: template });
};

/**
 * loadCssIntoHead
 * @param url URL to load CSS in
 * @param linkUUID linkUUID of the link
 * @returns void
 */
export const loadCSS = (url: string, linkUUID: string): void => {
  //Note: Remove current CSS link to avoid stacking unnecessary stylesheets and overrides.
  const cssLinkToRemove = document.getElementById(linkUUID);
  if (cssLinkToRemove) {
    cssLinkToRemove.remove();
  }

  const link: HTMLLinkElement = document.createElement('link');

  if (linkUUID !== '') link.id = linkUUID;
  link.rel = 'stylesheet';
  link.href = url;
  document.head.appendChild(link);
};

/**
 * Service to fetch CSS template
 * @param templateUUID templateUUID of the template
 * @returns Promise
 */
export const fetchOneCSSApi = async (templateUUID: string): Promise<string> => {
  return httpClient.get<string>(`/template/${templateUUID}/css`);
};
