/*
 * UserTokenSlice.tsx (InstaLOD GmbH)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file UserTokenSlice.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  IAPIEntityResponse,
  IAPIErrorData
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IReducerAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import { IUserTokenTransaction } from '@abstract/abstractwebcommon-shared/interfaces/user/UserTokenTransaction';
import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { Reducer } from 'react';
import i18n from '../Services/I18n';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { UserTokenAPI } from '../Services/UserTokenApi';
import {
  IPaginationResponse,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';

export const userTokenTransactionFeatureKey: string = 'userTokenTransaction';

/**
 * @interface IUserTokenTransactionState
 */
export interface IUserTokenTransactionState {
  isCreated: boolean /**< Is created or not */;
  createError: any /**< Creation error */;
  isLoading: boolean /**< Loading state */;
  fetchError: any /**< Fetch error */;
  userTokenList: IPaginationResponse<IUserTokenTransaction> | null /**< UserToken list */;
}

export const userTokenInitialState: IUserTokenTransactionState = {
  isCreated: false,
  createError: null,
  isLoading: false,
  fetchError: null,
  userTokenList: {
    totalRecords: 0,
    records: null,
    skip: 0,
    limit: defaultTableLimit
  }
};

export const userTokenAdapter: EntityAdapter<IUserTokenTransactionState> = createEntityAdapter();
export const initialUserTokenState: EntityState<IUserTokenTransactionState> &
  IUserTokenTransactionState = userTokenAdapter.getInitialState(userTokenInitialState);

/**
 * Create user token
 */
export const createUserTokenTransactionAction = createAsyncThunk(
  'userToken/create',
  async (
    {
      createUserTokenPayload,
      getUserTokenListPayload
    }: {
      createUserTokenPayload: Partial<IUserTokenTransaction>;
      getUserTokenListPayload: ITablePayload;
    },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;
    const {
      createUserTokenActionRequest,
      createUserTokenActionSuccess,
      createUserTokenActionFailure
    } = userTokenActions;

    try {
      dispatch(createUserTokenActionRequest());
      const result: IAPIEntityResponse<IUserTokenTransaction> = await asyncErrorHandler(
        UserTokenAPI.create(createUserTokenPayload)
      );
      if (result.error) {
        dispatch(createUserTokenActionFailure(result.error));
      } else {
        dispatch(createUserTokenActionSuccess());
        dispatch(getUserTokenTransactionListAction(getUserTokenListPayload));
      }
    } catch (exception: any) {
      dispatch(createUserTokenActionFailure(exception.message));
    }
  }
);

/**
 * Get user token list
 */
export const getUserTokenTransactionListAction = createAsyncThunk(
  'userTokens/get',
  async (payload: ITablePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getUserTokenListActionRequest,
      getUserTokenListActionSuccess,
      getUserTokenListActionFailure
    } = userTokenActions;
    try {
      dispatch(getUserTokenListActionRequest());
      const result: IAPIEntityResponse<IUserTokenTransaction[]> = await asyncErrorHandler(
        UserTokenAPI.getAllUserToken(payload)
      );
      if (result.error) {
        dispatch(getUserTokenListActionFailure(result.error));
      } else {
        dispatch(getUserTokenListActionSuccess(result.data));
      }
    } catch (exception: any) {
      dispatch(getUserTokenListActionFailure(exception.message));
    }
  }
);

const clearErrors = (state: IUserTokenTransactionState) => {
  state.isCreated = false;
  state.fetchError = null;
  state.createError = null;
};

/**
 * Creates Slice - All UserToken related state will be stored here
 */
export const userTokenSlice = createSlice({
  name: userTokenTransactionFeatureKey,
  initialState: initialUserTokenState,
  reducers: {
    reset: (state: IUserTokenTransactionState) => clearErrors(state),
    createUserTokenActionRequest(state: IUserTokenTransactionState) {
      state.isLoading = false;
      state.isCreated = false;
      state.createError = null;
    },
    createUserTokenActionSuccess(state: IUserTokenTransactionState) {
      state.isLoading = false;
      state.isCreated = true;
      state.createError = null;
    },
    createUserTokenActionFailure(
      state: IUserTokenTransactionState,
      action: IReducerAction<string>
    ) {
      state.isLoading = false;
      state.createError = action.payload || i18n.t('I18N.error_messages.create_userToken_failure');
    },
    getUserTokenListActionRequest(state: IUserTokenTransactionState) {
      state.isLoading = true;
      state.fetchError = null;
    },
    getUserTokenListActionSuccess(
      state: IUserTokenTransactionState,
      action: PaginationResponseAction<IUserTokenTransaction>
    ) {
      state.isLoading = false;
      state.userTokenList = action.payload as IPaginationResponse<IUserTokenTransaction>;
      state.fetchError = null;
    },
    getUserTokenListActionFailure(
      state: IUserTokenTransactionState,
      action: IReducerAction<IAPIErrorData>
    ) {
      state.isLoading = false;
      state.fetchError =
        action.payload.message || i18n.t('I18N.error_messages.fetch_userToken_failure');
      state.userTokenList = {};
    }
  }
});

export const userTokenTransactionReducer: Reducer<
  EntityState<IUserTokenTransactionState> & IUserTokenTransactionState,
  AnyAction
> = userTokenSlice.reducer;
export const userTokenActions: any = userTokenSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = userTokenAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getUserTokenState: any = (rootState: any) => rootState[userTokenTransactionFeatureKey];
export const selectAllUserToken: any = createSelector(getUserTokenState, selectAll);
export const selectUserTokenEntities: any = createSelector(getUserTokenState, selectEntities);
