/**
* SMTPSettingsForm.tsx (abstractuser) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2021
* @file SMTPSettingsForm.tsx
* @author Etienne Daher
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { withFormik, FormikProps, FormikBag } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import SharedSettingsSMTPForm from '@abstract/abstractwebcommon-client/SettingsPage/SettingsSMTPForm';
import { DatabaseType } from '@abstract/abstractwebcommon-shared/constants/connectionDatabaseType';
import { CheckboxProps } from 'primereact/checkbox';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { InputText } from 'primereact/inputtext';

interface ISMTPSettingsProperties {
  settings?: any;
  translation?: any;
  isLoadingTest?: boolean;
  isLoading?: boolean;
  showConfirmation?: boolean;
  isFormValid?: boolean;
  isAnyUpdatesExist?: boolean | string;
  bindForm: (mode: string, values: any, errors: any) => void;
  revalidateErrors: (mode: string) => void;
  revalidateUpdates: (updates: any) => void;
  handleTestSMTP: (values: any) => Promise<void>;
  saveButtonClick?: (event: any) => void;
}

interface ISMTPSettingFormValues {
  smtpHost?: string;
  smtpPort?: string;
  smtpSecure?: boolean;
  smtpUser?: string;
  smtpPassword?: string;
  fromEmail?: string;
  fromName?: string;
}

interface ISMTPSettingsInitialFormProperties {
  initialSmtpHost?: string;
  initialSmtpPort?: string;
  initialSmtpSecure?: boolean;
  initialSmtpUser?: string;
  initialSmtpPassword?: string;
  initialFromEmail?: string;
  initialFromName?: string;
  translation?: any;
  settings?: any;
  isLoadingTest?: boolean;
  isLoading?: boolean;
  showConfirmation?: boolean;
  isFormValid?: boolean;
  isAnyUpdatesExist?: boolean | string;
  bindForm: (mode: string, values: any, errors: any) => void;
  revalidateErrors: (mode: string) => void;
  revalidateUpdates: (updates: any) => void;
  handleTestSMTP: (values: any) => Promise<void>;
  saveButtonClick?: (event: any) => void;
}

const SettingsForm = (
  properties: ISMTPSettingsProperties & FormikProps<ISMTPSettingFormValues>
) => {
  const {
    handleChange,
    handleBlur,
    errors,
    values,
    touched,
    isLoadingTest: loadingTest,
    isLoading,
    showConfirmation,
    isFormValid,
    isAnyUpdatesExist,
    bindForm,
    revalidateErrors,
    revalidateUpdates,
    handleTestSMTP,
    setFieldValue,
    saveButtonClick
  } = properties;
  bindForm('SMTP', values, errors); // expose submission handler out

  const [isSMTPTestDialogVisible, setSMTPTestDialogVisible] = useState<boolean>(false);
  const [smtpTestRecipient, setSMTPTestRecipient] = useState<string | null>(null);

  const onChange = (event: any): void => {
    handleChange(event);
    revalidateUpdates(event.target);
  };

  const testSMTPOnClick = (smtpValues: any): void => {
    handleTestSMTP(smtpValues);
  };

  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    revalidateUpdates({
      id: key,
      value
    });
  };

  revalidateErrors('SMTP');
  const { t } = useTranslation();

  return (
    <>
      <Col xs="12" className="px-0">
        <Form>
          <Fieldset legend={t('/admin/settings.smtp_fieldset.legend')}>
            <Row>
              <SharedSettingsSMTPForm
                handleChange={onChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                values={values}
                databaseType={DatabaseType.mySQL}
                handleOnChangeSecureCheckbox={(e: CheckboxProps) =>
                  setFieldValueLocal('smtpSecure', e.checked)
                }
                isFromUserProject
                isLoading={isLoading}
              />
            </Row>
            <Row>
              <Col sm={6} className="pb-0 mb-2 mb-sm-0">
                <Button
                  className={`p-button-raised p-button-primary btn-block ${
                    isLoading ||
                    loadingTest ||
                    showConfirmation ||
                    !isFormValid ||
                    !isAnyUpdatesExist
                      ? 'custom-disabled-button'
                      : ''
                  }`}
                  onClick={saveButtonClick}
                  disabled={
                    isLoading ||
                    loadingTest ||
                    showConfirmation ||
                    !isFormValid ||
                    !isAnyUpdatesExist
                  }>
                  {loadingTest
                    ? t('/admin/settings.loading')
                    : t('/admin/settings.smtp_fieldset.form.save')}
                </Button>
              </Col>
              <Col sm={6} className="pb-0">
                <Button
                  className={`p-button-raised p-button-primary btn-block ${
                    isLoading ||
                    loadingTest ||
                    showConfirmation ||
                    !isFormValid ||
                    !isAnyUpdatesExist
                      ? ''
                      : 'custom-disabled-button'
                  }`}
                  onClick={() => setSMTPTestDialogVisible(true)}
                  disabled={loadingTest || (isAnyUpdatesExist as boolean)}>
                  {loadingTest
                    ? t('/admin/settings.loading')
                    : t('/admin/settings.smtp_fieldset.form.testSmtp')}
                </Button>
              </Col>
            </Row>
          </Fieldset>
        </Form>
      </Col>

      <DialogWrapper
        isDialogVisible={isSMTPTestDialogVisible}
        onHide={() => setSMTPTestDialogVisible(false)}
        headerTitle={t('I18N.settings.test_smtp')}>
        <InputText
          name="recipient"
          id="recipient"
          onChange={(value) => setSMTPTestRecipient(value.target.value)}
          value={smtpTestRecipient}
        />
        <Button
          className={`p-button-raised p-button-primary btn-block mt-3 ${
            loadingTest || (isAnyUpdatesExist as boolean) ? 'custom-disabled-button' : ''
          }`}
          onClick={() =>
            testSMTPOnClick({
              ...values,
              recipient: smtpTestRecipient
            })
          }
          disabled={loadingTest || (isAnyUpdatesExist as boolean)}>
          {loadingTest
            ? t('/admin/settings.loading')
            : t('/admin/settings.smtp_fieldset.form.testSmtp')}
        </Button>
      </DialogWrapper>
    </>
  );
};

const SMTPSettingsForm = withFormik<ISMTPSettingsInitialFormProperties, ISMTPSettingFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (properties) => {
    const { settings } = properties;
    if (settings) {
      return {
        smtpHost: settings.smtpHost,
        smtpPort: settings.smtpPort,
        smtpSecure: settings.smtpSecure,
        smtpUser: settings.smtpUser,
        smtpPassword: settings.smtpPassword,
        fromEmail: settings.fromEmail,
        fromName: settings.fromName
      };
    }
    return {
      smtpHost: properties.initialSmtpHost || null,
      smtpPort: properties.initialSmtpPort || null,
      smtpSecure: properties.initialSmtpSecure || false,
      smtpUser: properties.initialSmtpUser || null,
      smtpPassword: properties.initialSmtpPassword || null,
      fromEmail: properties.initialFromEmail || null,
      fromName: properties.initialFromName || null
    };
  },
  validationSchema: (properties: any) => {
    const t = properties.translation;

    return Yup.object({
      smtpHost: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .required(
          t('/validations.required', { field: t('/admin/settings.smtp_fieldset.form.host') })
        ),
      smtpPort: Yup.number().required(
        t('/validations.required', { field: t('/admin/settings.smtp_fieldset.form.port') })
      ),
      smtpSecure: Yup.boolean().oneOf([true, false]),
      smtpUser: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .required(
          t('/validations.required', { field: t('/admin/settings.smtp_fieldset.form.user') })
        ),
      smtpPassword: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .required(
          t('/validations.required', { field: t('/admin/settings.smtp_fieldset.form.password') })
        ),
      fromEmail: Yup.string()
        .email(t('/validations.valid_email'))
        .min(2, t('/validations.min', { field: '2' }))
        .required(
          t('/validations.required', { field: t('/admin/settings.smtp_fieldset.form.fromEmail') })
        ),
      fromName: Yup.string()
        .nullable()
        .min(2, t('/validations.min', { field: '2' }))
        .matches(validators.NOT_GT_OR_LT, t('/validations.notGtOrLt'))
    });
  },
  handleSubmit: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    values: ISMTPSettingFormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bags: FormikBag<ISMTPSettingsInitialFormProperties, ISMTPSettingFormValues>
  ) => {
    return 'Save is handled from parent component';
  }
})(SettingsForm);

export default SMTPSettingsForm;
