/**
* AddressDialog.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022 
* @file AddressDialog.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/
import React from 'react';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import AddressForm from './AddressForm';
import { translate } from '../../../../Utils/Translate';

/**
 * @interface IAdressDialogProperties
 */
interface IAdressDialogProperties {
  onHide: (e: boolean) => void /**< hide dialog function */;
  editAddress: IAddress /**< The address that needs to be edited */;
  isDialogVisible: boolean /**< check is dialog is visible. True if visible, false otherwise */;
  handleAddressSubmit: (
    payload: IAddress,
    id?: string
  ) => void /**<  handle address submit function*/;
  isLoading: boolean /**< checks for any address API request. True if there is, false otherwise */;
}
/**
 * Address dialog component
 */
const AddressDialog = ({
  onHide,
  editAddress,
  isDialogVisible,
  handleAddressSubmit,
  isLoading
}: IAdressDialogProperties): JSX.Element => {
  /// Get addressform if editAddress is present for address edit or to create a new address; else return loader
  const getAddressForm = () => {
    return (
      <AddressForm
        editAddress={editAddress}
        handleSubmit={handleAddressSubmit}
        isLoading={isLoading}
      />
    );
  };

  return (
    <DialogWrapper
      isDialogVisible={isDialogVisible}
      onHide={() => onHide(false)}
      headerTitle={
        editAddress && editAddress.addressUUID
          ? translate('/admin.address_fieldset.edit_address_dialog.header')
          : translate('/admin.address_fieldset.add_address_dialog.header')
      }>
      {getAddressForm()}
    </DialogWrapper>
  );
};

export default AddressDialog;
