/**
* RoleSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file RoleSlice.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import {
  createRolesApi,
  deleteRolesFromServer,
  getRolesFromServer,
  updateRolesApi
} from '../Services/RolesApi';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IPaginationRequest } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { getUsers } from '../Services/UserApi';
import i18n from '../Services/I18n';
import { Reducer } from 'react';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IDeleteRoleServiceResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { IUserCount } from './Interfaces';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const roleFeatureKey: string = 'roles';

/**
 * Interface USER_INITIAL_STATE
 */
export interface IRoleState {
  isLoading: boolean;
  isFetched: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  createError: any;
  updateError: any;
  deleteError: any;
  fetchError: any;
  roles: any;
  deleteData: any;
  totalRecords: number;
  criteria: any;
}

const defaultCriteria: IPaginationRequest<IRole> = {
  limit: defaultTableLimit,
  skip: 0,
  sort: {
    created: 'DESC'
  }
};

export const roleInitialState: IRoleState = {
  isLoading: false,
  isFetched: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  createError: null,
  updateError: null,
  deleteError: null,
  fetchError: null,
  roles: null,
  deleteData: {
    userCount: -1,
    usersNotShownCount: -1,
    linkedUsers: []
  },
  totalRecords: 0,
  criteria: defaultCriteria
};

export const roleAdapter: EntityAdapter<IRoleState> = createEntityAdapter();
export const initialRoleState: EntityState<IRoleState> & IRoleState =
  roleAdapter.getInitialState(roleInitialState);

export const getRolesListAction = createAsyncThunk('roles/get', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getRolesListActionRequest, getRolesListActionSuccess, getRolesListActionFailure } =
    roleActions;
  try {
    const criteria: any = payload;
    dispatch(getRolesListActionRequest(criteria || defaultCriteria));
    const result: PaginatedAPIEntityResponse<IRole> = await asyncErrorHandler(
      getRolesFromServer(criteria || defaultCriteria)
    );
    if (result.error) {
      dispatch(getRolesListActionFailure(result.error));
    } else {
      dispatch(getRolesListActionSuccess(result.data));
    }
  } catch (error: any) {
    dispatch(getRolesListActionFailure(error.message));
  }
});

export const getUserCountByRoleIdAction = createAsyncThunk(
  'roles/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getUserCountByRoleIdActionRequest,
      getUserCountByRoleIdActionSuccess,
      getUserCountByRoleIdActionFailure,
      deleteRolesActionFailure,
      deleteRolesActionSuccess
    } = roleActions;
    try {
      dispatch(getUserCountByRoleIdActionRequest());

      const userPaginationPayload: any = {
        limit: 20,
        skip: 0,
        sort: {
          created: 'DESC'
        },
        filter: [
          {
            column: 'roleUUIDs',
            operator: 'IN',
            value: payload.roleUUIDs
          }
        ]
      };
      const userResult: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(
        getUsers(userPaginationPayload)
      );

      if (userResult.error || !userResult.data) {
        dispatch(getUserCountByRoleIdActionFailure(userResult.error || `Error in delete roles`));
      } else {
        const userData: any = userResult.data;
        const userCount: number = userData.totalRecords;
        const linkedUsers: IUser[] =
          userData !== undefined &&
          userData !== null &&
          userData.records.map((each: IUser) => {
            return { username: each.username, email: each.email };
          });

        if (userCount === 0) {
          const result: IAPIEntityResponse<IDeleteRoleServiceResponse> = await asyncErrorHandler(
            deleteRolesFromServer(payload)
          );
          if (result.error) {
            dispatch(deleteRolesActionFailure(result.error));
          } else {
            dispatch(deleteRolesActionSuccess());
          }
          return;
        } else {
          const userCountData: IUserCount = {
            isDeleted: false,
            linkedUsers,
            userCount,
            usersNotShownCount: userCount - userPaginationPayload.limit
          };
          dispatch(getUserCountByRoleIdActionSuccess(userCountData));
          return;
        }
      }
    } catch (error: any) {
      dispatch(getUserCountByRoleIdActionFailure(error.message));
    }
  }
);

export const deleteRolesAction = createAsyncThunk(
  'roles/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { deleteRolesActionRequest, deleteRolesActionSuccess, deleteRolesActionFailure } =
      roleActions;
    try {
      dispatch(deleteRolesActionRequest());
      const result: IAPIEntityResponse<IDeleteRoleServiceResponse> = await asyncErrorHandler(
        deleteRolesFromServer(payload)
      );
      if (result.error) {
        dispatch(deleteRolesActionFailure(result.error));
      } else {
        dispatch(deleteRolesActionSuccess());
      }
    } catch (error: any) {
      dispatch(deleteRolesActionFailure(error.message));
    }
  }
);

export const updateRolesAction = createAsyncThunk(
  'roles/update',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { updateRolesActionRequest, updateRolesActionSuccess, updateRolesActionFailure } =
      roleActions;
    try {
      dispatch(updateRolesActionRequest());
      const result: IAPIEntityResponse<IRole> = await asyncErrorHandler(
        updateRolesApi(payload.body, payload.id)
      );
      if (result.error) {
        dispatch(updateRolesActionFailure(result.error));
      } else {
        dispatch(updateRolesActionSuccess());
      }
    } catch (error: any) {
      dispatch(updateRolesActionFailure(error.message));
    }
  }
);

export const createRolesAction = createAsyncThunk(
  'roles/create',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { createRolesActionRequest, createRolesActionSuccess, createRolesActionFailure } =
      roleActions;
    try {
      dispatch(createRolesActionRequest());
      const result: IAPIEntityResponse<IRole> = await asyncErrorHandler(createRolesApi(payload));
      if (result.error) {
        dispatch(createRolesActionFailure(result.error));
      } else {
        dispatch(createRolesActionSuccess());
      }
    } catch (error: any) {
      dispatch(createRolesActionFailure(error.message));
    }
  }
);

const clearErrors = (state: IRoleState) => {
  state.isCreated = false;
  state.isUpdated = false;
  state.isDeleted = false;
  state.fetchError = null;
  state.createError = null;
  state.updateError = null;
  state.deleteError = null;
};

const clearOnRequest = (state: IRoleState) => {
  state.isLoading = true;
  clearErrors(state);
};

/**
 * Creates Slice - All User related state will be stored here
 */
export const roleSlice = createSlice({
  name: roleFeatureKey,
  initialState: initialRoleState,
  reducers: {
    reset: (state: IRoleState) => clearErrors(state),
    getRolesListActionRequest(state: IRoleState, action: PaginationResponseAction<IRole>) {
      clearOnRequest(state);
      state.criteria = { ...action.payload }; // if filter sent, update criteria for the next refresh
      state.isFetched = false;
      state.isLoading = true;
    },
    getRolesListActionSuccess(state: IRoleState, action: PaginationResponseAction<IRole>) {
      state.isLoading = false;
      state.roles = action.payload.records || [];
      state.totalRecords = action.payload.totalRecords || 0;
      state.fetchError = null;
      state.isFetched = true;
    },
    getRolesListActionFailure(state: IRoleState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.roles = [];
      state.fetchError =
        (action && action.payload) || i18n.t('I18N.error_messages.fetch_role_failure');
    },
    createRolesActionRequest(state: IRoleState) {
      clearOnRequest(state);
    },
    createRolesActionSuccess(state: IRoleState) {
      state.isLoading = false;
      state.isCreated = true;
      state.createError = null;
    },
    createRolesActionFailure(state: IRoleState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.isCreated = false;
      state.createError =
        (action && action.payload) || i18n.t('I18N.error_messages.create_role_failure');
    },
    updateRolesActionRequest(state: IRoleState) {
      clearOnRequest(state);
    },
    updateRolesActionSuccess(state: IRoleState) {
      state.isLoading = false;
      state.isUpdated = true;
      state.updateError = null;
    },
    updateRolesActionFailure(state: IRoleState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.isUpdated = false;
      state.updateError =
        (action && action.payload) || i18n.t('I18N.error_messages.update_role_failure');
    },
    deleteRolesActionRequest(state: IRoleState) {
      clearOnRequest(state);
    },
    deleteRolesActionSuccess(state: IRoleState) {
      state.isLoading = false;
      state.isDeleted = true;
      state.deleteError = null;
    },
    deleteRolesActionFailure(state: IRoleState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.isDeleted = false;
      state.deleteError =
        (action && action.payload) || i18n.t('I18N.error_messages.delete_role_failure');
    },
    getUserCountByRoleIdActionRequest(state: IRoleState) {
      clearOnRequest(state);
      state.deleteData = {};
    },
    getUserCountByRoleIdActionSuccess(state: IRoleState, action: IReducerAction<IUserCount>) {
      state.isLoading = false;
      state.deleteData = action.payload;
    },
    getUserCountByRoleIdActionFailure(state: IRoleState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.deleteError =
        (action && action.payload) || i18n.t('I18N.error_messages.delete_role_failure');
    }
  }
});

export const roleReducer: Reducer<EntityState<IRoleState> & IRoleState, AnyAction> =
  roleSlice.reducer;

export const roleActions: any = roleSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = roleAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getRoleState: any = (rootState: any) => rootState[roleFeatureKey];
export const selectAllRole: any = createSelector(getRoleState, selectAll);
export const selectRoleEntities: any = createSelector(getRoleState, selectEntities);
