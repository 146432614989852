/**
* StaticLinksApi.ts (InstaLOD GmbH) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Polina Ovsiannikova, 2022
* @file StaticLinksApi.ts
* @author Polina Ovsiannikova
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section Web Common
*/

import {
    IAPIEntityResponse,
    IDeleteStaticLinksFrontendResponse,
    PaginatedAPIEntityResponse
} from "@abstract/abstractwebcommon-shared/interfaces/api";
import { IStaticLink } from "@abstract/abstractwebcommon-shared/interfaces/staticLinks";
import { HttpClient } from "../utils/HttpClient";
import {
    IImageUploadResponse,
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { paginationRequest } from "../Pagination/paginationRequest";
import { IPaginationResponse } from "@abstract/abstractwebcommon-shared/interfaces/pagination";
import { CreateErrorLog } from "@abstract/abstractwebcommon-shared/utils/CreateErrorLog";

let httpClient: HttpClient = null;

export class StaticLinksApi {

    constructor(baseApiUrl: string, onCreateErrorLog: (payload: CreateErrorLog) => void) {
        httpClient = new HttpClient(baseApiUrl, onCreateErrorLog);
    }

    /**
     * sends uploaded file as form data to the rest end point with isIcon as true
     * @param file File to be uploaded
     * @returns Promise
     */
    uploadIconApi = (url: string, file: Blob): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
        const formData: FormData = new FormData();
        formData.append('file', file, file['name']);
        formData.append('mode', 'icon');
        return httpClient.imageUpload<IImageUploadResponse>(url, formData);
    };

    /**
     * update app static link
     * @param body Payload to send with the request
     * @param staticLinkUUID staticLinkUUID of the link
     * @returns Promise
     */
    createOrUpdateStaticLinkAPI = (
        url: string,
        body: Record<string, any>,
    ): Promise<IAPIEntityResponse<IStaticLink>> => {
        return httpClient.post<IStaticLink>(url, body);
    };


    /**
     * get app static links
     * @returns Promise
     */
    getStaticLinksAPI = (url: string, payload?: Record<string, any>): Promise<IAPIEntityResponse<IPaginationResponse<IStaticLink>>> => {
        const paginationRequestUrl: string = paginationRequest(url, payload);
        return httpClient.get<IPaginationResponse<IStaticLink>>(paginationRequestUrl);
    };

    /**
     * get app static links
     * @returns Promise
     */
    getAllStaticLinksAPI = (url: string): Promise<IAPIEntityResponse<IStaticLink>> => {
        return httpClient.get<IStaticLink>(url);
    };

    /**
     * delete app static links
     * @param payload Payload to send with the request
     * @returns Promise
     */
    deleteStaticLinksAPI = (
        url: string,
        payload: string[]
    ): Promise<IAPIEntityResponse<IDeleteStaticLinksFrontendResponse>> => {
        return httpClient.post<IDeleteStaticLinksFrontendResponse>(url, {
            staticLinkUUIDs: payload
        });
    };
};


