/**
* UserProfilePic.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file UserProfilePic.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import ImagePreview from '@abstract/abstractwebcommon-client/ImagePreview';
import { useTranslation } from 'react-i18next';

/**
 * Interface for UserProfilePic properties.
 */
interface IUserProfilePicProperties {
  imageUrl: string /**< Base64 data url. */;
  imgClass?: string /**< Style class of the component. */;
  showDelete?: boolean /**< Show clear button only if showDelete is present. */;
  deleteHandler?: (event: any) => void /**< Delete function implementation. */;
  children?: ReactNode /**< Children component. */;
  setLogoLoaded?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< State to control when logo is loaded */;
  isLogoLoaded?: boolean /**< is Logo loaded */;
}

const UserProfilePic = (properties: IUserProfilePicProperties): JSX.Element => {
  const { t } = useTranslation();

  const noImageContainer = () => {
    return (
      <>
        <i className="pi pi-user p-3 text-white" />
        {properties?.children}
      </>
    );
  };

  /// Gets the ImagePreview component.
  const getImage = () => {
    if (properties.imageUrl) {
      return (
        <ImagePreview
          showDelete={properties.showDelete}
          deleteHandler={properties.deleteHandler}
          imageUrl={properties.imageUrl}
          imgClass={`img-rounded img-thumbnail ${properties.imgClass}`}
          noImageContainer={noImageContainer()}
          isLogo={true}
          altText={t('I18N.user_profile.profile_picture_alt')}
          setLogoFullLoaded={properties.setLogoLoaded}
        />
      );
    }
    properties.setLogoLoaded(true);
    return (
      <>
        <i className="pi pi-user p-3 text-white" />
        {properties.children}
      </>
    );
  };

  return (
    <>
      <Col
        sm={12}
        className={properties.isLogoLoaded ? 'bg-dark p-2 primary-border-radius' : 'd-none'}>
        <Col sm={12} md={12} className="d-flex justify-content-center pb-0">
          <Col className="text-center">{getImage()}</Col>
        </Col>
      </Col>
    </>
  );
};

export default UserProfilePic;
