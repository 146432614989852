/**
* ProfileHeaderSkeleton.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues, 2022 
* @file ProfileHeaderSkeleton.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import SkeletonWrapper from '../SkeletonWrapper'

import './ProfileHeaderSkeleton.css'

/**
 * Render a skeleton for Profile Header component when data is loading for it.
 */
const ProfileHeaderSkeleton = () => {
  return (
    <div className="w-100 d-flex align-items-center profile-header-skeleton-container">
      <SkeletonWrapper circle width="50px" height="50px" />

      <div className="w-100 ml-4 d-flex flex-column">
        <SkeletonWrapper height="25px" />
        <SkeletonWrapper width="145px" />
      </div>
    </div>
  )
}

export default ProfileHeaderSkeleton