/**
* AuthApi.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file AuthApi.ts
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ISendVerificationResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { IUser, IRegisterFormData } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/user/auth';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Logs in user
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const loginApi = (payload: IAuth): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>('/auth/login', payload, {}, false);
};

/**
 * Activates a user
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const activateApi = (payload: Record<string, any>): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.get<IUser>(
    `/user/${payload.userID}/activate`,
    {
      Authorization: `Bearer ${payload.token}`
    },
    false
  );
};

/**
 * Validates a token
 * @returns Promise
 */
export const validateTokenAPI = (): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get('/system/validate');
};

/**
 * Register new user from data.
 * @param data user details to send with the request.
 * @returns IUser.
 */
export const registerUserAPI = (data: IUser): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>('/auth/register', data, {}, false);
};

/**
 * Send Verification link to user email.
 * @param data User data.
 * @returns ISendVerificationResponse.
 */
export const sendVerificationAPI = (
  data: IUser
): Promise<IAPIEntityResponse<ISendVerificationResponse>> => {
  return httpClient.post<ISendVerificationResponse>('/auth/verification/sendmail', data, {}, false);
};

/**
 * Complete the registration process.
 * @param payload User information.
 * @returns IUser.
 */
export const completeRegistrationAPI = (
  payload: IRegisterFormData
): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>(
    `/auth/registration/complete?token=${payload.token}`,
    payload,
    {},
    false
  );
};

/**
 * Login with token
 * @param token User token.
 * @returns IUser.
 */
export const loginWithTokenAPI = (token: string): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>(
    '/auth/login/token',
    { token },
    { Authorization: `Bearer ${token}` },
    false
  );
};

/**
 * Check if the user verification link is valid
 * @param userUUID User UUID.
 * @param token User UUID - Optional, only used in the invitation link logic
 * @param isCompleteRegistration User UUID - Optional, only used in the invitation link logic
 * @returns boolean.
 */
export const isVerificationLinkValid = (
  userUUID: string,
  token?: string,
  isCompleteRegistration: boolean = false
): Promise<IAPIEntityResponse<boolean>> => {
  return httpClient.get(
    `/auth/${userUUID}/is/verification/link/valid?isCompleteRegistration=${isCompleteRegistration}${
      token ? `&token=${token}` : ``
    }`
  );
};
