/**
* ProfileApi.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022

* @file ProfileApi.ts
* @author Etienne Daher
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IGetProfileResponse,
  IUpdateProfileResponse,
  IUser
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { createLogApi } from './LogApi';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Updates user's profile
 * @param payload Payload to send with the request
 * @param userUUID userUUID of the user to be updated
 * @returns Promise
 */
export const updateProfileApi = (
  payload: Record<string, any>,
  userUUID: string
): Promise<IAPIEntityResponse<IUpdateProfileResponse>> => {
  return httpClient.post(`/profile/${userUUID}/update`, payload);
};

/**
 * Updates user's password
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const updatePasswordApi = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post(`/auth/password/change`, payload);
};

/**
 * Gets user's profile data
 * @returns Promise
 */
export const getProfileApi = (): Promise<IAPIEntityResponse<IGetProfileResponse>> => {
  return httpClient.get(`/profile`);
};

/**
 * Create a new address from data. If addressUUID is set,
 * updates the existing address.
 * @param data Payload to send with the request.
 * @param addressUUID id of the address
 * @returns Promise
 */
export const createOrUpdateAddressAPI = (
  data: IAddress,
  addressUUID?: string | null
): Promise<IAPIEntityResponse<IAddress>> => {
  const userUUID: string = LocalStorage.getXUserUUID() || ''; /**< userUUID. */
  if (addressUUID) {
    return httpClient.post(`/profile/address/${addressUUID}/update`, data);
  } else {
    return httpClient.post(`/profile/${userUUID}/address/create`, data);
  }
};

/**
 * getAddress get all user address.
 * @returns Promise
 */
export const getAddress = (payload: ITablePayload): Promise<IAPIEntityResponse<IAddress[]>> => {
  const userUUID: string = LocalStorage.getXUserUUID() || ''; /**< userUUID. */
  const url: string = paginationRequest(`/profile/${userUUID}/address`, payload);
  return httpClient.get(url);
};

/**
 * Deletes address
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const deleteAddress = (payload: IAddress[]): Promise<IAPIEntityResponse<IAddress>> => {
  return httpClient.post(`/profile/address/delete`, { addresses: payload });
};
