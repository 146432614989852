/**
* routesNames.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file routesNames.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

/**
 * @enum RouteName
 * Use this file to centralize all route names in the application
 * Add route value below as needed.
 */
export enum RouteName {
  validatePermissionRoute = '/validate/permission/' /**< /validate/permission/ route name */,
  forgotPasswordRoute = '/forgot-password' /**< /forgot-password route name */,
  resetPasswordRoute = '/reset-password' /**< /reset-password route name */,
  authVerifyRoute = '/auth/verify' /**< /auth/verify route name */,
  activateUserRoute = '/activate-user' /**< /activate-user route name */,
  verifyEmailRoute = '/verify-email' /**< /verify-email route name */,
  userProfileRoute = `${SharedMainRouteName.userRoute}/profile` /**< /user/profile route name */,
  adminDashboardRoute = `${SharedMainRouteName.adminRoute}/dashboard` /**< /admin/dashboard route name */,
  adminRoleManagementRoute = `${SharedMainRouteName.adminRoute}/role-management` /**< /admin/role-management route name */,
  adminApplicationManagementRoute = `${SharedMainRouteName.adminRoute}/application-management` /**< /admin/application-management route name */,
  adminUserTokenManagementRoute = `${SharedMainRouteName.adminRoute}/userToken-management` /**< /admin/userToken-management route name */,
  adminSettingsRoute = `${SharedMainRouteName.adminRoute}/settings` /**< /admin/settings route name */,
  adminTemplateEditorRoute = `${SharedMainRouteName.adminRoute}/template-editor` /**< /admin/template-editor route name */,
  adminCSSEditorRoute = `${SharedMainRouteName.adminRoute}/css-editor` /**< /admin/css-editor route name */,
  adminProfileRoute = `${SharedMainRouteName.adminRoute}/profile` /**< /admin/profile route name */,
  adminLogsRoute = `${SharedMainRouteName.adminRoute}/logs` /**< /admin/logs route name */
}
