/*
 * UserTotalCount.tsx (User)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file UserTotalCount.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section User
 */

import React from 'react';
import { useSelector } from 'react-redux';
import MenuBadgeCount from '@abstract/abstractwebcommon-client/Sidebar/MenuBadgeCount/MenuBadgeCount';

import { getTotalUsersCountAction, getUserState, IUserState } from '../../../../Store/UserSlice';

/**
 * Display the total of users registered
 * @returns JSX.Element
 */
const UserTotalCount = (): JSX.Element => {
  const userState: IUserState = useSelector(getUserState);

  return (
    <MenuBadgeCount
      getTotalItemCount={getTotalUsersCountAction}
      itemCount={userState.userCount}
      fetchError={userState.fetchError}
      itemName="userState"
    />
  );
};

export default UserTotalCount;
