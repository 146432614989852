/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
* helpers.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file helpers.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

/* Temporary fix for the issue related to automatically closing the multi-select dropdown when deselecting a value.
 * Issue reported with a temporary fix: https://github.com/primefaces/primereact/issues/4523
 **/
export const handleCheckboxIconClick = (event: any): void => {
  event?.stopPropagation();
  if (event?.target?.parentNode && typeof event.target.parentNode.click === 'function') {
    event?.target?.parentNode?.click();
  }
};

/* Temporary fix for the issue related to automatically closing the multi-select dropdown when deselecting a value.
 * Issue reported with a temporary fix: https://github.com/primefaces/primereact/issues/4523
 **/
export const handleHeaderCheckboxClick = (event: any): void => {
  event?.stopPropagation();
  if (event?.target?.parentNode && typeof event.target.parentNode.click === 'function') {
    event?.target?.parentNode?.click();
  }
};
