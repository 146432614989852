/**
* HomeComponent.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2022 
* @file HomeComponent.tsx
* @author Timothy Fadayini
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { Col, Row } from 'react-bootstrap';
import { profileTabs } from '@abstract/abstractwebcommon-client/utils/profileTabSelectors';
import { ISettingsState } from '../../../Store/SettingsSlice';
import { removeSchemaFromURL } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * @interface IProfileHomeProperties
 */
export interface IProfileHomeProperties {
  changeTabHandler: (
    tabNumber: number
  ) => void /**< handles tab change on clicking for the button */;
  settingState: ISettingsState | null /**< settings state from the store */;
}

/**
 * Profile Home tab component
 */
const HomeComponent = (properties: IProfileHomeProperties): JSX.Element => {
  const safeSettings: any | null = properties.settingState
    ? properties.settingState.safeSettings
    : null;
  const translation: TFunction = useTranslation().t;
  const basicInfoFooter = (
    <Row className="col-md-12 justify-content-end px-0 mx-0">
      <Button onClick={() => properties.changeTabHandler(profileTabs.basicInformationComponent)}>
        {translation('I18N.profile.update_basic_info')}
      </Button>
    </Row>
  );
  const securityFooter = (
    <Row className="col-md-12 justify-content-end px-0 mx-0">
      <Button onClick={() => properties.changeTabHandler(profileTabs.passwordComponent)}>
        {translation('I18N.profile.update_password')}
      </Button>
    </Row>
  );

  return (
    <Container fluid={true} className={'px-0'}>
      <Row>
        <Col md={6}>
          <Card
            className="profile-home-cards"
            title={translation('I18N.profile.full_basic_info')}
            footer={basicInfoFooter}>
            <p className="m-0">{translation('I18N.profile.basic_info_details')}</p>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="profile-home-cards"
            title={translation('I18N.profile.update_password')}
            footer={securityFooter}>
            <p className="m-0">{translation('I18N.profile.password_details')}</p>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="profile-home-cards"
            title={translation('I18N.profile.contact_information')}>
            <p>
              {translation('I18N.profile.visit_our_website')}{' '}
              <a
                href={
                  safeSettings && safeSettings.WEBSITE
                    ? removeSchemaFromURL(safeSettings.WEBSITE)
                    : '#'
                }
                target="_blank"
                rel="noopener noreferrer">
                {safeSettings && safeSettings.WEBSITE}
              </a>
            </p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeComponent;
