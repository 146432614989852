/**
* AddressForm.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file AddressForm.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState, useEffect, Dispatch } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown } from 'primereact/dropdown';
import { Country, ICountry, State, IState } from 'country-state-city';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { translate } from '../../../../Utils/Translate';
import { deleteAddressAction } from '../../../../Store/ProfileSlice';
import { useDispatch } from 'react-redux';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';

/**
 * @interface IAddressFormProperties
 */
interface IAddressFormProperties {
  editAddress: IAddress /**< The address that needs to be edited */;
  handleSubmit: (payload: IAddress, id?: string) => void /**< handle address submit function*/;
  isLoading: boolean /**< checks for any address API request. True if there is, false otherwise */;
}

/**
 * @interface ICountryData
 */
interface ICountryData {
  value: string;
  label: string;
}

/**
 * AddressForm form component.
 */
const AddressForm = ({
  handleSubmit,
  isLoading,
  editAddress
}: IAddressFormProperties): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const [isConfirmationPopupVisible, setConfirmationPopupVisible] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] =
    useState<any>(null); /**< ConfirmationPopup Target */
  const [countries, setCountries] = useState<ICountryData[]>([]);
  const [statesOfCountry, setStatesOfCountry] = useState<ICountryData[]>(
    []
  ); /**< State of country */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editAddress.name || '',
      address: editAddress.address || '',
      addressTwo: editAddress.addressTwo || '',
      city: editAddress.city || '',
      zipCode: editAddress.zipCode || '',
      stateOrProvince: editAddress.stateOrProvince || '',
      country: editAddress.country || ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, translate('/validations.min', { field: '2' }))
        .max(50, translate('/validations.max', { field: '50' }))
        .matches(/(?!^\d+$)^.+$/, translate('/validations.notOnlyNumbers'))
        .required(
          translate('/validations.required', { field: translate('I18N.address_table.name') })
        ),
      address: Yup.string()
        .min(2, translate('/validations.min', { field: '2' }))
        .max(500, translate('/validations.max', { field: '500' }))
        .required(
          translate('/validations.required', { field: translate('I18N.address_table.address') })
        ),
      addressTwo: Yup.string()
        .min(2, translate('/validations.min', { field: '2' }))
        .max(500, translate('/validations.max', { field: '500' }))
        .nullable()
        .notRequired(),
      city: Yup.string()
        .max(300, translate('/validations.max', { field: '250' }))
        .required(
          translate('/validations.required', { field: translate('I18N.address_table.city') })
        ),
      zipCode: Yup.string()
        .max(300, translate('/validations.max', { field: '20' }))
        .required(
          translate('/validations.required', { field: translate('I18N.address_table.zipCode') })
        ),
      stateOrProvince: Yup.string().required(
        translate('/validations.required', {
          field: translate('I18N.address_table.stateOrProvince')
        })
      ),
      country: Yup.string().required(
        translate('/validations.required', { field: translate('I18N.address_table.country') })
      )
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (editAddress && editAddress.addressUUID) {
            if (Object.keys(payload).length) {
              handleSubmit(payload, editAddress.addressUUID);
            }
          } else {
            handleSubmit(payload);
          }
        }
      });
    }
  });

  /// Calls deleteUsers on accept
  const onAccept = (): void => {
    dispatch(deleteAddressAction([editAddress])); // Delete adress.
    setConfirmationPopupVisible(false);
  };

  /// Countries names
  const getCountries = () => {
    setCountries(
      Country.getAllCountries()
        .map(
          (country: ICountry) =>
            ({
              value: country.isoCode,
              label: country.name
            } as ICountryData)
        )
        .sort((country1: ICountryData, country2: ICountryData) =>
          country1.label.localeCompare(country2.label)
        )
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  /// Close confirmPopup on reject
  const onReject = (): void => {
    setConfirmationPopupVisible(false);
  };

  /// Handle Delete button
  const deleteButtonClicked = (event: any): void => {
    setConfirmationPopupVisible(true);
    setConfirmPopupTarget(event.target);
  };

  // Get states of country
  useEffect(() => {
    const states: ICountryData[] = State.getStatesOfCountry(formik.values.country).map(
      (state: IState) =>
        ({
          value: state.isoCode,
          label: state.name
        } as ICountryData)
    );
    setStatesOfCountry(states);
  }, [formik.values.country]);

  return (
    <>
      <FormWrapper
        onSubmit={formik.handleSubmit}
        controlButtonLabel={editAddress && !!editAddress.addressUUID}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClicked(event)
        }
        handleSubmitButton={() => formik.handleSubmit()}>
        <Row>
          <Col xs={12}>
            <label htmlFor="name" className="required">
              {translate('/admin.address_fieldset.datatable.columns.name')}
            </label>
            <InputText
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={formik.touched.name && formik.errors.name ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <small id="name-invalid" className="p-invalid">
                {formik.errors.name}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="address" className="required">
              {translate('/admin.address_fieldset.datatable.columns.address')}
            </label>
            <InputTextarea
              id="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className={formik.touched.address && formik.errors.address ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
              autoResize
            />
            {formik.touched.address && formik.errors.address ? (
              <small id="address-invalid" className="p-invalid">
                {formik.errors.address}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="addressTwo">
              {translate('/admin.address_fieldset.datatable.columns.addressTwo')}
            </label>
            <InputTextarea
              id="addressTwo"
              value={formik.values.addressTwo}
              onChange={formik.handleChange}
              className={formik.touched.addressTwo && formik.errors.addressTwo ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
              autoResize
            />
            {formik.touched.addressTwo && formik.errors.addressTwo ? (
              <small id="addressTwo-invalid" className="p-invalid">
                {formik.errors.addressTwo}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <label htmlFor="city" className="required">
              {translate('/admin.address_fieldset.datatable.columns.city')}
            </label>
            <InputText
              id="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.city && formik.errors.city ? 'p-invalid' : ''}
            />
            {formik.touched.city && formik.errors.city ? (
              <small id="city-invalid" className="p-invalid">
                {formik.errors.city}
              </small>
            ) : null}
          </Col>
          <Col xs={12} sm={6}>
            <label htmlFor="zipCode" className="required">
              {translate('/admin.address_fieldset.datatable.columns.zipCode')}
            </label>
            <InputText
              id="zipCode"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.zipCode && formik.errors.zipCode ? 'p-invalid' : ''}
            />
            {formik.touched.zipCode && formik.errors.zipCode ? (
              <small id="zipCode-invalid" className="p-invalid">
                {formik.errors.zipCode}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <label htmlFor="stateOrProvince" className="required">
              {translate('/admin.address_fieldset.datatable.columns.country')}
            </label>
            <Dropdown
              name="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              options={countries}
              placeholder={translate('/admin.address_fieldset.datatable.columns.country')}
              className={formik.touched.country && formik.errors.country ? 'p-invalid' : ''}
            />
            {formik.touched.country && formik.errors.country ? (
              <small id="country-invalid" className="p-invalid">
                {formik.errors.country}
              </small>
            ) : null}
          </Col>
          <Col xs={12} sm={6}>
            <label htmlFor="stateOrProvince" className="required">
              {translate('/admin.address_fieldset.datatable.columns.stateOrProvince')}
            </label>
            <Dropdown
              name="stateOrProvince"
              value={formik.values.stateOrProvince}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={statesOfCountry}
              disabled={!formik.values.country}
              placeholder={translate('/admin.address_fieldset.datatable.columns.stateOrProvince')}
              className={
                formik.touched.stateOrProvince && formik.errors.stateOrProvince ? 'p-invalid' : ''
              }
            />
            {formik.touched.stateOrProvince && formik.errors.stateOrProvince ? (
              <small id="stateOrProvince-invalid" className="p-invalid">
                {formik.errors.stateOrProvince}
              </small>
            ) : null}
          </Col>
        </Row>
      </FormWrapper>
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isConfirmationPopupVisible}
        title={translate('/confirm_messages.delete_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
      <div className="d-flex">
        {formik.status && formik.status.message ? (
          <small className="p-invalid">{formik.status.message}</small>
        ) : null}
      </div>
    </>
  );
};

export default AddressForm;
