/**
* AppForm.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file AppForm.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useState, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Checkbox } from 'primereact/checkbox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { Dialog } from 'primereact/dialog';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { InputTextarea } from 'primereact/inputtextarea';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import { RadioButton } from 'primereact/radiobutton';
import { ISettingsState, getSettingsState } from '../../../Store/SettingsSlice';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';

interface IAppFormProperties {
  editApp: any;
  handleSubmit: any;
  deleteApps: any;
  deleteData: any;
  isLoading: boolean;
}

const AppForm = ({
  editApp,
  handleSubmit,
  deleteApps,
  deleteData,
  isLoading
}: IAppFormProperties): JSX.Element => {
  const { t } = useTranslation();

  const settingsState: ISettingsState = useSelector(getSettingsState);

  const isNotEditingRootApplication =
    settingsState.safeSettings.rootApplication.applicationUUID !== editApp.applicationUUID;

  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [showDeleteLinkedApplicationsWarning, setShowDeleteLinkedApplicationsWarning] =
    useState<boolean>(false);
  const [isAppLogoImageDelete, setIsAppLogoImageDelete] = useState<boolean>(false);
  const [uploadedAppLogoFile, setUploadedAppLogoFile] = useState<any>(null);
  const [isIconImageDelete, setIsIconImageDelete] = useState<boolean>(false);
  const [uploadedIconFile, setUploadedIconFile] = useState<any>(null);
  const [isLogoFileChanged, setIsLogoFileChanged] = useState<boolean>(false);
  const [isIconFileChanged, setIsIconFileChanged] = useState<boolean>(false);
  const [croppedLogo, setCroppedLogo] = useState<any>(null);
  const [croppedIcon, setCroppedIcon] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      applicationName: editApp.applicationName || '',
      isActive: editApp.isActive || false,
      verificationURL: editApp.verificationURL || '',
      logoImageName: editApp.logoImageName || '',
      icon: editApp.icon || '',
      description: editApp.description || '',
      nonRegisteredURL: editApp.nonRegisteredURL || '',
      isAlwaysVisible: editApp.isAlwaysVisible || false,
      whenRegistered: editApp.whenRegistered || Object.keys(editApp).length === 0 ? true : false,
      isNotEditingRootApplication: isNotEditingRootApplication,
      website: editApp.website || ''
    },
    validationSchema: Yup.object({
      isNotEditingRootApplication: Yup.boolean().oneOf([true, false]),
      applicationName: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(50, t('/validations.max', { field: '50' }))
        .required(t('/validations.required', { field: t('I18N.application.name') })),
      description: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(200, t('/validations.max', { field: '200' }))
        .required(t('/validations.required', { field: t('I18N.application.description') })),
      isActive: Yup.boolean().oneOf([true, false]),
      isAlwaysVisible: Yup.boolean().oneOf([true, false]),
      whenRegistered: Yup.boolean().oneOf([true, false]),
      verificationURL: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(500, t('/validations.max', { field: '500' }))
        .required(t('/validations.required', { field: t('I18N.application.verification_URL') }))
        .matches(/https?:\/\/+[a-zA-Z]/g, t('/validations.isUrl')),
      nonRegisteredURL: Yup.string().when(['isAlwaysVisible', 'isNotEditingRootApplication'], {
        is: true,
        then: Yup.string()
          .required(t('/validations.required', { field: t('I18N.application.non_registered_URL') }))
          .matches(/https?:\/\/+[a-zA-Z]/g, t('/validations.isUrl')),
        otherwise: Yup.string().nullable()
      }),
      logoImageName: Yup.string().nullable().notRequired(),
      website: Yup.string()
        .nullable()
        .notRequired()
        .matches(/https?:\/\/+[a-zA-Z]/g, t('/validations.isUrl')),
      icon: Yup.object().nullable().notRequired()
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (editApp.id) {
            if (Object.keys(payload).length) {
              handleSubmit(payload, editApp.applicationUUID);
            }
          } else {
            handleSubmit(payload);
          }
        }
        if (uploadedAppLogoFile !== null) {
          if (isAppLogoImageDelete) {
            payload['logoImageName'] = '';
          } else {
            payload['logoImage'] = uploadedAppLogoFile;
          }
          setUploadedAppLogoFile(null);
        }
        if (uploadedIconFile !== null) {
          if (isIconImageDelete) {
            payload['icon'] = '';
          } else {
            payload['iconImage'] = uploadedIconFile;
          }
          setUploadedIconFile(null);
        }
      });
    }
  });

  /// Triggers on application logo upload success. Sets the logoImageName to logo image
  const onLogoUpload = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    setUploadedAppLogoFile(file);
    setIsLogoFileChanged(true);
    setIsAppLogoImageDelete(false);
  };

  /// Sets the logoImageName to null
  const clearLogoImage = (): void => {
    setIsAppLogoImageDelete(true);
    setUploadedAppLogoFile('');
  };

  /// Triggers on application icon  upload success. Sets the icon to icon image
  const onIconUpload = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    setUploadedIconFile(file);
    setIsIconFileChanged(true);
    setIsIconImageDelete(false);
  };

  /// Sets the IconImageName to null
  const clearIconImage = (): void => {
    setIsIconImageDelete(true);
    setUploadedIconFile('');
  };

  const errorHandler = (error: any): void => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: t('I18N.settings.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };
  /// Gets the light logo fileUpload component.
  const getLogoFileUpload = (): JSX.Element => {
    return (
      <div className="bg-dark text-center px-4 py-2 primary-border-radius">
        <InstaImageUpload
          showLegend={false}
          imageUrl={editApp.logoImageURL || ''}
          showDelete={true}
          deleteHandler={clearLogoImage}
          imgContainerClass={'px-3'}
          onChange={onLogoUpload}
          croppedImage={croppedLogo}
          errorHandler={errorHandler}
          imgClass={'imageUrl rounded'}
          altText={t('I18N.settings.logo_alt')}
          isPlainBtn={true}
          plainBtnLabel={t('I18N.application.choose_app_light_logo')}
        />
      </div>
    );
  };

  /// Gets the icon fileUpload component.
  const getIconFileUpload = (): JSX.Element => {
    return (
      <div className="bg-dark text-center px-4 py-2 primary-border-radius">
        <InstaImageUpload
          showLegend={false}
          imageUrl={editApp.icon || ''}
          showDelete={true}
          deleteHandler={clearIconImage}
          imgContainerClass={'px-3'}
          onChange={onIconUpload}
          croppedImage={croppedIcon}
          errorHandler={errorHandler}
          imgClass={'imageUrl rounded'}
          altText={t('I18N.settings.logo_alt')}
          isPlainBtn={true}
          plainBtnLabel={t(
            '/admin/application-management.apps_fieldset.add_app_dialog.choose_icon'
          )}
          uploadButtonClass={'ml-0 col-md-12'}
        />
      </div>
    );
  };

  /// Calls confirmpopup
  const deleteButtonClicked = (event: any): void => {
    setShowConfirmPopup(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete applications on Accept
  const onAccept = (): void => {
    deleteApps([editApp], false);
    setShowDeleteLinkedApplicationsWarning(true);
    setShowConfirmPopup(false);
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setShowConfirmPopup(false);
    setShowDeleteLinkedApplicationsWarning(false);
  };

  const onDelete = (): void => {
    deleteApps([editApp], true);
    setShowDeleteLinkedApplicationsWarning(false);
  };

  const renderRowDeleteDialogFooter = (): ReactElement => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplicationsNo'
          )}
        </Button>
        <Button
          onClick={() => onDelete()}
          autoFocus
          variant="danger"
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplicationsYes'
          )}
        </Button>
      </div>
    );
  };

  const handleLogoImageCropComplete = (image: any): void => {
    setCroppedLogo(image);
    setUploadedAppLogoFile(image);
  };

  const handleIconImageCropComplete = (image: any): void => {
    setCroppedIcon(image);
    setUploadedIconFile(image);
  };

  return (
    <>
      <FormWrapper
        onSubmit={formik.handleSubmit}
        controlButtonLabel={editApp.id}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClicked(event)
        }
        handleSubmitButton={() => formik.handleSubmit()}>
        <Row>
          <Col xs={12}>{getLogoFileUpload()}</Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="applicationName" className="required">
              {t('/admin/application-management.apps_fieldset.datatable.columns.name')}
            </label>
            <InputText
              id="applicationName"
              value={formik.values.applicationName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.applicationName && formik.errors.applicationName ? 'p-invalid' : ''
              }
            />
            {formik.touched.applicationName && formik.errors.applicationName ? (
              <small className="p-invalid">{formik.errors.applicationName}</small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="applicationName" className="required">
              {t('/admin/application-management.apps_fieldset.datatable.columns.description')}
            </label>
            <InputTextarea
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.description && formik.errors.description ? 'p-invalid' : ''}
              autoResize
            />
            {formik.touched.description && formik.errors.description ? (
              <small className="p-invalid">{formik.errors.description}</small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>{getIconFileUpload()}</Col>
        </Row>

        {isNotEditingRootApplication && (
          <>
            <Row>
              <Col xs={12} className="d-flex">
                <label htmlFor="sidebarVisualization" className="required mr-3">
                  {t(
                    '/admin/application-management.apps_fieldset.datatable.columns.sidebar_visualization'
                  )}
                </label>

                <div className="d-flex justify-content-center">
                  <RadioButton
                    name="isAlwaysVisible"
                    onChange={(e: any) => {
                      formik.setFieldValue('isAlwaysVisible', e.target.checked);
                      formik.setFieldValue('whenRegistered', false);
                    }}
                    value={false}
                    checked={formik.values.isAlwaysVisible}
                    inputId="isAlwaysVisible"
                  />
                  <label htmlFor="isAlwaysVisible" className="mx-3">
                    {t(
                      '/admin/application-management.apps_fieldset.datatable.columns.always_visible'
                    )}
                  </label>
                </div>

                <div className="d-flex justify-content-center">
                  <RadioButton
                    name="whenRegistered"
                    onChange={(e) => {
                      formik.setFieldValue('whenRegistered', e.target.checked);
                      formik.setFieldValue('isAlwaysVisible', false);
                      formik.setFieldValue('nonRegisteredURL', '');
                      formik.setFieldTouched('nonRegisteredURL', false);
                    }}
                    value={false}
                    checked={formik.values.whenRegistered}
                    inputId="whenRegistered"
                  />
                  <label htmlFor="whenRegistered" className="ml-3">
                    {t(
                      '/admin/application-management.apps_fieldset.datatable.columns.when_registered'
                    )}
                  </label>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <label
                  htmlFor="nonRegisteredURL"
                  className={formik.getFieldProps('isAlwaysVisible').value ? 'required' : ''}>
                  {t(
                    '/admin/application-management.apps_fieldset.datatable.columns.non_registered_URL'
                  )}
                </label>
                <InputText
                  id="nonRegisteredURL"
                  value={formik.values.nonRegisteredURL}
                  disabled={formik.getFieldProps('whenRegistered').value}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.nonRegisteredURL && formik.errors.nonRegisteredURL
                      ? 'p-invalid'
                      : ''
                  }
                />
                {formik.touched.nonRegisteredURL && formik.errors.nonRegisteredURL ? (
                  <small className="p-invalid">{formik.errors.nonRegisteredURL}</small>
                ) : null}
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col xs={12}>
            <label htmlFor="verificationURL" className="required">
              {t('/admin/application-management.apps_fieldset.datatable.columns.verificationURL')}
            </label>
            <InputText
              id="verificationURL"
              value={formik.values.verificationURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.verificationURL && formik.errors.verificationURL ? 'p-invalid' : ''
              }
            />
            {formik.touched.verificationURL && formik.errors.verificationURL ? (
              <small className="p-invalid">{formik.errors.verificationURL}</small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="website">
              {t('/admin/application-management.apps_fieldset.datatable.columns.website')}
            </label>
            <InputText
              id="website"
              value={formik.values.website}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.website && formik.errors.website ? 'p-invalid' : ''}
            />
            {formik.touched.website && formik.errors.website ? (
              <small className="p-invalid">{formik.errors.website}</small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Checkbox
              inputId="isActive"
              checked={formik.values.isActive}
              value={formik.values.isActive}
              onChange={(event: any) => formik.setFieldValue('isActive', event.checked)}
            />
            <label htmlFor="isActive" className="pl-2 mb-0">
              {t('/admin/application-management.apps_fieldset.datatable.columns.confirmed')}
            </label>
            {formik.touched.isActive && formik.errors.isActive ? (
              <small className="p-invalid">{formik.errors.isActive}</small>
            ) : null}
          </Col>
        </Row>
      </FormWrapper>
      <div>
        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={showConfirmPopup}
          title={t('/confirm_messages.delete_record')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
        />

        <Dialog
          header={t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplicationsHeader'
          )}
          visible={
            showDeleteLinkedApplicationsWarning &&
            deleteData &&
            (deleteData.userCount > 0 || deleteData.roleCount > 0)
          }
          className="custom-dialog-container"
          footer={renderRowDeleteDialogFooter()}
          onHide={() => setShowDeleteLinkedApplicationsWarning(false)}
          draggable={false}>
          {`
          ${t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplications'
          )}
          `}
          <br />
          <br />
          {`
          ${
            deleteData.userCount > 0
              ? `Total Users linked: ${deleteData.userCount} [${deleteData.linkedUsers
                  .map((user) => user.username)
                  .join(', ')}]`
              : ''
          }
          ${deleteData.usersNotShownCount > 0 ? `(${deleteData.usersNotShownCount} not shown)` : ``}
          `}
          <br />
          {` 
          ${
            deleteData.roleCount > 0
              ? `Total Roles linked: ${deleteData.roleCount} [${deleteData.linkedRoles.join(', ')}]`
              : ''
          } 
          ${deleteData.rolesNotShownCount > 0 ? `(${deleteData.rolesNotShownCount} not shown)` : ``}
          `}
        </Dialog>

        <CropDialog
          isVisible={isLogoFileChanged}
          setVisible={setIsLogoFileChanged}
          file={uploadedAppLogoFile}
          onImageCropComplete={handleLogoImageCropComplete}
        />

        <CropDialog
          isVisible={isIconFileChanged}
          setVisible={setIsIconFileChanged}
          file={uploadedIconFile}
          onImageCropComplete={handleIconImageCropComplete}
          isIcon
        />
      </div>
    </>
  );
};

export default AppForm;
