/**
* ForgotPasswordSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ForgotPasswordSlice.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  IAPIEntityResponse,
  IAPIErrorData,
  IApiBackEndGeneralResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IValidateVerificationCodeResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { Reducer } from 'react';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import { passwordReset, sendVerificationCode, verifyCode } from '../Services/UserApi';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const forgotPasswordFeatureKey: string = 'forgot-password';

export interface IForgotPasswordState {
  verificationError: any;
  resetPasswordError: any;
  verificationSuccess: any;
  resetPasswordSuccess: any;
  isLoading: boolean;
  token: any;
  passwordResetError: any;
  passwordResetSuccess: any;
  selectedUser: any;
  sendVerificationSuccess: boolean /**< Send verifcation success state */;
  sendVerificationError: string /**< Send verifcation error state */;
}

export const forgotPasswordInitialState: IForgotPasswordState = {
  verificationError: null,
  resetPasswordError: null,
  isLoading: false,
  verificationSuccess: null,
  resetPasswordSuccess: null,
  token: null,
  passwordResetError: null,
  passwordResetSuccess: null,
  selectedUser: null,
  sendVerificationSuccess: false,
  sendVerificationError: null
};

export const forgotPasswordAdapter: EntityAdapter<IForgotPasswordState> = createEntityAdapter();
export const initialForgotPasswordState: EntityState<IForgotPasswordState> & IForgotPasswordState =
  forgotPasswordAdapter.getInitialState(forgotPasswordInitialState);

export const sendVerificationCodeAction = createAsyncThunk(
  'forgot-password/send-verification-code',
  async (payload: any) => {
    const email: string = payload.email;
    const response: IAPIEntityResponse<IApiBackEndGeneralResponse> = await asyncErrorHandler(
      sendVerificationCode(email)
    );
    return response;
  }
);

export const verifyCodeAction = createAsyncThunk('forgot-password/verify', async (payload: any) => {
  const response: IAPIEntityResponse<IValidateVerificationCodeResponse> = await asyncErrorHandler(
    verifyCode(payload)
  );
  return response;
});

export const passwordResetAction = createAsyncThunk(
  'forgot-password/reset-pass',
  async (payload: any) => {
    const response: IAPIEntityResponse<{ message: string }> = await asyncErrorHandler(
      passwordReset({
        token: payload.token,
        username: payload.username,
        password: payload.password,
        verificationCode: payload.verificationCode
      })
    );
    return response;
  }
);

const clearState = (state: IForgotPasswordState) => {
  state.verificationError = null;
  state.resetPasswordError = null;
  state.isLoading = false;
  state.verificationSuccess = null;
  state.resetPasswordSuccess = null;
  state.passwordResetError = null;
  state.passwordResetSuccess = null;
  state.token = null;
  state.selectedUser = null;
  state.sendVerificationSuccess = false;
  state.sendVerificationError = null;
};

export const forgotPasswordSlice = createSlice({
  name: forgotPasswordFeatureKey,
  initialState: initialForgotPasswordState,
  reducers: {
    reset: (state: IForgotPasswordState) => clearState(state),
    updateUser: (state: IForgotPasswordState, action: IReducerAction<{ user: IUser }>) => {
      state.selectedUser = action.payload.user;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendVerificationCodeAction.pending, (state: IForgotPasswordState) => {
        state.isLoading = true;
        state.sendVerificationSuccess = false;
        state.sendVerificationError = null;
      })
      .addCase(
        sendVerificationCodeAction.fulfilled,
        (
          state: IForgotPasswordState,
          action: IReducerAction<IAPIEntityResponse<IApiBackEndGeneralResponse>>
        ) => {
          state.isLoading = false;
          if (action.payload.status === 200) {
            state.sendVerificationSuccess = true;
          } else {
            state.sendVerificationError = (action.payload.error as string) || '';
          }
        }
      )
      .addCase(
        sendVerificationCodeAction.rejected,
        (state: IForgotPasswordState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.sendVerificationSuccess = false;
          state.sendVerificationError = (action.error && action.error.message) || '';
        }
      )
      .addCase(verifyCodeAction.pending, (state: IForgotPasswordState) => {
        state.isLoading = true;
        state.verificationError = null;
        state.verificationSuccess = null;
        state.token = null;
      })
      .addCase(
        verifyCodeAction.fulfilled,
        (
          state: IForgotPasswordState,
          action: IReducerAction<IAPIEntityResponse<IValidateVerificationCodeResponse>>
        ) => {
          state.isLoading = false;
          if (action.payload.status === 200) {
            state.token = action.payload.data.token;
            state.verificationSuccess = true;
            state.verificationError = null;
          } else {
            state.verificationError = action.payload.error || '';
            state.verificationSuccess = null;
            state.token = null;
          }
        }
      )
      .addCase(
        verifyCodeAction.rejected,
        (state: IForgotPasswordState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.verificationError = (action.error && action.error.message) || '';
          state.verificationSuccess = null;
          state.token = null;
        }
      )
      .addCase(passwordResetAction.pending, (state: IForgotPasswordState) => {
        state.isLoading = true;
        state.passwordResetError = null;
        state.passwordResetSuccess = null;
      })
      .addCase(
        passwordResetAction.fulfilled,
        (
          state: IForgotPasswordState,
          action: IReducerAction<IAPIEntityResponse<{ message: string }>>
        ) => {
          state.isLoading = false;
          if (action.payload.status === 200) {
            state.passwordResetSuccess = true;
            state.passwordResetError = null;
          } else {
            state.passwordResetSuccess = null;
            state.passwordResetError = action.payload.error;
          }
        }
      )
      .addCase(
        passwordResetAction.rejected,
        (state: IForgotPasswordState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.passwordResetSuccess = null;
          state.passwordResetError = action && action.error && action.error.message;
        }
      );
  }
});

export const forgotPasswordReducer: Reducer<
  EntityState<IForgotPasswordState> & IForgotPasswordState,
  AnyAction
> = forgotPasswordSlice.reducer;

export const forgotPasswordActions: any = forgotPasswordSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = forgotPasswordAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getForgotPasswordState: any = (rootState: any) => rootState[forgotPasswordFeatureKey];
export const selectAllForgotPassword: any = createSelector(getForgotPasswordState, selectAll);
export const selectForgotPasswordEntities: any = createSelector(
  getForgotPasswordState,
  selectEntities
);
