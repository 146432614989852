/**
* index.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file index.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import ReactDOM from 'react-dom';
import './Global.css';
import '@abstract/abstractwebcommon-client/common.css';
import '@abstract/abstractwebcommon-client/theme.scss';
import './Scss/App.scss'; //Note: used only to apply custom Bootstrap breakpoints
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './ServiceWorker';
import { store } from './Store/Store';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
