/**
* RoleManagement.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file RoleManagement.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// other dependencies
import {
  deleteRolesAction,
  getUserCountByRoleIdAction,
  getRolesListAction,
  getRoleState,
  createRolesAction,
  updateRolesAction,
  roleActions,
  IRoleState
} from '../../../Store/RolesSlice';
import RoleForm from './RoleForm';
import RoleTable from './RoleTable';
import { TFunction } from 'i18next';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { getSettingsState, ISettingsState } from '../../../Store/SettingsSlice';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';

const RoleManagement = (): JSX.Element => {
  const roleState: IRoleState = useSelector(getRoleState);
  const settingsState: ISettingsState = useSelector(getSettingsState); /**< Get settings state. */
  const adminRoleName: string =
    (settingsState && settingsState.safeSettings?.adminRoleName) ||
    'ROLE_ADMIN'; /**< Admin Role name. */
  const dispatch: Dispatch<any> = useDispatch();
  const t: TFunction = useTranslation().t;

  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);
  const [editRole, setEditRole] = useState<any>({});
  const [selectedRoles, setSelectedRoles] = useState<IRole[] | null>(null);
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'DESC'
    },
    searchTerm: ''
  }); /**< Default Payload */

  ///  checks if the roles list is loaded, else dispatches action to load roles
  useEffect(() => {
    dispatch(getRolesListAction(payload)); // on mount

    // on unmount
    return () => {
      dispatch(roleActions.reset());
    };
  }, [dispatch]);

  /// Delete functionality
  useEffect(() => {
    onHide();
    if (roleState.isDeleted) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.role_delete_success'),
        detail: ''
      });
      setSelectedRoles(null);
      dispatch(getRolesListAction(payload));
    }
  }, [dispatch, roleState.isDeleted, t]);

  // check errors
  useEffect(() => {
    if (roleState.deleteError !== null) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: roleState.deleteError?.message || roleState.deleteError
      });
    }
    if (roleState.fetchError !== null) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: roleState.fetchError?.message || roleState.fetchError
      });
    }
  }, [dispatch, roleState.fetchError, roleState.deleteError, t]);

  const handleSubmit = (rolePayload: any, roleId: string) => {
    if (roleId) {
      // on edit
      dispatch(updateRolesAction({ body: rolePayload, id: roleId }));
    } else {
      // on new role
      dispatch(createRolesAction(rolePayload));
    }
  };

  /// Triggered on RoleForm dialog hide event. This dispatches loadUser action if there is any change in the roles list
  const onHide = (load?: boolean) => {
    setEditRole({});
    setDialogVisible(false);
    if (load) {
      refreshList(payload);
    }
  };

  useEffect(() => {
    if (roleState.isCreated) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.role_create_success'),
        detail: ''
      });
      onHide(true);
    } else if (roleState.isUpdated) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.role_update_success'),
        detail: ''
      });
      onHide(true);
    }
  }, [roleState.isCreated, roleState.isUpdated, dispatch, onHide, t]);

  // Check errors
  useEffect(() => {
    if (roleState.createError !== null) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: roleState.createError?.message || roleState.createError
      });
    }
    if (roleState.updateError !== null) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: roleState.updateError?.message || roleState.updateError
      });
    }
  }, [roleState, dispatch, t]);

  /// Dispatches action to delete selected rows
  const deleteRoles = async (selectedRoles: any, isConfirmedDelete: boolean) => {
    const adminRole: IRole = selectedRoles.some((role: any) => role['name'] === adminRoleName);
    const rootApplication = selectedRoles.some(
      (role: any) =>
        role.application?.applicationUUID === process.env.REACT_APP_ROOT_APPLICATION_UUID
    );

    if (adminRole && rootApplication) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: t('I18N.error_messages.admin_role_delete_failure')
      });
    } else {
      const roleUUIDs: any = { roleUUIDs: selectedRoles.map((each: any) => each.roleUUID) };
      isConfirmedDelete
        ? dispatch(deleteRolesAction(roleUUIDs))
        : dispatch(getUserCountByRoleIdAction(roleUUIDs));
      return;
    }
  };

  const refreshList = (updatedCriteria?: ITablePayload) => {
    setPayload(updatedCriteria);
    dispatch(getRolesListAction(updatedCriteria));
  };

  const tableData = {
    criteria: payload,
    isLoading: roleState.isLoading,
    roles: roleState.roles,
    totalRecords: roleState.totalRecords
  }; /**< Data for Role table. */

  const getDataTable = () => {
    return (
      <RoleTable
        tableData={tableData}
        refreshList={refreshList}
        setDialogVisible={setDialogVisible}
        deleteRoles={deleteRoles}
        deleteData={roleState.deleteData}
        setEditRole={setEditRole}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
      />
    );
  };

  /// Returns the role dialog form.
  const getRoleDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isDialogVisible}
        onHide={() => onHide()}
        headerTitle={
          editRole.id
            ? t('/admin/role-management.roles_fieldset.edit_role_dialog.header')
            : t('/admin/role-management.roles_fieldset.add_role_dialog.header')
        }>
        <RoleForm
          editRole={editRole}
          deleteRoles={deleteRoles}
          deleteData={roleState.deleteData}
          handleSubmit={handleSubmit}
          onHide={onHide}
          isLoading={roleState.isLoading}
          adminRoleName={adminRoleName}
        />
      </DialogWrapper>
    );
  };

  return (
    <>
      {getDataTable()}
      {getRoleDialog()}
    </>
  );
};

export default RoleManagement;
