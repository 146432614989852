/**
* ForgotPasswordPage.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan k, 2020 
* @file ForgotPasswordPage.tsx
* @author Sai Charan k
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ForgotPasswordWrapper from '@abstract/abstractwebcommon-client/ForgotPassword/ForgotPasswordWrapper';
import {
  getForgotPasswordState,
  sendVerificationCodeAction,
  forgotPasswordActions,
  verifyCodeAction,
  passwordResetAction,
  IForgotPasswordState
} from '../../Store/ForgotPasswordSlice';
import { useTranslation } from 'react-i18next';
import {
  getApplicationSafeDetailsAction,
  getApplicationState,
  IApplicationState
} from '../../Store/ApplicationSlice';
import { TFunction } from 'i18next';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/LogApi';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Card } from 'primereact/card';
import { Alert } from 'react-bootstrap';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { validateForgotPasswordToken } from '../../Services/UserApi';
import Loader from '@abstract/abstractwebcommon-client/Loader';

enum FORGOT_PASS_STEPS {
  FIND_ACCOUNT,
  VERIFY,
  RESET_PASS
}

/**
 * Defines the interface for the function isVerificationLinkValid and the UserAPI service validateForgotPasswordToken.
 */
export interface IForgotPasswordTokenValidationResponse {
  isTokenValid: boolean /* Defines if the token is valid */;
}

const ForgotPasswordPage = (): JSX.Element => {
  const history: any = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const state: IForgotPasswordState = useSelector(getForgotPasswordState);
  const appState: IApplicationState = useSelector(getApplicationState);
  const [appDetails, setAppDetails] = useState<any>(null);
  const t: TFunction = useTranslation().t;
  const urlParams = new URLSearchParams(window.location.search);
  const verificationCode: string = urlParams.get('verificationCode');
  const userUUID: string = urlParams.get('userUUID');
  const username: string = urlParams.get('username');
  const token: string = urlParams.get('token'); /**< Token from query string */

  const [step, setStep] = useState<number>(0);

  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search);
  const app: string = parsedString.app;
  const identifier: string = parsedString.identifier;
  const [isTokenValid, setTokenValid] = useState<boolean>(); /**< Token is valid or not */

  /// Clear state and redirect to login on cancel
  const onCancel = () => {
    const passwordRedirectUrl: string = appState?.safeApplicationDetails?.passwordResetRedirectUrl;
    dispatch(forgotPasswordActions.reset());
    if (app && identifier && passwordRedirectUrl && passwordRedirectUrl !== '') {
      window.location.href = passwordRedirectUrl;
    } else {
      ///Note: Go to the login page when you click the Cancel button.
      if (step === 2) {
        // Go to the login page when you click cancel button in step 2 (Reset Password) of email verfication link.
        history.push({ pathname: SharedCommomRouteName.loginRoute });
      } else {
        // Go back to the application login page if you are in other applications like License or Ecommerce.
        history.goBack();
      }
    }
  };

  /// Clear state on unmount
  useEffect(() => {
    return () => {
      dispatch(forgotPasswordActions.reset());
    };
  }, [dispatch]);

  /// get the safe app details
  useEffect(() => {
    if (app) {
      dispatch(getApplicationSafeDetailsAction({ name: app, key: identifier }));
    }
  }, [app, dispatch, getApplicationSafeDetailsAction]);

  useEffect(() => {
    setAppDetails(appState.safeApplicationDetails);
  }, [appState.safeApplicationDetails]);

  /**
   * To validate token. We should send a new verification link in case the current one is expired.
   * @returns Promise<boolean>
   */
  const isVerificationLinkValid = async (): Promise<boolean> => {
    const response: IAPIEntityResponse<IForgotPasswordTokenValidationResponse> =
      await validateForgotPasswordToken({ token, username });
    return response?.data?.isTokenValid ?? false;
  };

  /**
   * To send verification codee
   * @returns Promise<void>
   */
  const sendVerificationCode = async (): Promise<void> => {
    if (userUUID && verificationCode && username) {
      let isVerificationLinkValidResult: boolean = false;
      if (token) {
        isVerificationLinkValidResult = await isVerificationLinkValid();
        setTokenValid(isVerificationLinkValidResult);
      }
      // If there is a token in the query string and it is valid or there is no token, proceed to verify the code.
      if ((token && isVerificationLinkValidResult) || !token) {
        dispatch(
          verifyCodeAction({
            userUUID: userUUID,
            verificationCode: parseInt(verificationCode)
          })
        );
      }
    }
  };

  useEffect(() => {
    sendVerificationCode();
  }, []);

  /**
   * Token expired
   * @returns JSX.Element
   */
  const TokenExpired = (): JSX.Element => {
    return (
      <Container className="activationContainer">
        <Row>
          <Col xs={12} className="mt-3">
            <Card
              className="rounded-corners-to-card-component keep-original-card-style keep-original-card-title-style"
              title={t('I18N.reset_password.title')}>
              <Alert variant="warning">
                {t('I18N.reset_password.password_reset_token_expired')}
              </Alert>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    if (token && isTokenValid != undefined && !isTokenValid) {
      setStep(FORGOT_PASS_STEPS.RESET_PASS);
    }
  }, [isTokenValid]);

  /// Check verification of code success/failure
  useEffect(() => {
    if (state.verificationSuccess) {
      setStep(FORGOT_PASS_STEPS.RESET_PASS);
    }
    if (state.verificationError) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: state.verificationError.message || state.verificationError
      });
    }
  }, [state.verificationSuccess, state.verificationError]);

  /// Check password reset
  useEffect(() => {
    const passwordRedirectUrl = appState?.safeApplicationDetails?.passwordResetRedirectUrl;
    if (state.passwordResetSuccess) {
      showToast({ severity: 'success', summary: t('I18N.reset_password.password_reset_success') });
      setTimeout(() => {
        if (app && identifier && passwordRedirectUrl && passwordRedirectUrl !== '') {
          window.location.href = passwordRedirectUrl;
        } else {
          history.push({ pathname: SharedCommomRouteName.loginRoute });
        }
      }, 1000);
    }
    if (state.passwordResetError) {
      showToast({
        severity: 'error',
        summary: state.passwordResetError?.message || state.passwordResetError
      });
    }
  }, [state.passwordResetSuccess, state.passwordResetError, dispatch, t, history]);

  // Check send verification of code success/failure
  useEffect(() => {
    if (state.sendVerificationSuccess) {
      setStep(FORGOT_PASS_STEPS.VERIFY);
    }
    if (state.sendVerificationError) {
      showToast({
        severity: 'error',
        summary: state.sendVerificationError
      });
    }
  }, [state.sendVerificationSuccess, state.sendVerificationError]);

  /// Perform submit actions on different steps
  const onSubmit = async (data?: any) => {
    if (step === FORGOT_PASS_STEPS.FIND_ACCOUNT) {
      if (data) {
        dispatch(sendVerificationCodeAction({ email: data.email }));
      }
    } else if (step === FORGOT_PASS_STEPS.RESET_PASS) {
      let isVerificationLinkValidResult: boolean = false;
      if (token) {
        // Validate the token again as the user may remain on the same page before resetting the password.
        isVerificationLinkValidResult = await isVerificationLinkValid();
        setTokenValid(isVerificationLinkValidResult);
      }
      if ((token && isVerificationLinkValidResult) || !token) {
        dispatch(
          passwordResetAction({
            username: username,
            password: data.password,
            token: state.token,
            verificationCode: parseInt(verificationCode)
          })
        );
      }
    }
  };

  if (token && isTokenValid != undefined && !isTokenValid) {
    return <TokenExpired />;
  }

  return (
    <div className="forgotPasswordPage">
      <div className="forgotPasswordContainer">
        {token && isTokenValid === undefined ? (
          <Loader />
        ) : (
          <>
            <h3 className="passwordResetTitle">
              {appDetails?.applicationName?.replace(/_|-|,/g, ' ')}
            </h3>
            <ForgotPasswordWrapper
              pageIndex={step}
              onCancel={onCancel}
              onSubmit={onSubmit}
              isLoading={state.isLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(ForgotPasswordPage, createLogApi);
