/**
* InstaAutoComplete.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file InstaAutoComplete.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { ReactNode } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';

/**
 * Interface for InstaAutoComplete properties.
 */
interface IInstaAutoCompleteProperties {
  name: string /**< Name of the input element. */;
  value: IApplications /**< Value of the component. */;
  suggestions: IApplications[] /**< An array of suggestions to display. */;
  className: string /**< Style class of the component. */;
  completeMethod: (
    event: any
  ) => Promise<void> /**< Callback to invoke to search for suggestions. */;
  field: string /**< Field of a suggested object to resolve and display. */;
  onChange: (event: any) => void /**< Callback to invoke when autocomplete value changes. */;
  onBlur: any /**< Callback to invoke when autocomplete loses focus. */;
  inputId: string /**< Identifier of the input element. */;
  children?: ReactNode /**< Children component. */;
}

const InstaAutoComplete = (properties: IInstaAutoCompleteProperties): JSX.Element => {
  return (
    <>
      <AutoComplete
        name={properties && properties.name}
        value={properties && properties.value}
        suggestions={properties && properties.suggestions}
        className={properties && properties.className}
        field={properties && properties.field}
        onChange={properties && properties.onChange}
        onBlur={properties && properties.onBlur}
        completeMethod={properties && properties.completeMethod}
        dropdown={true}
        {...properties}
      />
      {properties && properties.children}
    </>
  );
};

export default InstaAutoComplete;
