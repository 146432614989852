/**
* ApplicationManagement.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file ApplicationManagement.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Other dependencies
import {
  deleteApplicationAction,
  getApplicationListAction,
  getApplicationState,
  createApplicationAction,
  updateApplicationAction,
  applicationActions,
  IApplicationState,
  getUserCountByApplicationIDAction
} from '../../../Store/ApplicationSlice';
import { AppTable } from './AppTable';
import AppForm from './AppForm';
import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';

const ApplicationManagement = (): JSX.Element => {
  const appState: IApplicationState = useSelector(getApplicationState);
  const dispatch: Dispatch<any> = useDispatch();
  const t: TFunction = useTranslation().t;

  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);
  const [editApp, setEditApp] = useState<any>({});
  const [selectedApps, setSelectedApps] = useState<IApplications[] | null>(null);

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'DESC'
    },
    searchTerm: ''
  }); /**< Default Payload */

  useEffect(() => {
    dispatch(getApplicationListAction(payload));

    // on unmount
    return () => {
      dispatch(applicationActions.reset());
    };
  }, [dispatch]);

  /// Check delete flag
  useEffect(() => {
    if (appState.isDeleted) {
      setDialogVisible(false);
      showToast({
        severity: 'success',
        summary: 'Application(s) deleted successfully',
        detail: ''
      });
      setSelectedApps(null);
      dispatch(getApplicationListAction(payload));
    }
  }, [dispatch, appState]);

  /// check errors
  useEffect(() => {
    if (appState.deleteError !== null) {
      showToast({
        severity: 'error',
        summary: 'Failed',
        detail: appState.deleteError?.message || appState.deleteError
      });
    }
    if (appState.fetchError !== null) {
      showToast({ severity: 'error', summary: 'Failed', detail: appState.fetchError });
    }
  }, [dispatch, appState]);

  /// Triggered on AppForm dialog hide event. This dispatches loadApplications action if there is any change in the applications list
  const onHide = () => {
    // dispatch(initializeAppStateAction({}));
    setDialogVisible(false);
    setEditApp({});
  };

  useEffect(() => {
    if (appState.createError !== null) {
      showToast({
        severity: 'error',
        summary: 'Failed',
        detail: appState.createError?.message || appState.createError
      });
    }
    if (appState.updateError !== null) {
      showToast({
        severity: 'error',
        summary: 'Failed',
        detail: appState.updateError?.message || appState.updateError
      });
    }
  }, [dispatch, appState]);

  useEffect(() => {
    if (appState.isCreated) {
      showToast({ severity: 'success', summary: 'Application created successfully', detail: '' });
      onHide();
    }

    if (appState.isUpdated) {
      showToast({ severity: 'success', summary: 'Application updated successfully', detail: '' });
      onHide();
    }
  }, [dispatch, appState]);

  const refreshList = (updatedCriteria?: ITablePayload) => {
    setPayload(updatedCriteria);
    dispatch(getApplicationListAction(updatedCriteria));
  };

  const deleteApps = async (selectedApplications: any, confirmedDelete: boolean) => {
    const applicationUUIDs: any = {
      applicationUUIDs: selectedApplications.map(
        (eachApplication: any) => eachApplication.applicationUUID
      )
    };
    confirmedDelete
      ? dispatch(deleteApplicationAction(applicationUUIDs.applicationUUIDs))
      : dispatch(getUserCountByApplicationIDAction(applicationUUIDs));
  };

  const handleSubmit = (submitPayload: any, id: string) => {
    if (id) {
      dispatch(updateApplicationAction({ data: submitPayload, id }));
    } else {
      dispatch(createApplicationAction(submitPayload));
    }
  };

  /// Returns the add or edit application dialog
  const getEditApplicationDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isDialogVisible}
        onHide={() => {
          setDialogVisible(false);
          setEditApp({});
        }}
        headerTitle={
          editApp.id
            ? t('/admin/application-management.apps_fieldset.edit_app_dialog.header')
            : t('/admin/application-management.apps_fieldset.add_app_dialog.header')
        }>
        <AppForm
          editApp={editApp}
          deleteApps={deleteApps}
          deleteData={appState.deleteData}
          handleSubmit={handleSubmit}
          isLoading={appState.isLoading}
        />
      </DialogWrapper>
    );
  };

  const tableData = {
    criteria: payload,
    isLoading: appState.isLoading,
    applications: appState.applications,
    totalRecords: appState.totalRecords
  };

  return (
    <>
      <AppTable
        tableData={tableData}
        deleteData={appState.deleteData}
        refreshList={refreshList}
        setDialogVisible={setDialogVisible}
        setEditApp={setEditApp}
        deleteApps={deleteApps}
        selectedApps={selectedApps}
        setSelectedApps={setSelectedApps}
      />
      {getEditApplicationDialog()}
    </>
  );
};

export default ApplicationManagement;
