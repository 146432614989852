/*
 * i18n.js (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file i18n.js
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../Translations/En.json';
import translationDe from '../Translations/De.json';
import translationJa from '../Translations/Ja.json';
import translationZh from '../Translations/Zh.json';
import translationKo from '../Translations/Ko.json';
import translationCommonEn from '@abstract/abstractwebcommon-client/translations/en.json';
import translationCommonDe from '@abstract/abstractwebcommon-client/translations/De.json';
import translationCommonJa from '@abstract/abstractwebcommon-client/translations/Ja.json';
import translationCommonZh from '@abstract/abstractwebcommon-client/translations/Zh.json';
import translationCommonKo from '@abstract/abstractwebcommon-client/translations/Ko.json';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  ns: ['common', 'awc'],
  defaultNS: 'common',
  resources: {
    en: {
      common: translationEn.translation,
      awc: translationCommonEn.translation
    },
    de: {
      common: translationDe.translation,
      awc: translationCommonDe.translation
    },
    ja: {
      common: translationJa.translation,
      awc: translationCommonJa.translation
    },
    zh: {
      common: translationZh.translation,
      awc: translationCommonZh.translation
    },
    ko: {
      common: translationKo.translation,
      awc: translationCommonKo.translation
    }
  },
  lng: 'en',
  interpolation: { escapeValue: false }
});

export default i18n;
