/**
* TemplateSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file TemplateSlice.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ITemplates } from '@abstract/abstractwebcommon-shared/interfaces/user/templates';
import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState,
  ThunkDispatch
} from '@reduxjs/toolkit';
import { Reducer } from 'react';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import i18n from '../Services/I18n';
import {
  getAllCSSTemplatesApi,
  getAllPublicTemplatesApi,
  getAllTemplatesApi,
  getCSSApi,
  updateCSSApi,
  updateTemplateApi
} from '../Services/TemplateApi';
import { getSafeAppSettingsAction } from './SettingsSlice';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const templateFeatureKey: string = 'templates';

/**
 * Interface USER_INITIAL_STATE
 */
export interface ITemplateState {
  templates: any[];
  publicTemplates: any[];
  CSSTemplates: any[];
  CSSTemplate: any;
  loadPublicTemplateError: any;
  loadTemplateError: any;
  loadCssError: any;
  isTemplateUpdated: boolean;
  isCssUpdated: boolean;
  selectedTemplate: any;
  isLoading: boolean;
}

export const templateInitialState: ITemplateState = {
  templates: null,
  publicTemplates: [],
  CSSTemplates: [],
  CSSTemplate: null,
  loadTemplateError: null,
  loadPublicTemplateError: null,
  loadCssError: null,
  isTemplateUpdated: false,
  isCssUpdated: false,
  selectedTemplate: null,
  isLoading: false
};

export const templateAdapter: EntityAdapter<ITemplateState> = createEntityAdapter();
export const initialTemplateState: EntityState<ITemplateState> & ITemplateState =
  templateAdapter.getInitialState(templateInitialState);

export const getTemplatesAction = createAsyncThunk(
  'templates/get',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getTemplatesActionRequest, getTemplatesActionSuccess, getTemplatesActionFailure } =
      templateActions;
    try {
      dispatch(getTemplatesActionRequest());
      const result: IAPIEntityResponse<ITemplates[]> = await asyncErrorHandler(
        getAllTemplatesApi()
      );
      if (result.error) {
        dispatch(getTemplatesActionFailure(result.error));
      } else {
        dispatch(getTemplatesActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getTemplatesActionFailure(error.message));
    }
  }
);

/**
 * get all public template action
 */
export const getPublicTemplatesAction = createAsyncThunk(
  'templates/get/public',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getPublicTemplatesActionRequest,
      getPublicTemplatesActionSuccess,
      getPublicTemplatesActionFailure
    } = templateActions;
    try {
      dispatch(getPublicTemplatesActionRequest());
      const result: IAPIEntityResponse<ITemplates[]> = await asyncErrorHandler(
        getAllPublicTemplatesApi()
      );
      if (result.error) {
        dispatch(getPublicTemplatesActionFailure(result.error));
      } else {
        dispatch(getPublicTemplatesActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getPublicTemplatesActionFailure(error.message));
    }
  }
);

/**
 * get all css template action
 */
export const getAllCSSTemplatesAction = createAsyncThunk(
  'templates/get',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const getAllCSSTemplatesActionRequest: any = templateActions.getAllCSSTemplatesActionRequest;
    const getAllCSSTemplatesActionSuccess: any = templateActions.getAllCSSTemplatesActionSuccess;
    const getAllCSSTemplatesActionFailure: any = templateActions.getAllCSSTemplatesActionFailure;
    try {
      dispatch(getAllCSSTemplatesActionRequest());
      const result: IAPIEntityResponse<ITemplates[]> = await asyncErrorHandler(
        getAllCSSTemplatesApi()
      );
      if (result.error) {
        dispatch(getAllCSSTemplatesActionFailure(result.error));
      } else {
        dispatch(getAllCSSTemplatesActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getAllCSSTemplatesActionFailure(error.message));
    }
  }
);

/**
 * get css template action
 */
export const getCSSAction = createAsyncThunk(
  'templates/css/get',
  async (payload: { templateUUID?: string }, thunkAPI) => {
    const templateUUID: string | null = payload.templateUUID ?? null;
    const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = thunkAPI.dispatch;
    const getState: any = thunkAPI.getState;

    const getCSSActionRequest: any = templateActions.getCSSActionRequest;
    const getCSSActionSuccess: any = templateActions.getCSSActionSuccess;
    const getCSSActionFailure: any = templateActions.getCSSActionFailure;
    try {
      dispatch(getCSSActionRequest());
      if (!templateUUID) {
        dispatch(getSafeAppSettingsAction()).then(async () => {
          const state: any = getState();
          if (!state.settings.safeSettings.selectedCSSTemplate) {
            dispatch(getCSSActionFailure(i18n.t('I18N.template_editor.no_selected_CSS_template')));
          } else {
            const result: IAPIEntityResponse<string> = await asyncErrorHandler(
              getCSSApi(state.settings.safeSettings.selectedCSSTemplate.templateUUID)
            );
            if (result.error) {
              dispatch(getCSSActionFailure(result.error));
            } else if (result.data) {
              dispatch(getCSSActionSuccess(result.data));
            }
          }
        });
      } else {
        const result: IAPIEntityResponse<string> = await asyncErrorHandler(getCSSApi(templateUUID));
        if (result.error) {
          dispatch(getCSSActionFailure(result.error));
        } else if (result.data) {
          dispatch(getCSSActionSuccess(result.data));
        }
      }
    } catch (error: any) {
      dispatch(getCSSActionFailure(error.message));
    }
  }
);

/**
 * Update css template action
 */
export const updateCSSAction = createAsyncThunk(
  'templates/update',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const updateCSSActionRequest: any = templateActions.updateCSSActionRequest;
    const updateCSSActionSuccess: any = templateActions.updateCSSActionSuccess;
    const updateCSSActionFailure: any = templateActions.updateCSSActionFailure;

    try {
      dispatch(updateCSSActionRequest());
      const result: IAPIEntityResponse<ITemplates> = await asyncErrorHandler(
        updateCSSApi(payload.templateUUID, payload.css)
      );
      if (result.error) {
        dispatch(updateCSSActionFailure(result.error));
      } else {
        dispatch(updateCSSActionSuccess());
      }
    } catch (error: any) {
      dispatch(updateCSSActionFailure(error.message));
    }
  }
);

/**
 * Update template action
 */
export const updateTemplatesAction = createAsyncThunk(
  'templates/update',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      updateTemplatesActionRequest,
      updateTemplatesActionSuccess,
      updateTemplatesActionFailure
    } = templateActions;

    try {
      dispatch(updateTemplatesActionRequest());
      const result: IAPIEntityResponse<ITemplates> = await asyncErrorHandler(
        updateTemplateApi(payload.templateUUID, payload)
      );
      if (result.error) {
        dispatch(updateTemplatesActionFailure(result.error));
      } else {
        dispatch(updateTemplatesActionSuccess());
      }
    } catch (error: any) {
      dispatch(updateTemplatesActionFailure(error.message));
    }
  }
);

const clearOnRequest = (state: ITemplateState) => {
  state.isLoading = true;
  state.isTemplateUpdated = false;
  state.loadTemplateError = null;
  state.isCssUpdated = false;
  state.loadTemplateError = null;
};

/**
 * Creates Slice - All User related state will be stored here
 */
export const templateSlice = createSlice({
  name: templateFeatureKey,
  initialState: initialTemplateState,
  reducers: {
    getTemplatesActionRequest(state: ITemplateState) {
      clearOnRequest(state);
    },
    getTemplatesActionSuccess(state: ITemplateState, action: IReducerAction<ITemplates[]>) {
      state.isLoading = false;
      state.templates = action && action.payload;
      state.loadTemplateError = null;
    },
    getTemplatesActionFailure(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.loadTemplateError =
        (action && action.payload) || i18n.t('I18N.error_messages.fetch_template_failure');
    },
    getPublicTemplatesActionRequest(state: ITemplateState) {
      clearOnRequest(state);
      state.publicTemplates = [];
    },
    getPublicTemplatesActionSuccess(state: ITemplateState, action: IReducerAction<ITemplates[]>) {
      state.isLoading = false;
      state.publicTemplates = action && action.payload;
      state.loadPublicTemplateError = null;
    },
    getPublicTemplatesActionFailure(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.loadPublicTemplateError =
        (action && action.payload) || i18n.t('I18N.error_messages.fetch_template_failure');
    },
    getAllCSSTemplatesActionRequest(state: ITemplateState) {
      clearOnRequest(state);
    },
    getAllCSSTemplatesActionSuccess(state: ITemplateState, action: IReducerAction<ITemplates[]>) {
      state.isLoading = false;
      state.CSSTemplates = action && action.payload;
      state.loadTemplateError = null;
    },
    getAllCSSTemplatesActionFailure(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.loadTemplateError =
        (action && action.payload) || i18n.t('I18N.error_messages.fetch_template_failure');
    },
    updateTemplatesActionRequest(state: ITemplateState) {
      clearOnRequest(state);
    },
    updateTemplatesActionSuccess(state: ITemplateState) {
      state.isLoading = false;
      state.isTemplateUpdated = true;
    },
    updateTemplatesActionFailure(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.loadTemplateError =
        (action && action.payload) || i18n.t('I18N.error_messages.update_template_failure');
    },
    getCSSActionRequest(state: ITemplateState) {
      clearOnRequest(state);
      state.isLoading = true;
    },
    getCSSActionSuccess(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.CSSTemplate = action && action.payload;
      state.loadCssError = null;
    },
    getCSSActionFailure(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.loadCssError =
        (action && action.payload) || i18n.t('I18N.error_messages.fetch_css_failure');
    },
    updateCSSActionRequest(state: ITemplateState) {
      clearOnRequest(state);
    },
    updateCSSActionSuccess(state: ITemplateState) {
      state.isLoading = false;
      state.isCssUpdated = true;
    },
    updateCSSActionFailure(state: ITemplateState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.loadCssError =
        (action && action.payload) || i18n.t('I18N.error_messages.update_css_failure');
    }
  }
});

export const templateReducer: Reducer<EntityState<ITemplateState> & ITemplateState, AnyAction> =
  templateSlice.reducer;

export const templateActions: any = templateSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = templateAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getTemplateState: any = (rootState: any) => rootState[templateFeatureKey];
export const selectAllTemplate: any = createSelector(getTemplateState, selectAll);
export const selectTemplateEntities: any = createSelector(getTemplateState, selectEntities);
