/**
* PermissionPage.ts (Web Common) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file PermissionPage.ts
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section Web Common
*/

import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Col from "react-bootstrap/Col";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox } from 'primereact/checkbox';
import ImagePreview from "../ImagePreview";
import './PermissionPage.css'

/**
 * @interface IPermissionPageProperties
 */
interface IPermissionPageProperties {
  logoURL: string /** Brand logo URL to display */
  description: string /** Application description */
  applicationName: string /** Application name */
  isLoading: boolean /**< Is the requested endpoint loading */
  handleGrantPermission?: (e: React.MouseEvent<HTMLButtonElement>) => void /**< Call endpoint to update user permission */
  isEulaPermissionPage?: boolean /**< Eula permission page */;
  isEulaAccepted?: boolean /**< Eula terms accepted or not */;
  setEulaAccepted?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Set Eula accepted. */;
  eulaTermsText?: string /**< Eula terms Text */;
  handleAcceptEula?: (event: React.MouseEvent<HTMLButtonElement>) => void /**< Call endpoint to accept eula */
  isSLAPermissionPage?: boolean /**< SLA permission page */;
  isSLAAccepted?: boolean /**< SLA terms accepted or not */;
  setSLAAccepted?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Set SLA accepted. */;
  slaTermsText?: string /**< SLA terms Text */;
  handleAcceptSLA?: (event: React.MouseEvent<HTMLButtonElement>) => void /**< Call endpoint to accept SLA */
}

/**
 * Display this page when an user is accessing an app for the first time
 * @returns JSX.Element
 */
const PermissionPage = (properties: IPermissionPageProperties): JSX.Element => {
  const { t } = useTranslation();

  const [isLogoFullLoaded, setLogoFullLoaded] = useState<boolean>(false);

  const noImageContainer = () => {
    return <h2 className="text-center">{t("awc:/.page_title")}</h2>;
  };

  const SpinnerComponent = (): JSX.Element => (
    <>
      {properties.isLoading ? (
        <Spinner
          className="ml-2"
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : <></>}
    </>
  )

  /**
   * Render the default component for the permission page requirement.
   * @returns JSX.Element
   */
  const PermissionPageDefaultDescription = (): JSX.Element => (
    <>
      <p>{t("awc:/.application_permission_page.description")}</p>
      <br></br>
      <p>
        <Trans i18nKey="awc:/.application_permission_page.grant_access"
          values={{ applicationName: properties.applicationName ?? '' }}>
        </Trans>
      </p>
    </>
  )

  /**
   * Render the default footer component for the permission page requirement.
   * @returns JSX.Element
   */
  const PermissionPageDefaultFooter = (): JSX.Element => (
    <>
      <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => properties.handleGrantPermission(e)} className="btn-block btn btn-primary">{t('awc:/.application_permission_page.grant_access_button')}</button>
      <SpinnerComponent />
    </>
  )

  /**
   * Render the component to accept the EULA terms.
   * @returns JSX.Element
   */
  const AcceptEULAFooter = (): JSX.Element => (
    <div className="py-0 px-0 col-12">
      <div className="d-flex pb-2">
        <div>
          <Checkbox
            inputId="isEulaAccepted"
            value={properties.isEulaAccepted}
            onChange={(event: any) => properties.setEulaAccepted(event.checked)}
            checked={properties.isEulaAccepted}
            className='terms-checkbox'
          />
        </div>
        <div>
          <label htmlFor="isEulaAccepted" id="isEulaAccepted"
            className="mb-0 pl-2"
            dangerouslySetInnerHTML={{
              __html: properties.eulaTermsText
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <button
          disabled={properties.isEulaAccepted ? false : true}
          className="btn-block btn btn-primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => properties.handleAcceptEula(event)}
        >
          {t("awc:/.eula_permission_page.continue")}
        </button>

        <SpinnerComponent />
      </div>
    </div>
  )

  /**
   * Render the component to accept the SLA terms.
   * @returns JSX.Element
   */
  const AcceptSLAFooter = (): JSX.Element => (
    <div className="p-0 px-0 col-12">
      <div className="d-flex pb-2">
        <div>
          <Checkbox
            inputId="isSLAAccepted"
            value={properties.isSLAAccepted}
            onChange={(event: any) => properties.setSLAAccepted(event.checked)}
            checked={properties.isSLAAccepted}
            className='terms-checkbox'
          />
        </div>
        <div>
          <label htmlFor="isSLAAccepted" id="isSLAAccepted"
            className="mb-0 pl-2"
            dangerouslySetInnerHTML={{
              __html: properties.slaTermsText
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <button
          disabled={properties.isSLAAccepted ? false : true}
          className="btn-block btn btn-primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => properties.handleAcceptSLA(event)}
        >
          {t("awc:/.sla_permission_page.continue")}
        </button>

        <SpinnerComponent />
      </div>
    </div>
  )

  /**
   * Method to handle what should be the text to render in the head component.
   */
  const setPageHeadingText = () => {
    const { isEulaPermissionPage, isSLAPermissionPage } = properties

    switch (true) {
      case isEulaPermissionPage:
        return t("awc:/.eula_permission_page.heading");
      case isSLAPermissionPage:
        return t("awc:/.eula_permission_page.heading");
      default:
        return t("awc:/.application_permission_page.heading");
    }
  }

  /**
   * Method to handle what should be the text to render in the footer component.
   * @returns JSX.Element
   */
  const setPageFooterText = (): JSX.Element => {
    const { isEulaPermissionPage, isSLAPermissionPage } = properties

    switch (true) {
      case isEulaPermissionPage:
        return <AcceptEULAFooter />;
      case isSLAPermissionPage:
        return <AcceptSLAFooter />;
      default:
        return <PermissionPageDefaultFooter />;
    }
  }

  /**
   * Method to handle what should be the text to render in the description div.
   * @returns JSX.Element
   */
  const setDescriptionText = (): JSX.Element => {
    const { isEulaPermissionPage, isSLAPermissionPage } = properties

    switch (true) {
      case isEulaPermissionPage:
        return <p className="mb-0"> {t("awc:/.eula_permission_page.description")} </p>;
      case isSLAPermissionPage:
        return <p className="mb-0"> {t("awc:/.sla_permission_page.description")} </p>;
      default:
        return <PermissionPageDefaultDescription />;

    }
  }

  return (
    <Col className={`permissions-container loginForm col-md-10 ${isLogoFullLoaded || !properties.logoURL ? 'custom-display-element' : 'custom-hide-element'}`}>
      <div className="p-card p-component">
        <div className="p-card-body card-body-container">
          <div className="p-card-content">
            <div className="text-center justify-content-md-center row">
              <div className="col-12">
                {properties.logoURL && (
                  <ImagePreview
                    setLogoFullLoaded={setLogoFullLoaded}
                    altText={t("awc:/.login.logo_alt")}
                    showDelete={false}
                    imageUrl={properties.logoURL}
                    imgClass="logoImage"
                    isLogo={true}
                    noImageContainer={noImageContainer()}
                  />
                )}
                <div className="p-dialog-mask p-component-overlay p-dialog-center">
                </div>
              </div>
            </div>

            <h2 className="loginHeader custom-title-weight">
              {setPageHeadingText()}
            </h2>
            <p className="text-break mt-4">{properties.description ?? ''}</p>

            <div className="alert alert-secondary" style={{ marginTop: '25px' }}>
              {setDescriptionText()}
            </div>

            <form>
              <div className="row">
                <div className="py-0 col-12 d-flex align-items-center">
                  {setPageFooterText()}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default PermissionPage