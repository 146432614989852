/**
* FormSkeleton.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues K, 2022 
* @file FormSkeleton.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import SkeletonWrapper from '../SkeletonWrapper'

/**
 * Render a skeleton for Form component when data is loading for it.
 * 
 * @returns JSX.Element
 */
const FormSkeleton = (): JSX.Element => {
  const handleNumberFieldsToRender = () => {
    const htmlSkeleton = []

    for (let i = 0; i < 10; i++) { //10 is the number of fields to render in the skeleton
      htmlSkeleton.push(
        <div className="my-2">
          <SkeletonWrapper className="mb-2 w-100" />
          <SkeletonWrapper height="40px" className="w-100" />
        </div>
      );
    }

    return htmlSkeleton
  }

  return (
    <div className="w-100">
      {handleNumberFieldsToRender()}
    </div>
  )
}

export default FormSkeleton