/**
* SettingsApi.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file SettingsApi.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IImageUploadResponse,
  IGetSettingsResponse,
  IGetLogoResponse,
  IGetFavouriteIconResponse,
  IGetSafeSettingsResponse
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/user/environmentVariables';
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';
import {
  IAppSettings,
  IPublicLoginInformation
} from '@abstract/abstractwebcommon-shared/interfaces/user/settings';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * sends uploaded file as form data to the rest end point with isLogo as true
 * @param file File to be uploaded
 * @returns Promise
 */
export const uploadLogoApi = (file: Blob): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const fd = new FormData();
  fd.append('file', file, file['name']);
  fd.append('mode', 'logo');
  return httpClient.imageUpload<IImageUploadResponse>(`/system/image/upload`, fd);
};

/**
 * sends uploaded file as form data to the rest end point with isFavouriteIcon as true
 * @param file File to be uploaded
 * @returns Promise
 */
export const uploadFavouriteIconApi = (
  file: Blob
): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const fd: FormData = new FormData();
  fd.append('file', file, file['name']);
  fd.append('mode', 'favouriteIcon');
  return httpClient.imageUpload<IImageUploadResponse>(`/system/image/upload`, fd);
};

/**
 * test smtp settings
 * @param body Payload to send with the request
 * @returns Promise
 */
export const testSMTPActionAPI = (
  body: Record<string, any>
): Promise<IAPIEntityResponse<string>> => {
  if ('recipient' in body) {
    return httpClient.post<string>(
      `/system/settings/smtp/test?email=${encodeURIComponent(body.recipient)}`,
      body
    );
  } else {
    return httpClient.post<string>(`/system/settings/smtp/test`, body);
  }
};

/**
 * get app settings
 * @returns Promise
 */
export const getAppSettingsAPI = (): Promise<IAPIEntityResponse<IGetSettingsResponse>> => {
  return httpClient.get<IGetSettingsResponse>('/system/settings');
};

/**
 * get app settings
 * @returns Promise
 */
export const getApplicationEnvironmentVariablesAPI = (): Promise<
  IAPIEntityResponse<IPublicEnvironmentVariables>
> => {
  return httpClient.get<IPublicEnvironmentVariables>('/system/environment/information');
};

/**
 * get logo
 * @returns Promise
 */
export const getLogoAPI = (): Promise<IAPIEntityResponse<IGetLogoResponse>> => {
  return httpClient.get<IGetLogoResponse>('/system/settings/logo');
};

/**
 * get favouriteIcon
 * @returns Promise
 */
export const getFavouriteIconAPI = (): Promise<IAPIEntityResponse<IGetFavouriteIconResponse>> => {
  return httpClient.get<IGetFavouriteIconResponse>('/system/settings/favouriteIcon');
};

/**
 * get safe app settings
 * @returns Promise
 */
export const getSafeAppSettingsAPI = (): Promise<IAPIEntityResponse<IGetSafeSettingsResponse>> => {
  return httpClient.get<IGetSafeSettingsResponse>('/system/information', {}, false);
};

/**
 * update app settings
 * @param body Payload to send with the request
 * @returns Promise
 */
export const updateApplicationSettingsAPI = (
  body: Record<string, any>
): Promise<IAPIEntityResponse<IGetSettingsResponse>> => {
  return httpClient.post<IGetSettingsResponse>('/system/settings/update', body);
};

/**
 * Get Configuration settings
 * @returns Promise
 */
export const getConfigurationSettings = (): Promise<IAPIEntityResponse<IAppSettings>> => {
  return httpClient.get<any>('/system/settings/configuration');
};

/**
 * Get login pages information in a single endpoint.
 * @param applicationUUID Defines the Application UUID during the login process with an external app.
 * @returns Promise<IAPIEntityResponse<IPublicLoginInformation>>
 */
export const getLoginPagesInformationSettingsAPI = (
  applicationUUID?: string | null
): Promise<IAPIEntityResponse<IPublicLoginInformation>> => {
  return httpClient.get<IPublicLoginInformation>(
    `/system/public/login/information/${
      isStringEmptyOrNullOrUndefined(applicationUUID) ? '' : applicationUUID
    }`,
    {}
  );
};
