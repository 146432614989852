/**
* ApplicationSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ApplicationSlice.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { Reducer } from 'react';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import {
  createApplicationApi,
  deleteAppApi,
  getApplications,
  getSafeApplicationByNameApi,
  getUserApplications,
  updateApplicationApi
} from '../Services/ApplicationApi';
import i18n from '../Services/I18n';
import { getRolesFromServer } from '../Services/RolesApi';
import { uploadLogoApi } from '../Services/SettingsApi';
import { getUsers } from '../Services/UserApi';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationRequestAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IPaginationRequest } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { IRoleAndUserCount } from './Interfaces';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { getUserApplicationsForSidebarAction } from './UserApplicationsSlice';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const applicationFeatureKey: string = 'application';

/**
 * Interface USER_INITIAL_STATE
 */
export interface IApplicationState {
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  createError: any;
  updateError: any;
  deleteError: any;
  getSafeError: any;
  fetchError: string | null;
  applications: any[];
  userApplications: any[]; //non-Admin
  safeApplicationDetails: any;
  expandRow: any;
  first: number;
  totalRecords: number;
  isLoading: boolean;
  applicationList: any[];
  criteria: IPaginationRequest<IApplications> /**< Filter criteria. */;
  deleteData: any;
}

export const applicationInitialState: IApplicationState = {
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  createError: null,
  updateError: null,
  deleteError: null,
  fetchError: null,
  getSafeError: null,
  applications: null,
  userApplications: [],
  safeApplicationDetails: null,
  deleteData: {},
  expandRow: null,
  first: 0,
  totalRecords: 0,
  isLoading: false,
  applicationList: [],
  criteria: {
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'DESC'
    }
  }
};

/**
 * @interface IUpdateApplicationResponse
 */
export interface IUpdateApplicationResponse extends IApplications {
  isUserApplicationUpdated: boolean /**< Is userApplication is updated or not. */;
  darkLogoImageURL: string /**< Dark logo field. */;
  logoImageURL: string /**< Light logo field. */;
}

export const applicationAdapter: EntityAdapter<IApplicationState> = createEntityAdapter();
export const initialApplicationState: EntityState<IApplicationState> & IApplicationState =
  applicationAdapter.getInitialState(applicationInitialState);

export const getApplicationListAction = createAsyncThunk(
  'application/get',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getApplicationListActionRequest,
      getApplicationListActionSuccess,
      getApplicationListActionFailure
    } = applicationActions;
    try {
      const criteria = payload;
      dispatch(getApplicationListActionRequest(criteria));
      const result: IAPIEntityResponse<IPaginationRequest<IApplications>> = await asyncErrorHandler(
        getApplications(criteria)
      );
      if (result.error) {
        dispatch(getApplicationListActionFailure(result.error));
      } else {
        dispatch(getApplicationListActionSuccess(result.data));
      }
    } catch (exception: any) {
      dispatch(getApplicationListActionFailure(exception.message));
    }
  }
);

export const getApplicationSafeDetailsAction = createAsyncThunk(
  'application/get/safe',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getApplicationSafeDetailsRequest,
      getApplicationSafeDetailsSuccess,
      getApplicationSafeDetailsFailure
    } = applicationActions;
    try {
      const { name, key } = payload;
      dispatch(getApplicationSafeDetailsRequest());
      const result: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
        getSafeApplicationByNameApi(name, key)
      );
      if (result.error) {
        dispatch(getApplicationSafeDetailsFailure(result.error));
      } else {
        dispatch(getApplicationSafeDetailsSuccess(result.data));
      }
    } catch (exception: any) {
      dispatch(getApplicationSafeDetailsFailure(exception.message));
    }
  }
);

export const getApplicationsByUserId = createAsyncThunk(
  'applications/list/mine',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getApplicationsByUserIdActionRequest,
      getApplicationsByUserIdActionSuccess,
      getApplicationsByUserIdActionFailure
    } = applicationActions;
    try {
      dispatch(getApplicationsByUserIdActionRequest());
      const userUUID: string = LocalStorage.getXUserUUID() || '';
      if (userUUID) {
        const result: IAPIEntityResponse<IApplications[]> = await asyncErrorHandler(
          getUserApplications(userUUID)
        );
        if (result.error) {
          dispatch(getApplicationsByUserIdActionFailure(result.error));
        } else {
          dispatch(getApplicationsByUserIdActionSuccess(result.data));
          return result.data;
        }
      }
    } catch (exception: any) {
      dispatch(getApplicationsByUserIdActionFailure(exception.message));
    }
  }
);

export const getUserCountByApplicationIDAction = createAsyncThunk(
  'applications/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      getUserCountByApplicationIDActionRequest,
      getUserCountByApplicationIDActionSuccess,
      getUserCountByApplicationIDActionFailure,
      deleteApplicationActionFailure,
      deleteApplicationActionSuccess
    } = applicationActions;
    try {
      dispatch(getUserCountByApplicationIDActionRequest());

      const paginationPayload: any = {
        limit: 20,
        skip: 0,
        sort: {
          created: 'DESC'
        },
        filter: [
          {
            column: 'applicationUUID',
            operator: 'IN',
            value: payload.applicationUUIDs
          }
        ]
      };

      const rolesResult: PaginatedAPIEntityResponse<IRole> = await asyncErrorHandler(
        getRolesFromServer(paginationPayload)
      );
      const usersResult: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(
        getUsers(paginationPayload)
      );

      if (usersResult.error || !usersResult.data) {
        dispatch(
          getUserCountByApplicationIDActionFailure(
            usersResult.error || `Error in delete applications (on fetch users)`
          )
        );
      } else if (rolesResult.error || !rolesResult.data) {
        dispatch(
          getUserCountByApplicationIDActionFailure(
            usersResult.error || `Error in delete applications (on fetch roles)`
          )
        );
      } else {
        const userData: any = usersResult.data;
        const userCount: number = userData.totalRecords;
        const linkedUsers: IUser[] = userData.records.map((eachUser: IUser) => {
          return { username: eachUser.username, email: eachUser.email };
        });

        const roleData: any = rolesResult.data;
        const roleCount: number = roleData.totalRecords;
        const linkedRoles: IRole[] = roleData.records.map((eachRole: IRole) => eachRole.name);

        if (userCount === 0 && roleCount === 0) {
          const result: IAPIEntityResponse<void> = await asyncErrorHandler(
            deleteAppApi(payload.applicationUUIDs)
          );
          if (result.error) {
            dispatch(deleteApplicationActionFailure(result.error));
          } else {
            getState();
            dispatch(deleteApplicationActionSuccess());
          }
          return;
        } else {
          const roleAndUserCountData: IRoleAndUserCount = {
            isDeleted: false,
            linkedRoles,
            linkedUsers,
            roleCount,
            userCount,
            rolesNotShownCount: roleCount - paginationPayload.limit,
            usersNotShownCount: userCount - paginationPayload.limit
          };
          dispatch(getUserCountByApplicationIDActionSuccess(roleAndUserCountData));
          return;
        }
      }
    } catch (exception: any) {
      dispatch(getUserCountByApplicationIDActionFailure(exception.message));
    }
  }
);

export const deleteApplicationAction = createAsyncThunk(
  'application/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    getState();
    const {
      deleteApplicationActionRequest,
      deleteApplicationActionSuccess,
      deleteApplicationActionFailure
    } = applicationActions;
    try {
      dispatch(deleteApplicationActionRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(deleteAppApi(payload));
      if (result.error) {
        dispatch(deleteApplicationActionFailure(result.error));
      } else {
        dispatch(deleteApplicationActionSuccess());
      }
    } catch (exception: any) {
      dispatch(deleteApplicationActionFailure(exception.message));
    }
  }
);

export const updateApplicationAction = createAsyncThunk(
  'application/update',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state: any = getState();
    const {
      updateApplicationActionRequest,
      updateApplicationActionSuccess,
      updateApplicationActionFailure
    } = applicationActions;
    try {
      dispatch(updateApplicationActionRequest());
      /// upload logo image
      if (payload.data.logoImage) {
        const imageResponse: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadLogoApi(payload.data.logoImage)
        );
        if (imageResponse.status === 200) {
          payload.data['logoImageName'] = imageResponse.data.imageName;
        }
      }
      /// upload icon
      if (payload.data.iconImage) {
        const imageResponse: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadLogoApi(payload.data.iconImage)
        );
        if (imageResponse.status === 200) {
          payload.data['icon'] = imageResponse.data.imageName;
        }
      }
      const result: IAPIEntityResponse<IUpdateApplicationResponse> = await asyncErrorHandler(
        updateApplicationApi(payload.data, payload.id)
      );
      if (result.error) {
        dispatch(updateApplicationActionFailure(result.error));
      } else {
        dispatch(updateApplicationActionSuccess());
        dispatch(getApplicationListAction(state.application.criteria));
        if (LocalStorage.getXAuthToken()) {
          dispatch(getUserApplicationsForSidebarAction());
        }

        ///Note: When userApplication is updated, getApplications to display the updated change in the sidebar.
        if (result.data && result.data.isUserApplicationUpdated) {
          dispatch(getApplicationsByUserId({}));
        }
      }
    } catch (exception: any) {
      dispatch(updateApplicationActionFailure(exception.message));
    }
  }
);

export const createApplicationAction = createAsyncThunk(
  'application/create',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state: any = getState();
    const {
      createApplicationActionRequest,
      createApplicationActionSuccess,
      createApplicationActionFailure
    } = applicationActions;
    try {
      dispatch(createApplicationActionRequest());
      /// upload logo image
      if (payload.logoImage) {
        const imageResponse: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadLogoApi(payload.logoImage)
        );
        if (imageResponse.status === 200) {
          payload['logoImageName'] = imageResponse.data.imageName;
        }
      }
      /// upload icon
      if (payload.iconImage) {
        const imageResponse: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadLogoApi(payload.iconImage)
        );
        if (imageResponse.status === 200) {
          payload['icon'] = imageResponse.data.imageName;
        }
      }
      /// If no other radio button is selected, set the default value for the whenRegistered and isAlwaysVisible.
      if (payload.whenRegistered === undefined) {
        payload.whenRegistered = true;
        payload.isAlwaysVisible = false;
      }

      const result: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
        createApplicationApi(payload)
      );
      if (result.error) {
        dispatch(createApplicationActionFailure(result.error));
      } else {
        dispatch(createApplicationActionSuccess());
        dispatch(getApplicationListAction(state.application.criteria));
        if (LocalStorage.getXAuthToken()) {
          dispatch(getUserApplicationsForSidebarAction());
        }
      }
    } catch (exception: any) {
      dispatch(createApplicationActionFailure(exception.message));
    }
  }
);

export const searchApplicationAction = createAsyncThunk(
  'application/search',
  async (payload: any) => {
    const body: any = {
      limit: 0,
      offset: 10,
      sort: {
        created: 'DESC'
      }
    };
    if (payload) {
      body.filter = [{ column: 'applicationName', value: payload, operator: 'LIKE' }];
    }
    const response: PaginatedAPIEntityResponse<IApplications> = await asyncErrorHandler(
      getApplications(body)
    );
    return response;
  }
);

const clearErrors = (state: IApplicationState) => {
  state.isCreated = false;
  state.isUpdated = false;
  state.isDeleted = false;
  state.fetchError = null;
  state.createError = null;
  state.updateError = null;
  state.deleteError = null;
};

const clearOnRequest = (state: IApplicationState) => {
  state.isLoading = true;
  clearErrors(state);
};

/**
 * Creates Slice - All Application related state will be stored here
 */
export const applicationSlice = createSlice({
  name: applicationFeatureKey,
  initialState: initialApplicationState,
  reducers: {
    reset: (state: IApplicationState) => clearErrors(state),
    getApplicationListActionRequest(
      state: IApplicationState,
      action: PaginationRequestAction<IApplications>
    ) {
      clearOnRequest(state);
      state.criteria = { ...action.payload }; // if filter sent, update criteria for the next refresh
    },
    getApplicationListActionSuccess(
      state: IApplicationState,
      action: PaginationResponseAction<IApplications>
    ) {
      state.isLoading = false;
      state.applications = action.payload.records || [];
      state.totalRecords = action.payload.totalRecords || 0;
      state.fetchError = null;
    },
    getApplicationListActionFailure(
      state: IApplicationState,
      action: IReducerAction<IAPIErrorData>
    ) {
      state.isLoading = false;
      state.applications = [];
      state.fetchError = action.payload.message || i18n.t('I18N.error_messages.fetch_app_failure');
    },
    getApplicationSafeDetailsRequest(state: IApplicationState) {
      clearOnRequest(state);
      state.safeApplicationDetails = null;
    },
    getApplicationSafeDetailsSuccess(
      state: IApplicationState,
      action: IReducerAction<IApplications>
    ) {
      state.isLoading = false;
      state.safeApplicationDetails = action.payload || null;
      state.getSafeError = null;
    },
    getApplicationSafeDetailsFailure(
      state: IApplicationState,
      action: IReducerAction<string | IAPIErrorData>
    ) {
      state.isLoading = false;
      state.getSafeError = action.payload || i18n.t('I18N.error_messages.fetch_app_failure');
    },
    getApplicationsByUserIdActionRequest(state: IApplicationState) {
      clearOnRequest(state);
      state.userApplications = [];
    },
    getApplicationsByUserIdActionSuccess(
      state: IApplicationState,
      action: IReducerAction<IApplications[]>
    ) {
      state.isLoading = false;
      state.userApplications = action.payload || [];
      state.fetchError = null;
    },
    getApplicationsByUserIdActionFailure(
      state: IApplicationState,
      action: IReducerAction<IAPIErrorData>
    ) {
      state.isLoading = false;
      state.fetchError = action.payload.message || i18n.t('I18N.error_messages.fetch_app_failure');
    },
    createApplicationActionRequest(state: IApplicationState) {
      clearOnRequest(state);
    },
    createApplicationActionSuccess(state: IApplicationState) {
      state.isLoading = false;
      state.isCreated = true;
    },
    createApplicationActionFailure(state: IApplicationState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.createError = action.payload || i18n.t('I18N.error_messages.create_app_failure');
    },
    updateApplicationActionRequest(state: IApplicationState) {
      clearOnRequest(state);
    },
    updateApplicationActionSuccess(state: IApplicationState) {
      state.isLoading = false;
      state.isUpdated = true;
    },
    updateApplicationActionFailure(state: IApplicationState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.updateError = action.payload || i18n.t('I18N.error_messages.update_app_failure');
    },
    deleteApplicationActionRequest(state: IApplicationState) {
      clearOnRequest(state);
    },
    deleteApplicationActionSuccess(state: IApplicationState) {
      state.isLoading = false;
      state.isDeleted = true;
    },
    deleteApplicationActionFailure(state: IApplicationState, action: IReducerAction<string>) {
      state.isLoading = false;
      state.deleteError = action.payload || i18n.t('I18N.error_messages.delete_app_failure');
    },
    getUserCountByApplicationIDActionRequest(state: IApplicationState) {
      clearOnRequest(state);
      state.deleteData = {};
    },
    getUserCountByApplicationIDActionSuccess(
      state: IApplicationState,
      action: IReducerAction<IRoleAndUserCount>
    ) {
      state.isLoading = false;
      state.deleteData = action.payload;
    },
    getUserCountByApplicationIDActionFailure(
      state: IApplicationState,
      action: IReducerAction<string>
    ) {
      state.isLoading = false;
      state.deleteError = action?.payload || i18n.t('I18N.error_messages.delete_role_failure');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchApplicationAction.pending, (state: IApplicationState) => {
        state.isLoading = true;
        state.applicationList = [];
      })
      // TODO: fix any type on reducer action
      .addCase(
        searchApplicationAction.fulfilled,
        (
          state: IApplicationState,
          action: IReducerAction<PaginatedAPIEntityResponse<IApplications>>
        ) => {
          state.isLoading = false;
          if (action.payload.status === 200 && action.payload.data) {
            state.applicationList = action.payload.data.records;
          }
        }
      )
      .addCase(searchApplicationAction.rejected, (state: IApplicationState) => {
        state.isLoading = false;
        state.applicationList = [];
      });
  }
});

export const applicationReducer: Reducer<
  EntityState<IApplicationState> & IApplicationState,
  AnyAction
> = applicationSlice.reducer;
export const applicationActions: any = applicationSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = applicationAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getApplicationState: any = (rootState: any) => rootState[applicationFeatureKey];
export const selectAllApplication: any = createSelector(getApplicationState, selectAll);
export const selectApplicationEntities: any = createSelector(getApplicationState, selectEntities);
