/*
 * FindAccount.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file FindAccount.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ActionButtons from "./ActionButtons";
import InstaInputText from "../FormControl/InstaInputText";
import { Form } from 'react-bootstrap';

interface IFindAccountProperties {
  onCancel: () => void;
  onFindAccount: (data: any) => void;
}

function FindAccount({
  onCancel,
  onFindAccount,
}: IFindAccountProperties): ReactElement {
  const { t } = useTranslation();

  /// Validate email
  const formik = useFormik({
    initialValues: {
      email: "",
      user: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
              .min(2, t("awc:/validations.min", { field: "2" }))
              .max(200, t("awc:/validations.max", { field: "200" }))
              .required(
                t("awc:/validations.required", {
                  field: t("awc:/.login.usernameOrEmail_tooltip"),
                })
              ),
    }),
    onSubmit: (data) => {
      onFindAccount(data);
    },
  });
  
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Col className="input-password-container px-0 custom-margin">
          <InstaInputText
            label={t("awc:/.forgot_password.account.enter_emailOrUsername")}
            placeholder={t("awc:/.login.usernameOrEmail_tooltip")}
            id={"email"}
            className="specialInputs"
            errors={formik.touched.email && formik.errors.email}
            name={"email"}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched}
            type={"text"}
            value={formik.values.email}
            labelClassName="required"
          />
        </Col>
        
        <ActionButtons
          onCancel={onCancel}
          cancelLabel={t("awc:/.forgot_password.button.cancel")}
          submitLabel={t("awc:/.forgot_password.button.submit")}
          submitBtnIcon="bi bi-check2-circle btn-icon"
        />
      </Form>
    </>
  );
}

export default FindAccount;
