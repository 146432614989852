/**
* ApplicationSettingsForm.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ApplicationSettingsForm.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { withFormik, FormikProps, FormikBag } from 'formik';
import * as Yup from 'yup';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import InstaCheckbox from '@abstract/abstractwebcommon-client/FormControl/InstaCheckbox';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'primereact/dropdown';
import { Form } from 'react-bootstrap';
import InstaImageUpload, {
  RemoveButtonStyle
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { ISettingsState } from '../../../Store/SettingsSlice';
import { IAppSettings } from '@abstract/abstractwebcommon-shared/interfaces/user/settings';
import '@abstract/abstractwebcommon-client/SettingsFormFaviconLogo.css';
import { defaultRenewalRefreshTokenPeriodInSeconds } from '@abstract/abstractwebcommon-shared/constants';
import FieldSkeleton, {
  SkeletonHeight,
  controlInputClassName
} from '@abstract/abstractwebcommon-client/SkeletonLoader/FieldSkeleton/FieldSkeleton';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';
import { InputTextarea } from 'primereact/inputtextarea';

interface IApplicationSettingsProperties {
  settingsState?: any;
  CSSTemplates?: any;
  translation?: any;
  bindForm: (mode: string, values: any, errors: any) => void;
  revalidateErrors: (mode: string) => void;
  revalidateUpdates: (updates: any) => void;
  showConfirmation?: boolean;
  isFormValid?: boolean;
  isAnyUpdatesExist?: boolean;
  saveButtonClick?: (event: any) => void;
  uploadLogo: (event: any) => Promise<void>;
  deleteLogo: (event: any) => void;
  uploadFavouriteIcon: (event: any) => Promise<void>;
  deleteFavouriteIcon: (event: any) => void;
  displayCroppedFavouriteIcon: any;
  displayCroppedLogo: any;
  errorHandler: (error: string) => void;
}

interface IApplicationSettingFormValues {
  maxResetPasswordAttempts?: number;
  maxForgotPasswordAttempts?: number;
  userActivationMailExpiry?: string;
  forgotPasswordVerificationExpiry?: string;
  resetPasswordVerificationExpiry?: string;
  enableDuplicateEmails?: boolean;
  rssFeedUrl?: string;
  adminRoleUUID?: string;
  applicationTitle?: string /**< This is the title of the application */;
  selectedCSSTemplate?: string;
  renewalRefreshTokenPeriodInSeconds?: number /** Period to renewal/refresh JWT token */;
  adminRoleName?: string /**< Admin Role name */;
  cacheFeedsTTL?: number /**< Cache Feeds TTL */;
  rssDelay?: number /**< RSS Delay */;
  website?: string /**< Website */;
  migrationContactEmail?: string /**< Migration Contact Email */;
  termsOfServiceURL?: string /**< Terms of service URL */;
  completeRegistrationText?: string /**< Complete registration text */;
  jobsLockDuration?: number /**< Jobs lock duration */;
}

interface IApplicationSettingsInitialFormProperties {
  initialMaxResetPasswordAttempts?: number;
  initialMaxForgotPasswordAttempts?: number;
  initialUserActivationMailExpiry?: string;
  initialForgotPasswordVerificationExpiry?: string;
  initialResetPasswordVerificationExpiry?: string;
  initialRssFeedUrl?: string;
  initialAdminRoleUUID?: string;
  initialApplicationTitle?: string;
  initialSelectedCSSTemplate?: string;
  initialEnableDuplicateEmails?: boolean;
  initialRenewalRefreshTokenPeriodInSeconds?: number;
  initialAdminRoleName?: string;
  initialCacheFeedsTTL?: number;
  initialRssDelay?: number;
  initialWebsite?: string;
  initialMigrationContactEmail?: string;
  initialTermsOfServiceURL?: string;
  initialCompleteRegistrationText?: string;
  initialJobsLockDuration?: number;
  translation?: any;
  settingsState?: any;
  CSSTemplates?: any;
  bindForm: (mode: string, values: any, errors: any) => void;
  revalidateErrors: (mode: string) => void;
  revalidateUpdates: (updates: any) => void;
  showConfirmation: boolean;
  isFormValid: boolean;
  isAnyUpdatesExist: boolean;
  saveButtonClick: (event: any) => void;
  uploadLogo: (event: any) => Promise<void>;
  deleteLogo: (event: any) => void;
  uploadFavouriteIcon: (event: any) => Promise<void>;
  deleteFavouriteIcon: (event: any) => void;
  errorHandler: (error: string) => void;
  displayCroppedFavouriteIcon: any;
  displayCroppedLogo: any;
}

const SettingsForm = (
  properties: IApplicationSettingsProperties & FormikProps<IApplicationSettingFormValues>
): JSX.Element => {
  const {
    handleChange,
    handleBlur,
    errors,
    values,
    touched,
    bindForm,
    revalidateErrors,
    revalidateUpdates,
    setFieldValue,
    CSSTemplates,
    showConfirmation,
    isFormValid,
    isAnyUpdatesExist,
    saveButtonClick,
    settingsState,
    uploadLogo,
    deleteLogo,
    uploadFavouriteIcon,
    deleteFavouriteIcon,
    displayCroppedFavouriteIcon,
    displayCroppedLogo,
    errorHandler
  } = properties;
  bindForm('application', values, errors); // expose submission handler out

  const isLoading: boolean = settingsState.isLoading;
  const [toggleFullSizePreviewFavouriteIcon, setToggleFullSizePreviewFavouriteIcon] =
    useState<boolean>(false);
  const [toggleFullSizePreviewLogo, setToggleFullSizePreviewLogo] = useState<boolean>(false);

  const onChange = (event: any): void => {
    handleChange(event);
    revalidateUpdates(event.target);
  };

  const CSSDropdownTemplate = (option: any): JSX.Element => {
    return (
      <p>
        {option.name} - {option.templateUUID}
      </p>
    );
  };

  /// Get logo div
  const getLogo = (): JSX.Element => {
    return (
      <>
        <div id="logo" className="flex-grow-1 input-fixed-height">
          <label htmlFor="logo" className="flex-grow-1">
            {t('/admin/settings.change_logo')}
          </label>
          <PopupInformation
            id={'logo'}
            popupText={t('I18N.settings.app_settings.fields.logo_popupText')}
          />
          <div className={`p-2 p-inputtext ${changeInputClassName(true)}`}>
            <InstaImageUpload
              showLegend={false}
              removeButtonStyle={RemoveButtonStyle.SEPARATE}
              imageUrl={settingsState.logoUrl}
              showDelete={true}
              deleteHandler={deleteLogo}
              onChange={uploadLogo}
              errorHandler={errorHandler}
              imgClass={'imageUrl rounded'}
              altText={t('/admin/settings.no_logo_found')}
              plainBtnLabel={t('/admin/settings.button_choose_file')}
              isPlainBtn={true}
              isHorizontalLayout
              setToggleFullSizePreview={setToggleFullSizePreviewLogo}
              toggleFullSizePreview={toggleFullSizePreviewLogo}
              croppedImage={displayCroppedLogo}
            />
          </div>
          {RenderSkeletonComponent(true)}
        </div>
      </>
    );
  };
  /// Get favouriteIcon div
  const getFavouriteIcon = (): JSX.Element => {
    return (
      <>
        <div id="favouriteIcon" className="flex-grow-1 mt-2 input-fixed-height">
          <label htmlFor="favouriteIcon" className="flex-grow-1">
            {t('/admin/settings.change_favicon')}
          </label>
          <PopupInformation
            id={'favouriteIcon'}
            popupText={t('I18N.settings.app_settings.fields.favouriteIcon_popupText')}
          />
          <div className={`p-2 p-inputtext ${changeInputClassName(true)}`}>
            <InstaImageUpload
              showLegend={false}
              imageUrl={settingsState.favouriteIconUrl}
              removeButtonStyle={RemoveButtonStyle.SEPARATE}
              showDelete={true}
              deleteHandler={deleteFavouriteIcon}
              onChange={uploadFavouriteIcon}
              imgClass={'imageUrl rounded'}
              errorHandler={errorHandler}
              altText={t('/admin/settings.no_favIcon_found')}
              plainBtnLabel={t('/admin/settings.button_choose_file')}
              isPlainBtn={true}
              isHorizontalLayout
              setToggleFullSizePreview={setToggleFullSizePreviewFavouriteIcon}
              toggleFullSizePreview={toggleFullSizePreviewFavouriteIcon}
              croppedImage={displayCroppedFavouriteIcon}
            />
          </div>
          {RenderSkeletonComponent(true)}
        </div>
      </>
    );
  };

  revalidateErrors('application');
  const { t } = useTranslation();

  const RenderSkeletonComponent = (isFileInput = false): JSX.Element =>
    !settingsState.applicationSettings && (
      <FieldSkeleton
        skeletonHeightType={isFileInput ? SkeletonHeight.FileType : SkeletonHeight.Default}
      />
    );

  const changeInputClassName = (isFileField = false): string =>
    controlInputClassName(!settingsState.applicationSettings, isFileField);

  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    revalidateUpdates({
      id: key,
      value
    });
  };

  return (
    <Col xs="12" lg="6">
      <Form>
        <Fieldset legend={t('I18N.settings.app_settings.header')}>
          <div className="mb-2">
            {getLogo()}
            {getFavouriteIcon()}
          </div>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.applicationTitle')}
                id={'applicationTitle'}
                errors={errors.applicationTitle}
                name={'applicationTitle'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.applicationTitle}
                value={values['applicationTitle']}
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.applicationTitle_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.adminRoleUUID')}
                id={'adminRoleUUID'}
                errors={errors.adminRoleUUID}
                name={'adminRoleUUID'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.adminRoleUUID}
                value={values['adminRoleUUID']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.adminRoleUUID_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.adminRoleName')}
                id={'adminRoleName'}
                errors={errors.adminRoleName}
                name={'adminRoleName'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.adminRoleName}
                value={values['adminRoleName']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.adminRoleName_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Label>{t('I18N.settings.app_settings.fields.selectedCSSTemplate')}</Form.Label>
              <PopupInformation
                id={'selectedCSSTemplate'}
                popupText={t('I18N.settings.app_settings.fields.selectedCSSTemplate_popupText')}
              />
              <Dropdown
                filter
                id="selectedCSSTemplate"
                optionValue="templateUUID"
                optionLabel="templateUUID"
                value={values['selectedCSSTemplate']}
                options={CSSTemplates && CSSTemplates.length === 0 ? [{}] : CSSTemplates}
                onChange={(event) => setFieldValueLocal('selectedCSSTemplate', event.value)}
                itemTemplate={CSSDropdownTemplate}
                className={changeInputClassName()}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.maxResetPasswordAttempts')}
                id={'maxResetPasswordAttempts'}
                errors={errors.maxResetPasswordAttempts}
                name={'maxResetPasswordAttempts'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.maxResetPasswordAttempts}
                value={values['maxResetPasswordAttempts']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t(
                  'I18N.settings.app_settings.fields.maxResetPasswordAttempts_popupText'
                )}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.forgotPasswordVerificationExpiry')}
                id={'forgotPasswordVerificationExpiry'}
                errors={errors.forgotPasswordVerificationExpiry}
                name={'forgotPasswordVerificationExpiry'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.forgotPasswordVerificationExpiry}
                value={values['forgotPasswordVerificationExpiry']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t(
                  'I18N.settings.app_settings.fields.forgotPasswordVerificationExpiry_popupText'
                )}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={`align-items-center ${changeInputClassName()}`}>
              <InstaCheckbox
                label={t('I18N.settings.app_settings.fields.enableDuplicateEmails')}
                id={'enableDuplicateEmails'}
                errors={errors}
                name={'enableDuplicateEmails'}
                onBlur={handleBlur}
                onChange={(event: any) =>
                  setFieldValueLocal('enableDuplicateEmails', event.checked)
                }
                touched={touched}
                value={values['enableDuplicateEmails']}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.enableDuplicateEmails_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.maxForgotPasswordAttempts')}
                id={'maxForgotPasswordAttempts'}
                errors={errors.maxForgotPasswordAttempts}
                name={'maxForgotPasswordAttempts'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.maxForgotPasswordAttempts}
                value={values['maxForgotPasswordAttempts']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t(
                  'I18N.settings.app_settings.fields.maxForgotPasswordAttempts_popupText'
                )}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.resetPasswordVerificationExpiry')}
                id={'resetPasswordVerificationExpiry'}
                errors={errors.resetPasswordVerificationExpiry}
                name={'resetPasswordVerificationExpiry'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.resetPasswordVerificationExpiry}
                value={values['resetPasswordVerificationExpiry']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t(
                  'I18N.settings.app_settings.fields.resetPasswordVerificationExpiry_popupText'
                )}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.userActivationMailExpiry')}
                id={'userActivationMailExpiry'}
                errors={errors.userActivationMailExpiry}
                name={'userActivationMailExpiry'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.userActivationMailExpiry}
                value={values['userActivationMailExpiry']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t(
                  'I18N.settings.app_settings.fields.userActivationMailExpiry_popupText'
                )}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.rssFeedUrl')}
                id={'rssFeedUrl'}
                errors={errors.rssFeedUrl}
                name={'rssFeedUrl'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.rssFeedUrl}
                value={values['rssFeedUrl']}
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.rssFeedUrl_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.cacheFeedsTTL')}
                id={'cacheFeedsTTL'}
                errors={errors.cacheFeedsTTL}
                name={'cacheFeedsTTL'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.cacheFeedsTTL}
                value={values['cacheFeedsTTL']}
                type="number"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.cacheFeedsTTL_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.rssDelay')}
                id={'rssDelay'}
                errors={errors.rssDelay}
                name={'rssDelay'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.rssDelay}
                value={values['rssDelay']}
                type="number"
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.rssDelay_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.renewalRefreshTokenPeriodInSeconds')}
                id={'renewalRefreshTokenPeriodInSeconds'}
                errors={errors.renewalRefreshTokenPeriodInSeconds}
                name={'renewalRefreshTokenPeriodInSeconds'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.renewalRefreshTokenPeriodInSeconds}
                value={values['renewalRefreshTokenPeriodInSeconds']}
                type="number"
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t(
                  'I18N.settings.app_settings.fields.renewalRefreshTokenPeriodInSeconds_popupText'
                )}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.website')}
                id={'website'}
                errors={errors.website}
                name={'website'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.website}
                value={values['website']}
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.website_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.migrationContactEmail')}
                id={'migrationContactEmail'}
                errors={errors.migrationContactEmail}
                name={'migrationContactEmail'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.migrationContactEmail}
                value={values['migrationContactEmail']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.migrationContactEmail_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.termsOfServiceURL')}
                id={'termsOfServiceURL'}
                errors={errors.termsOfServiceURL}
                name={'termsOfServiceURL'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.termsOfServiceURL}
                value={values['termsOfServiceURL']}
                labelClassName="required"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.termsOfServiceURL_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <>
                <Form.Label htmlFor="completeRegistrationText" className="required">
                  {' '}
                  {t('I18N.settings.app_settings.fields.completeRegistrationText')}
                </Form.Label>
                <PopupInformation
                  id="completeRegistrationText"
                  popupText={t(
                    'I18N.settings.app_settings.fields.completeRegistrationText_popupText'
                  )}
                />
                <InputTextarea
                  name="completeRegistrationText"
                  id="completeRegistrationText"
                  value={values['completeRegistrationText']}
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    touched.completeRegistrationText && errors.completeRegistrationText
                      ? 'p-invalid'
                      : ''
                  }
                  rows={1}
                  autoResize
                />
                {touched.completeRegistrationText && errors.completeRegistrationText ? (
                  <small className="p-invalid">{errors.completeRegistrationText}</small>
                ) : null}
              </>
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <InstaInputText
                label={t('I18N.settings.app_settings.fields.jobsLockDuration')}
                id={'jobsLockDuration'}
                labelClassName="required"
                errors={errors.jobsLockDuration}
                name={'jobsLockDuration'}
                onBlur={handleBlur}
                onChange={onChange}
                touched={touched.jobsLockDuration}
                value={values['jobsLockDuration']}
                type="number"
                spanClassName={changeInputClassName()}
                isShowInformationPopup={true}
                popupText={t('I18N.settings.app_settings.fields.jobsLockDuration_popupText')}
              />
              {RenderSkeletonComponent()}
            </Col>
          </Row>
          <Col md={12} sm={12} lg={12} className="p-0">
            <Button
              className={`p-button-raised p-button-primary btn-block ${
                isLoading || showConfirmation || !isFormValid || !isAnyUpdatesExist
                  ? 'custom-disabled-button'
                  : ''
              }`}
              onClick={saveButtonClick}
              disabled={isLoading || showConfirmation || !isFormValid || !isAnyUpdatesExist}>
              {t('/admin/settings.smtp_fieldset.form.save')}
            </Button>
          </Col>
        </Fieldset>
      </Form>
    </Col>
  );
};

const ApplicationSettingsForm = withFormik<
  IApplicationSettingsInitialFormProperties,
  IApplicationSettingFormValues
>({
  enableReinitialize: true,
  mapPropsToValues: (properties) => {
    if (properties.settingsState && properties.settingsState.applicationSettings) {
      const settingsState: ISettingsState = properties.settingsState;
      const settings: IAppSettings = settingsState.applicationSettings;
      return {
        maxResetPasswordAttempts: settings.maxResetPasswordAttempts,
        maxForgotPasswordAttempts: settings.maxForgotPasswordAttempts,
        userActivationMailExpiry: settings.userActivationMailExpiry,
        applicationTitle: settings.applicationTitle,
        rssFeedUrl: settings.rssFeedUrl,
        forgotPasswordVerificationExpiry: settings.forgotPasswordVerificationExpiry,
        resetPasswordVerificationExpiry: settings.resetPasswordVerificationExpiry,
        enableDuplicateEmails: settings.enableDuplicateEmails,
        adminRoleUUID: settings.adminRole && settings.adminRole.roleUUID,
        selectedCSSTemplate:
          settings.selectedCSSTemplate && settings.selectedCSSTemplate.templateUUID,
        renewalRefreshTokenPeriodInSeconds:
          settings.renewalRefreshTokenPeriodInSeconds &&
          settings.renewalRefreshTokenPeriodInSeconds,
        adminRoleName: settings.adminRoleName && settings.adminRoleName,
        cacheFeedsTTL: settings.cacheFeedsTTL && settings.cacheFeedsTTL,
        rssDelay: settings.rssDelay && settings.rssDelay,
        website: settings.website && settings.website,
        migrationContactEmail: settings.migrationContactEmail && settings.migrationContactEmail,
        termsOfServiceURL: settings.termsOfServiceURL && settings.termsOfServiceURL,
        completeRegistrationText:
          settings.completeRegistrationText && settings.completeRegistrationText,
        jobsLockDuration: settings.jobsLockDuration && settings.jobsLockDuration
      };
    }
    return {
      maxResetPasswordAttempts: properties.initialMaxResetPasswordAttempts || 3,
      enableDuplicateEmails: properties.initialEnableDuplicateEmails || false,
      forgotPasswordVerificationExpiry: properties.initialForgotPasswordVerificationExpiry || '1h',
      maxForgotPasswordAttempts: properties.initialMaxForgotPasswordAttempts || 3,
      resetPasswordVerificationExpiry: properties.initialResetPasswordVerificationExpiry || '1h',
      userActivationMailExpiry: properties.initialUserActivationMailExpiry || '1h',
      rssFeedUrl: properties.initialRssFeedUrl || '',
      adminRoleUUID: properties.initialAdminRoleUUID || '',
      applicationTitle: properties.initialApplicationTitle || null,
      selectedCSSTemplate: properties.initialSelectedCSSTemplate || '',
      renewalRefreshTokenPeriodInSeconds:
        properties.initialRenewalRefreshTokenPeriodInSeconds && 4320,
      adminRoleName: properties.initialAdminRoleName || '',
      cacheFeedsTTL: properties.initialCacheFeedsTTL || 0,
      rssDelay: properties.initialRssDelay || 5,
      website: properties.initialWebsite || '',
      migrationContactEmail: properties.initialMigrationContactEmail || '',
      termsOfServiceURL: properties.initialTermsOfServiceURL || '',
      completeRegistrationText: properties.initialCompleteRegistrationText || '',
      jobsLockDuration: properties.initialJobsLockDuration || 0
    };
  },
  validationSchema: (properties: any) => {
    const t = properties.translation;

    return Yup.object({
      adminRoleUUID: Yup.string()
        .min(36, t('/validations.min', { field: '36' }))
        .max(36, t('/validations.max', { field: '36' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.adminRoleUUID')
          })
        ),
      applicationTitle: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(50, t('/validations.max', { field: '50' }))
        .nullable(),
      selectedCSSTemplate: Yup.string()
        .min(36, t('/validations.min', { field: '36' }))
        .max(36, t('/validations.max', { field: '36' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.selectedCSSTemplate')
          })
        ),
      maxResetPasswordAttempts: Yup.string()
        .min(1, t('/validations.min', { field: '1' }))
        .max(2, t('/validations.max', { field: '6' }))
        .matches(/^[0-9]+$/, t('/validations.only_numeric'))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.maxResetPasswordAttempts')
          })
        ),
      enableDuplicateEmails: Yup.boolean().oneOf([true, false]),
      forgotPasswordVerificationExpiry: Yup.string()
        .min(1, t('/validations.min', { field: '1' }))
        .max(6, t('/validations.max', { field: '6' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.forgotPasswordVerificationExpiry')
          })
        ),
      maxForgotPasswordAttempts: Yup.string()
        .min(1, t('/validations.min', { field: '1' }))
        .max(2, t('/validations.max', { field: '6' }))
        .matches(/^[0-9]+$/, t('/validations.only_numeric'))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.maxForgotPasswordAttempts')
          })
        ),
      resetPasswordVerificationExpiry: Yup.string()
        .min(1, t('/validations.min', { field: '1' }))
        .max(6, t('/validations.max', { field: '6' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.resetPasswordVerificationExpiry')
          })
        ),
      userActivationMailExpiry: Yup.string()
        .min(1, t('/validations.min', { field: '1' }))
        .max(6, t('/validations.max', { field: '6' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.userActivationMailExpiry')
          })
        ),
      rssFeedUrl: Yup.string()
        .matches(
          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})?$/,
          t('I18N.user_table.website_regex_invalid')
        )
        .max(500, t('/validation.max', { field: '500' }))
        .nullable(),
      renewalRefreshTokenPeriodInSeconds: Yup.number()
        .min(defaultRenewalRefreshTokenPeriodInSeconds, 'Must be 5 minutes or longer')
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.renewalRefreshTokenPeriodInSeconds')
          })
        ),
      adminRoleName: Yup.string().required(
        t('/validations.required', {
          field: t('I18N.settings.app_settings.fields.adminRoleName')
        })
      ),
      cacheFeedsTTL: Yup.number().nullable(),
      rssDelay: Yup.number().required(
        t('/validations.required', {
          field: t('I18N.settings.app_settings.fields.rssDelay')
        })
      ),
      website: Yup.string()
        .matches(
          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})?$/,
          t('I18N.user_table.website_regex_invalid')
        )
        .max(500, t('/validation.max', { field: '500' }))
        .nullable(),
      migrationContactEmail: Yup.string()
        .email(t('/validations.valid_email'))
        .min(2, t('/validations.min', { field: '2' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.migrationContactEmail')
          })
        ),
      termsOfServiceURL: Yup.string()
        .matches(
          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})?$/,
          t('I18N.user_table.website_regex_invalid')
        )
        .max(500, t('/validation.max', { field: '500' }))
        .required(
          t('/validations.required', {
            field: t('I18N.settings.app_settings.fields.termsOfServiceURL')
          })
        ),
      completeRegistrationText: Yup.string().required(
        t('/validations.required', {
          field: t('I18N.settings.app_settings.fields.completeRegistrationText')
        })
      ),
      jobsLockDuration: Yup.number().required(
        t('/validations.required', {
          field: t('I18N.settings.app_settings.fields.jobsLockDuration')
        })
      )
    });
  },
  handleSubmit: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    values: IApplicationSettingFormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bags: FormikBag<IApplicationSettingsInitialFormProperties, IApplicationSettingFormValues>
  ) => {
    return 'Save is handled from parent component';
  }
})(SettingsForm);

export default ApplicationSettingsForm;
