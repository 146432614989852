/**
* RoleTable.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file RoleTable.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import Button from 'react-bootstrap/Button';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import Col from 'react-bootstrap/Col';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { Dialog } from 'primereact/dialog';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';

const RoleTable = ({
  tableData,
  refreshList,
  setEditRole,
  setDialogVisible,
  deleteRoles,
  deleteData,
  selectedRoles,
  setSelectedRoles
}: {
  tableData: any;
  refreshList: any;
  setEditRole: any;
  setDialogVisible: any;
  deleteRoles: any;
  deleteData: any;
  selectedRoles: IRole[] | null /* Manages the selected roles from the role table/list */;
  setSelectedRoles: React.Dispatch<
    React.SetStateAction<IRole[]>
  > /* State to set selected role from the role table/list */;
}): JSX.Element => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<any>({});
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showDeleteLinkedRolesWarning, setShowDeleteLinkedRolesWarning] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const deleteButtonReference: any = useRef(null);
  const roleTableReference: any = useRef(null);
  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tableData.
  tableData.criteria?.sort &&
    Object.keys(tableData.criteria?.sort).forEach((key) => {
      defaultSortField = key; // Sortfield
      defaultSortOrder = tableData.criteria.sort[key]; //Sortorder
    });
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedTableRoles: IRole[] = event.value;
    if (Array.isArray(selectedTableRoles)) {
      const selectedRows: IRole[] = selectedTableRoles.map((row: IRole) => {
        return row;
      });
      setSelectedRoles(selectedRows);
    }
  };

  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any) => {
    const updatedCriteria = { ...tableData.criteria };
    updatedCriteria.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedCriteria.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }

    refreshList(updatedCriteria);
    setMultiSortMeta(event.multiSortMeta);
  };

  const onFilter = (termToFind: string) => {
    const updatedCriteria = { ...tableData.criteria };
    Object.assign(updatedCriteria, { searchTerm: termToFind });
    refreshList(updatedCriteria);
  };

  /// This specifies the template for row expansion.
  const GetRowExpansionTemplate = ({ rowData }: any) => (
    <>
      <tr>
        <th>{t('/admin/role-management.roles_fieldset.datatable.columns.id')}</th>
        <td>{rowData['roleUUID'] || ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/role-management.roles_fieldset.datatable.columns.name')}</th>
        <td>{rowData['name'] || ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/role-management.roles_fieldset.datatable.columns.description')}</th>
        <td>{rowData['description'] || ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/role-management.roles_fieldset.datatable.columns.application')}</th>
        <td>{rowData.application ? rowData.application.applicationName : ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/role-management.roles_fieldset.datatable.columns.confirmed')}</th>
        <td>{<ShowCheckOrUncheckIcon value={rowData.isActive} />}</td>
      </tr>
      <tr>
        <th>
          <th>{t('/admin/role-management.roles_fieldset.datatable.columns.created')}</th>
        </th>
        <td> {rowData.created ? formatDate(rowData.created) : ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/role-management.roles_fieldset.datatable.columns.updated')}</th>
        <td>{rowData.updated ? formatDate(rowData.updated) : ''}</td>
      </tr>
    </>
  );

  /// This will render the expansion row template.
  const renderExpansionRows = (rowData: any) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Div containing header action buttons
  const getHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="headerTableContainer">
          <ActionButton
            variant="danger"
            onClick={(event: any) => deleteButtonClick(event)}
            isDisabled={
              ((selectedRoles && Object.keys(selectedRoles).length === 0) || !selectedRoles) ??
              false
            }
            buttonReference={deleteButtonReference}
          />

          <ActionButton onClick={() => setDialogVisible(true)} />
        </div>

        <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
      </div>
    );
  };

  /// show delete popup
  const deleteButtonClick = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete roles on Accept
  const onAccept = async () => {
    deleteRoles(selectedRoles, false);
    setShowDeleteLinkedRolesWarning(true);
    setShowConfirmation(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
    setShowDeleteLinkedRolesWarning(false);
  };

  const onDelete = () => {
    deleteRoles(selectedRoles, true);
    setShowDeleteLinkedRolesWarning(false);
  };

  const renderRowDeleteDialogFooter = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t('/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRolesNo')}
        </Button>
        <Button
          onClick={() => onDelete()}
          autoFocus
          variant="danger"
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {t('/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRolesYes')}
        </Button>
      </div>
    );
  };
  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <>
        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={showConfirmation}
          title={t('/confirm_messages.delete_records')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="bottom"
        />

        <Dialog
          header={t(
            '/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRolesHeader'
          )}
          visible={showDeleteLinkedRolesWarning && deleteData.userCount > 0}
          className="overflow-auto custom-dialog-container"
          footer={renderRowDeleteDialogFooter()}
          onHide={() => setShowDeleteLinkedRolesWarning(false)}
          draggable={false}>
          <Col className="mb-2 px-0">
            {t('/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRoles')}
          </Col>
          {deleteData.userCount > 0 && (
            <Col className="mb-2 px-0">
              Total Affected Users: <b>{deleteData.userCount}</b>
            </Col>
          )}
          {deleteData.userCount > 0 && (
            <Col className="px-0">
              <table className="w-100">
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                </tr>
                {deleteData.linkedUsers.map((user: IUser) => (
                  <tr key={user.id}>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </table>
            </Col>
          )}

          {deleteData.usersNotShownCount > 0 && (
            <Col>{deleteData.usersNotShownCount} not shown</Col>
          )}
        </Dialog>
      </>
    );
  };

  /// Show edit dialog
  const onEditButtonClicked = (event: any) => {
    setEditRole(event.data);
    setDialogVisible(true);
  };

  const onPage = (event: any): void => {
    const updatedCriteria = { ...tableData.criteria };
    updatedCriteria.limit = event.rows || defaultTableLimit;
    updatedCriteria.skip = event.first || 0;
    refreshList(updatedCriteria);
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.roleUUID]: true });
    }
  };

  /// Returns datatable
  const getDataTable = () => {
    return (
      <BaseDatatable
        value={tableData.roles}
        header={getHeader()}
        selection={selectedRoles}
        dataKey="roleUUID"
        totalRecords={tableData.totalRecords}
        expandedRows={expandedRows}
        rowExpansionTemplate={(data: any) => renderExpansionRows(data)}
        onSelectionChange={(event: any) => onSelectionChange(event)}
        rows={tableData.criteria?.limit}
        first={tableData.criteria?.skip}
        isLoading={!tableData.roles}
        parentClass="roleDataTable" /**< ClassName for div component. */
        onPage={(event: any) => onPage(event)}
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        onFilter={(event: any) => onFilter(event)}
        onSort={(event: any) => onSort(event)}
        sortMode="multiple"
        selectionMode="checkbox"
        multiSortMeta={multiSortMeta}
        ref={roleTableReference}>
        <Column expander className="p-0 col-width-3" />
        <Column selectionMode="multiple" className="col-width-3" />
        <Column
          field="name"
          header={t('/admin/role-management.roles_fieldset.datatable.columns.name')}
          sortable
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/role-management.roles_fieldset.datatable.columns.name')}
              data={rowData.name || ''}
            />
          )}
          className="col-width-20"
        />
        <Column
          field="description"
          header={t('/admin/role-management.roles_fieldset.datatable.columns.description')}
          sortable
          className="d-table-cell d-sm-none d-md-table-cell col-width-25"
          headerClassName="d-table-cell d-sm-none d-md-table-cell col-width-25"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/role-management.roles_fieldset.datatable.columns.description')}
              data={rowData.description || ''}
            />
          )}
        />
        <Column
          field="applicationName"
          header={t('/admin/role-management.roles_fieldset.datatable.columns.application')}
          sortable
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/role-management.roles_fieldset.datatable.columns.application')}
              data={rowData.application ? rowData.application.applicationName || '' : ''}
            />
          )}
          className="col-width-20"
        />
        <Column
          field="isActive"
          header={t('/admin/role-management.roles_fieldset.datatable.columns.confirmed')}
          sortable
          sortFunction={(event) => {
            const roles = tableData?.roles.slice(); // clone
            return roles.sort((x: any, y: any) => event.order * (x.isActive - y.isActive));
          }}
          className="d-table-cell d-sm-none d-xl-table-cell custom-header-min-width-allowed"
          headerClassName="d-table-cell d-sm-none d-xl-table-cell custom-header-min-width-allowed"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/role-management.roles_fieldset.datatable.columns.confirmed')}
              data={<ShowCheckOrUncheckIcon value={rowData.isActive} />}
            />
          )}
        />
        <Column
          field="created"
          header={t('/admin/role-management.roles_fieldset.datatable.columns.created')}
          sortable
          className="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/role-management.roles_fieldset.datatable.columns.created')}
              data={rowData.created ? formatDate(rowData.created) : ''}
            />
          )}
        />
        <Column
          field="updated"
          header={t('/admin/role-management.roles_fieldset.datatable.columns.updated')}
          sortable
          className="d-table-cell d-sm-none d-xxl-table-cell updatedDateCol"
          headerClassName="d-table-cell d-sm-none d-xxl-table-cell updatedDateCol"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/role-management.roles_fieldset.datatable.columns.updated')}
              data={rowData.updated ? formatDate(rowData.updated) : ''}
            />
          )}
        />
        <Column
          field="edit"
          className="p-0 col-width-3 absolute-position-responsive-screen"
          body={(rowData: any) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => {
                  onEditButtonClicked({ data: rowData });
                }}
                variant="outline">
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getConfirmPopup()}
    </>
  );
};

export default RoleTable;
