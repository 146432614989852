/**
* VerifyPage.tsx (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file VerifyPage.tsx
* @author James Ugbanu
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Card } from 'primereact/card';
import { Alert } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RegisterForm from '@abstract/abstractwebcommon-client/RegisterForm';
import { AppDispatch } from '../../Store/Store';
import {
  completeRegistrationAction,
  getAuthState,
  IAuthState,
  isVerificationLinkValidAction
} from '../../Store/AuthSlice';
import { IRegisterFormData } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { translate } from '../../Utils/Translate';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import queryString from 'query-string';
import { createLogApi } from '../../Services/LogApi';
import {
  IApplications,
  IPublicApplicationInformation
} from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { getSettingsState, ISettingsState } from '../../Store/SettingsSlice';
import Loader from '@abstract/abstractwebcommon-client/Loader';

/**
 * User registration verification component.
 */
const VerifyPage = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch<AppDispatch>();
  const authState: IAuthState = useSelector(getAuthState);
  const translation: TFunction = useTranslation().t;

  const history = useHistory<() => void>();
  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search);
  const token: string = parsedString['token'];
  const userID: string = parsedString['userID'];
  const isFromInvitationLink: string = parsedString['isFromInvitationLink'];
  const settingsState: ISettingsState = useSelector(getSettingsState); /**< Settings state */

  const [applicationDetails, setApplicationDetails] = useState<IApplications>(null);
  const [isVerificationTokenValid, setVerificationTokenValid] = useState<boolean | null>(null);

  const onSubmit = (data: IRegisterFormData) => {
    data.token = token;
    dispatch(completeRegistrationAction(data));
  };

  const getRegisterForm = () => {
    const application: IPublicApplicationInformation = {
      applicationName: applicationDetails?.applicationName,
      description: applicationDetails?.description,
      logoImageURL: applicationDetails?.logoImageURL,
      website: applicationDetails?.website
    }; /**< Application details */

    if (authState.user && Object.keys(authState.user).length) {
      return (
        <RegisterForm
          isLoading={authState.isLoading}
          userFormData={authState.user}
          onSubmit={onSubmit}
          loginURL={SharedCommomRouteName.loginRoute}
          application={application}
          completeRegistrationText={
            (settingsState && settingsState.safeSettings?.completeRegistrationText) ||
            translate('I18N.register.completeRegistrationText')
          }
        />
      );
    }
  };

  const GetActivationMesssage = (): JSX.Element => {
    return (
      <Alert variant={authState.isUserActivated ? 'success' : 'warning'}>
        {authState.isUserActivated
          ? translation('I18N.user_activation.user_activated')
          : `${
              authState.activateUserError
                ? authState.activateUserError
                : translation('I18N.user_activation.error')
            }`}
      </Alert>
    );
  };

  const ActivationExpired = (): JSX.Element => {
    return (
      <Container className="activationContainer">
        <Row>
          <Col xs={12} className="mt-3">
            <Card
              className="rounded-corners-to-card-component keep-original-card-style keep-original-card-title-style"
              title={translation('I18N.user_activation.title')}>
              {GetActivationMesssage()}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    if (authState.registerUserError) {
      showToast({
        severity: 'error',
        summary: translate(authState.registerUserError)
      });
    }

    if (authState.isCompleteRegistrationFinished && isFromInvitationLink === 'false') {
      showToast({
        severity: 'success',
        summary: translate('I18N.register.user_verified')
      });
      history.push('/user');
    }

    if (authState.isUserAlreadyVerified && isFromInvitationLink === 'false') {
      showToast({
        severity: 'success',
        summary: translate('I18N.register.user_already_verified')
      });
      history.push('/user');
    }

    setVerificationTokenValid(authState.isVerificationLinkValid);
  }, [authState]);

  useEffect(() => {
    if (userID) {
      const payload = {
        userUUID: userID,
        isCompleteRegistration: true
      };

      if (isFromInvitationLink === 'true') {
        Object.assign(payload, { token });
      }

      dispatch(isVerificationLinkValidAction(payload));
    }
  }, []);

  useEffect(() => {
    const applicationRedirectUrl: string = authState?.redirectURL;

    if (
      applicationRedirectUrl &&
      applicationRedirectUrl !== '' &&
      isFromInvitationLink === 'true' &&
      (authState.isUserAlreadyVerified || authState.isCompleteRegistrationFinished)
    ) {
      if (authState.isCompleteRegistrationFinished) {
        showToast({
          severity: 'success',
          summary: translate('I18N.register.user_verified')
        });
      }

      if (authState.isUserAlreadyVerified) {
        showToast({
          severity: 'success',
          summary: translate('I18N.register.user_already_verified')
        });
      }

      window.location.href = applicationRedirectUrl; /**< Redirect to application. */
    }
  }, [
    authState.isUserAlreadyVerified,
    authState.isCompleteRegistrationFinished,
    authState?.redirectURL
  ]);

  useEffect(() => {
    if (!applicationDetails) {
      if (settingsState.loginPageInformation) {
        setApplicationDetails(settingsState.loginPageInformation.applicationDetails);
      }
    }
  }, [applicationDetails, settingsState.loginPageInformation]);

  if (authState.isCheckingVerificationLinkValidity) {
    return <Loader />;
  }

  return (
    <>
      {isVerificationTokenValid === undefined || (isVerificationTokenValid === null && <Loader />)}

      {isVerificationTokenValid === false ? <ActivationExpired /> : <>{getRegisterForm()}</>}
    </>
  );
};

export default withErrorBoundary(VerifyPage, createLogApi);
