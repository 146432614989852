/**
* AdminContent.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file AdminContent.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAdminRoutes } from '../../Routes/AdminRoutes';
import Col from 'react-bootstrap/Col';
import { getAuthState, IAuthState } from '../../Store/AuthSlice';
import { useSelector } from 'react-redux';
import PageHeader from '@abstract/abstractwebcommon-client/PageHeader/PageHeader';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

interface IAdminContent {
  isAdmin: boolean;
}

const AdminContent = ({ isAdmin }: IAdminContent): JSX.Element => {
  const authState: IAuthState = useSelector(getAuthState);
  const languageSettingsMode: string =
    LocalStorage.getLanguageSettingsMode() ||
    LanguageSettingsMode.english; /**< Language settings mode */
  const [routes, setRoutes] = useState<any>([]); /**< Routes */

  // Get admin routes when language changes
  useEffect(() => {
    const adminRoutes = getAdminRoutes();
    setRoutes([...adminRoutes]);
  }, [languageSettingsMode]);

  //Note: Get admin routes when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const adminRoutes = getAdminRoutes();
      setRoutes([...adminRoutes]);
    }
  });

  /// Render specific component if isAuthenticated and if isAdmin, Else redirect to login.
  const renderComponent = routes.map(({ Component, path, showHeading, headingKey }, key) => {
    if (!authState.isAuthenticated && !isAdmin) {
      return <Redirect key={key} to={{ pathname: SharedCommomRouteName.loginRoute }} />;
    }

    return (
      <Route exact path={path} key={key}>
        {showHeading && headingKey ? <PageHeader title={headingKey} /> : null}
        <Col sm={12}>
          <Component />
        </Col>
      </Route>
    );
  });

  return <Switch>{renderComponent}</Switch>;
};

export default AdminContent;
