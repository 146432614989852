/**
* Login.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file Login.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
* @modified Rafael Rodrigues - 2022
*/

import React, { Dispatch, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthState,
  IAuthState,
  loginAction,
  loginUserWithTokenAction,
  logoutAction,
  resetAuthStateAction
} from '../../Store/AuthSlice';
import {
  getUserApplicationsState,
  IUserApplicationsState,
  userApplicationsAction,
  validateUserPermissionAction
} from '../../Store/UserApplicationsSlice';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import LoginForm from '@abstract/abstractwebcommon-client/LoginForm';
import StateLoader from '../../Store/StateLoader';
import { TFunction } from 'i18next';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IApplications,
  IPublicApplicationInformation
} from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { translate } from '../../Utils/Translate';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/user/auth';
import TopBar from '@abstract/abstractwebcommon-client/TopBar';
import { createLogApi } from '../../Services/LogApi';
import { getConfigurationSettings } from '../../Services/SettingsApi';
import { IAppSettings } from '@abstract/abstractwebcommon-shared/interfaces/user/settings';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from '../../Utils/routesNames';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import i18n from '../../Services/I18n';
import {
  isStringEmptyOrNullOrUndefined,
  parseBoolean
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { ISettingsState, getSettingsState } from '../../Store/SettingsSlice';

/**
 * @interface ILoginProperties
 */
interface ILoginProperties {
  themeMode?: string /**< theme mode to use */;
  didChangeTheme?: (theme: string) => void /**< change theme function */;
  languageSettingsMode?: string /**< Language settings mode to use */;
  didChangeLanguage?: (language: string) => void /**< change language function */;
}

const Login = (properties: ILoginProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;

  const dispatch: Dispatch<any> = useDispatch();
  const authState: IAuthState = useSelector(getAuthState);
  const authStateReference: any = useRef(); /**< AuthState Reference. */
  const userApplicationsState: IUserApplicationsState = useSelector(getUserApplicationsState);
  const userApplicationsStateReference: any = useRef(); /**< UserApplicationsState Reference. */

  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [applicationDetails, setApplicationDetails] = useState<IApplications>(null);
  const [username, setUsername] = useState<string>(null);
  const [password, setPassword] = useState<string>(null);

  const history: any = useHistory();

  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search);
  const verificationURL: string = parsedString['verification_url'];
  const redirectURL: string = parsedString['redirect_url'];
  const applicationUUID: string = parsedString['app'];
  const token: string = parsedString['token']; /**< User Token. */
  const returnURL: string =
    parsedString[
      'returnURL'
    ]; /**< Defines the URL to redirect through the back button when visible. */
  const settingsState: ISettingsState = useSelector(getSettingsState);

  /// Dispatches logout action
  const fnLogout = () => {
    dispatch(logoutAction());
    history.push({ pathname: SharedCommomRouteName.loginRoute, hash: '' });
  };

  /// Update reference when authState, userApplicationsState changes
  useEffect(() => {
    authStateReference.current = authState;
    userApplicationsStateReference.current = userApplicationsState;
  }, [authState, userApplicationsState]);

  //Note:Reset state when the component is unmounted, otherwise the state value persists and the message is always displayed in toast.
  useEffect(() => {
    return () => {
      if (
        authStateReference.current.loginError ||
        authStateReference.current.verifyOnDate ||
        authStateReference.current.hasError
      ) {
        dispatch(resetAuthStateAction({})); // Reset Auth State
      }
      if (userApplicationsStateReference.current?.statusCode) {
        dispatch(userApplicationsAction.reset()); // Reset UserApplication state
      }
    };
  }, []);

  useEffect(() => {
    // If login with token
    if (token) {
      dispatch(loginUserWithTokenAction(token));
    }
  }, []);

  useEffect(() => {
    if (settingsState.loginPageInformation) {
      setApplicationDetails(settingsState.loginPageInformation.applicationDetails);
    }
  }, [settingsState.loginPageInformation]);

  /**
   * Clear localStorage on init
   */
  useEffect(() => {
    if (!isPageLoaded) {
      const hash: string = history.location.hash;
      const errorMessages: { [key: string]: string } = {
        'session-timeout': 'I18N.login.session_expired',
        'access-denied': 'I18N.login.access_denied'
      };
      if (hash !== '') {
        showToast({
          severity: 'error',
          summary: t(errorMessages[hash.replace(/#/i, '')] ?? 'I18N.login.generic_error')
        });
        fnLogout();
      }
      setIsPageLoaded(true);
    }
  }, [isPageLoaded, history, setIsPageLoaded, t, dispatch]);

  /// Fetch Configuration Settings
  const fetchConfigurationSettings = async () => {
    const result: IAPIEntityResponse<IAppSettings> = await asyncErrorHandler(
      getConfigurationSettings()
    );
    const configurationSettings: IAppSettings = result.data;

    // If there are settings to configure, go to the configuration page to configure the application.
    if (configurationSettings && Object.keys(configurationSettings).length > 0) {
      history.push({
        pathname: SharedCommomRouteName.configurationSettingsRoute,
        state: configurationSettings
      });
    } else {
      // Otherwise, go to the dashboard page.
      history.push({ pathname: RouteName.adminDashboardRoute });
    }
  };

  const handleUserAuthentication = async () => {
    //NOTE: If a user is requesting the login from an external application,
    //NOTE: this page must checks if there is a token and validates if the user has permission to access the requested application.
    //NOTE: Only trigger this condition if there is a stored token into local storage and when the page isn't requesting any endpoint.
    if (
      LocalStorage.getXAuthToken() &&
      applicationUUID &&
      !userApplicationsState.isLoading &&
      !authState.isLoading
    ) {
      const isTokenValid: boolean = StateLoader.isTokenValid(LocalStorage.getXAuthToken());
      if (isTokenValid) {
        dispatch(
          validateUserPermissionAction({
            token: LocalStorage.getXAuthToken(),
            applicationUUID: applicationUUID
          })
        );
      } else {
        LocalStorage.removeXAuthToken();
        LocalStorage.removeXUserUUID();
        LocalStorage.removeIsAdmin();

        const parameters: URLSearchParams = new URLSearchParams({
          verification_url: verificationURL,
          app: applicationUUID
        }); /**< Query parameters */

        if (!isStringEmptyOrNullOrUndefined(returnURL)) {
          parameters.append('returnURL', returnURL);
        }

        window.location.replace(`${SharedCommomRouteName.loginRoute}?${parameters.toString()}`);
      }
    }

    const handleIsAuthenticated = (): void => {
      if (authState.isAuthenticated) {
        if (authState.isAdmin) {
          history.push({ pathname: RouteName.adminProfileRoute });
        } else {
          history.push({ pathname: RouteName.userProfileRoute });
        }
      }
    };

    // NOTE: We should use "parseBoolean(LocalStorage.getIsRootApplication())" to identify if the request is from the User app. We shouldn't perform redirections while accessing the User app.
    if (token || parseBoolean(LocalStorage.getIsRootApplication())) {
      handleIsAuthenticated();
    } else {
      if (
        authState.isAuthenticated &&
        isPageLoaded &&
        !userApplicationsState.isLoading &&
        userApplicationsState.applicationPermission.isUserPermissionGranted
      ) {
        const isTokenValid: boolean = StateLoader.isTokenValid(LocalStorage.getXAuthToken());
        if (!isTokenValid) {
          fnLogout();
        } else {
          if (verificationURL) {
            const parameters: URLSearchParams = new URLSearchParams({
              token: LocalStorage.getXAuthToken(),
              themeMode: LocalStorage.getThemeMode(),
              languageSettingsMode: LocalStorage.getLanguageSettingsMode()
            }); /**< Query parameters */
            if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
              parameters.append('redirect_url', redirectURL);
            }
            window.location.href = `${verificationURL}?${parameters.toString()}`;
          } else {
            if (authState.isAdmin) {
              fetchConfigurationSettings();
            } else {
              history.push(SharedMainRouteName.userRoute);
            }
          }
        }
      }
    }
  };

  /**
   * Listen to state changes
   */
  useEffect(() => {
    handleUserAuthentication();

    if (authState.loginError && isPageLoaded) {
      showToast({
        severity: 'error',
        summary: t('I18N.login.auth_failed'),
        detail: authState.loginError?.message || authState.loginError
      });
    }
    if (authState.verifyOnDate) {
      showToast({
        severity: 'success',
        summary: translate('I18N.success_messages.send_mail_success')
      });
    }
    if (authState.hasError) {
      showToast({
        severity: 'error',
        summary: translate(authState.hasError)
      });
    }
  }, [
    authState,
    isPageLoaded,
    history,
    t,
    userApplicationsState.applicationPermission?.isUserPermissionGranted
  ]);

  const handleLogin = () => {
    if (username != null && password != null) {
      dispatch(
        loginAction({
          username: username,
          password: password
        })
      );
    }
  };

  /**
   * Handle login errors
   */
  useEffect(() => {
    if (userApplicationsState?.statusCode === 404) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: t('I18N.login.access_denied')
      });
    }

    if (userApplicationsState?.statusCode === 400) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: t('I18N.login.generic_error')
      });
    }

    if (userApplicationsState?.statusCode === 401) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: t('I18N.login.invalid_credentials')
      });
    }
  }, [userApplicationsState]);

  /**
   * Is triggered user is being redirect to the login page and is already logged in an external application and has permission to it.
   */
  useEffect(() => {
    if (
      userApplicationsState.applicationPermission &&
      Object.keys(userApplicationsState.applicationPermission).length > 0 &&
      userApplicationsState.applicationPermission?.isUserPermissionGranted &&
      LocalStorage.getXAuthToken()
    ) {
      if (!isStringEmptyOrNullOrUndefined(verificationURL)) {
        const parameters: URLSearchParams = new URLSearchParams({
          token: LocalStorage.getXAuthToken(),
          themeMode: LocalStorage.getThemeMode(),
          languageSettingsMode: LocalStorage.getLanguageSettingsMode()
        }); /**< Query parameters */
        if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
          parameters.append('redirect_url', redirectURL);
        }
        window.location.href = `${verificationURL}?${parameters.toString()}`;
      } else {
        // NOTE: Else case is for User App. If the User's local storage has the JWT token as the user already logged in on License or Ecommerce, delete the localstorage and login to User app
        if (LocalStorage.getXAuthToken()) {
          dispatch(logoutAction());
          handleLogin();
        }
      }
    }
  }, [userApplicationsState.applicationPermission?.isUserPermissionGranted]);

  /**
   * Call login endpoint if user has granted permission
   */
  useEffect(() => {
    if (
      userApplicationsState.applicationPermission &&
      Object.keys(userApplicationsState.applicationPermission).length > 0 &&
      userApplicationsState.applicationPermission?.isUserPermissionGranted &&
      !LocalStorage.getXAuthToken()
    ) {
      handleLogin();

      return () => dispatch(userApplicationsAction.reset());
    }
  }, [userApplicationsState.applicationPermission.isUserPermissionGranted]);

  const handleSubmit = (data: IAuth) => {
    setUsername(data.username);
    setPassword(data.password);

    dispatch(
      validateUserPermissionAction({
        username: data.username,
        password: data.password,
        applicationUUID
      })
    );
  };

  const handleForgotPassword = () => {
    history.push({ pathname: RouteName.forgotPasswordRoute });
  };

  const getLoginForm = () => {
    const application: IPublicApplicationInformation = {
      applicationName: applicationDetails?.applicationName,
      description: applicationDetails?.description,
      applicationUUID: applicationUUID,
      logoImageURL: applicationDetails?.logoImageURL,
      website: applicationDetails?.website
    }; /**< Application details */

    return (
      <>
        <TopBar
          themeMode={properties.themeMode}
          didChangeTheme={properties.didChangeTheme}
          languageSettingsMode={properties.languageSettingsMode}
          didChangeLanguage={properties.didChangeLanguage}
          i18nService={i18n}
        />
        <LoginForm
          isLoading={authState.isLoading || userApplicationsState.isLoading}
          handleSubmit={handleSubmit}
          showForgotPassword={true}
          forgotPassword={handleForgotPassword}
          userData={authState.user}
          lockedOutError={authState.lockedOutError}
          redirectURL={search}
          application={application}
          themeMode={properties.themeMode}
          isDuplicateEmailsEnabled={settingsState?.safeSettings?.enableDuplicateEmails}
        />
      </>
    );
  };

  if (
    applicationDetails &&
    userApplicationsState.applicationPermission &&
    Object.keys(userApplicationsState.applicationPermission).length > 0 &&
    !userApplicationsState.applicationPermission?.isUserPermissionGranted
  ) {
    const parameters: URLSearchParams = new URLSearchParams({
      verification_url: verificationURL,
      app: applicationUUID
    }); /**< Query parameters */
    return (
      <Redirect
        to={{
          pathname: `${RouteName.validatePermissionRoute}${
            applicationUUID ? `?${parameters.toString()}` : ''
          }`,
          state: {
            logoURL: applicationDetails?.logoImageURL,
            description: applicationDetails?.description,
            applicationName: applicationDetails?.applicationName,
            username,
            password,
            applicationUUID,
            verificationURL,
            redirectURL
          }
        }}
      />
    );
  }

  if (!applicationDetails) {
    return <></>;
  }

  return (
    <>
      {authState && authState.accessToken == null && (
        <div className="text-dark login-container">{getLoginForm()}</div>
      )}
    </>
  );
};

export default withErrorBoundary(Login, createLogApi);
