/**
* AppTable.tsx (abstractuser) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2021
* @file AppTable.tsx
* @author Etienne Daher
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Other dependencies
import { Column } from 'primereact/column';
import Button from 'react-bootstrap/Button';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import Col from 'react-bootstrap/Col';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { Dialog } from 'primereact/dialog';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  ISettingsState,
  getSafeAppSettingsAction,
  getSettingsState
} from '../../../Store/SettingsSlice';

interface IAppTableProperties {
  tableData: any;
  refreshList: any;
  setDialogVisible: any;
  setEditApp: any;
  deleteApps: any;
  deleteData: any;
  selectedApps:
    | IApplications[]
    | null /* Manages the selected applications from the app table/list */;
  setSelectedApps: React.Dispatch<
    React.SetStateAction<IApplications[]>
  > /* State to set selected applications from the app table/list */;
}

export const AppTable = ({
  tableData,
  refreshList,
  setDialogVisible,
  setEditApp,
  deleteApps,
  deleteData,
  selectedApps,
  setSelectedApps
}: IAppTableProperties): JSX.Element => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const settingsState: ISettingsState = useSelector(getSettingsState);

  const [expandedRows, setExpandedRows] = useState<any>({});

  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tableData.
  Object.keys(tableData.criteria.sort).forEach((key) => {
    defaultSortField = key; // Sortfield
    defaultSortOrder = tableData.criteria.sort[key]; //Sortorder
  });
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [showDeleteLinkedApplicationsWarning, setShowDeleteLinkedApplicationsWarning] =
    useState<boolean>(false);
  const deleteButtonReference: any = useRef(null);

  /// This specifies the template for row expansion.
  const GetRowExpansionTemplate = ({ rowData }: any) => (
    <>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.id')}</th>
        <td>{rowData['applicationUUID'] || ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.name')}</th>
        <td>{rowData['applicationName']}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.privateKey')}</th>
        <td>{rowData['privateKey']}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.confirmed')}</th>
        <td>{<ShowCheckOrUncheckIcon value={rowData.isActive} />}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.createdBy')}</th>
        <td>{rowData['createdByUserUUID']}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.created')}</th>
        <td>{rowData.created ? formatDate(rowData.created) : ''}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.lastUpdated')}</th>
        <td>{rowData.updated ? formatDate(rowData.updated) : ''}</td>
      </tr>
      <tr>
        <th>
          {t('/admin/application-management.apps_fieldset.datatable.columns.verificationURL')}
        </th>
        <td>{rowData['verificationURL']}</td>
      </tr>
      <tr>
        <th>{t('/admin/application-management.apps_fieldset.datatable.columns.website')}</th>
        <td>{rowData['website']}</td>
      </tr>
    </>
  );

  /// This will render the expansion row template.
  const renderExpansionRows = (rowData: any) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any) => {
    const updatedCriteria = { ...tableData.criteria };
    updatedCriteria.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedCriteria.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }

    refreshList(updatedCriteria);
    setMultiSortMeta(event.multiSortMeta);
  };

  /// Triggers on input data change on the column heading. This updates the criteria object to send a request to the backend with the specific column data.
  const onFilter = (termToFind: string) => {
    const updatedCriteria = { ...tableData.criteria };
    Object.assign(updatedCriteria, { searchTerm: termToFind });
    refreshList(updatedCriteria);
  };

  const onRowClick = (event: any) => {
    setEditApp(event.data);
    setDialogVisible(true);
  };

  /// Triggers on every page change to load new data
  const onPage = (event: any) => {
    const updatedCriteria = { ...tableData.criteria };
    updatedCriteria.limit = event.rows || defaultTableLimit;
    updatedCriteria.skip = event.first || 0;
    refreshList(updatedCriteria);
  };

  const onSelectionChange = (event: any) => {
    const selectedApplications: IApplications[] = event.value;
    if (Array.isArray(selectedApplications)) {
      const selectedRows: IApplications[] = selectedApplications.map((row: IApplications) => {
        return row;
      });
      setSelectedApps(selectedRows);
    }
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.applicationUUID]: true });
    }
  };

  useEffect(() => {
    if (settingsState.safeSettings == null) {
      // We need to fetch settings data in case accessing this page or refreshing it directly.
      // We use the .rootApplication value from settings to know if the user is editing the root application.
      // If user is editing the root application, we shouldn't display the fields "Sidebar Visualization" and "Link To Redirect When User Is Not Registered" in the app form.
      dispatch(getSafeAppSettingsAction());
    }
  }, [settingsState.safeSettings]);

  /// Returns datatable
  const getDataTable = () => {
    return (
      <BaseDatatable
        value={tableData.applications}
        selection={selectedApps}
        onSelectionChange={(event: any) => onSelectionChange(event)}
        header={header}
        expandedRows={expandedRows}
        rowExpansionTemplate={renderExpansionRows}
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        isLoading={!tableData.applications && !settingsState.safeSettings}
        parentClass="appDataTable" /**< ClassName for div component. */
        rows={tableData.criteria.limit}
        first={tableData.criteria.skip}
        dataKey="applicationUUID"
        onPage={(event: any) => onPage(event)}
        totalRecords={tableData.totalRecords}
        onSort={(event: any) => onSort(event)}
        onFilter={(event: any) => onFilter(event)}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        selectionMode="checkbox">
        <Column expander className="p-0 col-width-45" />
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          field="applicationName"
          header={t('/admin/application-management.apps_fieldset.datatable.columns.name')}
          sortable
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/application-management.apps_fieldset.datatable.columns.name')}
              data={rowData.applicationName}
            />
          )}
        />
        <Column
          field="isActive"
          header={t('/admin/application-management.apps_fieldset.datatable.columns.confirmed')}
          sortable
          className="custom-header-min-width-allowed"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/application-management.apps_fieldset.datatable.columns.confirmed')}
              data={<ShowCheckOrUncheckIcon value={rowData.isActive} />}
            />
          )}
        />
        <Column
          field="createdByUserUUID"
          sortable
          header={t('/admin/application-management.apps_fieldset.datatable.columns.createdBy')}
          body={(rowData: any) => {
            if (rowData.createdByUserUUID) {
              return (
                <DatatableColumn
                  title={t(
                    '/admin/application-management.apps_fieldset.datatable.columns.createdBy'
                  )}
                  data={rowData.createdByUserUUID}
                />
              );
            }
          }}
        />
        <Column
          field="created"
          header={t('/admin/application-management.apps_fieldset.datatable.columns.created')}
          sortable
          className="d-table-cell d-sm-none d-md-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-md-table-cell createdDateCol"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/application-management.apps_fieldset.datatable.columns.created')}
              data={formatDate(rowData.created)}
            />
          )}
        />
        <Column
          field="updated"
          header={t('/admin/application-management.apps_fieldset.datatable.columns.lastUpdated')}
          sortable
          className="d-table-cell d-sm-none d-xl-table-cell updatedDateCol"
          headerClassName="d-table-cell d-sm-none d-xl-table-cell updatedDateCol"
          body={(rowData: any) => (
            <DatatableColumn
              title={t('/admin/application-management.apps_fieldset.datatable.columns.lastUpdated')}
              data={formatDate(rowData.updated)}
            />
          )}
        />
        <Column
          field="edit"
          className="p-0 col-width-45 absolute-position-responsive-screen"
          body={(rowData: any) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => {
                  onRowClick({ data: rowData });
                }}
                variant="outline">
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
        />
      </BaseDatatable>
    );
  };

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteBtnClick(event)}
          isDisabled={
            ((selectedApps && Object.keys(selectedApps).length === 0) || !selectedApps) ?? false
          }
          buttonReference={deleteButtonReference}
        />

        <ActionButton onClick={() => setDialogVisible(true)} />
      </div>

      <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
    </div>
  );

  /// show delete popup
  const deleteBtnClick = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete applications on Accept
  const onAccept = (): void => {
    deleteApps(selectedApps, false);
    setShowDeleteLinkedApplicationsWarning(true);
    setShowConfirmation(false);
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setShowConfirmation(false);
    setShowDeleteLinkedApplicationsWarning(false);
  };

  const onDelete = (): void => {
    deleteApps(selectedApps, true);
    setShowDeleteLinkedApplicationsWarning(false);
  };

  const renderRowDeleteDialogFooter = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplicationsNo'
          )}
        </Button>
        <Button
          onClick={() => onDelete()}
          autoFocus
          variant="danger"
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplicationsYes'
          )}
        </Button>
      </div>
    );
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <>
        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={showConfirmation}
          title={t('/confirm_messages.delete_records')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="bottom"
        />

        <Dialog
          header={t(
            '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplicationsHeader'
          )}
          visible={
            showDeleteLinkedApplicationsWarning &&
            deleteData &&
            (deleteData.userCount > 0 || deleteData.roleCount > 0)
          }
          className="overflow-auto custom-dialog-container"
          footer={renderRowDeleteDialogFooter()}
          onHide={() => setShowDeleteLinkedApplicationsWarning(false)}
          draggable={false}>
          <Col className="mb-2 px-0">
            {t(
              '/admin/application-management.apps_fieldset.delete_app_dialog.confirmDeleteApplications'
            )}
          </Col>
          {deleteData.roleCount > 0 && (
            <Col className="mb-2 px-0">
              Total Affected roles: <b>{deleteData.roleCount}</b>
            </Col>
          )}
          {deleteData.userCount > 0 && (
            <Col className="mb-2 px-0">
              Total Affected users: <b>{deleteData.userCount}</b>
            </Col>
          )}
          {deleteData.roleCount > 0 && (
            <Col className="mb-2 px-0">
              Total Roles linked: <b>{deleteData.roleCount}</b> - [
              {deleteData.linkedRoles.join(', ')}]
            </Col>
          )}
          {deleteData.userCount > 0 && (
            <Col className="px-0">
              <table className="w-100">
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                </tr>
                {deleteData.linkedUsers.map((user: IUser) => (
                  <tr key={user.id}>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </table>
            </Col>
          )}

          {deleteData.rolesNotShownCount > 0 && (
            <Col>{deleteData.rolesNotShownCount} not shown</Col>
          )}
        </Dialog>
      </>
    );
  };

  return (
    <>
      {getDataTable()}
      {getConfirmPopup()}
    </>
  );
};

export default AppTable;
