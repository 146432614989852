/**
* TemplateForm.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file TemplateForm.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

/* eslint import/no-webpack-loader-syntax: off */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTemplateState, ITemplateState } from '../../../Store/TemplateSlice';
import TemplateComponent from '@abstract/abstractwebcommon-client/TemplateEditor/TemplateComponent';
import { TFunction } from 'i18next';
import { getSettingsState, ISettingsState } from '../../../Store/SettingsSlice';
import { BASE_API_URL } from '../../../Config';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

const codeMirrorOptions = {
  theme: 'dracula',
  lineNumbers: true,
  lineWrapping: true
};

interface ITemplateFormProperties {
  onTemplateSubmit: any;
}

const userBackendCss = require('!css-loader!resolve-url-loader!sass-loader!../../../Global.css');

const TemplateForm = ({ onTemplateSubmit }: ITemplateFormProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;
  const templateState: ITemplateState = useSelector(getTemplateState);
  const settingState: ISettingsState = useSelector(getSettingsState);
  const [masterTemplate, setMasterTemplate] = useState<any>(null);

  const onTemplateSubmitHandler = (updatedTemplate: any) => {
    onTemplateSubmit(updatedTemplate);
  };

  /// Copy placeholder value
  const copyPlaceholder = (placeholder: any) => {
    navigator.clipboard.writeText(`{{${placeholder}}}`);
    showToast({ severity: 'success', summary: t('I18N.template_editor.placeholder.copy') });
  };

  useEffect(() => {
    const master: any = templateState.templates
      ? templateState.templates.find((template: any) => template.type === 'masterTemplate') || {}
      : [];
    setMasterTemplate(master);
  }, [templateState.templates]);

  return (
    <>
      <TemplateComponent
        codeMirrorOptions={{
          mode: 'text/html',
          ...codeMirrorOptions
        }}
        masterTemplate={masterTemplate?.html}
        submitHandler={onTemplateSubmitHandler}
        templates={templateState.templates}
        copyPlaceholderHandler={copyPlaceholder}
        masterBodyPlaceholder={'{{Body}}'}
        templateTypePropertyName={'type'}
        applicationCSS={userBackendCss}
        customCSSLink={
          (settingState &&
            settingState.safeSettings &&
            BASE_API_URL + '/' + settingState.safeSettings['customCssLink']) ||
          ''
        }
        submitClasses="p-button p-button-primary"
        editorClasses=""
        changeLayoutClasses="p-button p-button-primary"
      />
    </>
  );
};

export default TemplateForm;
