/**
* RolesApi.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file RolesApi.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import {
  IDeleteRoleServiceResponse,
  IRoleUserResponse
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Gets roles
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const getRolesFromServer = (
  payload?: Record<string, any>
): Promise<PaginatedAPIEntityResponse<IRole>> => {
  const url: string = paginationRequest(`/roles/all`, payload);
  return httpClient.get(url);
};

/**
 * Gets roles for selected application IDs
 * @param selectedApplicationIDs applicationIDs of the applications to filter by
 * @returns Promise
 */
export const getRolesDropdown = (
  selectedApplicationIDs: string[]
): Promise<IAPIEntityResponse<IRole[]>> => {
  return httpClient.get(
    `/roles/applications?applicationIDs=${JSON.stringify(selectedApplicationIDs)}`
  );
};

/**
 * Deletes roles
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const deleteRolesFromServer = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IDeleteRoleServiceResponse>> => {
  return httpClient.post('/roles/delete', payload);
};

/**
 * Creates a role
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const createRolesApi = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IRole>> => {
  return httpClient.post('/role/create', payload);
};

/**
 * Updates a role
 * @param payload Payload to send with the request
 * @param roleID roleUUID of the role
 * @returns Promise
 */
export const updateRolesApi = (
  payload: Record<string, any>,
  roleID: string
): Promise<IAPIEntityResponse<IRole>> => {
  return httpClient.post<IRole>(`/role/${roleID}/update`, payload);
};

/**
 * Gets users by role id
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const getUsersByRoleId = (
  payload: Record<string, any>
): Promise<PaginatedAPIEntityResponse<IUser>> => {
  const url: string = paginationRequest(`${BASE_API_URL}/users/role/${payload.roleUUID}`, payload);
  return httpClient.get<IUser>(url);
};

/**
 * Gets role by ID.
 * @param roleUUID roleUUID of the role.
 * @returns Promise.
 */
export const getRoleByID = (roleUUID: string): Promise<IAPIEntityResponse<IRoleUserResponse>> => {
  return httpClient.get<IUser>(`/user/find/role/${roleUUID}`);
};
