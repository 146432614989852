/**
* BasicInformationComponent.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2022 
* @file BasicInformationComponent.tsx
* @author Timothy Fadayini
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import UserForm from '../UserManagement/UserForm';
import { IProfileState } from '../../../Store/ProfileSlice';
import { Card } from 'primereact/card';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

/**
 * @interface IProfileBasicInfoProperties
 */
export interface IProfileBasicInfoProperties {
  isLoading: boolean /**< check if information is still loading */;
  profileState: IProfileState /**< profile state from the store */;
  reloadRoles: (
    selectedApplicationIDs: number[]
  ) => Promise<void> /**< handles the reloading of roles */;
  onUpdateUser: (userData: IUser) => Promise<void> /**< handles user update button clicks */;
  onDelete: (isDeleted: boolean) => void /**< handles user delete button clicks */;
  handleUserDelete: (userUUIDs: string[]) => void /**< deletes the user after confirmation */;
}

/**
 * Basic information component
 */
const BasicInformationComponent = ({
  isLoading,
  profileState,
  reloadRoles,
  onUpdateUser,
  onDelete,
  handleUserDelete
}: IProfileBasicInfoProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;

  return (
    <Card className="profile-home-cards" title={t('I18N.profile.full_basic_info')}>
      <UserForm
        profile={true}
        onUpdateUser={onUpdateUser}
        editUser={profileState.profile || {}}
        roles={profileState.role}
        reloadRoles={reloadRoles}
        isFetchingRoles={profileState.isFetchingRoles}
        applications={profileState.applications}
        deleteUsers={handleUserDelete}
        onDelete={onDelete}
        isLoading={profileState.isProfilePageLoading || isLoading}
        isImageUploadAllowed={true}
      />
    </Card>
  );
};

export default BasicInformationComponent;
