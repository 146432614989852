/*
 * ImagePreview.js (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file ImagePreview.js
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from "react";
import { Badge, Button } from "react-bootstrap";
import "./ImagePreview.css";
import { Dialog } from "primereact/dialog";

const ImagePreview = ({
  deleteHandler,
  showDelete,
  noImageText,
  showInverted,
  imageUrl,
  isLogo,
  imgClass,
  altText,
  noImageContainer,
  setToggleFullSizePreview,
  toggleFullSizePreview = false,
}) => {
  /// Show clear button only if showDelete is present in props.
  const getClearButton = () => {
    if (showDelete) {
      return (
        <Button
          className="removeImageBtn"
          variant={"link"}
          onClick={deleteHandler}
        >
          &#215;
        </Button>
      );
    }
    return <></>;
  };

  /// This is rendered if no image is present
  const getNoImageContainer = () => {
    return (
      <Badge variant="secondary" className="noImageBadge">
        {noImageText}
      </Badge>
    );
  };

  /// Returns the image with delete button or noImageContainer if applicable
  const getImage = () => {
    if (imageUrl) {
      return (
        <>
          <div className="image-container-wrapper">
            <img
              className={`img-fluid ${!isLogo ? "bg-checkerboard" : ""} ${
                imgClass || ""
              } ${showInverted ? "inverted" : ""}`}
              src={imageUrl}
              alt={altText}
              onClick={(event) => setToggleFullSizePreview && setToggleFullSizePreview(true)}
            />
          </div>
          {getClearButton()}
          <Dialog
            visible={toggleFullSizePreview}
            onHide={() => {
              setToggleFullSizePreview && setToggleFullSizePreview(false);
            }}
            >
            <img src={imageUrl} alt={altText} className="fullSizePreview image" />
          </Dialog>
        </>
      );
    } else {
      return noImageContainer ? noImageContainer : getNoImageContainer();
    }
  };

  return getImage();
};

export default ImagePreview;
