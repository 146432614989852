/**
* ResetPasswordPage.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan k, 2020 
* @file ResetPasswordPage.tsx
* @author Sai Charan k
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ForgotPasswordForm from '@abstract/abstractwebcommon-client/ForgotPassword/ForgotPasswordForm';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getForgotPasswordState,
  IForgotPasswordState,
  passwordResetAction,
  verifyCodeAction
} from '../../Store/ForgotPasswordSlice';
import { TFunction } from 'i18next';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/LogApi';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import StateLoader from '../../Store/StateLoader';

const ResetPasswordPage = (): JSX.Element => {
  const history: any = useHistory();
  const t: TFunction = useTranslation().t;

  const dispatch: Dispatch<any> = useDispatch();
  const state: IForgotPasswordState = useSelector(getForgotPasswordState);

  const urlParams = new URLSearchParams(window.location.search);
  const [resetVerificationCode, setResetVerificationCode] =
    useState<number>(); /**< Reset Verification code */
  const [isTokenValid, setTokenValid] = useState<boolean | null>(
    null
  ); /**< Token is valid or not */

  /// check verification email success/failure
  useEffect(() => {
    const verificationCode: any = urlParams.get('verificationCode');
    const userUUID: any = urlParams.get('userUUID');
    const username: any = urlParams.get('username');
    const token: string = urlParams.get('token'); /**< Token from query string */

    // Before code verification, it is necessary to check whether the token is valid or not.
    if (userUUID && verificationCode && username && token) {
      const isCheckingTokenValidation: boolean = StateLoader.isTokenValid(token);
      setTokenValid(isCheckingTokenValidation);
      if (isCheckingTokenValidation) {
        const code: number = parseInt(verificationCode);
        setResetVerificationCode(code);
        history.push({
          search: `?username=${username}`
        });

        dispatch(
          verifyCodeAction({
            userUUID: userUUID,
            verificationCode: code
          })
        );
      }
    }
  }, [dispatch, urlParams]);

  /**
   * Token Validation error
   */
  const tokenValidationError = (): void =>
    showToast({
      severity: 'error',
      summary: t('I18N.reset_password.password_reset_link_expired')
    });

  useEffect(() => {
    if (isTokenValid !== null && !isTokenValid) {
      tokenValidationError();
    }
  }, [isTokenValid]);

  /// Check verification of code success/failure
  useEffect(() => {
    if (state.verificationError) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: state.verificationError?.message || state.verificationError
      });
      setTimeout(() => {
        history.push({ pathname: SharedCommomRouteName.loginRoute });
      }, 1000);
    }
  }, [state.verificationError]);

  useEffect(() => {
    if (state.passwordResetSuccess) {
      showToast({ severity: 'success', summary: t('I18N.reset_password.password_reset_success') });
      setTimeout(() => {
        history.push({ pathname: SharedCommomRouteName.loginRoute });
      }, 1000);
    }

    if (state.passwordResetError) {
      showToast({
        severity: 'error',
        summary: state.passwordResetError?.message || state.passwordResetError
      });
    }
  }, [state.passwordResetSuccess, state.passwordResetError]);

  const onCancel = () => {
    history.push({ pathname: SharedCommomRouteName.loginRoute });
  };

  const onSubmit = (data: any) => {
    const username = urlParams.get('username');
    if (isTokenValid) {
      dispatch(
        passwordResetAction({
          username,
          password: data.password,
          token: state.token,
          verificationCode: resetVerificationCode
        })
      );
    } else {
      tokenValidationError();
    }
  };

  const getResetPasswordForm = () => {
    if (state.isLoading) {
      return <Loader />;
    }
    return (
      <div className="mt-5">
        <Card
          title={t('awc:/.forgot_password.step.reset_pass')}
          className="rounded-corners-to-card-component">
          <ForgotPasswordForm onCancel={onCancel} onSubmit={onSubmit} />
        </Card>
      </div>
    );
  };

  return (
    <div className="container forgotPasswordPage">
      <div className="forgotPasswordContainer">{getResetPasswordForm()}</div>
    </div>
  );
};

export default withErrorBoundary(ResetPasswordPage, createLogApi);
