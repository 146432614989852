/**
* StaticLinksSlice.ts (InstaLOD GmbH) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Polina Ovsiannikova, 2022
* @file StaticLinksSlice.ts
* @author Polina Ovsiannikova
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section Web Common
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState
} from '@reduxjs/toolkit';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  IDeleteStaticLinksFrontendResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {IStaticLink} from '@abstract/abstractwebcommon-shared/interfaces/staticLinks';
import {translate} from '../utils/translate';
import {Reducer} from 'react';
import {
  IReducerAction,
  PaginationRequestAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {IImageUploadResponse,} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import {StaticLinksApi} from '../services/StaticLinksApi';
import {defaultTableLimit} from '../Constants';
import {IPaginationRequest} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {CreateErrorLog} from '@abstract/abstractwebcommon-shared/utils/CreateErrorLog';
import {LocalStorage} from './../utils/sharedLocalStorage';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export const staticLinksFeatureKey: string = 'staticLinks';

/**
* Interface for Static Links State.
*/
export interface IStaticLinksState {
  staticLinks: IStaticLink[];
  allStaticLinks: IStaticLink[];
  isStaticLinksLoading: boolean;
  staticLinksError: any;
  isStaticLinkCreated: boolean;
  isStaticLinkUpdated: boolean;
  isStaticLinksDeleted: boolean;
  criteria: IPaginationRequest<IStaticLink>;
  totalRecords: number;
}

const defaultCriteria: IPaginationRequest<IStaticLink> = {
  limit: defaultTableLimit,
  skip: 0,
  sort: {
    created: 'ASC'
  }
};

export const staticLinksInitialState: IStaticLinksState = {
  staticLinks: null,
  allStaticLinks: [],
  isStaticLinksLoading: false,
  staticLinksError: null,
  isStaticLinkCreated: false,
  isStaticLinkUpdated: false,
  isStaticLinksDeleted: false,
  criteria: defaultCriteria,
  totalRecords: 0,
}

/// Reset Staticlink state
const clearOnRequest = (state: IStaticLinksState) => {
  state.staticLinksError = null;
  state.isStaticLinkCreated = false;
  state.isStaticLinkUpdated = false;
  state.isStaticLinksDeleted = false;
};

export const staticLinksAdapter: EntityAdapter<IStaticLinksState> = createEntityAdapter();
export const initialStaticLinksState: EntityState<IStaticLinksState> & IStaticLinksState =
  staticLinksAdapter.getInitialState(staticLinksInitialState);

export const staticLinksSlice = createSlice({
  name: staticLinksFeatureKey,
  initialState: initialStaticLinksState,
  reducers: {
    reset: (state: IStaticLinksState) => clearOnRequest(state),
    createStaticLinkActionRequest(state: any) {
      state.staticLinksError = null;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.isStaticLinkCreated = false;
    },
    createStaticLinkActionSuccess(state: any) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkCreated = true;
    },
    createStaticLinkActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isStaticLinksLoading = false;
      state.staticLinksError =
        action.payload.error || translate('awc:/.error_messages.static_link_create_error');
    },
    updateStaticLinkActionRequest(state: any) {
      state.staticLinksError = null;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.isStaticLinkUpdated = false;
    },
    updateStaticLinkActionSuccess(state: any) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkUpdated = true;
    },
    updateStaticLinkActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isStaticLinksLoading = false;
      state.staticLinksError =
        action.payload.error || translate('awc:/.error_messages.static_link_update_error');
    },
    getStaticLinksActionRequest(state: any, action: PaginationRequestAction<IStaticLink>) {
      state.criteria = { ...action.payload }; // if filter sent, update criteria for the next refresh
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.staticLinksError = null;
    },
    getStaticLinksActionSuccess(state: any, action: PaginationResponseAction<IStaticLink>) {
      state.totalRecords = action.payload.totalRecords || 0;
      state.isStaticLinksLoading = false;
      state.staticLinks = action.payload.records;
    },
    getStaticLinksActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.staticLinks = []
      state.isStaticLinksLoading = false;
      state.staticLinksError =
        action.payload.error || translate('awc:/.error_messages.static_link_fetch_error');
    },
    deleteStaticLinksActionRequest(state: any) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksDeleted = false;
      state.isStaticLinksLoading = true;
      state.staticLinksError = null;
    },
    deleteStaticLinksActionSuccess(state: any) {
      state.isStaticLinksLoading = false;
      state.isStaticLinksDeleted = true;
    },
    deleteStaticLinksActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isStaticLinksLoading = false;
      state.staticLinksError =
        action.payload.error || translate('awc:/.error_messages.static_link_fetch_error');
    },
    uploadLogoActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.uploadLogoError =
        action.payload.error || translate('awc:/.error_messages.upload_logo_error');
      state.isStaticLinksLoading = false;
    },
    getAllStaticLinksActionRequest(state: any, action: any) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.allStaticLinks = [];
      state.staticLinksError = null;
    },
    getAllStaticLinksActionSuccess(state: any, action: IReducerAction<IStaticLink[]>) {
      state.isStaticLinksLoading = false;
      state.allStaticLinks = action.payload ?? [];
    },
    getAllStaticLinksActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isStaticLinksLoading = false;
      state.staticLinksError =
        action.payload.error || translate('awc:/.error_messages.static_link_fetch_error');
    },
  }
});

export const staticLinksReducer: Reducer<EntityState<IStaticLinksState> & IStaticLinksState, AnyAction> =
  staticLinksSlice.reducer;
export const getStaticLinksState: any = (rootState: any) => rootState[staticLinksFeatureKey];
export const staticLinksActions: any = staticLinksSlice.actions;

/**
* Interface for Static Links Actions.
*/
export interface IStaticLinksActionsProperties {
  baseApiUrl: string;
  onCreateErrorLog: (payload: CreateErrorLog) => void;
  getStaticLinksUrl: string; /**< getStaticLinks route. */
  getAllStaticLinksUrl: string; /**< getAllStaticLinks route. */
  uploadIconUrl: string; /**< uploadIconUrl route. */
  createStaticLinkUrl: string; /**< createStaticLinkUrl route. */
  generateUpdateStaticLinkUrlId: (staticLinkUUID: string) => string; /**< generates updateStaticUrl route with staticLInkUUID provided. */
  deleteStaticLinksUrl: string; /**< deleteStaticLinksUrl route. */
}

/**
* Static Links Actions class.
*/
export class StaticLinksActions {

  private staticLinksApi: StaticLinksApi;
  private getStaticLinksUrl: string;
  private getAllStaticLinksUrl: string;
  private uploadIconUrl: string;
  private createStaticLinkUrl: string;
  private generateUpdateStaticLinkUrlId: (staticLinkUUID: string) => string;
  private deleteStaticLinksUrl: string;


  constructor(properties: IStaticLinksActionsProperties) {
    this.staticLinksApi = new StaticLinksApi(properties.baseApiUrl, properties.onCreateErrorLog);
    this.getStaticLinksUrl = properties.getStaticLinksUrl;
    this.getAllStaticLinksUrl = properties.getAllStaticLinksUrl;
    this.uploadIconUrl = properties.uploadIconUrl;
    this.createStaticLinkUrl = properties.createStaticLinkUrl;
    this.generateUpdateStaticLinkUrlId = properties.generateUpdateStaticLinkUrlId;
    this.deleteStaticLinksUrl = properties.deleteStaticLinksUrl;
  }

/**
   * Get application static links Action with pagination.
   */
 getAllStaticLinksAction = createAsyncThunk(
  'settings/static/link/get/all',
  async (payload: void, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const getAllStaticLinksActionRequest: any = staticLinksActions.getAllStaticLinksActionRequest;
    const getAllStaticLinksActionSuccess: any = staticLinksActions.getAllStaticLinksActionSuccess;
    const getAllStaticLinksActionFailure: any = staticLinksActions.getAllStaticLinksActionFailure;
    try {
      dispatch(getAllStaticLinksActionRequest());
      const result: IAPIEntityResponse<IStaticLink> = await asyncErrorHandler(this.staticLinksApi.getAllStaticLinksAPI(
          this.getAllStaticLinksUrl,
      ));
      if (result.error) {
        if (result.status === 401) {
          dispatch(getAllStaticLinksActionFailure(result));
        } else {
          dispatch(getAllStaticLinksActionFailure(result.error));
        }
      } else {
        dispatch(getAllStaticLinksActionSuccess(result.data));
        return result.data;
      }
    } catch (exception: any) {
      dispatch(getAllStaticLinksActionFailure(exception.message));
    }
  }
);


  /**
   * Get application static links Action with pagination.
   */
  getStaticLinksAction = createAsyncThunk(
    'settings/static/link/get',
    async (payload: IPaginationRequest<IStaticLink>, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      const getStaticLinksActionRequest: any = staticLinksActions.getStaticLinksActionRequest;
      const getStaticLinksActionSuccess: any = staticLinksActions.getStaticLinksActionSuccess;
      const getStaticLinksActionFailure: any = staticLinksActions.getStaticLinksActionFailure;
      try {
        if (LocalStorage.getXAuthToken()) {
          const criteria: IPaginationRequest<IStaticLink> = payload;
          dispatch(getStaticLinksActionRequest(criteria || defaultCriteria));
          const result: PaginatedAPIEntityResponse<IStaticLink> = await asyncErrorHandler(this.staticLinksApi.getStaticLinksAPI(
              this.getStaticLinksUrl,
              criteria || defaultCriteria
          ));
          if (result.error) {
            if (result.status === 401) {
              dispatch(getStaticLinksActionFailure(result));
            } else {
              dispatch(getStaticLinksActionFailure(result.error));
            }
          } else {
            dispatch(getStaticLinksActionSuccess(result.data));
            return result.data;
          }
        }
      } catch (exception: any) {
        dispatch(getStaticLinksActionFailure(exception.message));
      }
    }
  );

  /**
   * create application static links Action.
   */
  createStaticLinkAction = createAsyncThunk(
    'settings/static/link/create',

    async (payload: any, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      const createStaticLinkActionRequest: any = staticLinksActions.createStaticLinkActionRequest;
      const createStaticLinkActionSuccess: any = staticLinksActions.createStaticLinkActionSuccess;
      const createStaticLinkActionFailure: any = staticLinksActions.createStaticLinkActionFailure;
      const uploadLogoActionFailure = staticLinksActions.uploadLogoActionFailure;
      try {
        dispatch(createStaticLinkActionRequest());

        const staticLinkData: any = payload;
        const uploadIcon: any = staticLinkData.uploadStaticLinkIcon;
        const deleteIcon: any = staticLinkData.deleteStaticLinkIcon;
        const staticLink: any = staticLinkData.staticLink;

        if (uploadIcon) {
          const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(this.staticLinksApi.uploadIconApi(
              this.uploadIconUrl,
              uploadIcon.file
          ));
          if (result.status === 200) {
            staticLink['icon'] = result.data.imageName;
          }
          if (result.error) {
            return dispatch(uploadLogoActionFailure(result.error));
          }
        }
        if (deleteIcon) {
          staticLink['icon'] = '';
        }

        if (staticLink) {
          const result: IAPIEntityResponse<IStaticLink> = await asyncErrorHandler(this.staticLinksApi.createOrUpdateStaticLinkAPI(this.createStaticLinkUrl, staticLink));
          if (result.error) {
            return dispatch(createStaticLinkActionFailure(result.error));
          }
        }
        dispatch(createStaticLinkActionSuccess());
        dispatch(this.getStaticLinksAction(staticLinkData.tablePayload));
        dispatch(this.getAllStaticLinksAction());
      } catch (exception: any) {
        dispatch(createStaticLinkActionFailure(exception.message));
      }
    }
  );

  /**
   * Update application static links Action.
   */
  updateStaticLinkAction = createAsyncThunk(
    'settings/static/link/update',

    async (payload: any, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      const updateStaticLinkActionRequest: any = staticLinksActions.updateStaticLinkActionRequest;
      const updateStaticLinkActionSuccess: any = staticLinksActions.updateStaticLinkActionSuccess;
      const updateStaticLinkActionFailure: any = staticLinksActions.updateStaticLinkActionFailure;
      const uploadLogoActionFailure = staticLinksActions.uploadLogoActionFailure;
      try {
        dispatch(updateStaticLinkActionRequest());

        const staticLinkData: any = payload.data;
        const staticLinkUUID: any = payload.staticLinkUUID;
        const uploadIcon: any = staticLinkData.uploadStaticLinkIcon;
        const deleteIcon: any = staticLinkData.deleteStaticLinkIcon;
        const staticLink: any = staticLinkData.staticLink;
        let imageUrl;
        if (uploadIcon) {
          const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(this.staticLinksApi.uploadIconApi(
              this.uploadIconUrl,
              uploadIcon.file
          ));
          if (result.status === 200) {
            staticLink['icon'] = result.data.imageName;
            imageUrl = result.data.imageUrl;
          }
          if (result.error) {
            return dispatch(uploadLogoActionFailure(result.error));
          }
        }
        if (deleteIcon) {
          staticLink['icon'] = '';
        }

        if (staticLink) {
          const result: IAPIEntityResponse<IStaticLink> = await asyncErrorHandler(this.staticLinksApi.createOrUpdateStaticLinkAPI(
              this.generateUpdateStaticLinkUrlId(staticLinkUUID),
              staticLink,
          ));
          if (result.error) {
            return dispatch(updateStaticLinkActionFailure(result.error));
          }
        }

        dispatch(updateStaticLinkActionSuccess());
        dispatch(this.getStaticLinksAction(staticLinkData.tablePayload));
        dispatch(this.getAllStaticLinksAction());
        if (imageUrl) {
          fetch(imageUrl);
          setTimeout(() => {
            dispatch(this.getStaticLinksAction(staticLinkData.tablePayload));
          }, 5000);
        }
      } catch (exception: any) {
        dispatch(updateStaticLinkActionFailure(exception.message));
      }
    }
  );

  deleteStaticLinkAction = createAsyncThunk(
    'application/delete',
    async (payload: any, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      const deleteStaticLinksActionRequest: any = staticLinksActions.deleteStaticLinksActionRequest;
      const deleteStaticLinksActionSuccess: any = staticLinksActions.deleteStaticLinksActionSuccess;
      const deleteStaticLinksActionFailure: any = staticLinksActions.deleteStaticLinksActionFailure;
      try {
        dispatch(deleteStaticLinksActionRequest());
        const result: IAPIEntityResponse<IDeleteStaticLinksFrontendResponse> =
            await asyncErrorHandler(this.staticLinksApi.deleteStaticLinksAPI(this.deleteStaticLinksUrl, payload.staticLinksUUIDs));
        if (result.error) {
          dispatch(deleteStaticLinksActionFailure(result.error));
        } else {
          dispatch(deleteStaticLinksActionSuccess());
          dispatch(this.getStaticLinksAction(payload.tablePayload));
          dispatch(this.getAllStaticLinksAction());
        }
      } catch (exception: any) {
        dispatch(deleteStaticLinksActionFailure(exception.message));
      }
    }
  );
}