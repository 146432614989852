/**
* AuthSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file AuthSlice.ts
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import {
  activateApi,
  completeRegistrationAPI,
  isVerificationLinkValid,
  loginApi,
  loginWithTokenAPI,
  registerUserAPI,
  sendVerificationAPI
} from '../Services/AuthApi';
import { Reducer } from 'react';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IRegisterFormData, IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { ISendVerificationResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { translate } from '../Utils/Translate';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/user/auth';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const authFeatureKey: string = 'auth';

/**
 * Interface authInitialState
 */
export interface IAuthState {
  loginError: any;
  logoutError: any;
  isAuthenticated: boolean;
  user: any;
  applications: any;
  role: any;
  accessToken: any;
  isLoading: boolean;
  isTimeout: boolean;
  passwordResetError: any;
  passwordResetSuccess: any;
  activateUserError: any;
  isUserActivated: boolean;
  isEmailUpdated: boolean;
  isAdmin: boolean;
  verifyOnDate: Date /**< Date a user can send verification. */;
  hasError: string | null /**< Error string. */;
  registerUserError: string | null /**< Error string. */;
  redirectURL: string /**< Redirect URL. */;
  lockedOutError: string /** Error locked out string. */;
  isCompleteRegistrationFinished: boolean /** Manage if the user finished the registration inserting a password. */;
  isVerificationLinkValid: boolean | null /** If the user verification link is valid. */;
  isUserAlreadyVerified: boolean | null /** If the user is already verified. */;
  isCheckingVerificationLinkValidity:
    | boolean
    | null /** If is requesting to check the validity of the verification link. */;
}

export const authInitialState: IAuthState = {
  loginError: null,
  logoutError: null,
  isAuthenticated: false,
  user: {},
  applications: null,
  role: null,
  accessToken: null,
  isLoading: false,
  isTimeout: false,
  passwordResetError: null,
  passwordResetSuccess: null,
  activateUserError: null,
  isUserActivated: false,
  isEmailUpdated: false,
  isAdmin: false,
  verifyOnDate: null,
  hasError: null,
  redirectURL: null,
  lockedOutError: null,
  registerUserError: null,
  isCompleteRegistrationFinished: false,
  isVerificationLinkValid: null,
  isUserAlreadyVerified: null,
  isCheckingVerificationLinkValidity: false
};

const clearErrors = (state: IAuthState) => {
  state.isLoading = false;
};

export const authAdapter: EntityAdapter<IAuthState> = createEntityAdapter();
export const initialAuthState: EntityState<IAuthState> & IAuthState =
  authAdapter.getInitialState(authInitialState);

/**
 * Login Action.
 * @param payload IAuth type
 */
export const loginAction = createAsyncThunk('auth/login', async (payload: IAuth) => {
  payload.themeMode = LocalStorage.getThemeMode();
  payload.languageSettingsMode = LocalStorage.getLanguageSettingsMode();
  const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(loginApi(payload));
  if (response && response.status === 401) {
    return { status: 401, error: response.error };
  }
  if (response && response.status === 500) {
    return { status: 500, error: response.error };
  }
  if (response && response.data) {
    const isAdmin = response.data.isAdmin;
    return { status: 200, data: response.data, isAdmin };
  }
  return response;
});

/**
 * Update access token.
 * @param payload
 */
export const updateAccessTokenAction = createAsyncThunk(
  'updateAccessToken',
  async (token: string) => {
    return token;
  }
);

/**
 * Logout Action.
 * @param payload
 */
export const logoutAction = createAsyncThunk('auth/logout', async () => {
  return true;
});

/**
 * manualUpdateUser Action
 * @param payload
 */
export const manualUpdateUser = createAsyncThunk('user/updateuser', async (payload: any) => {
  return payload;
});

export const activateUserAction = createAsyncThunk('user/activate', async (payload: any) => {
  return activateApi(payload);
});

export const isVerificationLinkValidAction = createAsyncThunk(
  'user/is/verification/link/valid',
  async (payload: any) => {
    return isVerificationLinkValid(
      payload.userUUID,
      payload?.token,
      payload.isCompleteRegistration
    );
  }
);

export const manualUpdateAuthAction = createAsyncThunk('user/auth/update', async (payload: any) => {
  return payload;
});

/**
 * Register new user.
 */
export const registerUserAction = createAsyncThunk(
  'register/user',
  async (payload: IRegisterFormData) => {
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(registerUserAPI(payload));
    return response;
  }
);

/**
 * Send verification email.
 */
export const sendVerificationAction = createAsyncThunk(
  'verification/sendmail',
  async (payload: IRegisterFormData) => {
    const response: IAPIEntityResponse<ISendVerificationResponse> = await asyncErrorHandler(
      sendVerificationAPI(payload)
    );
    return response;
  }
);

/**
 * Completer User registration.
 */
export const completeRegistrationAction = createAsyncThunk(
  'register/complete',
  async (payload: IRegisterFormData) => {
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(
      completeRegistrationAPI(payload)
    );
    return response;
  }
);

/**
 * Login with token.
 */
export const loginUserWithTokenAction = createAsyncThunk(
  'auth/login/token',
  async (payload: string) => {
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(loginWithTokenAPI(payload));
    if (response && response.status === 401) {
      return { status: 401, error: response.error };
    }
    if (response && response.status === 500) {
      return { status: 500, error: response.error };
    }
    if (response && response.data) {
      const isAdmin = response.data.isAdmin;
      return { status: 200, data: response.data, isAdmin };
    }
    return response;
  }
);

/**
 * Reset Auth State
 */
export const resetAuthStateAction = createAsyncThunk('auth/reset', async (payload: any) => {
  return payload;
});

/**
 * Creates Slice - All Auth related state will be stored here
 */
export const authSlice = createSlice({
  name: authFeatureKey,
  initialState: initialAuthState,
  reducers: {
    reset: (state: IAuthState) => clearErrors(state)
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state: IAuthState) => {
        state.isLoading = true;
        state.loginError = null;
        state.logoutError = null;
        state.isAdmin = false;
        state.user = {};
        state.lockedOutError = null;
      })
      .addCase(
        loginAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          if (
            action &&
            action.payload &&
            action.payload.data &&
            action.payload.data.isVerified &&
            action.payload.status === 200
          ) {
            const isAdmin: boolean = action.payload.isAdmin || false;
            state.isAuthenticated = true;
            state.loginError = null;
            state.logoutError = null;
            state.user = {
              email: action.payload.data.email,
              username: action.payload.data.username,
              firstName: action.payload.data.firstName,
              lastName: action.payload.data.lastName,
              id: action.payload.data.userUUID,
              isActive: action.payload.data.isActive,
              about: action.payload.data.about,
              isVerified: action.payload.data.isVerified
            };
            state.role = action.payload.data.role;
            state.applications = action.payload.data.applications;
            state.accessToken = action.payload.data.accessToken;
            state.accessToken = action.payload.data.accessToken;
            LocalStorage.setXAuthToken(`${action.payload.data.accessToken}`);
            LocalStorage.setXUserUUID(`${action.payload.data.userUUID}`);
            LocalStorage.setAdmin(JSON.stringify(isAdmin)); // Set isAdmin flag
            // set the initialLoginTime
            sessionStorage.setItem('initialLoginTime', `${new Date()}`);
            state.isAdmin = isAdmin;
          } else {
            state.isAuthenticated = false;
            state.isLoading = false;
            if (action && action.payload) {
              if (action.payload.data && !action.payload.data.isVerified) {
                state.user = {
                  email: action.payload.data.email,
                  isVerified: action.payload.data.isVerified,
                  username: action.payload.data.username,
                  userUUID: action.payload.data.userUUID
                };
                state.loginError = translate('I18N.register.user_not_verified');
              } else {
                state.loginError = action.payload.error || '';
              }
              state.lockedOutError = action.payload?.message;
            }
          }
        }
      )
      .addCase(loginAction.rejected, (state: IAuthState, action: IReducerAction<IAPIErrorData>) => {
        state.isAuthenticated = false;
        if (action && action.payload) {
          state.loginError = action.payload.error || '';
        }
        state.isLoading = false;
        state.isAdmin = false;
      })
      .addCase(logoutAction.fulfilled, (state: IAuthState) => {
        state.user = {};
        state.role = null;
        state.applications = null;
        state.accessToken = null;
        LocalStorage.removeSavedState();
        LocalStorage.removeXAuthToken();
        LocalStorage.removeXUserUUID();
        LocalStorage.removeIsAdmin();
        state.isAuthenticated = false;
        state.isLoading = false;
      })
      .addCase(manualUpdateUser.pending, (state: IAuthState) => {
        state.isLoading = true;
      })
      .addCase(manualUpdateUser.fulfilled, (state: IAuthState, action: IReducerAction<IUser>) => {
        state.isLoading = false;

        // update updated fields only!
        const usersFields: any[] = Object.keys(action.payload);
        usersFields.forEach((eachField) => {
          state.user[eachField] = action.payload[eachField];
        });
      })
      .addCase(activateUserAction.pending, (state: IAuthState) => {
        state.isLoading = true;
        state.isUserActivated = false;
        state.activateUserError = null;
      })
      .addCase(
        activateUserAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isLoading = false;
          if (action && action.payload && action.payload.data && action.payload.status === 200) {
            const isAdmin: boolean = action.payload.data.isAdmin || false;
            state.isUserActivated = true;
            state.activateUserError = null;
            state.accessToken =
              action.payload.data.accessToken; /**< Update the new user accesstoken. */
            state.isAuthenticated = true;
            state.loginError = null;
            state.logoutError = null;
            state.user = {
              email: action.payload.data.email,
              username: action.payload.data.username,
              firstName: action.payload.data.firstName,
              lastName: action.payload.data.lastName,
              id: action.payload.data.userUUID,
              isActive: action.payload.data.isActive,
              about: action.payload.data.about
            };
            state.role = action.payload.data.role;
            state.applications = action.payload.data.applications;
            LocalStorage.setXAuthToken(`${action.payload.data.accessToken}`);
            LocalStorage.setXUserUUID(`${action.payload.data.userUUID}`);
            LocalStorage.setAdmin(JSON.stringify(isAdmin)); // Set isAdmin flag
            // set the initialLoginTime
            sessionStorage.setItem('initialLoginTime', `${new Date()}`);
            state.isAdmin = isAdmin;
          } else {
            state.isUserActivated = false;
            state.activateUserError = (action && action.payload && action.payload.error) || '';
          }
        }
      )
      .addCase(
        activateUserAction.rejected,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isLoading = false;
          state.isUserActivated = false;
          state.activateUserError = action && action.error && action.error.message;
        }
      )
      .addCase(isVerificationLinkValidAction.pending, (state: IAuthState) => {
        state.isVerificationLinkValid = null;
        state.isUserAlreadyVerified = null;
        state.isCheckingVerificationLinkValidity = true;
      })
      .addCase(
        isVerificationLinkValidAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<any>>) => {
          state.isCheckingVerificationLinkValidity = false;

          if (action && action.payload && action.payload.data && action.payload.status === 200) {
            state.isVerificationLinkValid = action.payload.data.isVerificationLinkValid;
            state.user = action.payload.data.user;
          }

          if (action && action.payload && action.payload.error && action.payload.status === 400) {
            if (
              action.payload.error.message ===
              'This verification link has expired. A new verification link was sent to your email address.'
            ) {
              state.activateUserError = action.payload.error.message;
              state.isVerificationLinkValid = false;
            }
            if (action.payload.error.message === 'User already verified.') {
              state.isUserAlreadyVerified = true;
              state.redirectURL = action.payload.error.data.redirectApplicationURL;
            }
          }
        }
      )
      .addCase(
        manualUpdateAuthAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IUser>) => {
          state.isAdmin = action.payload.isAdmin;
        }
      )
      .addCase(sendVerificationAction.pending, (state: IAuthState) => {
        state.isLoading = true;
        state.verifyOnDate = null;
        state.registerUserError = null;
        state.loginError = null;
      })
      .addCase(
        sendVerificationAction.fulfilled,
        (
          state: IAuthState,
          action: IReducerAction<IAPIEntityResponse<ISendVerificationResponse>>
        ) => {
          if (action.payload?.status === 200) {
            state.verifyOnDate = action.payload.verifyOnDate;
          } else {
            state.registerUserError =
              (action.payload.error as string) || translate('I18N.error_messages.send_mail_fail');
          }
          state.isLoading = false;
        }
      )
      .addCase(
        sendVerificationAction.rejected,
        (
          state: IAuthState,
          action: IReducerAction<IAPIEntityResponse<ISendVerificationResponse>>
        ) => {
          state.verifyOnDate = action.payload.verifyOnDate;
          state.isLoading = false;
          state.registerUserError =
            action?.error?.message || translate('I18N.error_messages.send_mail_fail');
        }
      )
      .addCase(registerUserAction.pending, (state: IAuthState) => {
        state.isLoading = true;
        state.registerUserError = null;
        state.redirectURL = null;
      })
      .addCase(
        registerUserAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isLoading = false;
          if (action?.payload?.status === 200) {
            state.redirectURL = action.payload.redirectURL;
          } else {
            state.redirectURL = null;
            state.registerUserError =
              (action?.payload?.error.message as string) ??
              (action?.payload.error as string) ??
              translate('I18N.error_messages.create_user_error');
          }
        }
      )
      .addCase(
        registerUserAction.rejected,
        (state: IAuthState, action: IReducerAction<IAPIErrorData>) => {
          state.redirectURL = null;
          state.isLoading = false;
          state.registerUserError =
            action?.error?.message || translate('I18N.error_messages.create_user_error');
        }
      )
      .addCase(completeRegistrationAction.pending, (state: IAuthState) => {
        state.isLoading = true;
        state.hasError = null;
        state.redirectURL = null;
      })
      .addCase(
        completeRegistrationAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          if (action && action.payload && action.payload.status === 200) {
            const isAdmin: boolean = action.payload.data.isAdmin || false;
            state.isCompleteRegistrationFinished = true;
            state.isAuthenticated = true;
            state.loginError = null;
            state.logoutError = null;
            state.user = {
              email: action.payload.data.email,
              username: action.payload.data.username,
              firstName: action.payload.data.firstName,
              lastName: action.payload.data.lastName,
              id: action.payload.data.userUUID,
              isActive: action.payload.data.isActive,
              about: action.payload.data.about
            };
            state.role = action.payload.data.role;
            state.applications = action.payload.data.applications;
            state.redirectURL = action.payload.data.redirectApplicationURL;
            state.accessToken = action.payload.data.accessToken;
            LocalStorage.setXAuthToken(`${action.payload.data.accessToken}`);
            LocalStorage.setXUserUUID(`${action.payload.data.userUUID}`);
            LocalStorage.setAdmin(JSON.stringify(isAdmin)); // Set isAdmin flag
            // set the initialLoginTime
            sessionStorage.setItem('initialLoginTime', `${new Date()}`);
            state.isAdmin = isAdmin;
          } else {
            state.isAuthenticated = false;
            if (action && action.payload) {
              state.loginError = action.payload.error || '';
            }
            state.redirectURL = null;
          }
          state.isLoading = false;
        }
      )
      .addCase(
        completeRegistrationAction.rejected,
        (state: IAuthState, action: IReducerAction<IAPIErrorData>) => {
          state.isAuthenticated = false;
          state.isLoading = false;
          state.hasError =
            action?.error?.message || translate('I18N.error_messages.create_user_error');
          state.redirectURL = null;
        }
      )
      .addCase(loginUserWithTokenAction.pending, (state: IAuthState) => {
        state.isLoading = true;
        state.loginError = null;
        state.logoutError = null;
        state.isAdmin = false;
        state.user = {};
        state.lockedOutError = null;
      })
      .addCase(
        loginUserWithTokenAction.fulfilled,
        (state: IAuthState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isLoading = false;
          if (
            action &&
            action.payload &&
            action.payload.data &&
            action.payload.data.isVerified &&
            action.payload.status === 200
          ) {
            const isAdmin: boolean = action.payload.data.isAdmin || false;
            state.isAuthenticated = true;
            state.loginError = null;
            state.logoutError = null;
            state.user = {
              email: action.payload.data.email,
              username: action.payload.data.username,
              firstName: action.payload.data.firstName,
              lastName: action.payload.data.lastName,
              id: action.payload.data.userUUID,
              isActive: action.payload.data.isActive,
              about: action.payload.data.about,
              isVerified: action.payload.data.isVerified
            };
            state.role = action.payload.data.role;
            state.applications = action.payload.data.applications;
            state.accessToken = action.payload.data.accessToken;
            LocalStorage.setXAuthToken(`${action.payload.data.accessToken}`);
            LocalStorage.setXUserUUID(`${action.payload.data.userUUID}`);
            LocalStorage.setAdmin(JSON.stringify(isAdmin)); // Set isAdmin flag
            // set the initialLoginTime
            sessionStorage.setItem('initialLoginTime', `${new Date()}`);
            state.isAdmin = isAdmin;
          } else {
            state.isAuthenticated = false;
            state.isLoading = false;
            if (action && action.payload) {
              if (action.payload.data && !action.payload.data.isVerified) {
                state.user = {
                  email: action.payload.data.email,
                  isVerified: action.payload.data.isVerified,
                  username: action.payload.data.username,
                  userUUID: action.payload.data.userUUID
                };
                state.loginError = translate('I18N.register.user_not_verified');
              } else {
                state.loginError = action.payload.error || '';
              }
              state.lockedOutError = action.payload?.message;
            }
          }
        }
      )
      .addCase(
        loginUserWithTokenAction.rejected,
        (state: IAuthState, action: IReducerAction<IAPIErrorData>) => {
          state.isAuthenticated = false;
          if (action && action.payload) {
            state.loginError = action.payload.error || '';
          }
          state.isLoading = false;
          state.isAdmin = false;
        }
      )
      .addCase(resetAuthStateAction.fulfilled, (state: IAuthState) => {
        state.registerUserError = null;
        state.loginError = null;
        state.user = {};
        state.verifyOnDate = null;
        state.hasError = null;
      })
      .addCase(
        updateAccessTokenAction.fulfilled,
        (state: IAuthState, action: IReducerAction<any>) => {
          state.accessToken = action.payload;
        }
      );
  }
});

export const authReducer: Reducer<EntityState<IAuthState> & IAuthState, AnyAction> =
  authSlice.reducer;

export const authActions: any = authSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = authAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getAuthState: any = (rootState: any) => rootState[authFeatureKey];
export const selectAllAuth: any = createSelector(getAuthState, selectAll);
export const selectAuthEntities: any = createSelector(getAuthState, selectEntities);
