/**
* UserApi.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file UserApi.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import {
  IAPIEntityResponse,
  IApiBackEndGeneralResponse,
  IMessageResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IUser,
  IUserList,
  IUserProfile
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import {
  IGetUserByIDResponse,
  IDeleteUserResponse,
  IDeleteUsersResponse,
  IImageUploadResponse,
  IRemoveUserProfilePictureResponse,
  IValidateVerificationCodeResponse
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';
import { IForgotPasswordTokenValidationResponse } from '../Components/ForgotPassword/ForgotPasswordPage';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Fetch all users with pagination
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const getUsers = (
  payload: Record<string, any>
): Promise<PaginatedAPIEntityResponse<IUser>> => {
  const url: string = paginationRequest(`/users/all`, payload);
  return httpClient.get<IUser>(
    `${url}&searchTerm=${encodeURIComponent(payload.searchTerm ? payload.searchTerm : '')}`
  );
};
/**
 * Fetches a user
 * @param userUUID userUUID of the user
 * @returns Promise
 */
export const getUserByUUID = (
  userUUID: string
): Promise<IAPIEntityResponse<IGetUserByIDResponse>> => {
  return httpClient.get<IGetUserByIDResponse>(`/user/${userUUID}`);
};
/**
 * Deletes user(s)
 * @param userUUIDs
 * @returns Promise
 */
export const deleteUsersFromServer = (
  userUUIDs: string[]
): Promise<IAPIEntityResponse<IDeleteUsersResponse>> => {
  const payload: any = {
    userUUIDs
  };
  return httpClient.post<IDeleteUsersResponse>(`/users/delete`, payload);
};
/**
 * Deletes a user
 * @param userUUID userUUID of the user
 * @returns Promise
 */
export const deleteUserFromServer = (
  userUUID: string
): Promise<IAPIEntityResponse<IDeleteUserResponse>> => {
  return httpClient.get<IDeleteUserResponse>(`/user/${userUUID}/delete`);
};
/**
 * Creates a user
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const createUserApi = (payload: Record<string, any>): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>(`/user/create`, payload);
};
/**
 * Updates a user
 * @param payload Payload to send with the request
 * @param userUUID userUUID of the user
 * @returns Promise
 */
export const updateUserApi = (
  payload: Record<string, any>,
  userUUID: string
): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>(`/user/${userUUID}/update`, payload);
};
/**
 * Removes user's profile picture
 * @param userUUID userUUID of the user
 * @returns Promise
 */
export const removeProfilePicApi = (
  userUUID: string
): Promise<IAPIEntityResponse<IRemoveUserProfilePictureResponse>> => {
  return httpClient.get<IRemoveUserProfilePictureResponse>(`/profile/${userUUID}/removePicture`);
};
/**
 * Resets user's password
 * @param username
 * @returns Promise
 */
export const resetPasswordApi = (
  username: string
): Promise<IAPIEntityResponse<IMessageResponse>> => {
  return httpClient.post<IMessageResponse>(`/auth/password/reset/initiate`, { username });
};
/**
 * Toggles user's status
 * @param userUUID userUUID of the user
 * @param data
 * @returns Promise
 */
export const toggleStatusApi = (
  userUUID: string,
  data: Record<string, any>
): Promise<IAPIEntityResponse<IMessageResponse>> => {
  return httpClient.post<IMessageResponse>(`/user/${userUUID}/setStatus`, data);
};
/**
 * Uploads profile picture
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const uploadProfilePicApi = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const fd = new FormData();
  const file: Blob = payload.file; /**< The Image file. */
  fd.append('file', file, file['name']);
  fd.append('mode', 'profile');
  fd.append('userUUID', payload.userUUID); //Append userUUID for upload path.
  return httpClient.imageUpload<IImageUploadResponse>(`/system/image/upload`, fd);
};

/**
 * Verify user.
 * @param userUUID User uuid.
 * @returns Promise
 */
export const verifyUserAPI = (userUUID: string): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.post<IUser>(`/user/${userUUID}/verify`, {}, null);
};

/**
 * Fetches total users count
 * @returns Promise<IUserList>
 */
export const getTotalUsersCount = (): Promise<IAPIEntityResponse<IUserList>> => {
  return httpClient.get<IUserList>(`/users/get/count`);
};

/**
 * Send verification code to email.
 * @param email user email.
 * @returns Promise
 */
export const sendVerificationCode = (
  email: string
): Promise<IAPIEntityResponse<IApiBackEndGeneralResponse>> => {
  return httpClient.post<IApiBackEndGeneralResponse>(
    `/auth/password/reset/sendmail?email=${email}`,
    {},
    null,
    false
  );
};

/**
 * Verify code.
 * @param payload.
 * @returns Promise
 */
export const verifyCode = (
  payload: any
): Promise<IAPIEntityResponse<IValidateVerificationCodeResponse>> => {
  return httpClient.post<IValidateVerificationCodeResponse>(
    `/auth/password/reset/validatecode`,
    payload,
    {},
    false
  );
};

/**
 * Password reset
 * @param payload.
 * @returns Promise
 */
export const passwordReset = (payload: any): Promise<IAPIEntityResponse<{ message: string }>> => {
  return httpClient.post<{ message: string }>(
    `/auth/password/reset/complete`,
    {
      token: payload.token,
      username: payload.username,
      password: payload.password,
      verificationCode: payload.verificationCode
    },
    {},
    false
  );
};

/**
 * Update user theme preference
 * @param userUUID User UUID to update theme mode
 * @param themeMode Current application theme mode saved by User
 * @returns Promise<IAPIEntityResponse<IUser>>
 */
export const setUserThemeModeAPI = (
  userUUID: string,
  themeMode: string
): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.put(`/user/update/themeMode`, {
    userUUID,
    themeMode
  });
};

/**
 * Update user language preference
 * @param userUUID User UUID to update language settings mode
 * @param languageSettingsMode Current application language settings mode saved by User
 * @returns Promise<IAPIEntityResponse<IUser>>
 */
export const setUserLanguageSettingsModeAPI = (
  userUUID: string,
  languageSettingsMode: string
): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.put(`/user/update/languageSettingsMode`, {
    userUUID,
    languageSettingsMode
  });
};

/**
 * Fetches user profile information
 * @param userUUID userUUID of the user
 * @returns Promise<IAPIEntityResponse<IUserProfile>>
 */
export const getUserProfileInformation = (
  userUUID: string
): Promise<IAPIEntityResponse<IUserProfile>> => {
  return httpClient.get<IUserProfile>(`/user/${userUUID}/profile`);
};

/**
 * Defines the interface for the function validateForgotPasswordToken.
 */
export interface IValidateForgotPasswordToken {
  token: string /* Defines the JWT token */;
  username: string /* Defines the user name */;
}

/**
 * Validate forgot password token.
 * @param payload
 * @returns Promise<IAPIEntityResponse<Record<string, boolean>>>
 */
export const validateForgotPasswordToken = (
  payload: IValidateForgotPasswordToken
): Promise<IAPIEntityResponse<IForgotPasswordTokenValidationResponse>> => {
  return httpClient.post<IForgotPasswordTokenValidationResponse>(
    `/auth/password/reset/validateToken`,
    payload,
    null,
    false
  );
};
