/**
* AuthenticationVerificationPage.tsx (abstractuser) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2021
* @file AuthenticationVerificationPage.tsx
* @author Timothy Fadayini
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
* @modified Rafael Rodrigues - 2022
*/

import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { getAuthState, IAuthState, logoutAction } from '../../Store/AuthSlice';
import StateLoader from '../../Store/StateLoader';
import { getApplicationByUUID } from '../../Services/ApplicationApi';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import {
  getUserApplicationsState,
  IUserApplicationsState
} from '../../Store/UserApplicationsSlice';
import { validateUserPermission } from '../../Services/UserApplicationsAPI';
import { createLogApi } from '../../Services/LogApi';
import {
  ILogoutAllApplications,
  logoutAllApplications
} from '@abstract/abstractwebcommon-client/utils/LogoutAllApplications';
import { changeUserTheme } from '@abstract/abstractwebcommon-client/utils/themeModeUtils';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from '../../Utils/routesNames';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { changeUserLanguage } from '@abstract/abstractwebcommon-client/utils/LanguageSettingsModeUtils';
import i18n from '../../Services/I18n';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const AuthenticationVerificationPage = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();

  const authState: IAuthState = useSelector(getAuthState);
  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search);
  const applicationUUID: string = parsedString['app'];
  const isLogout: boolean = parsedString['logout'];
  const redirectURL: string = parsedString['redirect_url'];
  const autoLogoutReason: string = parsedString['autoLogoutReason'];
  const isLogoutAll: boolean = parsedString['logoutAll']; /**< isLogoutAll */
  const userApplications: IApplications[] = parsedString['userApplications']
    ? JSON.parse(parsedString['userApplications'])
    : []; /**< UserApplications */
  const userApplicationsState: IUserApplicationsState = useSelector(getUserApplicationsState);
  const themeMode: string | null = parsedString['themeMode'] ?? ThemeMode.lightMode;
  const languageSettingsMode: string | null =
    parsedString['languageSettingsMode'] ??
    LanguageSettingsMode.english; /**< Language settings mode */
  const returnURL: string =
    parsedString[
      'returnURL'
    ]; /**< Defines the URL to redirect through the back button when visible in the Login page. */

  const triggerLogout = (application: any) => {
    dispatch(logoutAction());

    changeUserTheme(themeMode);
    LocalStorage.setThemeMode(themeMode);
    //Fire a storage event to update theme mode
    window.dispatchEvent(new Event('storage'));

    // Change user preference language
    changeUserLanguage(languageSettingsMode, i18n);
    LocalStorage.setLanguageSettingsMode(languageSettingsMode); /**< To set localstorage key */
    //Fire a storage event to update language settings mode
    window.dispatchEvent(new Event('storage'));
    const parameters: URLSearchParams = new URLSearchParams({
      verification_url: application['verificationURL'],
      app: applicationUUID
    }); /**< Query parameters */

    if (!isStringEmptyOrNullOrUndefined(returnURL)) {
      parameters.append('returnURL', returnURL);
    }

    if (!application) {
      window.location.href = `${SharedCommomRouteName.loginRoute}#access-denied`;
    }
    if (redirectURL && redirectURL !== undefined && redirectURL !== 'undefined') {
      const parametersWithRedirectURL: URLSearchParams = new URLSearchParams({
        ...Object.fromEntries(parameters),
        redirect_url: redirectURL
      });
      window.location.href = `${
        SharedCommomRouteName.loginRoute
      }?${parametersWithRedirectURL.toString()}`;
    } else {
      window.location.href = `${SharedCommomRouteName.loginRoute}?${parameters.toString()}`;
    }
  };

  /// Logout root Application.
  const logOutRootApplication = () => {
    dispatch(logoutAction());
    history.push({ pathname: SharedCommomRouteName.loginRoute });
  };

  /// Logout all applications.
  const logoutAll = async () => {
    if (userApplications.length) {
      localStorage.setItem('userApplications', JSON.stringify(userApplications));
    }
    const applications: IApplications[] = JSON.parse(
      localStorage.getItem('userApplications')
    ); /**< User Applications. */

    const payload: ILogoutAllApplications = {
      applications: applications,
      loggedOutApplicationUUID: applicationUUID,
      logoutAction: logOutRootApplication
    };
    await asyncErrorHandler(
      logoutAllApplications(payload)
    ); /**< To logout all applciations (License & Ecommerce) */
  };

  const getApplicationByUUIDAction = async (uuid: string): Promise<IApplications> => {
    const applicationResult: any = await asyncErrorHandler(getApplicationByUUID(uuid));
    return applicationResult.data;
  };

  ///bypass IOS Back Caching Issue
  useEffect(() => {
    const bypassIOSBackCacheIssue = () => {
      window.onpageshow = (event: any) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    };

    bypassIOSBackCacheIssue();
  }, []);

  /// get the safe app details
  useEffect(() => {
    const validateToken = async () => {
      const application: IApplications = await asyncErrorHandler(
        getApplicationByUUIDAction(applicationUUID)
      );

      //NOTE: get user permission to redirect to login and then to permission page or directly to dashboard page
      let userHasGrantedPermission = false;
      if (application && authState.accessToken) {
        const userApplication = await asyncErrorHandler(
          validateUserPermission({
            token: authState.accessToken,
            applicationUUID: application.applicationUUID
          })
        );
        userHasGrantedPermission = userApplication.data.isUserPermissionGranted;
      }

      //NOTE: if user don't have permission to the application e already is logged in, he'll be sent directly to the permission page
      //NOTE: If user is accessing the root application, he won't be hit by this condition
      if (!userHasGrantedPermission && authState.isAuthenticated) {
        const isTokenValid: boolean = StateLoader.isTokenValid(authState.accessToken);

        if (isTokenValid) {
          const parameters: URLSearchParams = new URLSearchParams({
            verification_url: application['verificationURL'],
            app: applicationUUID
          }); /**< Query parameters */

          history.push({
            pathname: `${RouteName.validatePermissionRoute}${
              applicationUUID ? `?${parameters.toString()}` : ''
            }`,
            state: {
              logoURL: application?.logoImageURL,
              description: application?.description,
              applicationName: application?.applicationName,
              accessToken: authState.accessToken,
              applicationUUID,
              verificationURL: application['verificationURL'],
              redirectURL
            }
          });
        }

        return;
      }

      if (userHasGrantedPermission && authState.isAuthenticated && application) {
        const isTokenValid: boolean = StateLoader.isTokenValid(authState.accessToken);
        if (isTokenValid) {
          //  on valid token, check if autoLogoutReason is sent from license (error in license), prevent infinite loops
          if (autoLogoutReason === '403') {
            triggerLogout(application);
          } else {
            const parameters: URLSearchParams = new URLSearchParams({
              token: authState.accessToken,
              themeMode: LocalStorage.getThemeMode(),
              languageSettingsMode: LocalStorage.getLanguageSettingsMode()
            }); /**< Query parameters */
            if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
              parameters.append('redirect_url', redirectURL);
            }
            window.location.href = `${application['verificationURL']}?${parameters.toString()}`;
          }
        } else {
          const parameters: URLSearchParams = new URLSearchParams({
            verification_url: application['verificationURL'],
            app: applicationUUID
          }); /**< Query parameters */

          if (!isStringEmptyOrNullOrUndefined(returnURL)) {
            parameters.append('returnURL', returnURL);
          }

          window.location.href = `${SharedCommomRouteName.loginRoute}?${parameters.toString()}`;
        }
      } else {
        triggerLogout(application);
      }
    };

    const logoutUser = async () => {
      if (applicationUUID !== undefined || applicationUUID !== null || applicationUUID !== '') {
        const application: IApplications = await asyncErrorHandler(
          getApplicationByUUIDAction(applicationUUID)
        );
        triggerLogout(application);
        return;
      }
      triggerLogout(null);
    };

    if (isLogoutAll) {
      logoutAll(); /**< Logout all applications. */
    } else {
      isLogout ? logoutUser() : validateToken();
    }
  }, [
    applicationUUID,
    userApplicationsState.applicationPermission?.isUserPermissionGranted,
    authState.accessToken
  ]);

  return <div className="row text-dark login-pages-global-container" />;
};

export default withErrorBoundary(AuthenticationVerificationPage, createLogApi);
