/*
 * UserTokenApi.tsx (InstaLOD GmbH)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file UserTokenApi.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { BASE_API_URL } from '../Config';
import { createLogApi } from './LogApi';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IUserTokenTransaction } from '@abstract/abstractwebcommon-shared/interfaces/user/UserTokenTransaction';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * @class UserTokenAPI
 */
export class UserTokenAPI {
  /**
   * Service to create user token
   * @param userToken userToken data
   * @returns Promise<IAPIEntityResponse<IUserTokenTransaction>>
   */
  static create = async (
    userToken: Partial<IUserTokenTransaction>
  ): Promise<IAPIEntityResponse<IUserTokenTransaction>> => {
    const url: string = `/userToken/create`;

    return httpClient.post<IUserTokenTransaction>(`${url}`, userToken);
  };

  /**
   * Service to get all user tokens
   * @param payload Pagination payload
   * @returns Promise<IAPIEntityResponse<IUserTokenTransaction[]>>
   */
  static getAllUserToken = async (
    payload: ITablePayload
  ): Promise<IAPIEntityResponse<IUserTokenTransaction[]>> => {
    const url: string = paginationRequest(`/userTokens/all`, payload);

    return httpClient.get(url);
  };
}
