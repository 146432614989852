/**
* Address.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file Address.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState, useEffect, useRef, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddressTable from './AddressTable';
import AddressDialog from './AddessDialog';
import {
  createAddressAction,
  deleteAddressAction,
  getAddressAction,
  IProfileState,
  profileActions,
  updateAddressAction
} from '../../../../Store/ProfileSlice';
import AddressRowExpansionTemplate from './AddressRowExpansionTemplate';
import { translate } from '../../../../Utils/Translate';
import { IRootState } from '../../../../Store/Store';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  ITablePayload,
  IMultiSortMetaProperties
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { sortOptions } from '@abstract/abstractwebcommon-shared/enum/sort';

const Address = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const profileState: IProfileState = useSelector((state: IRootState) => state.profile);
  const [expandedRows, setExpandedRows] = useState<any>({});
  const [editAddress, setEditAddress] = useState<IAddress>({});
  const [selectedAddress, setSelectedAddress] = useState<IAddress[]>(null);
  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const deleteButtonReference: any = useRef(null);
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: sortOptions.DESC.name
    },
    searchTerm: ''
  }); /**< Default Payload */
  const fieldNameToSort: string = Object.keys(tablePayload.sort).pop();
  const [multiSortMeta, setMultiSortMeta] = useState<IMultiSortMetaProperties[]>([
    {
      field: fieldNameToSort,
      order:
        tablePayload.sort[fieldNameToSort] === sortOptions.ASC.name
          ? sortOptions.ASC.order
          : sortOptions.DESC.order
    }
  ]);

  /// show delete popup
  const onDeleteButtonClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Triggered on UserForm dialog hide event. This dispatches loadUser action if there is any change in the users list
  const onHide = () => {
    setDialogVisible(false);
    setEditAddress({});
  };
  /// Triggers on rowClick
  const onButtonClick = (event: React.SetStateAction<any>) => {
    setExpandedRows([]);
    setEditAddress(event.data);
    setDialogVisible(true);
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedUUIDs: IAddress[] = event.value;
    if (Array.isArray(selectedUUIDs)) {
      const selectedRow = selectedUUIDs.map((row: IAddress) => {
        return row;
      });
      setSelectedAddress(selectedRow);
    }
  };

  /// Handles Address form submission
  // NOTE: id paramater still exists here because that property handleAddressSubmit in the Address component requires it.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddressSubmit = (payload: IAddress, id?: string) => {
    if (id) {
      payload.addressUUID = id;
      dispatch(updateAddressAction(payload));
    } else {
      dispatch(createAddressAction(payload));
    }
  };

  /// Delete roles on Accept
  const onAccept = async () => {
    dispatch(deleteAddressAction(selectedAddress));
    setShowConfirmation(false);
    setSelectedAddress(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any) => {
    const updatedPayload: ITablePayload = tablePayload;
    updatedPayload.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedPayload.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }

    setPayload(updatedPayload);
    setMultiSortMeta(event.multiSortMeta);
    dispatch(getAddressAction(updatedPayload));
  };

  const handlePageUpdate = (event: any) => {
    const updatedPayload = {
      skip: event.first,
      limit: event.rows,
      sort: tablePayload.sort,
      searchTerm: tablePayload.searchTerm
    };

    setPayload(updatedPayload);
    dispatch(getAddressAction(updatedPayload));
  };

  /// listen to success event
  useEffect(() => {
    if (profileState.successMessage) {
      setEditAddress({});
      showToast({
        severity: 'success',
        summary: translate(profileState.successMessage)
      });
      setDialogVisible(false);
      dispatch(getAddressAction(tablePayload));
    }
    if (profileState.errorMessage) {
      showToast({
        severity: 'error',
        summary: translate(profileState.errorMessage)
      });
    }

    dispatch(profileActions.reset());
  }, [profileState]);

  /// fetch address from API and
  useEffect(() => {
    dispatch(getAddressAction(tablePayload));
  }, []);

  /// Div containing header action buttons
  const getHeader = () => {
    return (
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => onDeleteButtonClicked(event)}
          isDisabled={
            ((selectedAddress && Object.keys(selectedAddress).length === 0) || !selectedAddress) ??
            false
          }
          buttonReference={deleteButtonReference}
        />

        <ActionButton onClick={() => setDialogVisible(true)} />
      </div>
    );
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={translate('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.addressUUID]: true });
    }
  };

  return (
    <>
      <AddressTable
        header={getHeader()}
        onButtonClick={onButtonClick}
        getRowExpansionTemplate={(rowData: IAddress) => (
          <AddressRowExpansionTemplate rowData={rowData} />
        )}
        isLoading={!profileState.address}
        dataKey={'addressUUID'}
        selectedList={selectedAddress}
        onSelectionChange={onSelectionChange}
        totalRecords={profileState.totalRecords || 0}
        data={profileState.address}
        expandedRows={expandedRows}
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        onSort={onSort}
        tablePayload={tablePayload}
        multiSortMeta={multiSortMeta}
        handlePageUpdate={handlePageUpdate}
      />
      <AddressDialog
        isDialogVisible={isDialogVisible}
        onHide={onHide}
        editAddress={editAddress || {}}
        handleAddressSubmit={handleAddressSubmit}
        isLoading={profileState.isLoadingRequest}
      />
      {getConfirmPopup()}
    </>
  );
};

export default Address;
