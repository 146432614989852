/**
* UserDialog.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file UserDialog.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
* @modified Sai Charan K, 2021
*/
import React, { Dispatch, useEffect, useState } from 'react';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { useTranslation } from 'react-i18next';
import UserForm from './UserForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUserAction,
  getRolesAction,
  getUserState,
  updateUserAction,
  IUserState
} from '../../../Store/UserSlice';
import FormSkeleton from '@abstract/abstractwebcommon-client/SkeletonLoader/FormSkeleton/FormSkeleton';
import { TFunction } from 'i18next';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';

/**
 * Interface for UserDialog properties.
 */
interface IUserDialogProperties {
  isDialogVisible: boolean /**< Check dialog is visible. True if visible, false otherwise. */;
  onHide: (isLoad?: boolean) => void /**< Hide dialog function. */;
  editUser: IUser /**< The user that needs to be edited. */;
  deleteUsers: (userUUIDs?: string[]) => void /**< To delete users. */;
  userDataUpdated: (
    message: string,
    severity: string,
    isUpdated: boolean
  ) => void /**< Shows a toast message with the specific message. */;
}

const UserDialog = ({
  onHide,
  deleteUsers,
  userDataUpdated,
  ...properties
}: IUserDialogProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const userState: IUserState = useSelector(getUserState);
  const [loadRoles, setLoadRoles] = useState<any>(true);
  const [isProfilePicUpdated, setIsProfilePicUpdated] = useState<boolean>(false);

  const reloadRoles = async (selectedApplicationIDs: number[]): Promise<void> => {
    dispatch(getRolesAction({ selectedApplicationIDs: selectedApplicationIDs }));
    setLoadRoles(false);
  };

  // if profile is loaded and roles not loaded yet
  useEffect(() => {
    if (userState.applications && userState.applications.length !== 0 && loadRoles) {
      const selectedApplicationIDs = userState.applications.map(
        (eachApplication: IApplications) => eachApplication.id
      );
      reloadRoles(selectedApplicationIDs);
    }
  }, [userState.editUser, loadRoles]);

  /// Success Case
  useEffect(() => {
    if (userState.isCreated && properties.isDialogVisible) {
      userDataUpdated(t('/error_messages.user_created_success'), 'success', true);
    }
    if (userState.isUpdated && properties.isDialogVisible) {
      userDataUpdated(t('/error_messages.user_updated_success'), 'success', true);
    }
  }, [userState, properties, userDataUpdated, t]);

  /// Errors
  useEffect(() => {
    if (userState.createError !== null && properties.isDialogVisible) {
      userDataUpdated(userState.createError, 'error', false);
    }
    if (userState.updateError !== null && properties.isDialogVisible) {
      userDataUpdated(userState.updateError, 'error', false);
    }
    if (userState.fetchUserByIdError !== null && properties.isDialogVisible) {
      userDataUpdated(userState.fetchUserByIdError, 'error', true);
    }
  }, [userState, userDataUpdated, properties]);

  /// Trigger on Hide dialog, and loadRoles state
  const hideDialog = () => {
    onHide(isProfilePicUpdated ? true : false);
    setLoadRoles(true);
    setIsProfilePicUpdated(false);
  };

  /// Triggers on user create, Dispatch createUserAction
  const onCreateUser = async (payload: any) => {
    dispatch(createUserAction(payload));
  };

  /// Triggers on user update, Dispatch updateUserAction
  const onUpdateUser = async (payload: any) => {
    dispatch(updateUserAction(payload));
  };

  /// Triggers on user delete
  const onDeleteUser = (payload: any) => {
    deleteUsers(payload);
    onHide();
  };

  /// Get userform if editUser is present for user edit or to create a new user; else return loader
  const getUserForm = () => {
    if (!userState.isLoading) {
      return (
        <UserForm
          editUser={userState.editUser}
          onCreateUser={onCreateUser}
          onUpdateUser={onUpdateUser}
          deleteUsers={onDeleteUser}
          roles={userState.role}
          reloadRoles={reloadRoles}
          applications={userState.applications}
          isLoading={userState.isFormLoad}
          setIsProfilePicUpdated={setIsProfilePicUpdated}
        />
      );
    }
    return <FormSkeleton />;
  };

  return (
    <DialogWrapper
      isDialogVisible={properties.isDialogVisible}
      onHide={() => hideDialog()}
      headerTitle={
        properties.editUser && properties.editUser.id
          ? t('/admin.users_fieldset.edit_user_dialog.header')
          : t('/admin.users_fieldset.add_user_dialog.header')
      }>
      {getUserForm()}
    </DialogWrapper>
  );
};

export default UserDialog;
