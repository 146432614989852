/**
* SettingsPage.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file SettingsPage.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSettingsState,
  testSMTPAction,
  settingsActions,
  getAppSettingsAction,
  updateSettingsAction,
  ISettingsState,
  getApplicationEnvironmentVariablesAction
} from '../../../Store/SettingsSlice';
import SettingsForm from './SettingsForm';
import { FILE_UPLOAD_ERROR } from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { TFunction } from 'i18next';
import { getAllCSSTemplatesAction } from '../../../Store/TemplateSlice';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import {
  getStaticLinksState,
  IStaticLinksState,
  staticLinksActions
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import {
  createStaticLinkAction,
  deleteStaticLinkAction,
  getStaticLinksAction,
  updateStaticLinkAction
} from '../../../Store/StaticLinksSlice';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

const SettingsPage = (): JSX.Element => {
  const t: TFunction = useTranslation().t;

  const settingsState: ISettingsState = useSelector(getSettingsState);
  const staticLinksState: IStaticLinksState = useSelector(getStaticLinksState);

  const dispatch: Dispatch<any> = useDispatch();
  const [initiateLogoUpload, setInitiateLogoUpload] = useState<any>(null);
  const [statusLogoUpload, setStatusLogoUpload] = useState<string>('');
  const [initiateFavouriteIconUpload, setInitiateFavouriteIconUpload] = useState<any>(null);
  const [statusFavouriteIconUpload, setStatusFavouriteIconUpload] = useState<string>('');
  const [initiateStaticLinkIconUpload, setInitiateStaticLinkIconUpload] = useState<any>(null);
  const [statusStaticLinkIconUpload, setStatusStaticLinkIconUpload] = useState<string>('');
  const [isLogoFileChanged, setIsLogoFileChanged] = useState<boolean>(false);
  const [isFavouriteIconFileChanged, setIsFavouriteIconFileChanged] = useState<boolean>(false);
  const [isStaticLinkIconChanged, setIsStaticLinkIconChanged] = useState<boolean>(false);
  const [croppedLogo, setCroppedLogo] = useState<any>(null);
  const [croppedFavouriteIcon, setCroppedFavouriteIcon] = useState<any>(null);
  const [croppedStaticLinkIcon, setCroppedStaticLinkIcon] = useState<any>(null);
  const [tablePayload, setTablePayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'ASC'
    }
  });

  useEffect(() => {
    dispatch(getAppSettingsAction({}));
    dispatch(getAllCSSTemplatesAction());
    dispatch(getApplicationEnvironmentVariablesAction());
    if (!staticLinksState.staticLinks || staticLinksState.staticLinks.length <= 0) {
      dispatch(getStaticLinksAction(tablePayload));
    }

    // on unmount
    return () => {
      dispatch(settingsActions.reset());
      dispatch(staticLinksActions.reset()); // Reset staticLink state.
      setCroppedFavouriteIcon(null);
    };
  }, [dispatch]);

  /// listen to error events
  useEffect(() => {
    if (settingsState.isApplicationSettingsUpdated) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.app_settings_updated') });
    }
    if (settingsState.testSMTPResponse) {
      showToast({ severity: 'success', summary: settingsState.testSMTPResponse });
    }

    if (settingsState.uploadLogoError) {
      showToast({
        severity: 'error',
        summary: settingsState.uploadLogoError?.message || settingsState.uploadLogoError
      });
    }
    if (settingsState.uploadFavouriteIconError) {
      showToast({
        severity: 'error',
        summary:
          settingsState.uploadFavouriteIconError?.message || settingsState.uploadFavouriteIconError
      });
    }
    if (settingsState.testSMTPError) {
      showToast({
        severity: 'error',
        summary: settingsState.testSMTPError?.message || settingsState.testSMTPError
      });
    }
    if (settingsState.applicationSettingsError) {
      showToast({
        severity: 'error',
        summary:
          settingsState.applicationSettingsError?.message || settingsState.applicationSettingsError
      });
    }
  }, [settingsState, dispatch, t]);

  useEffect(() => {
    if (staticLinksState.staticLinksError) {
      showToast({
        severity: 'error',
        summary: staticLinksState.staticLinksError?.message || staticLinksState.staticLinksError
      });
    }
    if (staticLinksState.isStaticLinkCreated) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.static_link_created') });
    }
    if (staticLinksState.isStaticLinkUpdated) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.static_link_updated') });
    }
    if (staticLinksState.isStaticLinksDeleted) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.static_link_deleted') });
    }
  }, [staticLinksState, dispatch, t]);

  /// extract uploaded file and dispatch uploadLogoAction
  const uploadLogo = async (files: File): Promise<void> => {
    const file: any = files ? files[0] : null;
    if (file.type.indexOf('image/') !== -1) {
      setInitiateLogoUpload(file);
      setIsLogoFileChanged(true);
      setStatusLogoUpload('add');
    } else {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  /// extract uploaded file and dispatch uploadFavouriteIconAction
  const uploadFavouriteIcon = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    if (file.type.indexOf('image/') !== -1) {
      setInitiateFavouriteIconUpload(file);
      setIsFavouriteIconFileChanged(true);
      setStatusFavouriteIconUpload('add');
    } else {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  /// extract uploaded static icon file
  const uploadStaticLinkIcon = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    if (file.type.indexOf('image/') !== -1) {
      setInitiateStaticLinkIconUpload(file);
      setIsStaticLinkIconChanged(true);
      setStatusStaticLinkIconUpload('add');
    } else {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  /// delete static links
  const handleDeleteStaticLinks = async (selectedStaticLinks: any): Promise<void> => {
    const staticLinksUUIDs: any = {
      staticLinksUUIDs: selectedStaticLinks.map(
        (eachStaticLinks: any) => eachStaticLinks.staticLinkUUID
      )
    };
    dispatch(
      deleteStaticLinkAction({
        staticLinksUUIDs: staticLinksUUIDs.staticLinksUUIDs,
        tablePayload: tablePayload
      })
    );
  };

  /// update static link
  const handleStaticLinkUpdate = async (
    staticLink: any,
    staticLinkUUID: string | null
  ): Promise<void> => {
    const payload: any = {
      staticLink,
      tablePayload
    };

    if (statusStaticLinkIconUpload === 'add') {
      payload['uploadStaticLinkIcon'] = { file: initiateStaticLinkIconUpload };
    } else if (statusStaticLinkIconUpload === 'delete') {
      payload['deleteStaticLinkIcon'] = true;
    }

    if (staticLinkUUID && staticLinkUUID !== '') {
      dispatch(updateStaticLinkAction({ data: payload, staticLinkUUID }));
    } else {
      dispatch(createStaticLinkAction(payload));
    }
  };

  /// delete staticLinkIcon
  const deleteStaticLinkIcon = (): void => {
    setStatusStaticLinkIconUpload('delete');
  };

  /// delete favouriteIcon
  const deleteFavouriteIcon = (): void => {
    setStatusFavouriteIconUpload('delete');
  };

  /// delete logo
  const deleteLogo = (): void => {
    setStatusLogoUpload('delete');
  };

  const handleTestSMTP = async (values: any): Promise<void> => {
    dispatch(testSMTPAction(values));
  };

  const handleUpdateSettings = async (application: any): Promise<void> => {
    const payload: any = {
      application
    };

    if (statusLogoUpload === 'add') {
      payload['uploadLogo'] = { file: initiateLogoUpload };
    } else if (statusLogoUpload === 'delete') {
      payload['deleteLogo'] = true;
    }

    if (statusFavouriteIconUpload === 'add') {
      payload['uploadFavouriteIcon'] = { file: initiateFavouriteIconUpload };
    } else if (statusFavouriteIconUpload === 'delete') {
      payload['deleteFavouriteIcon'] = true;
    }

    dispatch(updateSettingsAction(payload));
    setStatusLogoUpload('');
    setStatusFavouriteIconUpload('');
  };

  const errorHandler = (error: any): void => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: t('I18N.settings.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  const handleLogoImageCropComplete = (image: any): void => {
    if (!image) {
      setStatusLogoUpload('');
    }
    setCroppedLogo(image);
    setInitiateLogoUpload(image);
  };

  const handleFavouriteIconImageCropComplete = (image: any): void => {
    if (!image) {
      setStatusFavouriteIconUpload('');
    }
    setCroppedFavouriteIcon(image);
    setInitiateFavouriteIconUpload(image);
  };

  const handleStaticLinkIconCropComplete = (image: any): void => {
    setCroppedStaticLinkIcon(image);
    setInitiateStaticLinkIconUpload(image);
  };

  const refreshSaticLinksList = (updatedCriteria?: ITablePayload) => {
    setTablePayload(updatedCriteria);
    dispatch(getStaticLinksAction(updatedCriteria));
  };

  return (
    <div>
      <SettingsForm
        uploadLogo={uploadLogo}
        deleteLogo={deleteLogo}
        uploadFavouriteIcon={uploadFavouriteIcon}
        deleteFavouriteIcon={deleteFavouriteIcon}
        errorHandler={errorHandler}
        handleTestSMTP={handleTestSMTP}
        handleUpdateSettings={handleUpdateSettings}
        statusLogoUpload={statusLogoUpload}
        handleStaticLinkUpdate={handleStaticLinkUpdate}
        handleStaticLinkDelete={handleDeleteStaticLinks}
        refreshSaticLinksList={refreshSaticLinksList}
        uploadStaticLinkIcon={uploadStaticLinkIcon}
        deleteStaticLinkIcon={deleteStaticLinkIcon}
        statusFavouriteIconUpload={statusFavouriteIconUpload}
        displayCroppedFavouriteIcon={croppedFavouriteIcon}
        displayCroppedLogo={croppedLogo}
        displayCroppedStaticLinkIcon={croppedStaticLinkIcon}
        setCroppedStaticLinkIcon={setCroppedStaticLinkIcon}
      />
      <CropDialog
        isVisible={isLogoFileChanged}
        setVisible={setIsLogoFileChanged}
        file={initiateLogoUpload}
        onImageCropComplete={handleLogoImageCropComplete}
      />

      <CropDialog
        isVisible={isFavouriteIconFileChanged}
        setVisible={setIsFavouriteIconFileChanged}
        file={initiateFavouriteIconUpload}
        onImageCropComplete={handleFavouriteIconImageCropComplete}
        isIcon
      />

      <CropDialog
        isVisible={isStaticLinkIconChanged}
        setVisible={setIsStaticLinkIconChanged}
        file={initiateStaticLinkIconUpload}
        onImageCropComplete={handleStaticLinkIconCropComplete}
        isIcon
      />
    </div>
  );
};

export default SettingsPage;
