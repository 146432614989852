/*
 * UserTokenForm.tsx (InstaLOD GmbH)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file UserTokenForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { IUserTokenTransaction } from '@abstract/abstractwebcommon-shared/interfaces/user/UserTokenTransaction';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'primereact/autocomplete';
import Row from 'react-bootstrap/Row';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { getUsersListAction } from '../../../Store/UserSlice';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { searchApplicationAction } from '../../../Store/ApplicationSlice';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { PaginatedAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';

import './UserTokenTransactionForm.css';

/**
 * @interface IUserTokenTransactionFormProperties
 */
interface IUserTokenTransactionFormProperties {
  handleSubmit: (
    payload: Partial<IUserTokenTransaction>,
    userTokenUUID?: string
  ) => void /**< Handler for submit button */;
  isLoading: boolean /**< Loading state */;
  editUserToken: Partial<IUserTokenTransaction> /**< To edit user token */;
}

const UserTokenTransactionForm = (properties: IUserTokenTransactionFormProperties): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const [userSuggestionList, setUserSuggestionList] = useState<IUser[]>([]);
  const [applicationSuggestionList, setApplicationSuggestionList] = useState<IApplications[]>([]);

  const formik = useFormik({
    initialValues: {
      user: properties?.editUserToken?.user || null,
      amount: properties?.editUserToken?.amount || 0,
      description: properties?.editUserToken?.description || '',
      application: properties?.editUserToken?.application || null
    },
    validationSchema: Yup.object({
      user: Yup.object()
        .nullable() // We need nullable and required here because we're validating an object and initializing it as "null".
        .required(
          translation('/validations.required', {
            field: translation('/admin/userToken-management.datatable.columns.user')
          })
        ),
      amount: Yup.string()
        .matches(
          /^-?\d*$/, // Regex allows numbers, and a negative sign optionally at the beginning
          'The amount must be a whole number, negative values allowed.'
        )
        .test(
          'is-integer',
          'The amount must be a whole number, negative values allowed.',
          (value) => !value || Number.isInteger(Number(value))
        )
        .required(
          translation('/validations.required', {
            field: translation('/admin/userToken-management.datatable.columns.amount')
          })
        ),
      description: Yup.string().required(
        translation('/validations.required', {
          field: translation('/admin/userToken-management.datatable.columns.description')
        })
      ),
      application: Yup.object()
        .nullable() // We need nullable and required here because we're validating an object and initializing it as "null".
        .required(
          translation('/validations.required', {
            field: translation(
              '/admin/role-management.roles_fieldset.datatable.columns.application'
            )
          })
        )
    }),
    onSubmit: (data) => {
      const payload: Partial<IUserTokenTransaction> = {};
      Object.keys(data).forEach((key: string, index: number) => {
        if ((key as keyof typeof formik.initialValues) === 'user') {
          if (properties?.editUserToken && Object.keys(properties?.editUserToken).length) {
            if (
              (!formik.initialValues['user'] && data['user']['userUUID']) ||
              data['user']['userUUID'] !== formik.initialValues['user']['userUUID']
            ) {
              payload['user'] = {
                userUUID: data['user']['userUUID']
              };
            }
          } else {
            payload['user'] = {
              userUUID: data['user']['userUUID']
            };
          }
        } else if ((key as keyof typeof formik.initialValues) === 'application') {
          if (properties?.editUserToken && Object.keys(properties?.editUserToken).length) {
            if (
              (!formik.initialValues['application'] && data['application']['applicationUUID']) ||
              data['application']['applicationUUID'] !==
                formik.initialValues['application']['applicationUUID']
            ) {
              payload['application'] = {
                applicationUUID: data['application']['applicationUUID']
              };
            }
          } else {
            payload['application'] = {
              applicationUUID: data['application']['applicationUUID']
            };
          }
        } else {
          if (
            data[key as keyof typeof formik.initialValues] !==
            formik.initialValues[key as keyof typeof formik.initialValues]
          ) {
            payload[key] = data[key as keyof typeof formik.initialValues];
          }
        }

        if (index === Object.keys(data).length - 1) {
          if (Object.keys(payload).length) {
            properties.handleSubmit(payload, properties?.editUserToken?.uuid);
          }
        }
      });
    }
  });

  /**
   * Item template for Autocomplete
   */
  const itemTemplate = (user: IUser): JSX.Element => {
    return <div>{`${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`}</div>;
  };

  /**
   * Search Application
   * @param event
   */
  const searchUser = async (event?: any): Promise<void> => {
    const userPayload: ITablePayload = {
      limit: 50,
      skip: 0,
      sort: {
        created: 'DESC'
      },
      searchTerm: event?.query?.trim() || ''
    }; /**< Default user payload */

    const response: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(
      dispatch(getUsersListAction(userPayload))
    );

    if (response?.payload?.status === 200) {
      setUserSuggestionList(response?.payload?.data?.records ?? []);
    }
  };

  /**
   * Search Application
   * @param event
   */
  const searchApplication = async (event?: any): Promise<void> => {
    const response: PaginatedAPIEntityResponse<IApplications> = await asyncErrorHandler(
      dispatch(searchApplicationAction(event?.query || ''))
    );
    if (response?.payload?.status === 200) {
      setApplicationSuggestionList(response?.payload?.data?.records ?? []);
    }
  };

  // On component did mount load user & applications dropdown
  useEffect(() => {
    searchApplication();

    return () => {
      setUserSuggestionList([]);
      setApplicationSuggestionList([]);
    };
  }, []);

  return (
    <FormWrapper
      onSubmit={formik.handleSubmit}
      controlButtonLabel={properties?.editUserToken?.id}
      isLoading={properties.isLoading}
      handleSubmitButton={() => formik.handleSubmit()}>
      <Row>
        <Form.Group as={Col} sm="6" md="6">
          <Form.Label htmlFor="user" className="required">
            {translation('/admin/userToken-management.datatable.columns.user')}
          </Form.Label>
          <AutoComplete
            id="addUserTokenFormAutoComplete"
            name="user"
            value={formik.values.user}
            suggestions={userSuggestionList}
            completeMethod={searchUser}
            field="fullName"
            placeholder={translation(
              '/admin/userToken-management.datatable.columns.user_placeholder'
            )}
            onChange={(event: any) => {
              formik.setFieldValue('user', event.value);
            }}
            onBlur={formik.handleBlur}
            dropdown
            appendTo="self"
            itemTemplate={itemTemplate}
            disabled={properties.isLoading}
            className={`${
              formik.touched.user && formik.errors.user ? 'p-invalid w-100' : 'w-100'
            } auto-complete`}
            onClear={() => {
              formik.setFieldValue('user', null);
            }}
          />
          {formik.touched.user && formik.errors.user ? (
            <small id="email-invalid" className="p-invalid error-text">
              {formik.errors.user}
            </small>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} sm="6" md="6">
          <InstaInputText
            label={translation('/admin/userToken-management.datatable.columns.amount')}
            labelClassName="required"
            name="amount"
            id="amount"
            isLoading={properties.isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.amount}
            errors={formik.errors.amount}
            value={formik.values.amount}
          />
        </Form.Group>
        <Form.Group as={Col} sm="6" md="6">
          <InstaInputText
            label={translation('/admin/userToken-management.datatable.columns.description')}
            labelClassName="required"
            name="description"
            id="description"
            isLoading={properties.isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.description}
            errors={formik.errors.description}
            value={formik.values.description}
          />
        </Form.Group>
        <Form.Group as={Col} sm="6" md="6">
          <Form.Label htmlFor="application" className="required">
            {translation('/admin/role-management.roles_fieldset.datatable.columns.application')}
          </Form.Label>
          <AutoComplete
            id="application"
            name="application"
            value={formik.values.application}
            suggestions={applicationSuggestionList}
            completeMethod={searchApplication}
            field="applicationName"
            onChange={(event: any) => {
              formik.setFieldValue('application', event.value);
            }}
            onBlur={formik.handleBlur}
            dropdown
            disabled={properties.isLoading}
            className={`${
              formik.touched.application && formik.errors.application ? 'p-invalid w-100' : 'w-100'
            } auto-complete`}
          />
          {formik.touched.application && formik.errors.application ? (
            <small id="email-invalid" className="p-invalid error-text">
              {formik.errors.application}
            </small>
          ) : null}
        </Form.Group>
      </Row>
    </FormWrapper>
  );
};

export default UserTokenTransactionForm;
