/**
* Store.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file Store.ts
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { configureStore, getDefaultMiddleware, ReducersMapObject } from '@reduxjs/toolkit';
import { applicationFeatureKey, applicationReducer } from './ApplicationSlice';
import { authFeatureKey, authReducer } from './AuthSlice';
import { logReducer, logFeatureKey } from './LogSlice';
import { phReducer, placeholderfeatureKey } from './PlaceholderSlice';
import { roleReducer, roleFeatureKey } from './RolesSlice';
import { profileReducer, profileFeatureKey } from './ProfileSlice';
import {
  dashboardReducer,
  dashboardFeatureKey
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import { settingsReducer, settingsFeatureKey } from './SettingsSlice';
import StateLoader from './StateLoader';
import { templateReducer, templateFeatureKey } from './TemplateSlice';
import { userReducer, userFeatureKey } from './UserSlice';
import { forgotPasswordReducer, forgotPasswordFeatureKey } from './ForgotPasswordSlice';
import { userApplicationReducer, userApplicationsFeatureKey } from './UserApplicationsSlice';
import {
  staticLinksFeatureKey,
  staticLinksReducer
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import {
  userTokenTransactionFeatureKey,
  userTokenTransactionReducer
} from './UserTokenTransactionSlice';

const stateLoader: any = new StateLoader();

const reducersMapObject: ReducersMapObject = {
  [authFeatureKey]: authReducer,
  [userFeatureKey]: userReducer,
  [logFeatureKey]: logReducer,
  [roleFeatureKey]: roleReducer,
  [profileFeatureKey]: profileReducer,
  [dashboardFeatureKey]: dashboardReducer,
  [applicationFeatureKey]: applicationReducer,
  [templateFeatureKey]: templateReducer,
  [placeholderfeatureKey]: phReducer,
  [settingsFeatureKey]: settingsReducer,
  [forgotPasswordFeatureKey]: forgotPasswordReducer,
  [userApplicationsFeatureKey]: userApplicationReducer,
  [staticLinksFeatureKey]: staticLinksReducer,
  [userTokenTransactionFeatureKey]: userTokenTransactionReducer
};

export const store = configureStore({
  reducer: reducersMapObject,
  middleware: [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [],
  preloadedState: stateLoader.loadState()
});

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

// The `IRootState` types from the store itself
export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
