/**
* App.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file App.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import VerifyPage from './Components/Register/VerifyPage';
import NotFound from './Components/NotFound/NotFound';
import i18n from './Services/I18n';
import ForbiddenPage from './Components/Forbidden/ForbiddenPage';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSettingsState,
  ISettingsState,
  getLoginPagesInformationAction
} from './Store/SettingsSlice';
import ForgotPasswordPage from './Components/ForgotPassword/ForgotPasswordPage';
import ResetPasswordPage from './Components/ResetPassword/ResetPasswordPage';
import ContentPanel from './Components/ContentPanel';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import PermissionPage from './Components/Login/PermissionPage';
import ActivateUser from './Components/Admin/Shared/ActivateUser';
import { loadCSS } from './Services/TemplateApi';
import { BASE_API_URL } from './Config';
import AuthenticationVerificationPage from './Components/Login/AuthenticationVerificationPage';
import {
  updateUserThemeModeStateAction,
  getUserState,
  IUserState,
  updateUserLanguageSettingsModeStateAction
} from './Store/UserSlice';
import { ErrorHandler } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { AlertToast, showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import ConfigurationSettingsPage from './Components/Login/ConfigurationSettingsPage';
import ScrollToTop from '@abstract/abstractwebcommon-client/ScrollToTop';
import { changeUserTheme } from '@abstract/abstractwebcommon-client/utils/themeModeUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from './Utils/routesNames';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { changeUserLanguage } from '@abstract/abstractwebcommon-client/utils/LanguageSettingsModeUtils';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import TopBar from '@abstract/abstractwebcommon-client/TopBar';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const translation: TFunction = useTranslation().t;

  const parsedString: any = window.location.search;
  const settingsState: ISettingsState = useSelector(getSettingsState);
  const userState: IUserState = useSelector(getUserState);
  const [themeMode, setThemeMode] = useState<string>(
    LocalStorage.getThemeMode() || ThemeMode.lightMode
  );
  const urlParams: URLSearchParams = new URLSearchParams(parsedString);
  const themeModeURL: string | null = urlParams.get('themeMode') ?? null;
  const [languageSettingsMode, setLanguageSettingsMode] = useState<string>(
    LocalStorage.getLanguageSettingsMode() || LanguageSettingsMode.english
  ); /**< Language settings mode */
  const languageSettingsModeParameter: string | null =
    urlParams.get('languageSettingsMode') ?? null; /**< Language settings mode from URL param */
  const applicationUUIDParameter: string | null =
    urlParams.get('app') ?? null; /**< Application UUID URL param */
  const [favouriteIcon, setFavouriteIcon] = useState<string>(''); /**< FavouriteIcon */

  const didChangeTheme = async (theme: ThemeMode): Promise<void> => {
    LocalStorage.setThemeMode(theme);
    setThemeMode(theme);

    if (!window.location.pathname.includes(SharedCommomRouteName.loginRoute)) {
      if (
        LocalStorage.getXAuthToken() &&
        !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
      ) {
        dispatch(
          updateUserThemeModeStateAction({
            userUUID: LocalStorage.getXUserUUID(),
            themeMode: theme
          })
        ); /** Update user theme mode preference in database */
      }
    }
  };

  /**
   * Change user language preference
   * @param language
   */
  const didChangeLanguage = async (language: string): Promise<void> => {
    LocalStorage.setLanguageSettingsMode(language);
    setLanguageSettingsMode(language);

    if (!window.location.pathname.includes(SharedCommomRouteName.loginRoute)) {
      if (
        LocalStorage.getXAuthToken() &&
        !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
      ) {
        dispatch(
          updateUserLanguageSettingsModeStateAction({
            userUUID: LocalStorage.getXUserUUID(),
            languageSettingsMode: language
          })
        ); /** Update user language settings mode preference in database */
      }
    }
  };

  // NOTE: When users log out from the License or E-commerce applications, we should automatically redirect them to the login page.
  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent): void => {
      if (event.key === LocalStorage.xAuthTokenKey && event.newValue === null) {
        window.location.href = SharedCommomRouteName.loginRoute;
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  /// Update theme mode if themeModeURL is present in the URL
  /// Update language settings mode if languageSettingsModeURL is present in the URL
  /// Triggered when User is accessed in License or Ecom sidebar
  useEffect(() => {
    if (themeModeURL) {
      LocalStorage.setThemeMode(themeModeURL);
      setThemeMode((prevState: string) => {
        prevState = themeModeURL;
        return prevState;
      });
    }
    if (languageSettingsModeParameter) {
      LocalStorage.setLanguageSettingsMode(languageSettingsModeParameter);
      setLanguageSettingsMode((prevState: string) => {
        prevState = languageSettingsModeParameter;
        return prevState;
      });
    }
  }, []);

  /// handle error when updating user theme mode state
  useEffect(() => {
    if (userState.updateThemeModeStateError) {
      showToast({
        severity: 'error',
        summary: translation('/user.update_theme_mode_failed'),
        detail: translation('I18N.error_messages.update_user_theme_mode_state')
      });
    }
  }, [userState.updateThemeModeStateError]);

  /// handle error when updating user language settings mode state
  useEffect(() => {
    if (userState.updateLanguageSettingsModeStateError) {
      showToast({
        severity: 'error',
        summary: translation('/user.update_language_settings_mode_failed'),
        detail: translation('I18N.error_messages.update_user_language_settings_mode_state')
      });
    }
  }, [userState.updateLanguageSettingsModeStateError]);

  /// fetch safe settings
  useEffect(() => {
    LocalStorage.setThemeMode(LocalStorage.getThemeMode() ?? ThemeMode.lightMode); //set theme when accessing login page
    LocalStorage.setLanguageSettingsMode(
      LocalStorage.getLanguageSettingsMode() ?? LanguageSettingsMode.english
    ); //set language when accessing login page

    dispatch(getLoginPagesInformationAction(applicationUUIDParameter));
  }, []);

  /// load custom css into application
  useEffect(() => {
    const customCssLink: string = settingsState?.loginPageInformation?.customCssLink;
    if (customCssLink) {
      loadCSS(BASE_API_URL + customCssLink, 'customCss');
    }
    setFavouriteIcon(settingsState?.loginPageInformation?.favouriteIconImageUrl as string);
  }, [settingsState.loginPageInformation]);

  // Update faviconURL when faviconURL(in settings) change.
  useEffect(() => {
    setFavouriteIcon(settingsState.favouriteIconUrl as string);
  }, [settingsState.favouriteIconUrl]);

  useEffect(() => {
    if (themeMode) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => e.matches && didChangeTheme(ThemeMode.darkMode));

      window
        .matchMedia('(prefers-color-scheme: light)')
        .addEventListener('change', (e) => e.matches && didChangeTheme(ThemeMode.lightMode));

      changeUserTheme(themeMode);
    }
  }, [themeMode]);

  useEffect(() => {
    if (languageSettingsMode) {
      changeUserLanguage(languageSettingsMode, i18n);
    }
  }, [languageSettingsMode]);

  // Listen to changes in localStorage.
  // HttpClient.ts file in AWC will fire a storage event when token is rewened to update theme-mode and language-settings-mode.
  // AuthenticationVerificationPage.tsx file will fire a storage event when redirect logic is applied to get theme mode and language settings mode from another application.
  window.addEventListener('storage', (event: StorageEvent) => {
    // Only triggers here when token is refresh in AWC/Client/HttpClient.ts
    if (event.key && event.key === LocalStorage.themeModeKey) {
      setThemeMode((prevState: string) => {
        prevState = event.newValue;
        return prevState;
      });
      LocalStorage.setThemeMode(event.newValue);
    } else if (event.key && event.key === LocalStorage.languageSettingsModeKey) {
      setLanguageSettingsMode((prevState: string) => {
        prevState = event.newValue;
        return prevState;
      });
      LocalStorage.setLanguageSettingsMode(event.newValue);
    } else {
      setThemeMode((prevState: string) => {
        prevState = LocalStorage.getThemeMode();
        return prevState;
      });
      setLanguageSettingsMode((prevState: string) => {
        prevState = LocalStorage.getLanguageSettingsMode();
        return prevState;
      });
    }
  });

  /**
   * Get TopBar wrapper element
   */
  const getTopBarWrapper = (): JSX.Element => {
    return (
      <TopBar
        languageSettingsMode={languageSettingsMode}
        didChangeLanguage={didChangeLanguage}
        i18nService={i18n}
        isThemeModeVisible={false}
      />
    );
  };

  return (
    <>
      {settingsState && settingsState.loginPageInformation && (
        <div className="d-flex flex-column h-100">
          <Helmet>
            <title>{settingsState.loginPageInformation.applicationTitle}</title>
            <link rel="icon" type="image/png" href={favouriteIcon} />
          </Helmet>
          <Suspense fallback={() => <Loader />}>
            <ErrorHandler />
            <AlertToast />
            <Router>
              <ScrollToTop />
              <Switch>
                <Route path={SharedCommomRouteName.loginRoute}>
                  <Login
                    themeMode={themeMode}
                    didChangeTheme={didChangeTheme}
                    languageSettingsMode={languageSettingsMode}
                    didChangeLanguage={didChangeLanguage}
                  />
                </Route>
                <Route path={SharedCommomRouteName.registerRoute}>
                  <Register
                    themeMode={themeMode}
                    didChangeTheme={didChangeTheme}
                    languageSettingsMode={languageSettingsMode}
                    didChangeLanguage={didChangeLanguage}
                  />
                </Route>
                <Route path={RouteName.validatePermissionRoute}>
                  <div className="text-dark login-container login-pages-global-container">
                    {getTopBarWrapper()}
                    <PermissionPage />
                  </div>
                </Route>
                <Route path={RouteName.forgotPasswordRoute}>
                  <div className="forgotPasswordPage-container">
                    {getTopBarWrapper()}
                    <ForgotPasswordPage />
                  </div>
                </Route>
                <Route path={RouteName.resetPasswordRoute}>
                  <div className="forgotPasswordPage-container login-pages-global-container">
                    {getTopBarWrapper()}
                    <ResetPasswordPage />
                  </div>
                </Route>
                <Route path={RouteName.authVerifyRoute}>
                  <AuthenticationVerificationPage />
                </Route>
                <Route path={RouteName.activateUserRoute}>
                  <div className="grey-background-to-activation-page login-pages-global-container">
                    <ActivateUser />
                  </div>
                </Route>
                <Route path={RouteName.verifyEmailRoute}>
                  <div className="text-dark registerForm-container login-pages-global-container">
                    {getTopBarWrapper()}
                    <VerifyPage />
                  </div>
                </Route>
                <Route exact path="/">
                  <Redirect to={{ pathname: SharedCommomRouteName.loginRoute }} />
                </Route>
                <Route path={SharedCommomRouteName.configurationSettingsRoute}>
                  <>
                    {getTopBarWrapper()}
                    <ConfigurationSettingsPage />
                  </>
                </Route>
                <Route path={SharedCommomRouteName.code401Route}>
                  <ForbiddenPage />
                </Route>
                <Route path={SharedCommomRouteName.code404Route}>
                  <NotFound />
                </Route>
                <Route path={SharedMainRouteName.adminRoute}>
                  <Route exact path={SharedMainRouteName.adminRoute}>
                    <Redirect to={{ pathname: RouteName.adminDashboardRoute }} />
                  </Route>
                  <ContentPanel
                    adminRoute={true}
                    themeMode={themeMode}
                    didChangeTheme={didChangeTheme}
                    languageSettingsMode={languageSettingsMode}
                    didChangeLanguage={didChangeLanguage}
                  />
                </Route>
                <Route path={SharedMainRouteName.userRoute}>
                  <Route exact path={SharedMainRouteName.userRoute}>
                    <Redirect to={{ pathname: RouteName.userProfileRoute }} />
                  </Route>
                  <ContentPanel
                    adminRoute={false}
                    themeMode={themeMode}
                    didChangeTheme={didChangeTheme}
                    languageSettingsMode={languageSettingsMode}
                    didChangeLanguage={didChangeLanguage}
                  />
                </Route>
                <Route>
                  <Redirect to={{ pathname: SharedCommomRouteName.code404Route }} />
                </Route>
              </Switch>
            </Router>
          </Suspense>
        </div>
      )}
    </>
  );
};

export default App;
