/**
* PrepareFilter.ts (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2022 
* @file PrepareFilter.ts
* @author Etienne Daher
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { DATA_OPERATOR } from '@abstract/abstractwebcommon-shared/enum/pagination';
import { IFilter } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

//TODO: Move to backend.
export const prepareFilter = (event: Record<string, any>, keys: any[]): IFilter[] => {
  const filter: IFilter[] = [];
  for (let i = 0; i < keys.length; i++) {
    if (event.filters[keys[i]]?.value) {
      if (keys[i] === 'created' || keys[i] === 'updated' || keys[i] === 'dateRange') {
        filter.push({
          column: keys[i],
          operator: DATA_OPERATOR.LESS_THAN_OR_EQUAL,
          value: event.filters[keys[i]]?.value
        });
      } else if (keys[i] === 'isActive') {
        filter.push({
          column: keys[i],
          operator: DATA_OPERATOR.EQUALS,
          value: event.filters[keys[i]]?.value
        });
      } else if (keys[i] === 'isVerified') {
        filter.push({
          column: keys[i],
          operator: DATA_OPERATOR.EQUALS,
          value: event.filters[keys[i]]?.value
        });
      } else {
        if (event?.filters[keys[i]]?.matchMode !== 'IN') {
          let filterValue = event.filters[keys[i]]?.value;
          filterValue = filterValue.replace(/'/g, "\\'");
          filter.push({
            column: keys[i],
            operator: DATA_OPERATOR.LIKE,
            value: filterValue
          });
        } else {
          filter.push({
            column: keys[i],
            operator: DATA_OPERATOR.IN,
            value: event.filters[keys[i]]?.value
          });
        }
      }
    }
  }
  return filter;
};
