/*
 * CssEditor.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file CssEditor.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCSSAction,
  updateCSSAction,
  getTemplateState,
  ITemplateState
} from '../../../Store/TemplateSlice';
import CSSEditorComponent from '@abstract/abstractwebcommon-client/TemplateEditor/CSSEditorComponent';
import { TFunction } from 'i18next';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

/**
 * Template Editor component.
 * @name CssEditor
 */
const CssEditor = (): JSX.Element => {
  const t: TFunction = useTranslation().t;
  const templateState: ITemplateState = useSelector(getTemplateState);
  const dispatch: Dispatch<any> = useDispatch();
  const [isTemplateLoaded, setIsTemplateLoaded] = useState<boolean>(false);

  const codeMirrorOptions: any = {
    theme: 'dracula',
    lineNumbers: true,
    lineWrapping: true
  };

  /// check if updated successfully
  useEffect(() => {
    if (isTemplateLoaded && templateState.isCssUpdated) {
      showToast({ severity: 'success', summary: t('I18N.template_editor.update_success') });
      dispatch(getCSSAction({}));
    }
  }, [isTemplateLoaded, templateState, dispatch, t]);

  /// check for error and show it
  useEffect(() => {
    if (isTemplateLoaded && templateState.loadCssError) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: templateState.loadCssError?.message || templateState.loadCssError
      });
    }
  }, [templateState, isTemplateLoaded, t]);

  /// onSubmit action
  const onSubmitHandler = (updatedPayload: string) => {
    dispatch(
      updateCSSAction({ templateUUID: templateState.CSSTemplate.templateUUID, css: updatedPayload })
    );
  };

  /// Fetch css template on Load.
  useEffect(() => {
    if (!isTemplateLoaded) {
      dispatch(getCSSAction({}));
      setIsTemplateLoaded(true);
    }
  }, []);
  return (
    <>
      <CSSEditorComponent
        title={t('/admin/css-editor.page_title')}
        isLoading={templateState && templateState.isLoading}
        codeMirrorOptions={{
          mode: 'css',
          ...codeMirrorOptions
        }}
        submitHandler={onSubmitHandler}
        submitLabel={t('/admin/css-editor.fieldset.editor.button')}
        submitClasses={'p-button p-button-primary'}
        confirmationMessage={t('/confirm_messages.save_record')}
        confirmationNo={t('/confirm_messages.no')}
        confirmationYes={t('/confirm_messages.yes')}
        CSSTemplate={templateState.CSSTemplate ? templateState.CSSTemplate.html : ''}
      />
    </>
  );
};

export default CssEditor;
