/**
* ClientRoutes.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ClientRoutes.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import Profile from '../Components/Admin/Profile/ProfilePage';
import NotFoundRedirect from '@abstract/abstractwebcommon-client/NotFound/NotFoundRedirect';
import { RouteName } from '../Utils/routesNames';
import i18n from '../Services/I18n';

/**
 * Get client routes
 * @returns clientRoutes
 */
export const getClientRoutes = () => {
  const clientRoutes = [
    {
      path: RouteName.userProfileRoute,
      Component: Profile,
      headingKey: i18n.t('/admin.sidebar.menu.profile')
    },
    {
      Component: NotFoundRedirect
    }
  ];

  return clientRoutes;
};
