/*
 * RegisterForm.tsx (AbstractUser)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file RegisterForm.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useState, useEffect } from "react";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "primereact/card";
import { Link, useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import queryString from 'query-string';
import "./index.css";
import "../services/i18n"; //TODO: init awc and load services from one file only
import { useTranslation } from "react-i18next";
import { IRegisterFormData } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import VerifyButton from "../VerifyButton";
import ImagePreview from "../ImagePreview";
import { IPublicApplicationInformation } from "@abstract/abstractwebcommon-shared/interfaces/user/applications";
import { ThemeMode } from "../../Shared/enum/theme";
import { removeSchemaFromURL } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { LocalStorage } from '../utils/sharedLocalStorage'
import { TFunction } from 'i18next';
import { isStringEmptyOrNullOrUndefined } from "../../Shared/utils/sharedFunctions";
import { maximumCharactersAllowedInPassword, minimumCharactersAllowedInPassword } from "../../Shared/constants";

const LoginClassNames = {
  display: 'custom-display-element',
  hide: 'custom-hide-element'
}

/**
 * IRegisterFormProperties interface
 */
export interface IRegisterFormProperties {
  onSubmit: (data: IRegisterFormData) => void; /**< Function to be called when the form is submitted. */
  isLoading?: boolean; /**< Whether the form is loading. */
  loginURL?: string; /**< Login URL path. */
  verificationImageURL?: string; /**< URL of the verification image. */
  onResendVerification?: (data: IRegisterFormData) => void; /**< Function called to resend verification. */
  userFormData?: IRegisterFormData; /**< User information. */
  termsOfServiceURL?: string; /**< Terms of service URL. */
  verifyOnDate?: Date; /**< Date a user can send verification. */
  application?: IPublicApplicationInformation; /**< Application details. */
  emailToRegister?: string; /**< In case registering from an invite. */
  completeRegistrationText?: string; /**< Complete registration text. */
  externalApplication?: IPublicApplicationInformation; /**< External application details. */
}

/**
 * Registration Form component.
 */
const RegisterForm = ({
  onSubmit,
  isLoading,
  loginURL,
  verificationImageURL,
  onResendVerification,
  userFormData,
  termsOfServiceURL,
  verifyOnDate,
  application,
  emailToRegister = null,
  completeRegistrationText,
  externalApplication
}: IRegisterFormProperties): ReactElement => {
  const search: string = useLocation().search;
  const parsedString: IRegisterFormData = queryString.parse(search);
  const token: string = parsedString['token'];
  const translate: TFunction = useTranslation().t;

  parsedString.email = decodeURIComponent(parsedString.email);
  const [ isPasswordVisible, setPasswordVisible ] = useState<boolean>(false);
  const [isLogoFullLoaded, setLogoFullLoaded] = useState<boolean>(false);
  const [displayLogoClassName, setDisplayLogoClassName] = useState<string>(LoginClassNames.hide);
  const themeMode = LocalStorage.getThemeMode()
  
  const onPasswordVisible = () => {
    setPasswordVisible(!isPasswordVisible);
  }
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userFormData?.email ?? emailToRegister ?? '',
      username: "" || userFormData?.username,
      firstName: "" || userFormData?.firstName,
      lastName: "" || userFormData?.lastName,
      password: "",
      isTOSAccepted: userFormData ? true : false
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(
          2,
          `${translate("awc:/.register.username")} ${translate("awc:/validations.min", {
            field: "2",
          })}`
        )
        .max(
          200,
          `${translate("awc:/.register.username")} ${translate("awc:/validations.max", {
            field: "200",
          })}`
        )
        .required(
            translate("awc:/validations.required", {
            field: translate("awc:/.register.username"),
          })
        )
        .matches(
          /^[a-zA-Z0-9]+$/,
          "Not allowed whitespace and special character"
        ),
        email: Yup.string()
        .email(`${translate("awc:/validations.valid_email")}`)
        .required(
            translate("awc:/validations.required", {
            field: translate("awc:/.register.email"),
          })
        ),
        firstName: Yup.string()
        .min(
          2,
          `${translate("awc:/.register.first_name")} ${translate("awc:/validations.min", {
            field: "2",
          })}`
        )
        .max(
          200,
          `${translate("awc:/.register.first_name")} ${translate("awc:/validations.max", {
            field: "200",
          })}`
        )
        .required(
            translate("awc:/validations.required", {
            field: translate("awc:/.register.first_name"),
          })
        ),
        lastName: Yup.string()
        .min(
          2,
          `${translate("awc:/.register.last_name")} ${translate("awc:/validations.min", {
            field: "2",
          })}`
        )
        .max(
          200,
          `${translate("awc:/.register.last_name")} ${translate("awc:/validations.max", {
            field: "200",
          })}`
        )
        .required(
            translate("awc:/validations.required", {
            field: translate("awc:/.register.last_name"),
          })
        ),
        password: Yup.string()
        .min(
          minimumCharactersAllowedInPassword,
          `${translate("awc:/.login.password_tooltip")} ${translate("awc:/validations.min", {
            field: minimumCharactersAllowedInPassword,
          })}`
        )
        .max(
          maximumCharactersAllowedInPassword,
          `${translate("awc:/.login.password_tooltip")} ${translate("awc:/validations.max", {
            field: maximumCharactersAllowedInPassword,
          })}`
        )
        .matches(validators.PASSWORD, translate('awc:/.register.password_regex'))
        .when("isTOSAccepted", {
          is: false,
          then: Yup.string().required(
            translate("awc:/validations.required", {
              field: translate('awc:/.login.password'),
            })
          )
        }),
        isTOSAccepted: Yup.boolean().oneOf([true], translate("awc:/.register.must_accept_TOS"))
    }),
    onSubmit: (data) => {
        const payload: any = {};
        Object.keys(data).forEach((key: string, i) => {
          if (
            data[key as keyof typeof formik.initialValues] !==
            formik.initialValues[key as keyof typeof formik.initialValues]
          ) {
            payload[key] = data[key as keyof typeof formik.initialValues];
          }
          if (i === Object.keys(data).length - 1) {
            payload.email = data.email
            onSubmit(payload);
          }
        })
    }
});

const getRegisterButton = () => (
  <Col xs={12} className="py-0">
  <Button
    type="submit"
    disabled={isLoading}
    className="btn-block"
    variant="primary"
    >
    {isLoading ? (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      />
    ) : (
      translate("awc:/.register.sign_up")
      )
    }
    </Button>
</Col>
);
  if(token && !window.location.pathname.includes('verify-email')) {
    return (
      <Col className={`my-0 mx-auto verificationForm ${isLogoFullLoaded ? LoginClassNames.display : LoginClassNames.display}`}>
        <Card>
          <p>{translate("awc:/.register.verify_email")}</p>
          <img className="img-fluid" alt="inbox" src={verificationImageURL} onLoad={() => setLogoFullLoaded(true)} />
          <p>{translate("awc:/.register.verification_sent")}{parsedString.email}</p>
            <hr />
            <VerifyButton 
              isLoading={isLoading}
              onResendVerification={onResendVerification}
              userData={parsedString}
              buttonText={translate("awc:/.register.resend_email")}
              verifyOnDate={verifyOnDate}
            />
        </Card>
      </Col>
    )
  }
  const noImageContainer = () => {
    return <h2 className="text-center text-break mb-0">{application?.applicationName ?? translate("awc:/.page_title")}</h2>;
  };

  const openNewBrowserTabOnLogoClick = (): void => {
    if(!isStringEmptyOrNullOrUndefined(application.website)) {
      window.open(application.website, '_blank');
    }
  }

  //Note: used to handle when to display the login form to not flick the component when there is a uploaded logo and the logo is loading.
  useEffect(() => {
    if (application.applicationName) {
      if ((application.logoImageURL && isLogoFullLoaded) || !application.logoImageURL) {
        setDisplayLogoClassName(LoginClassNames.display)
      }
    }

  }, [application, isLogoFullLoaded])
  
  /// Get register form card
  const getRegisterCard = () => {
    return (
      <Card>
          <Row className="text-center justify-content-md-center">
            <Col xs={12}>
              <div 
                onClick={openNewBrowserTabOnLogoClick} 
                className={`${!isStringEmptyOrNullOrUndefined(application.website) ? 'cursor-pointer' : ''}`}
              >
                <ImagePreview
                  setLogoFullLoaded={setLogoFullLoaded}
                  altText={translate("awc:/.register.logo_alt")}
                  showDelete={false}
                  imageUrl={application?.logoImageURL}
                  imgClass={`logoImage ${themeMode === ThemeMode.darkMode ? 'img-filter-grayscale-invert' : ''}`}
                  isLogo={true}
                  noImageContainer={noImageContainer()}
                />
              </div>
            </Col>
          </Row>
          <h2 className="text-center custom-margin">
            { userFormData ?
                translate("awc:/.register.verify_header") :
                translate("awc:/.register.header")
            }
          </h2>
          { userFormData ?
            <span className="text-break custom-margin">{completeRegistrationText}</span>
            : <></>
          }
          <p className="registerDescription text-break custom-margin">{application?.description}</p>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} className="mb-2">
                <div className="p-inputgroup specialInputs">
                  <FormControl
                    id="username"
                    placeholder={translate("awc:/.register.username")}
                    type="text"
                    required={true}
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    readOnly={userFormData?.username && true}
                    className={
                      formik.touched.username && formik.errors.username
                        ? "p-invalid input-register"
                        : "text-dark input-register"
                    }
                  />
                </div>
                {formik.touched.username && formik.errors.username ? (
                  <small id="username-invalid" className="p-invalid">
                      {formik.errors.username}
                  </small>
                  ) : null}
              </Col>
              <Col xs={12} className="pt-2 negateMarginTop mb-2">
                <div className="p-inputgroup specialInputs">
                  <FormControl
                    id="email"
                    placeholder={translate("awc:/.register.email")}
                    type="email"
                    required={true}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    readOnly={userFormData?.email && true}
                    minLength={2}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.email && formik.errors.email
                        ? "p-invalid  text-dark input-register"
                        : "text-dark input-register"
                    }
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <small id="email-invalid" className="p-invalid">
                      {formik.errors.email}
                  </small>
                  ) : null}
              </Col>
              <Col xs={12} className="pt-2 negateMarginTop mb-2">
                <div className="p-inputgroup specialInputs">
                  <FormControl
                    id="firstName"
                    placeholder={translate("awc:/.register.first_name")}
                    type="text"
                    required={true}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    readOnly={userFormData?.firstName && true}
                    minLength={2}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.firstName && formik.errors.firstName
                        ? "p-invalid  text-dark input-register"
                        : "text-dark input-register"
                    }
                  />
                </div>
                {formik.touched.firstName && formik.errors.firstName ? (
                  <small id="firstName-invalid" className="p-invalid">
                      {formik.errors.firstName}
                  </small>
                  ) : null}
              </Col>
              <Col xs={12} className="pt-2 negateMarginTop">
                <div className="p-inputgroup specialInputs">
                  <FormControl
                    id="lastName"
                    placeholder={translate("awc:/.register.last_name")}
                    type="text"
                    required={true}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    readOnly={userFormData?.lastName && true}
                    minLength={2}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.lastName && formik.errors.lastName
                        ? "p-invalid  text-dark input-register"
                        : "text-dark input-register"
                    }
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <small id="firstName-invalid" className="p-invalid">
                      {formik.errors.lastName}
                  </small>
                  ) : null}
              </Col>
              {userFormData && (
                <Col xs={12}>
                  <span className="p-input-icon-right w-100">
                    <FormControl
                      id="password"
                      placeholder={translate("awc:/.login.password")}
                      type={isPasswordVisible ? "text": "password"}
                      required={true}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.password && formik.errors.password
                          ? "p-invalid  text-dark input-register mr-2"
                          : "text-dark input-register mr-2"
                      }
                      />
                    <i
                      className={isPasswordVisible ? 'pi pi-eye' : 'pi pi-eye-slash'}
                      style={{ cursor: 'pointer' }}
                      onClick={onPasswordVisible}>
                    </i>
                  </span>
                  {formik.touched.password && formik.errors.password ? (
                    <small id="firstName-invalid" className="p-invalid">
                        {formik.errors.password}
                    </small>
                    ) : null}
                </Col>
              )}
              {!userFormData && termsOfServiceURL && (
              <Col className="pb-2">
              <div className="d-flex align-items-center">
              <Checkbox 
                  inputId="isTOSAccepted" 
                  value={formik.values.isTOSAccepted}
                  onChange={(e: any) => formik.setFieldValue('isTOSAccepted', e.checked)}
                  checked={formik.values.isTOSAccepted}
                  className='terms-checkbox'
                  />
                  <label htmlFor="isTOSAccepted" className="mb-0 pl-2">
                  {translate("awc:/.register.accept")}
                    <a
                      href={
                        termsOfServiceURL ? removeSchemaFromURL(termsOfServiceURL) : '#'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mb-0 pl-1">
                      {translate("awc:/.register.terms_of_service")}
                    </a>
                  </label>
              </div>
                  {formik.touched.isTOSAccepted && formik.errors.isTOSAccepted ? (
                <small id="isTOSAccepted-invalid" className="p-invalid">
                  {formik.errors.isTOSAccepted}
                </small>
              ) : null}
              </Col>
                )} 
            { getRegisterButton() }
            </Row>
          </form>
          <Row>
          <hr />
              <Col xs={12} className="d-flex justify-content-center p-0">
                  <Link
                      to={loginURL}
                  >
                  {translate("awc:/.register.have_account")}
                  </Link>
              </Col>
          </Row>
      </Card>
    )
  }

  /// Get external application card
  const getExternalApplicationCard = () => {
    const logoImageURL: string = externalApplication?.logoImageURL; /**< External Application logoImage URL */
    return (
      <div className={`p-2 externalApplication-registerForm registerForm externalApplication`}>
        <ul className='externalApplication-ul'>
          { logoImageURL ?
            <li>
              <img className={`logoImage invert-logo-background`} src={logoImageURL} alt={translate("awc:/.register.logo_alt")}/>
            </li> : 
            <></>
          }
          <li>
            <b>{externalApplication?.applicationName}</b>
          </li>
          <li>{externalApplication?.description}</li>
          <li>&nbsp;</li>
        </ul>
      </div>
    )
  }

  return (
    <>
      { externalApplication ? 
        <Row className='justify-content-center'>
          <div className={`p-2 externalApplication-registerForm registerForm ${displayLogoClassName} login-pages-global-container`}>
            {getRegisterCard()}
          </div>
          {getExternalApplicationCard()}
        </Row> : 
        <Col className={`p-2 registerForm margin-adjust ${displayLogoClassName} login-pages-global-container`}>
          {getRegisterCard()}
        </Col>
      }
    </>
  );
};

export default RegisterForm;
