/**
* DashboardForm.tsx (abstractuser) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2021

* @file DashboardForm.tsx
* @author Etienne Daher
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminDashboardSkeletonWrapper from '@abstract/abstractwebcommon-client/SkeletonLoader/DashboardSkeleton/AdminDashboard/AdminDashboardSkeletonWrapper';
import { CURRENT_VERSION, CURRENT_AWC_VERSION } from '../../../Config';
import { getSettingsState, ISettingsState } from '../../../Store/SettingsSlice';
import { useSelector } from 'react-redux';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import Dashboard from '@abstract/abstractwebcommon-client/Dashboard';
import { ISoftwareVersionProperties } from '@abstract/abstractwebcommon-client/Dashboard/SoftwareVersion';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

export const DashboardForm = ({
  chartData,
  backendVersion,
  feedsData,
  systemWarnings
}: {
  chartData: any;
  backendVersion: string;
  feedsData: any;
  systemWarnings: any;
}): JSX.Element => {
  const { t } = useTranslation();
  const settingsState: ISettingsState = useSelector(getSettingsState);
  const themeMode = LocalStorage.getThemeMode() || ThemeMode.lightMode;
  const chartDataStatistics = chartData.statistics;

  const isLoading: boolean =
    !chartData.statistics ||
    !chartData.version ||
    !chartData.feedsData ||
    !chartData.systemWarnings;

  const isChartDataStatisticsValid: boolean =
    chartDataStatistics !== undefined && chartDataStatistics !== null;

  const today: number =
    !isLoading && isChartDataStatisticsValid ? chartDataStatistics.todaysCount : 0;
  const lastWeek: number =
    !isLoading && isChartDataStatisticsValid ? chartDataStatistics.lastWeeksCount : 0;
  const lastYear: number =
    !isLoading && isChartDataStatisticsValid ? chartDataStatistics.lastYearsCount : 0;
  const allTimes: number =
    !isLoading && isChartDataStatisticsValid ? chartDataStatistics.allTimesCount : 0;
  const basicData = {
    labels: [
      t('I18N.dashboard.today'),
      t('I18N.dashboard.last_week'),
      t('I18N.dashboard.last_year'),
      t('I18N.dashboard.all_times')
    ],
    datasets: [
      {
        label: 'Total',
        backgroundColor: '#42A5F5',
        data: [today, lastWeek, lastYear, allTimes]
      }
    ]
  };

  const hasApplicationTitle =
    settingsState !== null &&
    settingsState !== undefined &&
    settingsState.safeSettings !== null &&
    settingsState.safeSettings !== undefined &&
    settingsState.safeSettings.applicationTitle !== null &&
    settingsState.safeSettings.applicationTitle !== undefined &&
    settingsState.safeSettings.applicationTitle !== '';

  if (
    !settingsState.safeSettings ||
    !chartData.statistics ||
    !chartData.version ||
    !chartData.feedsData ||
    !chartData.systemWarnings
  ) {
    return <AdminDashboardSkeletonWrapper />;
  }

  const frontendCommitLink = `https://bitbucket.org/TheAbstractCo/abstractuser/commits/${CURRENT_VERSION}`;
  const frontendCurrentVersion = `${CURRENT_VERSION}`;
  const backendCommitLink = `https://bitbucket.org/TheAbstractCo/abstractuser/commits/${backendVersion}`;
  const backendCurrentVersion = backendVersion;
  const awcCommitLink = `https://bitbucket.org/TheAbstractCo/abstractuser/commits/${CURRENT_AWC_VERSION}`;
  const awcCurrentVersion = CURRENT_AWC_VERSION;
  const dashboardTitle: string = t('/admin/dashboard.dashboard_fieldset.welcome', {
    applicationTitle: hasApplicationTitle
      ? settingsState.safeSettings.applicationTitle
      : t('/.page_title')
  }); /**< Dashboard Title. */
  const versionData: ISoftwareVersionProperties = {
    frontendCommitLink: frontendCommitLink,
    frontendCurrentVersion: frontendCurrentVersion,
    backendCommitLink: backendCommitLink,
    backendCurrentVersion: backendCurrentVersion,
    awcCommitLink: awcCommitLink,
    awcCurrentVersion: awcCurrentVersion
  }; /**< Software version data. */

  return (
    <Dashboard
      dashboardTitle={dashboardTitle}
      systemWarnings={systemWarnings}
      softwareVersion={versionData}
      feedsData={feedsData}
      isLoading={isLoading}
      themeMode={themeMode}
      systemData={basicData}
      systemDataNotes={t('I18N.dashboard.note_text')}
    />
  );
};

export default DashboardForm;
