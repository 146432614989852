/**
* AddressRowExpansionTemplate.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file AddressRowExpansionTemplate.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
* @modified Rafael Rodrigues - 2022
*/
import React from 'react';
import { translate } from '../../../../Utils/Translate';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { Country } from 'country-state-city';

/**
 * @interface IAddressRowExpansionTemplateProperties
 */
interface IAddressRowExpansionTemplateProperties {
  rowData: IAddress /**< The current row data clicked */;
}
const AddressRowExpansionTemplate = ({
  rowData
}: IAddressRowExpansionTemplateProperties): JSX.Element => {
  const RenderExpansionRows = () => (
    <>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.id')}</th>
        <td>{rowData.addressUUID}</td>
      </tr>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.address')}</th>
        <td>{rowData.address}</td>
      </tr>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.addressTwo')}</th>
        <td>{rowData.addressTwo}</td>
      </tr>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.city')}</th>
        <td>{rowData.city}</td>
      </tr>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.zipCode')}</th>
        <td>{rowData.zipCode}</td>
      </tr>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.stateOrProvince')}</th>
        <td>{rowData.stateOrProvince}</td>
      </tr>
      <tr>
        <th>{translate('/admin.address_fieldset.datatable.columns.country')}</th>
        <td>{Country.getCountryByCode(rowData.country)?.name}</td>
      </tr>
    </>
  );

  return (
    <>
      <ExpansionRow>
        <RenderExpansionRows />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <RenderExpansionRows />
      </ExpansionRow>
    </>
  );
};

export default AddressRowExpansionTemplate;
