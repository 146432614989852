/**
 * SettingsSlice.ts (abstractuser)
 * Copyright © 2020 InstaMaterial GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all it's contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 * @file SettingsSlice.ts
 * @author Sai Charan K
 * @copyright 2020 InstaMaterial GmbH. All rights reserved.
 * @section License
 */

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import {
  getApplicationEnvironmentVariablesAPI,
  getAppSettingsAPI,
  getFavouriteIconAPI,
  getLogoAPI,
  getSafeAppSettingsAPI,
  testSMTPActionAPI,
  updateApplicationSettingsAPI,
  uploadFavouriteIconApi,
  uploadLogoApi,
  getLoginPagesInformationSettingsAPI
} from '../Services/SettingsApi';
import { getRoleByID } from '../Services/RolesApi';
import i18n from '../Services/I18n';
import { Reducer } from 'react';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IGetFavouriteIconResponse,
  IGetLogoResponse,
  IGetSafeSettingsResponse,
  IGetSettingsResponse,
  IImageUploadResponse,
  IRoleUserResponse
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { ITemplates } from '@abstract/abstractwebcommon-shared/interfaces/user/templates';
import { IPlaceholder } from '@abstract/abstractwebcommon-shared/interfaces/user/placeholder';
import {
  IAPIEntityResponse,
  IAPIErrorData
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/user/environmentVariables';
import { IUpdatedSettingsPayload } from './Interfaces';
import { fetchSystemWarningsAction } from './DashboardSlice';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { IPublicLoginInformation } from '@abstract/abstractwebcommon-shared/interfaces/user/settings';

export const settingsFeatureKey: string = 'settings';

/**
 * Interface AUTH_INITIAL_STATE
 */
export interface ISettingsState {
  smtpSettings: any;
  safeSettings: any;
  environmentVariables: any;
  smtpSettingsError: any;
  isLoading: boolean;
  isLoadingTest: boolean;
  favouriteIconUrl: string | ArrayBuffer | null;
  uploadFavouriteIconError: any;
  isFavouriteIconLoading: boolean;
  logoUrl: string | ArrayBuffer | null;
  resizedLogoUrl: string | ArrayBuffer | null;
  uploadLogoError: any;
  isLogoLoading: boolean;
  applicationSettings: any;
  applicationSettingsError: any;
  applicationEnvironmentVariableError: any;
  safeSettingsError: any;
  isApplicationSettingsUpdated: boolean;
  testSMTPError: any;
  testSMTPResponse: string;
  errorHandlerTemplate?: ITemplates /** Get error handler template */;
  placeholdersErrorHandler?: IPlaceholder[] /** Get placeholders */;
  loginPageInformation: IPublicLoginInformation | null /** Defines the object to be used in the login pages (pp.tsx, NotFound.tsx, AuthenticationVerificationPage.tsx, Login.tsx, Register.tsx, VerifyPage.tsx) */;
}

export const settingsInitialState: ISettingsState = {
  smtpSettings: {},
  environmentVariables: null,
  safeSettings: null,
  smtpSettingsError: null,
  isLoading: false,
  isLoadingTest: false,
  favouriteIconUrl: '',
  uploadFavouriteIconError: null,
  isFavouriteIconLoading: false,
  logoUrl: '',
  resizedLogoUrl: '',
  uploadLogoError: null,
  isLogoLoading: false,
  applicationSettings: null,
  applicationSettingsError: null,
  applicationEnvironmentVariableError: null,
  isApplicationSettingsUpdated: false,
  safeSettingsError: null,
  testSMTPError: null,
  testSMTPResponse: '',
  loginPageInformation: null
};

export const settingsAdapter: EntityAdapter<ISettingsState> = createEntityAdapter();
export const initialSettingsState: EntityState<ISettingsState> & ISettingsState =
  settingsAdapter.getInitialState(settingsInitialState);

export const getLogoAction = createAsyncThunk(
  'settings/get/logo',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const getLogoActionRequest: any = settingsActions.getLogoActionRequest;
    const getLogoActionSuccess: any = settingsActions.getLogoActionSuccess;
    const getLogoActionFailure: any = settingsActions.getLogoActionFailure;

    try {
      dispatch(getLogoActionRequest());
      const result: IAPIEntityResponse<IGetLogoResponse> = await asyncErrorHandler(getLogoAPI());
      if (result.error) {
        dispatch(getLogoActionFailure(result.error));
      } else {
        LocalStorage.setLogoImageUrl(result.data.resizedLogoImageUrl);
        dispatch(getLogoActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getLogoActionFailure(error.message));
    }
  }
);

export const getFavouriteIconAction = createAsyncThunk(
  'settings/get/favouriteIcon',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const getFavouriteIconActionRequest: any = settingsActions.getFavouriteIconActionRequest;
    const getFavouriteIconActionSuccess: any = settingsActions.getFavouriteIconActionSuccess;
    const getFavouriteIconActionFailure: any = settingsActions.getFavouriteIconActionFailure;
    try {
      dispatch(getFavouriteIconActionRequest());
      const result: IAPIEntityResponse<IGetFavouriteIconResponse> = await asyncErrorHandler(
        getFavouriteIconAPI()
      );
      if (result.error) {
        dispatch(getFavouriteIconActionFailure(result.error));
      } else {
        dispatch(getFavouriteIconActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getFavouriteIconActionFailure(error.message));
    }
  }
);

/**
 * Get application setting Action.
 */

export const getAppSettingsAction = createAsyncThunk(
  'settings/appsettings/get',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getAppSettingsActionRequest,
      getAppSettingsActionSuccess,
      getAppSettingsActionFailure
    } = settingsActions;
    try {
      dispatch(getAppSettingsActionRequest());
      const result: IAPIEntityResponse<IGetSettingsResponse> = await asyncErrorHandler(
        getAppSettingsAPI()
      );
      if (result.error) {
        if (result.status === 401) {
          dispatch(getAppSettingsActionFailure(result));
        } else {
          dispatch(getAppSettingsActionFailure(result.error));
        }
      } else {
        dispatch(getAppSettingsActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getAppSettingsActionFailure(error.message));
    }
  }
);

/**
 * Get application environment variables Action.
 */
export const getApplicationEnvironmentVariablesAction = createAsyncThunk(
  'settings/environment/variable/get',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getAppEnvironmentVariablesActionRequest,
      getAppEnvironmentVariablesActionSuccess,
      getAppEnvironmentVariablesActionFailure
    } = settingsActions;
    try {
      dispatch(getAppEnvironmentVariablesActionRequest());
      const result: IAPIEntityResponse<IPublicEnvironmentVariables> = await asyncErrorHandler(
        getApplicationEnvironmentVariablesAPI()
      );
      if (result.error) {
        dispatch(getAppEnvironmentVariablesActionFailure(result.error));
      } else {
        dispatch(getAppEnvironmentVariablesActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getAppEnvironmentVariablesActionFailure(error.message));
    }
  }
);

/**
 * Get safe application setting Action.
 */
export const getSafeAppSettingsAction = createAsyncThunk(
  'settings/appsettings/get/safe',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getSafeAppSettingsActionRequest,
      getSafeAppSettingsActionSuccess,
      getSafeAppSettingsActionFailure
    } = settingsActions;
    try {
      dispatch(getSafeAppSettingsActionRequest());
      const result: IAPIEntityResponse<IGetSafeSettingsResponse> = await asyncErrorHandler(
        getSafeAppSettingsAPI()
      );
      if (result.error) {
        dispatch(getSafeAppSettingsActionFailure(result.error));
      } else {
        dispatch(getSafeAppSettingsActionSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getSafeAppSettingsActionFailure(error.message));
    }
  }
);

/**
 * Get login pages information Action.
 */
export const getLoginPagesInformationAction = createAsyncThunk(
  'settings/get/login/page/information/',
  async (applicationUUID: string | null, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getLoginPagesInformationRequest,
      getLoginPagesInformationSuccess,
      getLoginPagesInformationFailure
    } = settingsActions;
    try {
      dispatch(getLoginPagesInformationRequest());
      const result: IAPIEntityResponse<IPublicLoginInformation> = await asyncErrorHandler(
        getLoginPagesInformationSettingsAPI(applicationUUID)
      );
      if (result.error) {
        dispatch(getLoginPagesInformationFailure(result.error));
      } else {
        dispatch(getLoginPagesInformationSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getLoginPagesInformationFailure(error.message));
    }
  }
);

/**
 * Update application setting Action.
 */
export const updateSettingsAction = createAsyncThunk(
  'settings/smtp/update',

  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      updateSettingsActionRequest,
      updateSettingsActionSuccess,
      updateSettingsActionFailure,
      uploadLogoActionFailure,
      uploadFavouriteIconActionFailure
    } = settingsActions;
    try {
      dispatch(updateSettingsActionRequest());

      const application: any = payload.application;
      const uploadLogo: any = payload.uploadLogo;
      const deleteLogo: any = payload.deleteLogo;
      const uploadFavouriteIcon: any = payload.uploadFavouriteIcon;
      const deleteFavouriteIcon: any = payload.deleteFavouriteIcon;

      let updatedApp: any, updatedSmtp: any;

      if (uploadLogo) {
        const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadLogoApi(uploadLogo.file)
        );
        if (result.status === 200) {
          application['logoImageName'] = result.data.imageName;
        }
        if (result.error) {
          return dispatch(uploadLogoActionFailure(result.error));
        }
      }
      if (deleteLogo) {
        application['logoImageName'] = '';
      }
      if (uploadFavouriteIcon) {
        const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadFavouriteIconApi(uploadFavouriteIcon.file)
        );
        if (result.status === 200) {
          application['favouriteIconImageName'] = result.data.imageName;
        }
        if (result.error) {
          return dispatch(uploadFavouriteIconActionFailure(result.error));
        }
      }
      if (deleteFavouriteIcon) {
        application['favouriteIconImageName'] = '';
      }

      if (application && application.adminRoleUUID) {
        const result: IAPIEntityResponse<IRoleUserResponse> = await asyncErrorHandler(
          getRoleByID(application.adminRoleUUID)
        );
        if (result.error) {
          return dispatch(
            updateSettingsActionFailure({ error: i18n.t('I18N.error_messages.role_not_found') })
          );
        }
        if (!result.data.isUserRole) {
          return dispatch(
            updateSettingsActionFailure({
              error: i18n.t('I18N.error_messages.user_role_fetch_error')
            })
          );
        }
      }
      if (application) {
        const result: IAPIEntityResponse<IGetSettingsResponse> = await asyncErrorHandler(
          updateApplicationSettingsAPI(application)
        );
        if (result.error) {
          return dispatch(updateSettingsActionFailure(result.error));
        }
        LocalStorage.setLogoImageUrl(result.data.resizedLogoImageUrl);
        updatedApp = result.data;
      }
      dispatch(
        updateSettingsActionSuccess({
          updatedApp,
          updatedSmtp
        })
      );
      dispatch(fetchSystemWarningsAction());
      dispatch(getSafeAppSettingsAction());
      return;
    } catch (error: any) {
      dispatch(updateSettingsActionFailure(error.message));
    }
  }
);

export const testSMTPAction = createAsyncThunk(
  'settings/smtp/test',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { testSMTPActionRequest, testSMTPActionSuccess, testSMTPActionFailure } = settingsActions;
    try {
      dispatch(testSMTPActionRequest());
      const result: IAPIEntityResponse<string> = await asyncErrorHandler(
        testSMTPActionAPI(payload)
      );
      if (result.error) {
        dispatch(testSMTPActionFailure(result.error));
      } else {
        dispatch(testSMTPActionSuccess(result.data));
        dispatch(fetchSystemWarningsAction());
      }
    } catch (error: any) {
      dispatch(testSMTPActionFailure(error.message));
    }
  }
);

const clearOnRequest = (state: ISettingsState) => {
  state.uploadLogoError = null;
  state.uploadFavouriteIconError = null;
  state.applicationSettingsError = null;
  state.applicationEnvironmentVariableError = null;
  state.testSMTPError = null;
  state.safeSettingsError = null;
  state.isApplicationSettingsUpdated = false;
  state.testSMTPResponse = '';
};

/**
 * Creates Slice - All Settings related state will be stored here
 */
export const settingsSlice = createSlice({
  name: settingsFeatureKey,
  initialState: initialSettingsState,
  reducers: {
    reset: (state: any) => clearOnRequest(state),
    getAppSettingsActionRequest(state: any) {
      clearOnRequest(state);
      state.isLoading = true;
    },
    getAppSettingsActionSuccess(state: any, action: IReducerAction<IGetSettingsResponse>) {
      state.isLoading = false;
      state.applicationSettings = action.payload;
      state.logoUrl = action.payload.logoImageUrl;
      state.favouriteIconUrl = action.payload.favouriteIconImageUrl;
    },
    getAppSettingsActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.applicationSettingsError =
        action.payload.error || i18n.t('I18N.error_messages.app_settings_fetch_error');
    },
    getAppEnvironmentVariablesActionRequest(state: any) {
      clearOnRequest(state);
      state.isLoading = true;
      state.environmentVariables = null;
    },
    getAppEnvironmentVariablesActionSuccess(
      state: any,
      action: IReducerAction<IPublicEnvironmentVariables>
    ) {
      state.isLoading = false;
      state.environmentVariables = action.payload;
    },
    getAppEnvironmentVariablesActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.applicationEnvironmentVariableError =
        action.payload.error || i18n.t('I18N.error_messages.environment_variables_fetch_error');
    },
    getSafeAppSettingsActionRequest(state: any) {
      clearOnRequest(state);
      state.isLoading = true;
    },
    getSafeAppSettingsActionSuccess(state: any, action: IReducerAction<IGetSafeSettingsResponse>) {
      state.isLoading = false;
      state.safeSettings = action.payload;
    },
    getSafeAppSettingsActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.safeSettingsError =
        action.payload.error || i18n.t('I18N.error_messages.app_settings_fetch_error');
    },
    getLoginPagesInformationRequest(state: ISettingsState) {
      clearOnRequest(state);
      state.isLoading = true;
    },
    getLoginPagesInformationSuccess(
      state: ISettingsState,
      action: IReducerAction<IPublicLoginInformation>
    ) {
      state.isLoading = false;
      state.loginPageInformation = action.payload;
      // NOTE: Currently, only the root application is using this local storage key/value.
      LocalStorage.setRootApplication(
        JSON.stringify(action.payload.applicationDetails.isRootApplication)
      );
    },
    getLoginPagesInformationFailure(state: ISettingsState) {
      state.isLoading = false;
    },
    testSMTPActionRequest(state: any) {
      clearOnRequest(state);
      state.isLoadingTest = true;
    },
    testSMTPActionSuccess(state: any, action: IReducerAction<string>) {
      state.isLoadingTest = false;
      state.testSMTPResponse = action.payload;
      state.testSMTPError = null;
    },
    testSMTPActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoadingTest = false;
      state.testSMTPError = action.payload || i18n.t('I18N.error_messages.smtp_test_error');
    },
    uploadLogoActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.uploadLogoError =
        action.payload.error || i18n.t('I18N.error_messages.upload_logo_error');
    },
    uploadFavouriteIconActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.uploadFavouriteIconError =
        action.payload.error || i18n.t('I18N.error_messages.upload_logo_error');
    },
    deleteLogoActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.uploadLogoError =
        action.payload.error || i18n.t('I18N.error_messages.delete_logo_error');
    },
    deleteFavouriteIconActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.uploadFavouriteIconError =
        action.payload.error || i18n.t('I18N.error_messages.delete_logo_error');
    },
    getLogoActionRequest(state: any) {
      clearOnRequest(state);
      state.isLogoLoading = true;
      state.logoUrl = '';
    },
    getLogoActionSuccess(state: any, action: IReducerAction<IGetLogoResponse>) {
      state.logoUrl = action.payload.logoImageUrl;
      state.resizedLogoUrl = action.payload.resizedLogoImageUrl;
      state.isLogoLoading = false;
    },
    getLogoActionFailure(state: any) {
      state.logoUrl = '';
      state.isLogoLoading = false;
    },
    getFavouriteIconActionRequest(state: any) {
      clearOnRequest(state);
      state.isFavouriteIconLoading = true;
      state.favouriteIconUrl = '';
    },
    getFavouriteIconActionSuccess(state: any, action: IReducerAction<IGetFavouriteIconResponse>) {
      state.favouriteIconUrl = action.payload;
      state.isFavouriteIconLoading = false;
    },
    getFavouriteIconActionFailure(state: any) {
      state.favouriteIconUrl = '';
      state.isFavouriteIconLoading = false;
    },
    updateSettingsActionRequest(state: any) {
      clearOnRequest(state);
      state.isLoading = true;
    },
    updateSettingsActionSuccess(state: any, action: IReducerAction<IUpdatedSettingsPayload>) {
      state.isLoading = false;
      if (action.payload.updatedApp) {
        state.applicationSettings = action.payload.updatedApp;
        state.logoUrl = action.payload.updatedApp.logoImageUrl;
        state.favouriteIconUrl = action.payload.updatedApp.favouriteIconImageUrl;
      }
      if (action.payload.updatedSmtp) {
        state.smtpSettings = action.payload.updatedSmtp;
      }
      state.isApplicationSettingsUpdated = true;
    },
    updateSettingsActionFailure(state: any, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.applicationSettingsError =
        action.payload.error || i18n.t('I18N.error_messages.app_settings_update_error');
    }
  }
});

export const settingsReducer: Reducer<EntityState<ISettingsState> & ISettingsState, AnyAction> =
  settingsSlice.reducer;

export const settingsActions: any = settingsSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = settingsAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getSettingsState: any = (rootState: any) => rootState[settingsFeatureKey];
export const selectAllSettings: any = createSelector(getSettingsState, selectAll);
export const selectSettingsEntities: any = createSelector(getSettingsState, selectEntities);
