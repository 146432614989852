/*
 * ActionButtons.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file ActionButtons.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

interface IActionButtonProperties {
  onCancel: () => void;
  cancelLabel: string;
  submitLabel: string;
  cancelBtnIcon?: string;
  submitBtnIcon?: string;
}

const ActionButtons = ({
  onCancel,
  cancelLabel,
  submitLabel,
  cancelBtnIcon,
  submitBtnIcon,
}: IActionButtonProperties): ReactElement => {
  return (
    <Col xs={12} className="d-flex justify-content-start p-0">
      <Button
        type="button"
        className="mr-3 d-flex align-items-center action-buttons-container w-auto"
        onClick={onCancel}
        variant="danger"
      >
        <i
          className={`${
            cancelBtnIcon ? cancelBtnIcon : "bi bi-x-circle"
          } btn-icon`}
        ></i>
        {cancelLabel}
      </Button>

      <Button className="d-flex align-items-center action-buttons-container w-auto" type="submit">
        <i
          className={`${
            submitBtnIcon ? submitBtnIcon : "bi bi-check2-circle"
          } btn-icon`}
        ></i>
        {submitLabel}
      </Button>
    </Col>
  );
};

export default ActionButtons;
