/**
* LogSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file LogSlice.ts
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { deleteLogsAPI, exportLogsAPI, getLogsApi } from '../Services/LogApi';
import i18n from '../Services/I18n';
import { Reducer } from 'react';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ILog } from '@abstract/abstractwebcommon-shared/interfaces/user/log';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

export const logFeatureKey: string = 'logs';

/**
 * Interface logInitialState
 */
export interface ILogState {
  logs: any[];
  totalRecords: number;
  logError: any;
  isLoading: boolean;
  isLogExported: boolean;
  isExportingLog: boolean /**< Is exporting log */;
  isRequestingDeleteLogsEndpoint:
    | boolean
    | null /**< Defines if the endpoint to delete logs is being executed */;
  isDeleteLogsEndpointSuccess:
    | boolean
    | null /**< Defines if the endpoint to delete returned a success status code */;
}

export const logInitialState: ILogState = {
  logs: null,
  totalRecords: 0,
  logError: null,
  isLoading: false,
  isLogExported: false,
  isExportingLog: false,
  isRequestingDeleteLogsEndpoint: null,
  isDeleteLogsEndpointSuccess: null
};

export const logAdapter: EntityAdapter<ILogState> = createEntityAdapter();
export const initialLogState: EntityState<ILogState> & ILogState =
  logAdapter.getInitialState(logInitialState);

export const getLogsListAction: any = createAsyncThunk('logs/get', async (payload: any) => {
  const response: PaginatedAPIEntityResponse<ILog> = await asyncErrorHandler(getLogsApi(payload));
  return response;
});

export const updateLogErrorAction: any = createAsyncThunk(
  'logs/logError/update',
  async (payload: any) => {
    return payload;
  }
);

export const exportLogsAction: any = createAsyncThunk('logs/export', async (payload: any) => {
  const response: void | IAPIErrorData = await asyncErrorHandler(exportLogsAPI(payload));
  return response;
});

export const deleteLogsAction: any = createAsyncThunk(
  'logs/delete',
  async (deleteOption: string) => {
    const response: IAPIEntityResponse<ILog> = await asyncErrorHandler(deleteLogsAPI(deleteOption));
    return response;
  }
);

const clearErrors = (state: ILogState) => {
  state.isLogExported = false;
  state.logError = null;
  state.isRequestingDeleteLogsEndpoint = null;
  state.isDeleteLogsEndpointSuccess = null;
};

/**
 * Creates Slice - All Auth related state will be stored here
 */
export const logSlice = createSlice({
  name: logFeatureKey,
  initialState: initialLogState,
  reducers: {
    reset: (state: ILogState) => clearErrors(state)
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogsListAction.pending, (state: ILogState) => {
        state.isLoading = true;
      })
      .addCase(
        getLogsListAction.fulfilled,
        (state: ILogState, action: IReducerAction<PaginatedAPIEntityResponse<ILog>>) => {
          if (action.payload && action.payload.status === 200) {
            state.logs = action.payload.data.records;
            state.totalRecords = action.payload.data.totalRecords;
          } else {
            state.logError =
              action.payload.error || i18n.t('I18N.error_messages.fetch_log_failure');
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateLogErrorAction.fulfilled,
        (state: ILogState, action: IReducerAction<IAPIErrorData>) => {
          state.logError = action.payload || null;
          state.isLoading = false;
        }
      )
      .addCase(exportLogsAction.pending, (state: ILogState) => {
        state.isExportingLog = true;
      })
      .addCase(
        exportLogsAction.fulfilled,
        (state: ILogState, action: IReducerAction<IAPIErrorData>) => {
          if (action.payload && action.payload.error) {
            state.logError =
              action.payload.error.message || i18n.t('I18N.error_messages.export_log_failure');
          } else {
            state.isLogExported = true;
          }
          state.isExportingLog = false;
        }
      )
      .addCase(
        exportLogsAction.rejected,
        (state: ILogState, action: IReducerAction<IAPIErrorData>) => {
          if (action.error) {
            state.logError =
              action.error.message || i18n.t('I18N.error_messages.export_log_failure');
          }
          state.isExportingLog = false;
        }
      )
      .addCase(deleteLogsAction.pending, (state: ILogState) => {
        state.isRequestingDeleteLogsEndpoint = true;
      })
      .addCase(
        deleteLogsAction.fulfilled,
        (state: ILogState, action: IReducerAction<IAPIEntityResponse<ILog>>) => {
          state.isRequestingDeleteLogsEndpoint = false;
          state.isDeleteLogsEndpointSuccess = true;
          if (action.payload.status !== 200) {
            handleError({
              message: 'An error occurred while trying prune logs.'
            });
          } else {
            showSuccessToast(action.payload.message);
          }
        }
      )
      .addCase(deleteLogsAction.rejected, (state: ILogState) => {
        state.isRequestingDeleteLogsEndpoint = false;
        state.isDeleteLogsEndpointSuccess = false;
        handleError({
          message:
            'An error occurred while trying prune logs. Please refresh the page and check recent logs.'
        });
      });
  }
});

export const logReducer: Reducer<EntityState<ILogState> & ILogState, AnyAction> = logSlice.reducer;

export const logActions: any = logSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = logAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getLogState: any = (rootState: any) => rootState[logFeatureKey];
export const selectAllLogs: any = createSelector(getLogState, selectAll);
export const selectLogEntities: any = createSelector(getLogState, selectEntities);
