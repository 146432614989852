/**
* ClientContent.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ClientContent.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getClientRoutes } from '../../Routes/ClientRoutes';
import { getAuthState, IAuthState } from '../../Store/AuthSlice';
import Col from 'react-bootstrap/Col';
import PageHeader from '@abstract/abstractwebcommon-client/PageHeader/PageHeader';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

const ClientContent = (): JSX.Element => {
  const authState: IAuthState = useSelector(getAuthState);
  const languageSettingsMode: string =
    LocalStorage.getLanguageSettingsMode() ||
    LanguageSettingsMode.english; /**< Language settings mode */
  const [routes, setRoutes] = useState<any>([]); /**< Routes */

  // Get client routes when language changes
  useEffect(() => {
    const ClientRoutes = getClientRoutes();
    setRoutes([...ClientRoutes]);
  }, [languageSettingsMode]);

  //Note: Get client routes when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const clientRoutes = getClientRoutes();
      setRoutes([...clientRoutes]);
    }
  });

  /// If not authenticated, redirect to login.
  const renderComponent = routes.map(({ Component, path, headingKey }, key) => {
    if (!authState.isAuthenticated) {
      return <Redirect key={key} to={{ pathname: SharedCommomRouteName.loginRoute }} />;
    }

    return (
      <Route exact path={path} key={key}>
        {headingKey ? <PageHeader title={headingKey} /> : null}
        <Col sm={12}>
          <Component />
        </Col>
      </Route>
    );
  });

  return <Switch>{renderComponent}</Switch>;
};

export default ClientContent;
