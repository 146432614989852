import React, { ReactElement, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import Alert from "react-bootstrap/Alert";
import { translate } from '../utils/translate';
import "./index.css";
import { formatDate } from "@abstract/abstractwebcommon-shared/utils/sharedFunctions";

/**
 * IVerifyButtonProperties interface
 */
 export interface IVerifyButtonProperties {
    isLoading?: boolean; /**< Whether the form is loading. */
    onResendVerification?: (data: IUser) => void; /**< Function called to resend verification. */
    verifyOnDate: Date; /**< Date a user can send verification. */
    userData: IUser; /**< User information. */
    buttonText: string; /**< Button text. */
    
  }

const VerifyButton = ({
    isLoading,
    onResendVerification,
    verifyOnDate,
    userData,
    buttonText
}: IVerifyButtonProperties): ReactElement => {

    if(verifyOnDate) {
        return (
            <Row>
              <Col xs={12} className="pb-0">
                    <Alert variant={"info"} className="mb-0 mt-2">
                      <p className="mb-0">
                      {translate('awc:/.register.verification_link_extra_information', { date: formatDate(verifyOnDate, { isTime: true })})}
                      </p>
                    </Alert>
              </Col>
            </Row>
        )
    }
    return (
    <Button
    disabled={isLoading}
    className="btn-block"
    variant="primary"
    onClick={() => {
      onResendVerification(userData)
    }}
    >
    {isLoading ? (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      />
    ) : (
        buttonText
      )
    }
    </Button>
    )
}

export default VerifyButton;