/**
* ProfileSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2020 

* @file ProfileSlice.ts
* @author Etienne Daher
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState
} from '@reduxjs/toolkit';
import {
  createOrUpdateAddressAPI,
  deleteAddress,
  getAddress,
  getProfileApi,
  updatePasswordApi,
  updateProfileApi
} from '../Services/ProfileApi';
import { getApplications, getUserApplications } from '../Services/ApplicationApi';
import { getRolesDropdown } from '../Services/RolesApi';
import i18n from '../Services/I18n';
import { uploadProfilePictureAction, userActions } from './UserSlice';
import { Reducer } from 'react';
import { manualUpdateAuthAction } from './AuthSlice';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import { translate } from '../Utils/Translate';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import {
  IGetProfileResponse,
  IUpdateProfileResponse,
  IUser
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { getApplicationsByUserId } from './ApplicationSlice';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const profileFeatureKey: string = 'profile';

/**
 * Interface profileInitialState_INTERFACE
 */
export interface IProfileState {
  isUpdated: boolean;
  updateError: any;
  isPasswordUpdated: boolean;
  passwordUpdateError: any;
  isLoading: boolean;
  profile: any;
  role: any[];
  applications: any[];
  isProfilePageLoading: boolean;
  isFetchingRoles: boolean;
  errorMessage: string | null /**< Error response message */;
  successMessage: string | null /**< Success response message */;
  isLoadingRequest: boolean /**< checks for any address API request. True if there is, false otherwise  */;
  address: IAddress[] /**< Array of user address */;
  totalRecords: number /**< Number of records  */;
}

export const profileInitialState: IProfileState = {
  isUpdated: false,
  updateError: null,
  isLoading: false,
  profile: null,
  role: [],
  applications: [],
  isProfilePageLoading: false,
  isPasswordUpdated: false,
  passwordUpdateError: null,
  isFetchingRoles: false,
  errorMessage: null,
  successMessage: null,
  isLoadingRequest: false,
  address: null,
  totalRecords: 0
};

export const profileAdapter: EntityAdapter<IProfileState> = createEntityAdapter();
export const initialProfileState: EntityState<IProfileState> & IProfileState =
  profileAdapter.getInitialState(profileInitialState);

export const updateProfileAction = createAsyncThunk(
  'profile/update',
  async (payload: any, thunkAPI) => {
    const userUUID: string = payload.userUUID;
    delete payload.userUUID;
    delete payload.type;
    const dispatch: any = thunkAPI.dispatch;
    const resetUploadStatus: any = userActions.resetUploadStatus;
    await asyncErrorHandler(dispatch(resetUploadStatus()));
    if (payload.imageName) {
      const imageResponse: { payload: IAPIEntityResponse<IImageUploadResponse> } =
        await asyncErrorHandler(
          dispatch(uploadProfilePictureAction({ file: payload.imageName, userUUID: userUUID }))
        );
      if (imageResponse.payload.status === 200) {
        payload['imageName'] = imageResponse.payload.data.imageName;
      } else {
        return imageResponse.payload;
      }
    }
    const response: IAPIEntityResponse<IUpdateProfileResponse> = await asyncErrorHandler(
      updateProfileApi(payload, userUUID)
    );
    if (
      response !== undefined &&
      response.status === 200 &&
      response.data !== undefined &&
      response.data.userUUID === LocalStorage.getXUserUUID()
    ) {
      if (response.data.isRoleUpdated) {
        const isAdmin: boolean = response.data.isAdmin;
        LocalStorage.setAdmin(JSON.stringify(isAdmin)); // Set isAdmin flag
        dispatch(manualUpdateAuthAction({ isAdmin }));
      }
      if (response.data.isApplicationUpdated) {
        dispatch(getApplicationsByUserId({}));
      }
    }
    return response;
  }
);

export const updatePasswordAction = createAsyncThunk(
  'profile/password/update',
  async (payload: any) => {
    delete payload.type;
    delete payload.passwordRepeat;
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(updatePasswordApi(payload));
    return response;
  }
);

export const getProfileAction = createAsyncThunk('profile/', async (payload: any, thunkAPI) => {
  const dispatch: any = thunkAPI.dispatch;
  const getProfileActionRequest: any = profileActions.getProfileActionRequest;

  dispatch(getProfileActionRequest({}));
  const apiResponse: IAPIEntityResponse<IGetProfileResponse> = await asyncErrorHandler(
    getProfileApi()
  );
  if (apiResponse && apiResponse.status === 200) {
    return apiResponse.data;
  } else {
    throw apiResponse.error;
  }
});

export const getRolesAction = createAsyncThunk('users/roles/get', async (payload: any) => {
  const response: IAPIEntityResponse<IRole[]> = await asyncErrorHandler(getRolesDropdown(payload));
  return response;
});

export const getApplicationsAction = createAsyncThunk(
  'users/applications/get',
  async (isAdmin: boolean) => {
    const applications: IApplications[] = []; /**< Get applications for profile page */
    if (isAdmin) {
      // If user is admin, fetch all applications.
      const response: PaginatedAPIEntityResponse<IApplications> = await asyncErrorHandler(
        getApplications({
          limit: 0
        })
      ); /**< All applications */
      if (response && response.status === 200) {
        response.data.records.map((application: IApplications) => {
          applications.push(application);
        });
      }
    } // Otherwise, fetch userApplications
    else {
      const userUUID: string =
        LocalStorage.getXUserUUID() || ''; /**< Get UserId from localstorage. */
      const response: IAPIEntityResponse<IApplications[]> = await asyncErrorHandler(
        getUserApplications(userUUID)
      ); /**< UserApplications */
      if (response && response.status === 200) {
        response.data.map((application: IApplications) => {
          applications.push(application);
        });
      }
    }
    return applications;
  }
);

export const createAddressAction = createAsyncThunk(
  'profile/address/create',
  async (payload: IAddress) => {
    const response: IAPIEntityResponse<IAddress> = await asyncErrorHandler(
      createOrUpdateAddressAPI(payload)
    );
    return response;
  }
);

export const updateAddressAction = createAsyncThunk(
  'profile/address/update',
  async (payload: IAddress) => {
    const addressUUID: string = payload.addressUUID;
    delete payload.addressUUID;
    const response: IAPIEntityResponse<IAddress> = await asyncErrorHandler(
      createOrUpdateAddressAPI(payload, addressUUID)
    );
    return response;
  }
);

export const getAddressAction = createAsyncThunk(
  'profile/address/get',
  async (payload: ITablePayload) => {
    const response: IAPIEntityResponse<IAddress[]> = await asyncErrorHandler(getAddress(payload));
    return response;
  }
);

export const deleteAddressAction = createAsyncThunk(
  'profile/address/delete',
  async (payload: IAddress[]) => {
    const response: IAPIEntityResponse<IAddress> = await asyncErrorHandler(deleteAddress(payload));
    return response;
  }
);

const clearErrors = (state: IProfileState) => {
  state.isUpdated = false;
  state.updateError = null;
  state.isPasswordUpdated = false;
  state.passwordUpdateError = null;
  state.errorMessage = null;
  state.successMessage = null;
};

/**
 * Creates Slice - All User related state will be stored here
 */
export const profileSlice = createSlice({
  name: profileFeatureKey,
  initialState: initialProfileState,
  reducers: {
    reset: (state: IProfileState) => clearErrors(state),
    getProfileActionRequest(state: IProfileState) {
      state.updateError = null;
      state.isUpdated = false;
      state.isLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePasswordAction.pending, (state: IProfileState) => {
        state.isLoading = true;
        state.isPasswordUpdated = false;
        state.passwordUpdateError = null;
      })
      .addCase(
        updatePasswordAction.fulfilled,
        (state: IProfileState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          state.isLoading = false;
          if (action.payload.status === 200) {
            state.isPasswordUpdated = true;
            state.passwordUpdateError = null;
          } else {
            state.isPasswordUpdated = false;
            state.passwordUpdateError = action.payload.error;
          }
        }
      )
      .addCase(
        updatePasswordAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.isPasswordUpdated = false;
          state.passwordUpdateError =
            action.error || translate('I18N.error_messages.update_profile_failure');
        }
      )
      .addCase(updateProfileAction.pending, (state: IProfileState) => {
        state.isProfilePageLoading = true;
        state.updateError = null;
        state.isUpdated = false;
      })
      .addCase(
        updateProfileAction.fulfilled,
        (
          state: IProfileState,
          action: IReducerAction<IAPIEntityResponse<IAPIEntityResponse<IUpdateProfileResponse>>>
        ) => {
          state.isProfilePageLoading = false;
          if (action.payload && action.payload.status === 200) {
            state.updateError = null;
            state.isUpdated = true;
            state.profile = action.payload.data;
          } else {
            state.updateError =
              action.payload.error || i18n.t('I18N.error_messages.update_profile_failure');
            state.isUpdated = false;
          }
        }
      )
      .addCase(
        updateProfileAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isProfilePageLoading = false;
          state.updateError = action.error || i18n.t('I18N.error_messages.update_profile_failure');
          state.isUpdated = false;
        }
      )
      .addCase(getProfileAction.pending, (state: IProfileState) => {
        state.isLoading = false;
        state.updateError = null;
      })
      .addCase(
        getProfileAction.fulfilled,
        (state: IProfileState, action: IReducerAction<IGetProfileResponse>) => {
          state.isLoading = false;
          state.updateError = null;
          state.profile = action.payload;
        }
      )
      .addCase(
        getProfileAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoading = false;
          state.updateError = action.error || 'Error in loading profile';
        }
      )
      .addCase(getRolesAction.pending, (state: IProfileState) => {
        state.isFetchingRoles = true;
      })
      .addCase(
        getRolesAction.fulfilled,
        (state: IProfileState, action: IReducerAction<IAPIEntityResponse<IRole[]>>) => {
          if (action.payload && action.payload.status === 200) {
            state.role = action.payload.data;
          }
          state.isFetchingRoles = false;
        }
      )
      .addCase(getRolesAction.rejected, (state: IProfileState) => {
        state.isFetchingRoles = false;
      })
      .addCase(
        getApplicationsAction.fulfilled,
        (state: IProfileState, action: IReducerAction<IApplications[]>) => {
          state.applications = action.payload;
        }
      )
      .addCase(getAddressAction.pending, (state: IProfileState) => {
        state.isLoadingRequest = true;
      })
      .addCase(
        getAddressAction.fulfilled,
        (state: IProfileState, action: IReducerAction<IAPIEntityResponse<IAddress[]>>) => {
          state.isLoadingRequest = false;
          state.address = action.payload.data;
          state.totalRecords = action.payload.totalRecords;
        }
      )
      .addCase(
        getAddressAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoadingRequest = false;
          state.address = [];
          state.errorMessage =
            action.payload.message || translate('I18N.error_messages.fetch_address_fail');
        }
      )
      .addCase(createAddressAction.pending, (state: IProfileState) => {
        state.isLoadingRequest = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(
        createAddressAction.fulfilled,
        (state: IProfileState, action: IReducerAction<any>) => {
          state.isLoadingRequest = false;
          if (action.payload && action.payload.status === 200) {
            state.successMessage = translate('I18N.success_messages.create_address_success');
          } else {
            state.errorMessage = action.payload.error?.message;
          }
        }
      )
      .addCase(
        createAddressAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoadingRequest = false;
          state.errorMessage =
            action.payload?.error || translate('I18N.error_messages.create_address_failure');
        }
      )
      .addCase(updateAddressAction.pending, (state: IProfileState) => {
        state.isLoadingRequest = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(
        updateAddressAction.fulfilled,
        (state: IProfileState, action: IReducerAction<any>) => {
          state.isLoadingRequest = false;
          if (action.payload && action.payload.status === 200) {
            state.successMessage = translate('I18N.success_messages.create_updated_success');
          } else {
            state.errorMessage = action.payload.error?.message;
          }
        }
      )
      .addCase(
        updateAddressAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoadingRequest = false;
          state.errorMessage =
            action.payload?.error || translate('I18N.error_messages.update_address_failure');
        }
      )
      .addCase(deleteAddressAction.pending, (state: IProfileState) => {
        state.isLoadingRequest = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(
        deleteAddressAction.fulfilled,
        (state: IProfileState, action: IReducerAction<any>) => {
          state.isLoadingRequest = false;
          if (action.payload && action.payload.status === 200) {
            state.successMessage = translate('I18N.success_messages.delete_address_success');
          } else {
            state.errorMessage = action.payload.error?.message;
          }
        }
      )
      .addCase(
        deleteAddressAction.rejected,
        (state: IProfileState, action: IReducerAction<IAPIErrorData>) => {
          state.isLoadingRequest = false;
          state.errorMessage =
            action.payload?.error || translate('I18N.error_messages.deleted_address_failure');
        }
      );
  }
});

export const profileReducer: Reducer<EntityState<IProfileState> & IProfileState, AnyAction> =
  profileSlice.reducer;

export const profileActions: any = profileSlice.actions;

export const getProfileState: any = (rootState: any) => rootState[profileFeatureKey];
