/*
 * profileTabSelectors.ts (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2022
 *
 * @file profileTabSelectors.ts
 * @author Timothy Fadayini
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
*/

/**
 *  Define all static profile tabs and values.
 */
export const profileTabs = {
  homeComponent: 0, /**< Profile tab number for the `home` component */
  basicInformationComponent: 1, /**< Profile tab number for the `basic information` component */
  passwordComponent: 2, /**< Profile tab number for the `password` component */
  addressComponent: 3, /**< Profile tab number for the `address` component */
};
