/**
* ContentPanel.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ContentPanel.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SidebarMenu from './Admin/Shared/SidebarMenu';
import AdminContent from './Content/AdminContent';
import ClientContent from './Content/ClientContent';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthState, IAuthState, logoutAction } from '../Store/AuthSlice';
import { Dispatch } from 'redux';
import { validateTokenAPI } from '../Services/AuthApi';
import FullPageLoader from '@abstract/abstractwebcommon-client/Loader/FullPageLoader';
import { getTemplateState, ITemplateState } from '../Store/TemplateSlice';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../Services/LogApi';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { getLogoAction } from '../Store/SettingsSlice';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { parseBoolean } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

interface IContentPanel {
  adminRoute: boolean;
  themeMode: string /**< theme mode to use */;
  didChangeTheme: (theme: ThemeMode) => void /**< change theme function */;
  languageSettingsMode: string /**< Language settings mode to use */;
  didChangeLanguage: (language: string) => void /**< change language function */;
}

const ContentPanel = (properties: IContentPanel): JSX.Element => {
  /// Render adminContent or clientContent based on adminRoute prop
  const dispatch: Dispatch<any> = useDispatch();
  const authState: IAuthState = useSelector(getAuthState);
  const templateState: ITemplateState = useSelector(getTemplateState);
  const isAdmin: boolean = parseBoolean(LocalStorage.getAdmin()) ?? authState.isAdmin;
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(
    sessionStorage.getItem('initialLoginTime') ? true : false
  );
  const [fullLoaderTemplate, setFullLoaderTemplate] = useState<any>(null);
  const logoutHandler = (): void => {
    dispatch(logoutAction());
  };
  const [logoURL, setLogoURL] = useState<string>(''); /**< Logo URL. */
  const [isLogoLoaded, setLogoLoaded] = useState<boolean>(false); /**< Logo Loading state. */

  useEffect(() => {
    if (!sessionStorage.getItem('initialLoginTime')) {
      const timeoutID: any = setTimeout(async () => {
        const response: IAPIEntityResponse<void> = await asyncErrorHandler(validateTokenAPI());
        if (authState.isAuthenticated && response && response.status === 200) {
          setUserIsAuthenticated(true);
        } else {
          logoutHandler();
          setUserIsAuthenticated(false);
          window.location.href = SharedCommomRouteName.loginRoute;
        }
      }, 700);
      sessionStorage.setItem('initialLoginTime', `${new Date()}`);
      return () => clearTimeout(timeoutID);
    }
  }, []);

  useEffect(() => {
    if (templateState && templateState.publicTemplates) {
      setFullLoaderTemplate(
        templateState.publicTemplates.find((template: any) => template.type === 'splashLoader') ||
          null
      );
    }
  }, [templateState.publicTemplates]);

  /// Get Logo URL.
  useEffect(() => {
    const getLogoURL = async () => {
      if (LocalStorage.getLogoImageUrl() === null) {
        await asyncErrorHandler(dispatch(getLogoAction({})));
        setLogoLoaded(true);
      } else {
        setLogoURL(LocalStorage.getLogoImageUrl() || '');
        setLogoLoaded(true);
      }
    };
    if (authState.isAuthenticated) {
      getLogoURL();
    } else {
      setLogoLoaded(true);
    }
  }, [LocalStorage.getLogoImageUrl()]);

  const getContent = () => {
    if (properties.adminRoute) {
      return <AdminContent isAdmin={isAdmin} />;
    }
    return <ClientContent />;
  };

  if (!userIsAuthenticated && authState.isAuthenticated) {
    return <FullPageLoader template={(fullLoaderTemplate && fullLoaderTemplate.html) || ''} />;
  }

  return (
    <>
      {' '}
      {isLogoLoaded && (
        <main className="d-flex flex-column h-100">
          <Container fluid={true}>
            <Row>
              <SidebarMenu
                isAdmin={isAdmin}
                didChangeTheme={properties.didChangeTheme}
                themeMode={properties.themeMode}
                logoURL={logoURL}
                languageSettingsMode={properties.languageSettingsMode}
                didChangeLanguage={properties.didChangeLanguage}
              />
              {/* The id is used to keep user pop up information inside the div. */}
              <Col className="main-body" id="main-body">
                {getContent()}
              </Col>
            </Row>
          </Container>
        </main>
      )}
    </>
  );
};

export default withErrorBoundary(ContentPanel, createLogApi, LocalStorage.getAdmin());
