/**
* UserRowExpansionTemplate.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2021
* @file UserRowExpansionTemplate.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import UserProfilePic from '../Shared/UserProfilePic';
import Button from 'react-bootstrap/Button';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';

import './index.css';

interface IUserRowExpansionProperties {
  fnResetPassword: (data: any) => void;
  rowData: any;
  fnToggleUserStatus: (data: any) => void;
  currentUser: string;
}

const UserRowExpansionTemplate = ({
  fnResetPassword,
  rowData,
  fnToggleUserStatus,
  currentUser
}: IUserRowExpansionProperties): JSX.Element => {
  const { t } = useTranslation();
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [showToggleUserStatusPopup, setShowToggleUserStatusPopup] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [toggleUserStatusPopupTarget, setToggleUserStatusPopupTarget] = useState<any>(null);
  const [isLogoLoaded, setLogoLoaded] =
    useState<boolean>(false); /**< State to control when logo is loaded */

  const ref: any = useRef(null);

  const toggleUserPopupRef: any = useRef(null);

  const onAccept = () => {
    setShowConfirmPopup(false);
    fnResetPassword(rowData);
  };

  const onReject = () => {
    setShowConfirmPopup(false);
  };

  const onToggleUserStatusAccept = () => {
    setShowToggleUserStatusPopup(false);
    fnToggleUserStatus(rowData);
  };

  const onToggleUserStatusReject = () => {
    setShowToggleUserStatusPopup(false);
  };

  const RenderExpansionRows = () => (
    <>
      <tr className="mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.id')}</th>
        <td>{rowData['userUUID']}</td>
      </tr>
      <tr className="mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.username')}</th>
        <td>{rowData['username']}</td>
      </tr>
      <tr className="mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.user')}</th>
        <td>{`${rowData['fullName']}`}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.confirmed')}</th>
        <td>{<ShowCheckOrUncheckIcon value={rowData.isActive} />}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.verified')}</th>
        <td>{<ShowCheckOrUncheckIcon value={rowData.isVerified} />}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.email')}</th>
        <td>{rowData['email']}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.role')}</th>
        <td>
          {' '}
          {rowData['role']?.length
            ? rowData['role'].map((row: IRole) => row?.name || '').join(',')
            : ''}
        </td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.created')}</th>
        <td>{rowData.created ? formatDate(rowData.created) : ''}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.lastUpdated')}</th>
        <td>{rowData.updated ? formatDate(rowData.updated) : ''}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.about')}</th>
        <td>{rowData['about']}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.company')}</th>
        <td>{rowData['company']}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.website')}</th>
        <td>{rowData['website']}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.location')}</th>
        <td>{rowData['location']}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.notes')}</th>
        <td>{rowData['notes']}</td>
      </tr>
      <tr className="mt-2 mr-2">
        <th>{t('/admin.users_fieldset.datatable.columns.registered_apps')}</th>
        <td>
          {rowData['applications']?.length
            ? rowData['applications']
                .map((row: IApplications) => row?.applicationName || '')
                .join(',')
            : ''}
        </td>
      </tr>
    </>
  );

  return (
    <>
      <UserProfilePic
        imageUrl={rowData.imageUrl}
        imgClass="user-profile-picture-container rounded"
        setLogoLoaded={setLogoLoaded}
        isLogoLoaded={isLogoLoaded}
      />
      {isLogoLoaded && (
        <>
          <Row className="pt-2 px-2 justify-content-between">
            <Button
              onClick={(event: any) => {
                setShowConfirmPopup(true);
                setConfirmPopupTarget(event.target);
              }}
              variant="danger"
              ref={ref}
              className="m-2 d-flex action-buttons-row-expansive-custom-width">
              <i className="bi bi-key btn-icon"></i>
              {t('/admin.users_fieldset.datatable.actions.reset_password')}
            </Button>
            <ConfirmationPopup
              target={confirmPopupTarget}
              isShow={showConfirmPopup}
              title={t('/confirm_messages.reset_pass')}
              onAccept={onAccept}
              onReject={onReject}
              acceptBtnClass="danger"
              rejectBtnClass="secondary"
              rejectLabel={t('/confirm_messages.no')}
              acceptLabel={t('/confirm_messages.yes')}
              acceptBtnIcon="bi bi-check2-circle"
              rejectBtnIcon="bi bi-x-circle"
            />
            <Button
              onClick={(event: any) => {
                setShowToggleUserStatusPopup(true);
                setToggleUserStatusPopupTarget(event.target);
              }}
              variant={rowData.isActive ? 'danger' : 'primary'}
              ref={toggleUserPopupRef}
              disabled={rowData.userUUID === currentUser}
              className="m-2 d-flex action-buttons-row-expansive-custom-width">
              <i
                className={`btn-icon ${
                  rowData.isActive ? 'bi bi-x-circle' : 'bi bi-check2-circle'
                }`}></i>
              {rowData.isActive
                ? t('/admin.users_fieldset.datatable.actions.deactivate_user')
                : t('/admin.users_fieldset.datatable.actions.activate_user')}
            </Button>
            <ConfirmationPopup
              target={toggleUserStatusPopupTarget}
              isShow={showToggleUserStatusPopup}
              title={
                rowData.isActive
                  ? t('/confirm_messages.deactivate_user')
                  : t('/confirm_messages.activate_user')
              }
              onAccept={onToggleUserStatusAccept}
              onReject={onToggleUserStatusReject}
              acceptBtnClass="danger"
              rejectBtnClass="secondary"
              rejectLabel={t('/confirm_messages.no')}
              acceptLabel={t('/confirm_messages.yes')}
              acceptBtnIcon="bi bi-check2-circle"
              rejectBtnIcon="bi bi-x-circle"
            />
          </Row>
          <ExpansionRow>
            <RenderExpansionRows />
          </ExpansionRow>

          <ExpansionRow isSmallBreakpoint={true}>
            <RenderExpansionRows />
          </ExpansionRow>
        </>
      )}
    </>
  );
};

export default UserRowExpansionTemplate;
