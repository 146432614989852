/**
* ConfigurationSettingsPage.ts (InstaLOD GmbH) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022 
* @file ConfigurationSettingsPage.ts
* @author Alaguvelammal Alagusubbiah
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useEffect } from 'react';
import ConfigurationSettingsPageComponent from '@abstract/abstractwebcommon-client/ConfigurationSettingsPage/ConfigurationSettingsPage';
import { getSettingsState, ISettingsState, updateSettingsAction } from '../../Store/SettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IAppSettings } from '@abstract/abstractwebcommon-shared/interfaces/user/settings';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/LogApi';
import { RouteName } from '../../Utils/routesNames';

const ConfigurationSettingsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const translation: TFunction = useTranslation().t;
  const location = useLocation<any>();
  const configurationSettings: IAppSettings =
    location.state; /**< Settings to configure application. */
  const settingsState: ISettingsState = useSelector(getSettingsState); /**< Settings State. */
  const history: any = useHistory();

  /// Update configuration settings.
  const handleUpdateSettings = async (application: IAppSettings): Promise<void> => {
    const payload: any = {
      application
    }; /**< Payload to update application settings. */
    dispatch(updateSettingsAction(payload));
  };

  /// Listen to error events
  useEffect(() => {
    if (settingsState.isApplicationSettingsUpdated) {
      showToast({
        severity: 'success',
        summary: translation('I18N.success_messages.app_settings_updated')
      });
      history.push({ pathname: RouteName.adminDashboardRoute });
    }
    if (settingsState.applicationSettingsError) {
      showToast({
        severity: 'error',
        summary:
          settingsState.applicationSettingsError?.message || settingsState.applicationSettingsError
      });
    }
  }, [settingsState, dispatch, translation]);

  return (
    <>
      <ConfigurationSettingsPageComponent
        configurationSettings={configurationSettings}
        handleUpdateSettings={handleUpdateSettings}
      />
    </>
  );
};

export default withErrorBoundary(ConfigurationSettingsPage, createLogApi);
