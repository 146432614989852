/**
* UserManagement.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file UserManagement.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
* @modified Sai Charan K, 2021
*/

import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUsersAction,
  getUsersListAction,
  getUserState,
  getUsersByAppIdAction,
  getUsersByRoleIdAction,
  resetPasswordAction,
  initializeUserStateAction,
  toggleUserStatusAction,
  getUserByIdAction,
  IUserState,
  verifyUserAction,
  getApplicationsAction,
  getAllRolesAction
} from '../../../Store/UserSlice';
import { useTranslation } from 'react-i18next';
import { getAuthState, IAuthState } from '../../../Store/AuthSlice';
import UserTable from './UserTable';
import { IUser } from './IUser';
import UserRowExpansionTemplate from './UserRowExpansionTemplate';
import UserDialog from './UserDialog';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { TFunction } from 'i18next';
import {
  defaultTableLimit,
  defaultTableOptions
} from '@abstract/abstractwebcommon-client/Constants';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { MultiSelect } from 'primereact/multiselect';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { handleCheckboxIconClick, handleHeaderCheckboxClick } from '../../../Utils/helpers';

const UserManagement = (properties: Record<string, any>): JSX.Element => {
  const userState: IUserState = useSelector(getUserState);
  const authState: IAuthState = useSelector(getAuthState);
  const dispatch: Dispatch<any> = useDispatch();
  const t: TFunction = useTranslation().t;
  const [selectedUsers, setSelectedUsers] = useState<any[]>(null);
  const rowsPerPage: Array<number> = defaultTableOptions;
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'DESC'
    },
    searchTerm: '',
    filter: [
      {
        column: 'roleUUIDs',
        operator: 'IN',
        value: null
      },
      {
        column: 'applicationUUID',
        operator: 'IN',
        value: null
      }
    ] /**< Default filter. */
  });
  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<any>({});
  const [expandedRows, setExpandedRows] = useState<any>({});

  const [isUsersListLoaded, setIsUsersListLoaded] = useState<boolean>(false);
  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tableData.
  Object.keys(payload.sort).forEach((key) => {
    defaultSortField = key; // Sortfield
    defaultSortOrder = payload.sort[key]; //Sortorder
  });
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  const [isInitiateDelete, setIsInitiateDelete] = useState<boolean>(false);
  const [isInitiatePassReset, setIsInitiatePassReset] = useState<boolean>(false);
  const [isInitiateFetch, setIsInitiateFetch] = useState<boolean>(false);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);

  const deleteButtonReference: any = useRef(null);

  const [selectedRoles, setSelectedRoles] = useState<IRole[]>([]); /**< Selected Roles. */
  const [selectedApplications, setSelectedApplications] = useState<IApplications[]>(
    []
  ); /**< Selected Applications. */

  useEffect(() => {
    if (!isUsersListLoaded) {
      if (properties?.app_id) {
        dispatch(getUsersByAppIdAction({ ...payload, applicationUUID: properties.app_id }));
      } else if (properties?.role_id) {
        dispatch(getUsersByRoleIdAction({ ...payload, roleUUID: properties.role_id }));
      } else {
        dispatch(getUsersListAction(payload));
      }
      setIsUsersListLoaded(true);
      setIsInitiateFetch(true);
    }
  }, [
    dispatch,
    userState,
    isUsersListLoaded,
    setIsUsersListLoaded,
    setIsInitiateFetch,
    payload,
    properties
  ]);

  useEffect(() => {
    if (showConfirmation && Object.keys(selectedUsers)?.length === 0) {
      setShowConfirmation(false);
    }
  }, [selectedUsers, showConfirmation]);

  const loadUsers = () => {
    dispatch(getUsersListAction(payload));
  };

  const resetState = () => {
    dispatch(initializeUserStateAction({}));
  };

  /// Check errors and password reset
  useEffect(() => {
    if (userState?.isDeleted && isInitiateDelete) {
      showToast({
        severity: 'success',
        summary: t('/error_messages.user_deleted_success'),
        detail: ''
      });
      setIsUsersListLoaded(false);
      setIsInitiateDelete(false);
    }
    if (userState?.isPasswordReset && isInitiatePassReset) {
      showToast({
        severity: 'success',
        summary: t('/error_messages.password_reset_success'),
        detail: ''
      });
      setIsInitiatePassReset(false);
    }
    if (userState.deleteError !== null && isInitiateDelete) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: userState?.deleteError?.message || userState?.deleteError
      });
      setIsInitiateDelete(false);
    }
    if (userState.fetchError !== null && isInitiateFetch) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: userState?.fetchError?.message || userState?.fetchError
      });
      setIsInitiateFetch(false);
    }
    if (userState.resetPasswordError !== null && isInitiatePassReset) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: userState?.resetPasswordError?.message || userState?.resetPasswordError
      });
      setIsInitiatePassReset(false);
    }
    if (userState.toggleUserStatusSuccess !== null && !userState.isLoading) {
      showToast({
        severity: 'success',
        summary: userState.toggleUserStatusSuccess
      });
      loadUsers();
    }

    if (userState.toggleUserStatusFail !== null && !userState.isLoading) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: userState?.toggleUserStatusFail?.message || userState?.toggleUserStatusFail
      });
      resetState();
    }
  }, [
    userState.isDeleted,
    userState.isPasswordReset,
    userState.deleteError,
    userState.fetchError,
    userState.resetPasswordError,
    userState.toggleUserStatusSuccess,
    userState.toggleUserStatusFail,
    isInitiateDelete,
    isInitiatePassReset,
    isInitiateFetch,
    setIsInitiateDelete,
    setIsInitiatePassReset,
    setIsInitiateFetch,
    setIsUsersListLoaded,
    t
  ]);

  useEffect(() => {
    if (userState.toggleVerifiedStatusError) {
      showToast({
        severity: 'error',
        summary: userState.toggleVerifiedStatusError
      });
    }
    if (userState.toggleVerifiedStatus) {
      // Toast User verified status.
      showToast({
        severity: 'success',
        summary: userState.toggleVerifiedStatusSuccess
      });
      loadUsers();
    }
  }, [userState.toggleVerifiedStatusError, userState.toggleVerifiedStatus]);

  /// Dispatch DeleteUsersAction
  const deleteUsers = (userUUIDs?: any): void => {
    setIsInitiateDelete(true);
    dispatch(
      deleteUsersAction(!userUUIDs ? selectedUsers?.map((row: any) => row.userUUID) : userUUIDs)
    );
    setSelectedUsers(null);
    if (userUUIDs) {
      onHide(false);
    }
  };

  const onFilter = (termToFind: string) => {
    Object.assign(payload, { searchTerm: termToFind });
    setIsUsersListLoaded(false);
  };

  // Get all roles & Applications.
  useEffect(() => {
    dispatch(getAllRolesAction());
    dispatch(getApplicationsAction());
  }, []);

  /// Triggers on role multiselect change - Sets the value to the filter
  const onRoleChange = (event: any): void => {
    const roles: IRole[] = event.value; /**< Selected Roles. */
    setSelectedRoles(roles);

    const roleUUIDs: string[] = roles.map(
      (role: IRole) => role.roleUUID
    ); /**< Selected roleUUIDs */

    const updatedPayload: ITablePayload = payload;
    const roleFilterIndex: number = updatedPayload.filter.findIndex(
      (property) => property.column === 'roleUUIDs'
    );
    updatedPayload.filter[roleFilterIndex].value = roleUUIDs;

    setPayload(updatedPayload);
    setIsUsersListLoaded(false);
  };

  /// Triggers on application multiselect change - Sets the value to the filter
  const onApplicationChange = (event: any): void => {
    const applications: IApplications[] = event.value; /**< Selected Applications. */
    setSelectedApplications(applications);

    const applicationUUIDs: string[] = applications.map(
      (application: IApplications) => application.applicationUUID
    ); /**< Selected applicationUUIDs */

    const updatedPayload: ITablePayload = payload;
    const applicationFilterIndex: number = updatedPayload.filter.findIndex(
      (property) => property.column === 'applicationUUID'
    );
    updatedPayload.filter[applicationFilterIndex].value = applicationUUIDs;

    setPayload(updatedPayload);
    setIsUsersListLoaded(false);
  };

  /// Div containing header action buttons
  const getHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="headerTableContainer">
          <ActionButton
            variant="danger"
            onClick={(event: any) => deleteButtonClick(event)}
            isDisabled={
              ((selectedUsers && Object.keys(selectedUsers).length === 0) || !selectedUsers) ??
              false
            }
            buttonReference={deleteButtonReference}
          />

          <ActionButton onClick={() => setDialogVisible(true)} />
        </div>

        <div className="headerTableContainer header-search-filter">
          <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />

          <MultiSelect
            optionLabel="name"
            dataKey="roleUUID"
            name="role"
            inputId="roleFilter"
            value={selectedRoles}
            options={userState.allRoles}
            onChange={onRoleChange}
            placeholder={t('/admin/role-management.roles_fieldset.legend')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
            pt={{
              checkboxIcon: {
                onClick: (event: any) => handleCheckboxIconClick(event)
              },
              headerCheckbox: {
                onClick: (event: any) => handleHeaderCheckboxClick(event)
              }
            }}
          />
          <MultiSelect
            optionLabel="applicationName"
            dataKey="applicationUUID"
            name="application"
            inputId="applicationFilter"
            value={selectedApplications}
            options={userState.applications}
            onChange={onApplicationChange}
            placeholder={t('/admin/application-management.apps_fieldset.legend')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
            pt={{
              checkboxIcon: {
                onClick: (event: any) => handleCheckboxIconClick(event)
              },
              headerCheckbox: {
                onClick: (event: any) => handleHeaderCheckboxClick(event)
              }
            }}
          />
        </div>
      </div>
    );
  };

  /// show delete popup
  const deleteButtonClick = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event?.target);
  };

  /// Delete users on Accept
  const onAccept = () => {
    deleteUsers();
    setShowConfirmation(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation dialog
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  /// Triggers on every page change to load new data
  const onPage = (event: any) => {
    const updatedPayload = payload;
    updatedPayload['limit'] = event.rows || defaultTableLimit;
    updatedPayload['skip'] = event.first || 0;
    setPayload(updatedPayload);
    setIsUsersListLoaded(false);
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    setExpandedRows({ [event.data.id]: true });
  };

  /// Shows a toast message with the specific message and dismisses the user dialog form.
  const userDataUpdated = (message: string, severity: string, isUpdated: boolean) => {
    showToast({ severity: severity, summary: message, detail: '' });
    if (isUpdated) {
      onHide(true);
    }
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedUUIDs: IUser[] = event.value;
    if (Array.isArray(selectedUUIDs)) {
      const selectedRow = selectedUUIDs.map((row: IUser) => {
        return row;
      });
      setSelectedUsers(selectedRow);
    }
  };

  /// Triggered on UserForm dialog hide event. This dispatches loadUser action if there is any change in the users list
  const onHide = (isLoad?: boolean) => {
    dispatch(initializeUserStateAction({}));
    setDialogVisible(false);
    setEditUser({});
    if (isLoad) {
      setIsUsersListLoaded(false);
    }
  };

  /// Returns the user dialog form.
  const getUserForm = () => {
    return (
      <UserDialog
        isDialogVisible={isDialogVisible}
        onHide={onHide}
        editUser={editUser || {}}
        deleteUsers={deleteUsers}
        userDataUpdated={userDataUpdated}
      />
    );
  };

  /// This specifies the template for row expansion.
  const getRowExpansionTemplate = (rowData: any) => {
    return (
      <UserRowExpansionTemplate
        rowData={rowData}
        fnResetPassword={fnResetPassword}
        fnToggleUserStatus={fnToggleUserStatus}
        currentUser={authState.user.id}
      />
    );
  };

  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any) => {
    const updatedPayload = payload;
    updatedPayload.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedPayload.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }
    setPayload(updatedPayload);
    setIsUsersListLoaded(false);
    setMultiSortMeta(event.multiSortMeta);
  };

  /// Triggers dispatch of email and sends a toast notification.
  const fnResetPassword = (data: any) => {
    setIsInitiatePassReset(true);
    dispatch(resetPasswordAction(data.username));
  };

  // Toggle user status
  const fnToggleUserStatus = (data: any) => {
    // Check for null or undefined since isActive can be false.
    if (data.userUUID && data.username && (data.isActive !== null || data.isActive !== undefined)) {
      dispatch(
        toggleUserStatusAction({
          userUUID: data.userUUID,
          isActive: !data.isActive,
          email: data.email
        })
      );
    }
  };

  /// Triggers on rowClick
  const onRowClick = (event: any) => {
    setExpandedRows({});
    setEditUser(event.data);
    setDialogVisible(true);
    dispatch(getUserByIdAction({ userUUID: event.data.userUUID }));
  };

  /// Triggers verify user action.
  const handleVerifyUser = (userUUID: string) => {
    dispatch(verifyUserAction(userUUID));
  };

  return (
    <>
      <UserTable
        onRowClick={onRowClick}
        onSort={onSort}
        parentClass={'responsiveBaseDataTable userDataTable'}
        rowsPerPage={rowsPerPage}
        getRowExpansionTemplate={getRowExpansionTemplate}
        payload={payload}
        isLoading={!userState?.usersList}
        dataKey={'id'}
        onSelectionChange={onSelectionChange}
        totalRecords={userState?.totalRecords}
        sortMode={'multiple'}
        className={'userDataTable'}
        selectedList={selectedUsers}
        header={getHeader()}
        data={userState?.usersList}
        expandedRows={expandedRows}
        onPage={onPage}
        multiSortMeta={multiSortMeta}
        expandRow={expandRow}
        setExpandedRows={setExpandedRows}
        setIsUsersListLoaded={setIsUsersListLoaded}
        selectionMode="checkbox"
        handleVerifyUser={handleVerifyUser}
      />
      {getUserForm()}
      {getConfirmationPopup()}
    </>
  );
};

export default UserManagement;
