/**
* Register.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file Register.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RegisterForm from '@abstract/abstractwebcommon-client/RegisterForm';
import { translate } from '../../Utils/Translate';
import { IRegisterFormData } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import {
  getAuthState,
  IAuthState,
  registerUserAction,
  resetAuthStateAction,
  sendVerificationAction
} from '../../Store/AuthSlice';
import { AppDispatch } from '../../Store/Store';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { useLocation } from 'react-router-dom';
import {
  IApplications,
  IPublicApplicationInformation
} from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import TopBar from '@abstract/abstractwebcommon-client/TopBar';
import { createLogApi } from '../../Services/LogApi';
import queryString from 'query-string';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { getSettingsState, ISettingsState } from '../../Store/SettingsSlice';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { getApplicationByUUID } from '../../Services/ApplicationApi';
import i18n from '../../Services/I18n';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * @interface ILoginProperties
 */
interface ILoginProperties {
  themeMode?: string /**< theme mode to use */;
  didChangeTheme?: (theme: string) => void /**< change theme function */;
  languageSettingsMode?: string /**< Language settings mode to use */;
  didChangeLanguage?: (language: string) => void /**< change language function */;
}

/**
 * LocationParameters interface.
 */
interface ILocationParameters {
  redirectURL: string /**< Redirect URL. */;
  applicationDetails: IPublicApplicationInformation /**< Application details. */;
}

/**
 * New user registration component.
 */
const Register = (properties: ILoginProperties): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch<AppDispatch>();
  const authState: IAuthState = useSelector(getAuthState);
  const [isAuthStateReset, setAuthStateReset] =
    useState<boolean>(true); /**< AuthState reset or not. */
  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search); /**< Query Parameters. */
  const token: string = parsedString['token']; /**< Token. */
  const redirectApplicationURL: string =
    parsedString['redirectURL']; /**< redirectURL if registration from an invite. */
  const emailToRegister: string =
    parsedString['emailToRegister']; /**< emailToRegister if registration from an invite. */
  const applicationUUID: string = parsedString['app']; /**< Application UUID */
  const location: ILocationParameters =
    useLocation<ILocationParameters>().state; /**< Location state. */
  const redirectURL: string = location?.redirectURL; /**< Redirect URL. */
  const loginURL: string = redirectURL
    ? `${SharedCommomRouteName.loginRoute}${redirectURL}`
    : SharedCommomRouteName.loginRoute; /**< login page url. */
  const application: IPublicApplicationInformation =
    location?.applicationDetails; /**< Application details */
  const settingsState: ISettingsState = useSelector(getSettingsState); /**< Settings state */
  const [externalApplicationDetails, setExternalApplicationDetails] =
    useState<IApplications>(
      null
    ); /**< External application details. Eg: Ecommerce (i.e) Other than user */

  const onSubmit = (data: IRegisterFormData) => {
    delete data.isTOSAccepted;
    data.token = token;
    data.applicationUUID = application?.applicationUUID;
    data.redirectApplicationURL = redirectApplicationURL;
    dispatch(registerUserAction(data));
  };
  const onResendVerification = (data: IRegisterFormData) => {
    data.redirectApplicationURL = redirectApplicationURL;
    dispatch(sendVerificationAction(data));
  };

  //Note:Reset authState when the component is mounted, otherwise the state value persists and the message is always displayed in toast.
  useEffect(() => {
    if (authState.registerUserError || authState.verifyOnDate) {
      dispatch(resetAuthStateAction({})); // Reset Auth State
      setAuthStateReset(true);
    }

    const fetchApplicationDetailsByUUID = async () => {
      const applicationResult: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
        getApplicationByUUID(applicationUUID)
      );

      if (applicationResult.data) {
        setExternalApplicationDetails(applicationResult.data);
      }
    };

    if (applicationUUID) {
      fetchApplicationDetailsByUUID();
    }
  }, []);

  useEffect(() => {
    if (authState.registerUserError && !isAuthStateReset) {
      showToast({
        severity: 'error',
        summary: translate(authState.registerUserError)
      });
    }
    if (authState.verifyOnDate && !isAuthStateReset) {
      showToast({
        severity: 'success',
        summary: translate('I18N.success_messages.send_mail_success')
      });
    }
    if (authState.redirectURL) {
      window.location.href = authState.redirectURL;
    }
    setAuthStateReset(false);
  }, [authState]);

  const getRegisterForm = () => {
    return (
      <>
        <TopBar
          themeMode={properties.themeMode}
          didChangeTheme={properties.didChangeTheme}
          languageSettingsMode={properties.languageSettingsMode}
          didChangeLanguage={properties.didChangeLanguage}
          i18nService={i18n}
        />
        <RegisterForm
          isLoading={authState.isLoading}
          onSubmit={onSubmit}
          loginURL={loginURL}
          verificationImageURL={'images/new-email-notification.svg'}
          onResendVerification={onResendVerification}
          termsOfServiceURL={
            (settingsState && settingsState.loginPageInformation?.termsOfServiceURL) || '/'
          }
          verifyOnDate={authState.verifyOnDate}
          application={application ?? settingsState.loginPageInformation.applicationDetails}
          emailToRegister={emailToRegister}
          externalApplication={externalApplicationDetails}
        />
      </>
    );
  };

  return <div className="text-dark registerForm-container">{getRegisterForm()}</div>;
};

export default withErrorBoundary(Register, createLogApi);
