/*
 * sort.ts (AbstractUser)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file sort.ts
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @interface ISortOptionsProperties
 */
interface ISortOptionsProperties {
  [key: string]: {
    name: string /**< Order to sort, ASC or DESC */
    order: number /**< Order to sort, representing in number, 1 = ASC, -1 = DESC */
  }
}

/**
 * SortOptions
 */
export const sortOptions: ISortOptionsProperties = {
  ASC: {
    name: 'ASC',
    order: 1
  },
  DESC: {
    name: 'DESC',
    order: -1
  }
}
