/**
* UserApplicationsSlice.ts (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file UserApplicationsSlice.ts
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import {
  getUserApplicationsForSidebar,
  updateUserPermission,
  validateUserPermission
} from '../Services/UserApplicationsAPI';
import { Reducer } from 'react';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IUpdateUserPermission,
  IUserApplications
} from '@abstract/abstractwebcommon-shared/interfaces/user/userApplications';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import i18n from '@abstract/abstractwebcommon-client/services/i18n';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const userApplicationsFeatureKey: string = 'userApplications';

/**
 * Interface userApplicationInitialState
 */
export interface IUserApplicationsState {
  data: IUserApplications /**< Endpoints data. */;
  statusCode: number /**< Status code return. */;
  logError: any /**< Error handler. */;
  isLoading: boolean /**< Is loading. */;
  applicationPermission?: IUserApplications /** Application permission by user. */;
  userApplications: IApplications[] /** User application list. */;
  isUserApplicationListFetching: boolean /** Is user application list. */;
}

export const userApplicationInitialState: IUserApplicationsState = {
  data: {} as IUserApplications,
  statusCode: null,
  logError: null,
  isLoading: false,
  applicationPermission: {} as IUserApplications,
  userApplications: [] as IApplications[],
  isUserApplicationListFetching: false
};

export const userApplicationsAdapter: EntityAdapter<IUserApplicationsState> = createEntityAdapter();
export const initialUserApplicationsState: EntityState<IUserApplicationsState> &
  IUserApplicationsState = userApplicationsAdapter.getInitialState(userApplicationInitialState);

const userApplicationEndpoint = 'userApplication';

/**
 * Update user application permission Action.
 * @param payload IUpdateUserPermission type
 */
export const updateUserApplicationPermissionAction = createAsyncThunk(
  `${userApplicationEndpoint}/permission/user/update`,
  async (payload: IUpdateUserPermission) => {
    const response: IAPIEntityResponse<IUserApplications> = await asyncErrorHandler(
      updateUserPermission(payload)
    );
    return response;
  }
);

/**
 * Validate if user has granted permission.
 * @param payload IUpdateUserPermission type
 */
export const validateUserPermissionAction = createAsyncThunk(
  `${userApplicationEndpoint}/permission/validate`,
  async (payload: IUpdateUserPermission) => {
    const response: IAPIEntityResponse<IUserApplications> = await asyncErrorHandler(
      validateUserPermission(payload)
    );
    return response;
  }
);

/**
 * Get user applications for the Sidebar component.
 * @param userUUID User UUID
 */
export const getUserApplicationsForSidebarAction = createAsyncThunk(
  `${userApplicationEndpoint}/findByUserUUID`,
  async () => {
    const response: IAPIEntityResponse<IApplications[]> = await asyncErrorHandler(
      getUserApplicationsForSidebar()
    );
    return response;
  }
);

const clearErrors = (state: IUserApplicationsState) => {
  state.statusCode = null;
  state.logError = null;
  state.isLoading = false;
  state.applicationPermission = {} as IUserApplications;
};

/**
 * Creates Slice - All User Application related state will be stored here
 */
export const userApplicationSlice = createSlice({
  name: userApplicationsFeatureKey,
  initialState: initialUserApplicationsState,
  reducers: {
    reset: (state: IUserApplicationsState) => clearErrors(state)
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserApplicationPermissionAction.pending, (state: IUserApplicationsState) => {
        state.isLoading = true;
        state.statusCode = null;
      })
      .addCase(
        updateUserApplicationPermissionAction.fulfilled,
        (
          state: IUserApplicationsState,
          action: IReducerAction<IAPIEntityResponse<IUserApplications>>
        ) => {
          if (action.payload.status === 200) {
            state.applicationPermission.isUserPermissionGranted = true;
          }

          if (action.payload.status !== 200) {
            state.statusCode === action.payload.status;
          }

          state.isLoading = false;
        }
      )
      .addCase(validateUserPermissionAction.pending, (state: IUserApplicationsState) => {
        state.isLoading = true;
        state.statusCode = null;
      })
      .addCase(
        validateUserPermissionAction.fulfilled,
        (
          state: IUserApplicationsState,
          action: IReducerAction<IAPIEntityResponse<IUserApplications>>
        ) => {
          if (action.payload.status === 401) {
            state.statusCode = action.payload.status;
            state.isLoading = false;
            return;
          }

          state.applicationPermission = action.payload.data;
          state.isLoading = false;
        }
      )
      .addCase(validateUserPermissionAction.rejected, (state: IUserApplicationsState) => {
        state.isLoading = false;
        state.statusCode = 400;
      })
      .addCase(getUserApplicationsForSidebarAction.pending, (state: IUserApplicationsState) => {
        state.isUserApplicationListFetching = true;
      })
      .addCase(
        getUserApplicationsForSidebarAction.fulfilled,
        (
          state: IUserApplicationsState,
          action: IReducerAction<IAPIEntityResponse<IApplications[]>>
        ) => {
          state.isUserApplicationListFetching = false;
          state.userApplications = action.payload.data;
        }
      )
      .addCase(getUserApplicationsForSidebarAction.rejected, (state: IUserApplicationsState) => {
        state.isUserApplicationListFetching = false;
        handleError({ message: i18n.t('I18N.sidebar.errorOnFetchUserApplicationList') });
      });
  }
});

export const userApplicationReducer: Reducer<
  EntityState<IUserApplicationsState> & IUserApplicationsState,
  AnyAction
> = userApplicationSlice.reducer;

export const userApplicationsAction: any = userApplicationSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = userApplicationsAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getUserApplicationsState: any = (rootState: any) =>
  rootState[userApplicationsFeatureKey];
export const selectAllUserApplications: any = createSelector(getUserApplicationsState, selectAll);
export const selectUserApplicationsEntities: any = createSelector(
  getUserApplicationsState,
  selectEntities
);
