import jwtDecode from 'jwt-decode';
import { authFeatureKey, initialAuthState } from './AuthSlice';
import {
  IUserInformationToken,
  UserAuthenticationToken,
  IMappedRole
} from '@abstract/abstractwebcommon-shared/utils/UserAuthenticationToken';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

class StateLoader {
  loadState(): any {
    try {
      const serializedState = LocalStorage.getSavedState();
      const token = LocalStorage.getXAuthToken();
      if (!serializedState || !token) {
        return this.initializeState();
      }
      const updatedState = JSON.parse(serializedState);
      if (token !== updatedState.auth.accessToken) {
        const decodedToken: IUserInformationToken = new UserAuthenticationToken(
          jwtDecode(token),
          true
        ).getUserAuthenticationToken();
        const isAdmin: boolean = decodedToken.roles.find(
          (eachRole: IMappedRole) => eachRole.name === 'ROLE_ADMIN'
        )
          ? true
          : false;
        updatedState.auth = {
          isAuthenticated: true,
          isAdmin,
          accessToken: token,
          applications: decodedToken.applications,
          role: decodedToken.roles,
          user: {
            email: decodedToken.email,
            firstName: decodedToken.firstName,
            id: decodedToken.userUUID,
            lastName: decodedToken.lastName,
            username: decodedToken.username
          }
        };
      }
      return updatedState;
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state: Record<string, any>): void {
    try {
      const authState = {
        isAuthenticated: state.auth ? state.auth.isAuthenticated : false,
        user: state.auth ? state.auth.user : {},
        applications: state.auth ? state.auth.applications : [],
        role: state.auth ? state.auth.role : [],
        accessToken: state.auth ? state.auth.accessToken : '',
        isAdmin: state.auth ? state.auth.isAdmin : false
      };
      if (authState.applications) {
        //Formatted applications
        authState.applications = authState.applications.map((app: IApplications | string) => {
          if (typeof app === 'string') {
            return {
              applicationUUID: app
            };
          }
          return {
            id: app.id,
            applicationUUID: app.applicationUUID,
            applicationName: app.applicationName,
            isActive: app.isActive
          };
        });
      }

      const serializedState = JSON.stringify({ [authFeatureKey]: authState || null });
      LocalStorage.setSavedState(serializedState);
    } catch (err) {
      console.log('err ', err);
    }
  }

  initializeState(): any {
    return {
      [authFeatureKey]: {
        isAuthenticated: initialAuthState.isAuthenticated,
        user: initialAuthState.user,
        applications: initialAuthState.applications,
        role: initialAuthState.role,
        accessToken: initialAuthState.accessToken,
        isAdmin: initialAuthState.isAdmin
      }
    };
  }

  static isTokenValid(token: string): boolean {
    const decodedToken: IUserInformationToken = new UserAuthenticationToken(
      jwtDecode(token),
      true
    ).getUserAuthenticationTokenClientSide();
    let isValid: boolean;

    // JWT exp is in seconds
    if (new Date(decodedToken.exp * 1000) < new Date()) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }
}

export default StateLoader;
