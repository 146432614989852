/**
* ForbiddenPage.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file ForbiddenPage.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { logoutAction } from '../../Store/AuthSlice';
import { useTranslation } from 'react-i18next';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/LogApi';

const ForbiddenPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  dispatch(logoutAction());

  const goToLogin = () => {
    window.location.href = '/';
  };

  return (
    <div className="p-text-center text-dark m-5">
      {t('I18N.forbidden_page.header')} <br />
      <Button variant="primary" onClick={() => goToLogin()}>
        {t('I8N.forbidden_page.login_btn')}
      </Button>
    </div>
  );
};

export default withErrorBoundary(ForbiddenPage, createLogApi);
