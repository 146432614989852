/*
 * DataOperator.ts (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2021
 *
 * @file DataOperator.ts
 * @author Martin Witczak
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum DATA_OPERATOR
 */
export enum DATA_OPERATOR {
  IN = "IN",
  EQUALS = "EQUALS",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  LIKE = "LIKE",
}
