/**
* TemplatePage.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file TemplatePage.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTemplatesAction,
  getTemplateState,
  ITemplateState,
  updateTemplatesAction
} from '../../../Store/TemplateSlice';
import TemplateForm from './TemplateForm';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getSafeAppSettingsAction } from '../../../Store/SettingsSlice';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

const TemplatePage = (): JSX.Element => {
  const templateState: ITemplateState = useSelector(getTemplateState);
  const dispatch: Dispatch<any> = useDispatch();
  const t: TFunction = useTranslation().t;

  const [isTemplateLoaded, setIsTemplateLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isTemplateLoaded) {
      dispatch(getSafeAppSettingsAction());
      dispatch(getTemplatesAction({}));
      setIsTemplateLoaded(true);
    }
  }, [isTemplateLoaded, dispatch]);

  useEffect(() => {
    if (isTemplateLoaded && templateState.isTemplateUpdated) {
      showToast({ severity: 'success', summary: t('I18N.template_editor.update_success') });
      dispatch(getTemplatesAction({}));
    }
  }, [isTemplateLoaded, templateState, dispatch, t]);

  useEffect(() => {
    if (isTemplateLoaded && templateState.loadTemplateError) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: templateState.loadTemplateError?.message || templateState.loadTemplateError
      });
    }
  }, [templateState, isTemplateLoaded, t]);

  const onTemplateSubmit = (updatedTemplate: any) => {
    const updatedPayload = {
      templateUUID: updatedTemplate.templateUUID,
      name: updatedTemplate.name,
      subject: updatedTemplate.subject,
      html: updatedTemplate.html
    };
    dispatch(updateTemplatesAction(updatedPayload));
  };

  return (
    <>
      <TemplateForm onTemplateSubmit={onTemplateSubmit} />
    </>
  );
};

export default TemplatePage;
