import { BASE_API_URL } from '../Config';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';
import { IUpdateApplicationResponse } from '../Store/ApplicationSlice';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Get all applications
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const getApplications = (
  payload?: Record<string, any>
): Promise<PaginatedAPIEntityResponse<IApplications>> => {
  const url: string = paginationRequest(`/applications/all`, payload);
  return httpClient.get(
    `${url}&searchTerm=${encodeURIComponent(payload?.searchTerm ? payload.searchTerm : '')}`
  );
};

/**
 * Get all user applications
 * @param userUUID userUUID of the user
 * @returns Promise
 */
export const getUserApplications = (
  userUUID: string
): Promise<IAPIEntityResponse<IApplications[]>> => {
  return httpClient.get(`/applications/user/${userUUID}`);
};

/**
 * Delete application(s)
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const deleteAppApi = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post('/applications/delete', { applicationUUIDs: payload });
};

/**
 * Updates an application
 * @param payload Payload to send with the request
 * @param id id of the application to update
 * @returns Promise
 */
export const updateApplicationApi = (
  payload: Record<string, any>,
  id: string
): Promise<IAPIEntityResponse<IUpdateApplicationResponse>> => {
  return httpClient.post(`/application/${id}/update`, payload);
};

/**
 * Gets one application by UUID
 * @param applicationUUID applicationUUID of the application
 * @returns
 */
export const getApplicationByUUID = (
  applicationUUID: string
): Promise<IAPIEntityResponse<IApplications>> => {
  return httpClient.get(`/application/${applicationUUID}/public`);
};

/**
 * Creates a new application
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const createApplicationApi = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IApplications>> => {
  return httpClient.post('/application/create', payload);
};

/**
 * Gets users by applicationUUID
 * @param payload Payload to send with the request
 * @param applicationUUID applicationUUID of the application
 * @returns Promise
 */
export const getUsersByAppIdApi = (
  payload: Record<string, any>,
  applicationUUID: string
): Promise<PaginatedAPIEntityResponse<IUser>> => {
  const url: string = paginationRequest(`/users/application/${applicationUUID}`, payload);
  return httpClient.post(`${url}/users/application/${applicationUUID}`, payload);
};

/**
 * Gets an application by name and key
 * @param name Name of the application
 * @param key Key of the application
 * @returns Promise
 */
export const getSafeApplicationByNameApi = (
  name: string,
  key: string
): Promise<IAPIEntityResponse<IApplications>> => {
  return httpClient.get(`/applications/public?name=${name}&identifier=${key}`, {}, false);
};
