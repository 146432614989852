/**
* UserApplicationsAPI.ts (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file UserApplicationsAPI.ts
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IUpdateUserPermission,
  IUserApplications
} from '@abstract/abstractwebcommon-shared/interfaces/user/userApplications';

import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './LogApi';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

const userApplicationEndpoint = 'userApplication';
const userApplicationsEndpoint = 'userApplications';

/**
 * Update user permission
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const updateUserPermission = (
  payload: IUpdateUserPermission
): Promise<IAPIEntityResponse<IUserApplications>> => {
  return httpClient.put<IUserApplications>(
    `/${userApplicationEndpoint}/permission/update`,
    payload,
    {},
    false
  );
};

/**
 * Validate if user has granted permission
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const validateUserPermission = (
  payload: IUpdateUserPermission
): Promise<IAPIEntityResponse<IUserApplications>> => {
  return httpClient.post<IUserApplications>(
    `/${userApplicationEndpoint}/permission/validate`,
    payload,
    {},
    false,
    true,
    false
  );
};

/**
 * Get all user applications
 * @param userUUID userUUID of the user
 * @returns Promise
 */
export const getUserApplicationsForSidebar = (): Promise<IAPIEntityResponse<IApplications[]>> => {
  return httpClient.get(`/${userApplicationsEndpoint}/findByUserUUID/true`);
};
