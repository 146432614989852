/*
 * LogDetails.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2021
 *
 * @file LogDetails.tsx
 * @author Sai Charan K
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useState } from "react";
import Row from "react-bootstrap/esm/Row";
import { isJson } from "@abstract/abstractwebcommon-shared/utils/json";
import ReactJson from 'react-json-view';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { LocalStorage } from '../utils/sharedLocalStorage'

interface ILogDetailsProperties {
  details: any;
}

const LogDetails = ({ details }: ILogDetailsProperties): ReactElement => {
  const themeMode: string = LocalStorage.getThemeMode() || ThemeMode.lightMode; /**< Theme */

  /// In case none validation is trigger, show the value as string
  const showDetailsErrorWithoutStyle = (details: string): JSX.Element => (
    <pre className="error-category mb-0">{details}</pre>
  )

  // Display log details in JSON viewer.
  const GetMessage = () => {
    const message: string = "-";
    if (details === "") {
      return message;
    }

    if (isJson(details)) {
      const parsedMessage = JSON.parse(details);

      if (!parsedMessage) {
        return message;
      }
      const key = Object.keys(parsedMessage).length
        ? Object.keys(parsedMessage)[0]
        : null;
      if (!key) {
        return message;
      }

      const logDetails: Record<string,any>[] = []; /**< Log details */
      if (typeof parsedMessage[key] === 'object') {
        if(parsedMessage[key].length) {
          parsedMessage[key].map((currentMessage: any) => {
            const currentKey = Object.keys(currentMessage).length
              ? Object.keys(currentMessage)[0]
              : "";
  
            if(currentKey && currentMessage[currentKey]) {
              logDetails.push(currentMessage);
            }
          });
        } else {
          logDetails.push(parsedMessage);
        }

        return (
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
            enableClipboard={false}
            src={logDetails}
            theme={
              themeMode === ThemeMode.lightMode ? 'rjv-default' : 'railscasts'
            }
          />
        )
      } else {
        if(typeof parsedMessage === 'object') {
          const logDetails: Record<string,any>[] = []; /**< Log details */
          logDetails.push(parsedMessage);
          return (
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              name={false}
              enableClipboard={false}
              src={logDetails}
              theme={
                themeMode === ThemeMode.lightMode ? 'rjv-default' : 'railscasts'
              }
            />
          )
        } else {
          return showDetailsErrorWithoutStyle(details as string)
        }
      }
    } else {
      return showDetailsErrorWithoutStyle(details as string)
    }
  };

  return <Row>{GetMessage()}</Row>
};

export default LogDetails;
