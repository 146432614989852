/*
 * FullPageLoader.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2021
 *
 * @file FullPageLoader.tsx
 * @author Timothy Fadayini
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { TFunction } from "i18next";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

interface IFullPageLoaderProperties {
  template?: string;
}

const FullPageLoader = ({
  template = "",
}: IFullPageLoaderProperties): ReactElement => {
  const translation: TFunction = useTranslation().t;
  const [formattedTemplate, setFormattedTemplate] = useState("");

  //format content and show on screen
  useEffect(() => {
    const needle = "{{fullPageLoaderText}}";
    const regex = new RegExp(needle, "g");

    setFormattedTemplate(
      template.replace(regex, translation("awc:/.loader.full_page_loader_text"))
    );
  }, [template]);

  return <div dangerouslySetInnerHTML={{ __html: formattedTemplate }} />;
};

export default FullPageLoader;
