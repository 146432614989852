/**
* RoleForm.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file RoleForm.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import InstaAutoComplete from '../Shared/InstaAutoComplete';
import { searchApplicationAction } from '../../../Store/ApplicationSlice';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { TFunction } from 'i18next';
import { Dialog } from 'primereact/dialog';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const RoleForm = ({
  deleteRoles,
  deleteData,
  handleSubmit,
  isLoading,
  editRole,
  onHide,
  adminRoleName
}: {
  deleteRoles: any;
  deleteData: any;
  handleSubmit: any;
  isLoading: boolean;
  editRole: any;
  onHide: (load?: boolean) => void;
  adminRoleName: string;
}): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const t: TFunction = useTranslation().t;

  const initialApplication: any = editRole?.application || null;
  const [applicationSuggestions, setApplicationSuggestions] = useState<any[]>([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [showDeleteLinkedRolesWarning, setShowDeleteLinkedRolesWarning] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: editRole.name || '',
      description: editRole.description || '',
      isActive: editRole.isActive || false,
      application: initialApplication
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(20, t('/validations.max', { field: '20' }))
        .required(t('/validations.required', { field: t('I18N.role.name') })),
      description: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(50, t('/validations.max', { field: '255' })),
      isActive: Yup.boolean().oneOf([true, false]),
      application: Yup.object()
        .nullable()
        .required(t('/validations.required', { field: t('I18N.role.application') }))
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if ((key as keyof typeof formik.initialValues) !== 'application') {
          if (
            data[key as keyof typeof formik.initialValues] !==
            formik.initialValues[key as keyof typeof formik.initialValues]
          ) {
            payload[key] = data[key as keyof typeof formik.initialValues];
          }
        }
        if (data['application']) {
          if (editRole && Object.keys(editRole).length) {
            if (
              (!formik.initialValues['application'] && data['application']['id']) ||
              data['application']['id'] !== formik.initialValues['application']['id']
            ) {
              payload['application'] = {
                id: data['application']['id']
              };
            }
          } else {
            payload['application'] = {
              id: data['application']['id']
            };
          }
        }
        if (i === Object.keys(data).length - 1) {
          if (Object.keys(payload).length) {
            handleSubmit(payload, editRole.roleUUID);
          }
        }
      });
    }
  });
  /// Display confirmation popup
  const deleteBtnClick = (event: any) => {
    setConfirmPopupTarget(event.target);
    setShowConfirmPopup(true);
  };

  /// deleteRoles on accept
  const onAccept = (): void => {
    setShowConfirmPopup(false);
    deleteRoles([editRole], false);
    setShowDeleteLinkedRolesWarning(true);
  };

  /// Hide popup on reject
  const onReject = (): void => {
    setShowConfirmPopup(false);
    setShowDeleteLinkedRolesWarning(false);
  };

  const onDelete = (): void => {
    deleteRoles([editRole], true);
    setShowDeleteLinkedRolesWarning(false);
    onHide(true);
  };

  /// Update application in Formik on selection
  const setApplicationValue = (event: any) => {
    formik.setFieldValue('application', event.value);
  };

  const searchApplication = async (event: any) => {
    const response: any = await asyncErrorHandler(
      dispatch(searchApplicationAction(event?.query || ''))
    );
    let applicationList: IApplications[] = []; /**< Get searched application list */
    if (response && response.payload && response.payload.status === 200) {
      applicationList = response.payload.data && response.payload.data.records;
    }

    setApplicationSuggestions(applicationList);
  };

  // on component did mount load applications dropdown
  useEffect(() => {
    searchApplication('');
  }, []);

  const renderRowDeleteDialogFooter = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t('/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRolesNo')}
        </Button>
        <Button
          onClick={() => onDelete()}
          autoFocus
          variant="danger"
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {t('/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRolesYes')}
        </Button>
      </div>
    );
  };
  return (
    <>
      <FormWrapper
        onSubmit={formik.handleSubmit}
        controlButtonLabel={editRole.id}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) => deleteBtnClick(event)}
        handleSubmitButton={() => formik.handleSubmit()}
        disableDeleteButton={editRole.name === adminRoleName}>
        <Row>
          <Col xs={12}>
            <label htmlFor="name" className="required">
              {t('/admin/role-management.roles_fieldset.datatable.columns.name')}
            </label>
            <InputText
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.name && formik.errors.name ? 'p-invalid' : ''}
            />
            {formik.touched.name && formik.errors.name ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors.name}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="description">
              {t('/admin/role-management.roles_fieldset.datatable.columns.description')}
            </label>
            <InputText
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.description && formik.errors.description ? 'p-invalid' : ''}
            />
            {formik.touched.description && formik.errors.description ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors.description}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="application" className="required">
              {t('/admin/role-management.roles_fieldset.datatable.columns.application')}
            </label>
            <InstaAutoComplete
              name="application"
              value={formik.values.application}
              suggestions={applicationSuggestions}
              className={`${
                formik.touched.application && formik.errors.application
                  ? 'p-invalid w-100'
                  : 'w-100'
              } auto-complete`}
              completeMethod={searchApplication}
              field="applicationName"
              onChange={setApplicationValue}
              onBlur={formik.handleBlur}
              inputId="application">
              {formik.touched.application && formik.errors.application ? (
                <small id="application-invalid" className="p-invalid">
                  {formik.errors.application}
                </small>
              ) : null}
            </InstaAutoComplete>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Checkbox
              inputId="isActive"
              value={formik.values.isActive}
              onChange={(e: any) => formik.setFieldValue('isActive', e.checked)}
              checked={formik.values.isActive}
              className={formik.errors.isActive ? 'p-invalid' : ''}
            />
            <label htmlFor="isActive" className="mb-0 pl-2">
              {t('/admin/role-management.roles_fieldset.datatable.columns.confirmed')}
            </label>
            {formik.errors.isActive ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors.isActive}
              </small>
            ) : null}
          </Col>
        </Row>
      </FormWrapper>
      <div>
        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={showConfirmPopup}
          title={t('/confirm_messages.delete_record')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
        />
        <Dialog
          header={t(
            '/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRolesHeader'
          )}
          visible={showDeleteLinkedRolesWarning && deleteData.userCount > 0}
          className="custom-dialog-container"
          footer={renderRowDeleteDialogFooter()}
          onHide={() => setShowDeleteLinkedRolesWarning(false)}
          draggable={false}>
          {`
        ${t('/admin/role-management.roles_fieldset.delete_role_dialog.confirmDeleteRoles')} 
        ${
          deleteData.userCount > 0
            ? `Total Users linked: ${deleteData.userCount} [${deleteData.linkedUsers
                .map((user) => user.username)
                .join(', ')}]`
            : ''
        } 
        ${deleteData.usersNotShownCount > 0 ? `(${deleteData.usersNotShownCount} not shown)` : ``}
        `}
        </Dialog>
      </div>
    </>
  );
};

export default RoleForm;
