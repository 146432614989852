/**
* UserTable.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020
* @file UserTable.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useState } from 'react';
import { Column } from 'primereact/column';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import Button from 'react-bootstrap/Button';
import InstaTable, {
  IInstaTableProperties
} from '@abstract/abstractwebcommon-client/Table/InstaTable';
import { translate } from '../../../Utils/Translate';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import UserDetailsPopup from '@abstract/abstractwebcommon-client/UserDetailsPopup';

import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';

/**
 * Interface for UserTable properties.
 */
interface IUserTableProperties extends IInstaTableProperties {
  onRowClick: (event: any) => void /**< Handles table row button clicks.*/;
  setIsUsersListLoaded: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Check users list is loaded or not. */;
  handleVerifyUser: (userUUID: string) => void /**< Triggers verify user action. */;
}

const UserTable = ({
  onRowClick,
  onFilter,
  handleVerifyUser,
  ...properties
}: IUserTableProperties): JSX.Element => {
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [userUUID, setUserUUID] = useState<string | null>(null);

  // Accept dialog function.
  const onAccept = () => {
    setShowConfirmPopup(false);
    handleVerifyUser(userUUID);
  };

  // Reject dialog function.
  const onReject = () => {
    setShowConfirmPopup(false);
  };

  const getUserTable = () => {
    return (
      <>
        <InstaTable onFilter={onFilter} {...properties}>
          <Column expander className="p-0 col-width-45" headerClassName="p-0 col-width-45" />
          <Column selectionMode="multiple" className="col-width-45" />
          <Column
            field="username"
            header={translate('/admin.users_fieldset.datatable.columns.username')}
            className="custom-userName-header-width"
            headerClassName="custom-userName-header-width"
            sortable
            body={(rowData: any) => {
              if (properties.data) {
                return (
                  <>
                    <DatatableColumn
                      title={translate('/admin.users_fieldset.datatable.columns.username')}
                      data={
                        <UserDetailsPopup
                          users={properties.data}
                          userUUID={rowData.userUUID}
                          key={rowData.id}
                        />
                      }
                    />
                  </>
                );
              }
            }}
          />
          <Column
            field="fullName"
            header={translate('/admin.users_fieldset.datatable.columns.user')}
            sortable
            body={(rowData: IUser) => {
              return (
                <>
                  <DatatableColumn
                    title={translate('/admin.users_fieldset.datatable.columns.user')}
                    data={`${rowData['fullName']}`}
                  />
                </>
              );
            }}
          />
          <Column
            field="email"
            header={translate('/admin.users_fieldset.datatable.columns.email')}
            sortable
            className="d-table-cell d-sm-none d-md-table-cell"
            headerClassName="d-table-cell d-sm-none d-md-table-cell"
            body={(rowData: IUser) => (
              <DatatableColumn
                title={translate('/admin.users_fieldset.datatable.columns.email')}
                data={rowData.email}
                enableCopy={{ value: rowData.email, id: rowData.id }}
              />
            )}
          />
          <Column
            field="isActive"
            header={translate('/admin.users_fieldset.datatable.columns.confirmed')}
            sortable
            className="d-table-cell d-sm-none d-md-table-cell custom-header-min-width-allowed"
            body={(rowData: IUser) => (
              <DatatableColumn
                title={translate('/admin.users_fieldset.datatable.columns.confirmed')}
                data={<ShowCheckOrUncheckIcon value={rowData.isActive} />}
              />
            )}
          />
          <Column
            field="created"
            header={translate('/admin.users_fieldset.datatable.columns.created')}
            sortable
            className="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
            headerClassName="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
            body={(rowData: any) => (
              <DatatableColumn
                title={translate('/admin.users_fieldset.datatable.columns.created')}
                data={rowData.created ? formatDate(rowData.created) : ''}
              />
            )}
          />
          <Column
            field="updated"
            header={translate('/admin.users_fieldset.datatable.columns.lastUpdated')}
            sortable
            className="d-table-cell d-sm-none d-xxl-table-cell updatedDateCol"
            headerClassName="d-table-cell d-sm-none d-xxl-table-cell updatedDateCol"
            body={(rowData: any) => (
              <DatatableColumn
                title={translate('/admin.users_fieldset.datatable.columns.lastUpdated')}
                data={rowData.created ? formatDate(rowData.updated) : ''}
              />
            )}
          />
          <Column
            sortable
            field="isVerified"
            header={translate('/admin.users_fieldset.datatable.columns.verified')}
            className="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
            headerClassName="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
            body={(rowData: IUser) => (
              <DatatableColumn
                title={translate('/admin.users_fieldset.datatable.columns.verified')}
                data={<ShowCheckOrUncheckIcon value={rowData.isVerified} />}
              />
            )}
          />
          <Column
            field="edit"
            className="p-0 col-width-45 absolute-position-responsive-screen"
            body={(rowData: IUser) => {
              return (
                <Button
                  className="custom-action-column-action-position position-absolute-mr-30"
                  variant="outline"
                  onClick={() => {
                    onRowClick({ data: rowData });
                  }}>
                  <i className="bi bi-pencil-square editIcon fa-lg"></i>
                </Button>
              );
            }}
          />
          <Column
            field="verify"
            className="p-0 col-width-45 absolute-position-responsive-screen"
            body={(rowData: IUser) => {
              return (
                <Button
                  variant="outline"
                  disabled={rowData.isVerified}
                  className="custom-action-column-action-position"
                  onClick={(event) => {
                    setShowConfirmPopup(true);
                    setConfirmPopupTarget(event.target);
                    setUserUUID(rowData.userUUID);
                  }}>
                  <i
                    className={`bi bi-person-check-fill editIcon fa-lg ${
                      rowData.isVerified ? 'custom-disabled-icon' : ''
                    }`}
                  />
                </Button>
              );
            }}
          />
        </InstaTable>
        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={showConfirmPopup}
          title={translate('/confirm_messages.verify_user')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={translate('/confirm_messages.no')}
          acceptLabel={translate('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
        />
      </>
    );
  };

  return getUserTable();
};

export default UserTable;
