/*
 * MenuBadgeCount.tsx (Web Common)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file MenuBadgeCount.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section Web Common
 */

import React, { useEffect, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IAPIErrorData } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { showToast } from '../../AlertToast/AlertToast';

/**
 * @interface IMenuBadgeCountProperties
 */
interface IMenuBadgeCountProperties {
  getTotalItemCount: () => void /**< Function to get the total item count. */
  itemCount: number | null /**< Display the total item count on the interface. */
  fetchError: IAPIErrorData | null /**< Handle errors. */
  itemName: string /**< Helper to display the correct error message. */
}

/**
 * Display the total of a specific registered item in the system
 * @returns JSX.Element
 */
const MenuBadgeCount = (properties: IMenuBadgeCountProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (!properties.itemCount) {
      dispatch(properties.getTotalItemCount());
    }

    if (properties.fetchError) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: t(`I18N.error_messages.get_total_${properties.itemName}_count_error`)
      });
    }
  }, [properties.itemCount]);

  if (!properties.itemCount) {
    return <></>;
  }

  return (
    <>
      <div className="count-item-style">{properties.itemCount}</div>
    </>
  );
};

export default MenuBadgeCount;
