/**
* NotFound.tsx (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file NotFound.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section User
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ISettingsState,
  getSafeAppSettingsAction,
  getSettingsState
} from '../../Store/SettingsSlice';
import { IPlaceholder } from '@abstract/abstractwebcommon-shared/interfaces/user/placeholder';
import { ITemplates } from '@abstract/abstractwebcommon-shared/interfaces/user/templates';
import NotFoundBase from '@abstract/abstractwebcommon-client/NotFound/NotFoundBase';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/LogApi';

const NotFound = (): JSX.Element => {
  const settingsState: ISettingsState = useSelector(getSettingsState);
  const dispatch: Dispatch<any> = useDispatch();

  const [isSettingLoaded, setSettingLoaded] = useState<boolean>(false);
  const [templateToShow, setTemplateToShow] = useState<string>(null);

  const setPlaceholdersToTheTemplate = (): void => {
    const placeholdersErrorHandler: IPlaceholder[] =
      settingsState.safeSettings.placeholdersErrorHandler;
    const errorHandlerTemplate: ITemplates = settingsState.safeSettings.errorHandlerTemplate;

    const transformedTemplate = errorHandlerTemplate.html.replace(
      /{{([a-zA-Z]+)}}/g,
      (_, valueToMatch: string) => {
        const findPlaceholderIndex = placeholdersErrorHandler.findIndex(
          (placeholder) => placeholder.placeholderKey === valueToMatch
        );
        return placeholdersErrorHandler[findPlaceholderIndex].placeholderValue;
      }
    );
    setTemplateToShow(transformedTemplate);
  };

  useEffect(() => {
    if (!isSettingLoaded) {
      dispatch(getSafeAppSettingsAction());
      setSettingLoaded(true);
    }

    if (settingsState.safeSettings) {
      setPlaceholdersToTheTemplate();
    }
  }, [settingsState, isSettingLoaded, dispatch]);

  return <NotFoundBase isLoading={isSettingLoaded} templateToShow={templateToShow} />;
};

export default withErrorBoundary(NotFound, createLogApi);
