/**
* AddressTable.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file AddressTable.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import { Column } from 'primereact/column';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import Button from 'react-bootstrap/Button';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import { translate } from '../../../../Utils/Translate';
import { IInstaTableProperties } from '@abstract/abstractwebcommon-client/Table/InstaTable';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

/**
 * Interface for AddressTable properties.
 */
interface IAddressTableProperties extends IInstaTableProperties {
  onButtonClick: (event: React.SetStateAction<any>) => void /**< Handles table row button clicks.*/;
  onRowExpand: (event: any) => void /**< Handles table row expand.*/;
  onRowCollapse: () => void /**< Handles table row collapse.*/;
  onSort: (payload: ITablePayload) => void /**< Sort reports table. */;
  tablePayload: ITablePayload /**  Table payload. */;
  multiSortMeta: { [key: string]: any }[] /** sort meta. */;
  handlePageUpdate: (event: any) => void /**< Handle page update. */;
}

/**
 * Shows a table of address on the system.
 */
const AddressTable = ({ ...properties }: IAddressTableProperties): JSX.Element => {
  const tablePayload: ITablePayload = properties.tablePayload;

  const getAddressTable = () => {
    return (
      <div className="responsiveBaseDataTable">
        <BaseDatatable
          value={properties.data}
          rowExpansionTemplate={(rowExpansionData: any) =>
            properties.getRowExpansionTemplate(rowExpansionData)
          }
          selection={properties.selectedList}
          first={tablePayload.skip}
          rows={tablePayload.limit}
          onSort={(event: any) => properties.onSort(event)}
          multiSortMeta={properties.multiSortMeta}
          sortMode="multiple"
          onPage={properties.handlePageUpdate}
          {...properties}>
          <Column expander headerClassName="p-0 col-width-45" className="col-width-45 p-0" />
          <Column selectionMode="multiple" className="col-width-45" />
          <Column
            sortable
            field="name"
            header={translate('/admin.address_fieldset.datatable.columns.name')}
            body={(rowData: IAddress) => {
              return (
                <DatatableColumn
                  title={translate('/admin.address_fieldset.datatable.columns.name')}
                  data={rowData['name']}
                />
              );
            }}
          />
          <Column
            field="edit"
            body={(rowData: IAddress) => {
              return (
                <Button
                  variant="outline"
                  className="custom-action-column-action-position"
                  onClick={() => {
                    properties.onButtonClick({ data: rowData });
                  }}>
                  <i className="bi bi-pencil-square editIcon fa-lg"></i>
                </Button>
              );
            }}
            className="p-0 col-width-45 absolute-position-responsive-screen"
          />
        </BaseDatatable>
      </div>
    );
  };

  return getAddressTable();
};

export default AddressTable;
