/*
 * ForgotPasswordForm.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file ForgotPasswordForm.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ActionButtons from "./ActionButtons";
import InstaInputText from "../FormControl/InstaInputText";
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import { maximumCharactersAllowedInPassword, minimumCharactersAllowedInPassword } from "../../Shared/constants";

interface IForgotPasswordProperties {
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

const ForgotPasswordForm = ({
  onCancel,
  onSubmit,
}: IForgotPasswordProperties): ReactElement => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(minimumCharactersAllowedInPassword, t('awc:/validations.min', { field: minimumCharactersAllowedInPassword }))
        .max(maximumCharactersAllowedInPassword, t('awc:/validations.max', { field: maximumCharactersAllowedInPassword }))
        .matches(validators.PASSWORD, t('I18N.change_password.password_regex'))
        .required(
          t("awc:/validations.required", {
            field: t("awc:/.forgot_password.form.password"),
          })
        ),
      confirmPassword: Yup.string()
        .required(
          t("awc:/validations.required", {
            field: t("awc:/.forgot_password.form.confirm_password"),
          })
        )
        .oneOf(
          [Yup.ref("password"), ""],
          t("awc:/.forgot_password.form.password_match")
        ),
    }),
    onSubmit: (data) => {
      onSubmit(data);
    },
  });

  return (
    <>
      <Col xs={12} className="p-0">
        <p className="text-break custom-margin"> {t("awc:/.forgot_password.form.subtitle")}</p>
      </Col>
      <form onSubmit={formik.handleSubmit}>
        <Col xs={12} md={8} className="input-password-container px-0">
          <InstaInputText
            label={t("awc:/.forgot_password.form.password")}
            placeholder={t("awc:/.forgot_password.form.password")}
            id={"password"}
            className="specialInputs"
            errors={formik.touched.password && formik.errors.password}
            name={"password"}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched}
            type={"password"}
            value={formik.values.password}
            isPassword={true}
            labelClassName="required"
          />
        </Col>
        <Col xs={12} md={8} className="input-password-container px-0">
          <InstaInputText
            label={t("awc:/.forgot_password.form.confirm_password")}
            placeholder={t("awc:/.forgot_password.form.confirm_password")}
            id={"confirmPassword"}
            className="specialInputs"
            errors={formik.touched.confirmPassword && formik.errors.confirmPassword}
            name={"confirmPassword"}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched}
            type={"password"}
            value={formik.values.confirmPassword}
            isPassword={true}
            labelClassName="required"
          />
        </Col>
        <ActionButtons
          onCancel={onCancel}
          cancelLabel={t("awc:/.forgot_password.button.cancel")}
          submitLabel={t("awc:/.forgot_password.button.submit")}
        />
      </form>
    </>
  );
};

export default ForgotPasswordForm;
