/**
* SettingsForm.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020
* @file SettingsForm.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ApplicationSettingsForm from './ApplicationSettingsForm';
import { getSettingsState, ISettingsState } from '../../../Store/SettingsSlice';
import Col from 'react-bootstrap/Col';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import EnvironmentVariablePopup from '@abstract/abstractwebcommon-client/EnvironmentVariablePopup';
import { TFunction } from 'i18next';
import { getTemplateState, ITemplateState } from '../../../Store/TemplateSlice';
import SMTPSettingsForm from './SMTPSettingsForm';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { IPListForm } from '@abstract/abstractwebcommon-client/IPList/IPListForm';
import { Row } from 'react-bootstrap';
import StaticLinkForm from '@abstract/abstractwebcommon-client/StaticLinks/StaticLinksForm';
import Form from 'react-bootstrap/Form';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/staticLinks';
import Button from 'react-bootstrap/Button';

import './SettingsPage.css';
import {
  getStaticLinksState,
  IStaticLinksState
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

interface ISettingsFormProperties {
  handleTestSMTP: (values: any) => Promise<void>;
  handleUpdateSettings: (application: any) => Promise<void>;
  handleStaticLinkUpdate: (staticLink: any, staticLinkUUID: string | null) => Promise<void>;
  handleStaticLinkDelete: (staticLinkUUIDs: IStaticLink[]) => Promise<void>;
  uploadLogo: (event: any) => Promise<void>;
  deleteLogo: (event: any) => void;
  displayCroppedLogo: any;
  uploadFavouriteIcon: (event: any) => Promise<void>;
  deleteFavouriteIcon: (event: any) => void;
  displayCroppedFavouriteIcon: any;
  uploadStaticLinkIcon: (event: any) => Promise<void>;
  deleteStaticLinkIcon: (event: any) => void;
  errorHandler: (error: string) => void;
  statusLogoUpload: string;
  statusFavouriteIconUpload: string;
  displayCroppedStaticLinkIcon: any;
  setCroppedStaticLinkIcon: React.Dispatch<
    React.SetStateAction<any>
  > /**< Set cropped staticlink icon. */;
  refreshSaticLinksList: (updatedCriteria?: ITablePayload) => void;
}

const SettingsForm = ({
  uploadLogo,
  deleteLogo,
  displayCroppedLogo,
  uploadFavouriteIcon,
  deleteFavouriteIcon,
  displayCroppedFavouriteIcon,
  errorHandler,
  handleStaticLinkUpdate,
  handleTestSMTP,
  handleUpdateSettings,
  statusLogoUpload,
  statusFavouriteIconUpload,
  uploadStaticLinkIcon,
  deleteStaticLinkIcon,
  handleStaticLinkDelete,
  displayCroppedStaticLinkIcon,
  setCroppedStaticLinkIcon,
  refreshSaticLinksList
}: ISettingsFormProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;

  const settingsState: ISettingsState = useSelector(getSettingsState);
  const staticLinksState: IStaticLinksState = useSelector(getStaticLinksState);
  const templateState: ITemplateState = useSelector(getTemplateState);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showEnvironmentVariable, setShowEnvironmentVariable] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isSMTPFormValid, setIsSMTPFormValid] = useState<boolean>(true);
  const [isWhitelistIPsUpdate, setIsWhitelistIPsUpdate] = useState<boolean>();
  const [selectedIPRanges, setSelectedIPRanges] = useState<any>(null);
  const [isSMTPOrApplicationUpdate, setIsSMTPOrApplicationUpdate] = useState<boolean>(); // app or SMTP on submit: true for SMTP update
  const [isApplicationUpdatesExist, setIsApplicationUpdatesExist] = useState<boolean>(false); // app
  const [isSMTPUpdatesExist, setIsSMTPUpdatesExist] = useState<boolean>(false); // SMTP
  const [isLogoUpdateExists, setLogoUpdateExists] =
    useState<boolean>(false); /**< isLogoUpdateExists. */
  const [isFavouriteIconUpdateExists, setFavouriteIconUpdateExists] =
    useState<boolean>(false); /**< isFavouriteIconUpdateExists. */
  const [isBlacklistIPsUpdate, setBlacklistIPsUpdate] =
    useState<boolean>(); /**< Is blacklist IPs update. */
  const [selectedBlacklistIPRanges, setSelectedBlacklistIPRanges] =
    useState<string[]>(null); /**< Selected blacklist IP ranges. */

  // To check logo update exists or not.
  useEffect(() => {
    if ((statusLogoUpload === 'delete' && !settingsState.logoUrl) || !statusLogoUpload) {
      setLogoUpdateExists(false);
    } else if (statusLogoUpload) {
      setLogoUpdateExists(true);
    }
  }, [statusLogoUpload]);

  // To check favouriteicon update exists or not.
  useEffect(() => {
    if (
      (statusFavouriteIconUpload === 'delete' && !settingsState.favouriteIconUrl) ||
      !statusFavouriteIconUpload
    ) {
      setFavouriteIconUpdateExists(false);
    } else if (statusFavouriteIconUpload) {
      setFavouriteIconUpdateExists(true);
    }
  }, [statusFavouriteIconUpload]);

  const subForms: any = {
    application: {},
    SMTP: {}
  };

  const mapApplication = (values: any): any => {
    const payload: any = {};
    if (!settingsState.applicationSettings) {
      return {};
    }
    if (
      settingsState.applicationSettings['maxResetPasswordAttempts'] !==
      values.maxResetPasswordAttempts
    ) {
      payload['maxResetPasswordAttempts'] = values.maxResetPasswordAttempts;
    }
    if (
      settingsState.applicationSettings['maxForgotPasswordAttempts'] !==
      values.maxForgotPasswordAttempts
    ) {
      payload['maxForgotPasswordAttempts'] = values.maxForgotPasswordAttempts;
    }
    if (
      settingsState.applicationSettings['userActivationMailExpiry'] !==
      values.userActivationMailExpiry
    ) {
      payload['userActivationMailExpiry'] = values.userActivationMailExpiry;
    }
    if (
      settingsState.applicationSettings['forgotPasswordVerificationExpiry'] !==
      values.forgotPasswordVerificationExpiry
    ) {
      payload['forgotPasswordVerificationExpiry'] = values.forgotPasswordVerificationExpiry;
    }
    if (
      settingsState.applicationSettings['resetPasswordVerificationExpiry'] !==
      values.resetPasswordVerificationExpiry
    ) {
      payload['resetPasswordVerificationExpiry'] = values.resetPasswordVerificationExpiry;
    }
    if (
      settingsState.applicationSettings['enableDuplicateEmails'] !== values.enableDuplicateEmails
    ) {
      payload['enableDuplicateEmails'] = values.enableDuplicateEmails;
    }
    if (settingsState.applicationSettings['rssFeedUrl'] !== values.rssFeedUrl) {
      payload['rssFeedUrl'] = values.rssFeedUrl;
    }
    if (settingsState.applicationSettings['adminRole'].roleUUID !== values.adminRoleUUID) {
      payload['adminRoleUUID'] = values.adminRoleUUID;
    }
    if (
      settingsState.applicationSettings['selectedCSSTemplate'].templateUUID !==
      values.selectedCSSTemplate
    ) {
      payload['selectedCSSTemplate'] = values.selectedCSSTemplate;
    }
    if (settingsState.applicationSettings['applicationTitle'] !== values.applicationTitle) {
      payload['applicationTitle'] = values.applicationTitle === '' ? null : values.applicationTitle;
    }
    if (settingsState.applicationSettings['adminRoleName'] !== values.adminRoleName) {
      payload['adminRoleName'] = values.adminRoleName;
    }
    if (settingsState.applicationSettings['cacheFeedsTTL'] != values.cacheFeedsTTL) {
      payload['cacheFeedsTTL'] = values.cacheFeedsTTL;
    }
    if (settingsState.applicationSettings['rssDelay'] != values.rssDelay) {
      payload['rssDelay'] = values.rssDelay;
    }
    if (
      settingsState.applicationSettings['renewalRefreshTokenPeriodInSeconds'] !=
      values.renewalRefreshTokenPeriodInSeconds
    ) {
      payload['renewalRefreshTokenPeriodInSeconds'] = values.renewalRefreshTokenPeriodInSeconds;
    }
    if (settingsState.applicationSettings['contactEmail'] !== values.contactEmail) {
      payload['contactEmail'] = values.contactEmail;
    }
    if (settingsState.applicationSettings['website'] !== values.website) {
      payload['website'] = values.website;
    }
    if (
      settingsState.applicationSettings['migrationContactEmail'] !== values.migrationContactEmail
    ) {
      payload['migrationContactEmail'] = values.migrationContactEmail;
    }
    if (settingsState.applicationSettings['termsOfServiceURL'] !== values.termsOfServiceURL) {
      payload['termsOfServiceURL'] = values.termsOfServiceURL;
    }
    if (
      settingsState.applicationSettings['completeRegistrationText'] !==
      values.completeRegistrationText
    ) {
      payload['completeRegistrationText'] = values.completeRegistrationText;
    }
    if (settingsState.applicationSettings['jobsLockDuration'] != values.jobsLockDuration) {
      payload['jobsLockDuration'] = values.jobsLockDuration;
    }

    return payload;
  };

  const mapSMTP = (values: any) => {
    const payload: any = {};
    if (!settingsState.applicationSettings) {
      return {};
    }
    if (settingsState.applicationSettings['smtpHost'] !== values.smtpHost) {
      payload['smtpHost'] = values.smtpHost;
    }
    if (settingsState.applicationSettings['smtpPort'] !== values.smtpPort) {
      payload['smtpPort'] = values.smtpPort;
    }
    if (settingsState.applicationSettings['smtpSecure'] !== values.smtpSecure) {
      payload['smtpSecure'] = values.smtpSecure;
    }
    if (settingsState.applicationSettings['smtpUser'] !== values.smtpUser) {
      payload['smtpUser'] = values.smtpUser;
    }
    if (settingsState.applicationSettings['smtpPassword'] !== values.smtpPassword) {
      payload['smtpPassword'] = values.smtpPassword;
    }
    if (settingsState.applicationSettings['fromEmail'] !== values.fromEmail) {
      payload['fromEmail'] = values.fromEmail;
    }
    if (settingsState.applicationSettings['fromName'] !== values.fromName) {
      payload['fromName'] = values.fromName;
    }
    return payload;
  };

  const saveSMTPButtonClick = (event: any): void => {
    setIsSMTPOrApplicationUpdate(true);
    setShowConfirmation(true);
    setConfirmPopupTarget(event && event.target);
  };
  const saveApplicationButtonClick = (event: any): void => {
    setIsSMTPOrApplicationUpdate(false);
    setShowConfirmation(true);
    setConfirmPopupTarget(event && event.target);
  };

  // Handle save whitelistedIPs
  const saveWhitelistIPsClick = (event: any, selectedIPs: string[]): void => {
    setIsWhitelistIPsUpdate(true);
    setSelectedIPRanges(selectedIPs);
    setShowConfirmation(true);
    setConfirmPopupTarget(event && event.target);
  };

  // Save handler for blacklistedIPs
  const saveBlacklistIPsClick = (event: any, selectedIPs: string[]): void => {
    setBlacklistIPsUpdate(true);
    setSelectedBlacklistIPRanges(selectedIPs);
    setShowConfirmation(true);
    setConfirmPopupTarget(event && event.target);
  };

  const checkApplicationErrors = (): boolean => {
    return !(subForms.application.errors && Object.keys(subForms.application.errors).length > 0);
  };

  const checkSMTPErrors = (): boolean => {
    return !(subForms.SMTP.errors && Object.keys(subForms.SMTP.errors).length > 0);
  };
  // added to re evalutate if updates exists and determine disable main button
  const revalidateApplicationUpdates = (currentUpdate: any): void => {
    // Turn arround to evaluate updates with old state and current update
    const applicationValues = { ...subForms.application.values };
    if (applicationValues) {
      applicationValues[currentUpdate.id] = currentUpdate.value;
    }
    setIsApplicationUpdatesExist(checkIfApplicationUpdatesExists(applicationValues));
  };

  const revalidateSMTPUpdates = (currentUpdate: any): void => {
    const SMTPValues: any = { ...subForms.SMTP.values };
    if (SMTPValues) {
      SMTPValues[currentUpdate.id] = currentUpdate.value;
    }
    setIsSMTPUpdatesExist(checkIfSMTPUpdatesExists(SMTPValues));
  };

  // added to re evalutate errors and determine disable main button
  const revalidateErrors = (mode: string): void => {
    if (mode === 'application') {
      setIsFormValid(checkApplicationErrors());
    }
    if (mode === 'SMTP') {
      setIsSMTPFormValid(checkSMTPErrors());
    }
  };

  const checkIfApplicationUpdatesExists = (applicationValues: any): boolean => {
    return applicationValues && Object.keys(mapApplication(applicationValues)).length !== 0;
  };

  const checkIfSMTPUpdatesExists = (SMTPValues: any): boolean => {
    return SMTPValues && Object.keys(mapSMTP(SMTPValues)).length !== 0;
  };

  /// Reset application form checks to default
  const resetFormChecks = (mode: string): void => {
    revalidateErrors(mode);

    if (mode === 'application') {
      setIsApplicationUpdatesExist(false);
      setLogoUpdateExists(false);
      setFavouriteIconUpdateExists(false);
    }
    if (mode === 'SMTP') {
      setIsSMTPUpdatesExist(false);
    }
  };

  /// Handles from submission after confirmation dialog
  const handleApplicationSubmit = (): void => {
    if (checkApplicationErrors()) {
      const applicationUpdates: any = subForms.application.values;
      // check if there are any updates!
      let application: any;
      if (Object.keys(applicationUpdates).length !== 0) {
        application = applicationUpdates;
      }

      handleUpdateSettings(application);
      resetFormChecks('application');
    }
  };

  /// Handles from submission after confirmation dialog
  const handleSMTPSubmit = (): void => {
    if (checkSMTPErrors()) {
      const SMTPUpdates: any = subForms.SMTP.values;

      // check if there are any updates!
      let SMTP: any;
      if (Object.keys(SMTPUpdates).length !== 0) {
        SMTP = SMTPUpdates;
      }
      handleUpdateSettings(SMTP);
      resetFormChecks('SMTP');
    }
  };

  /// Handles from submission for static link
  const handleStaticLinkSubmit = (payload: any, staticLinkUUID: string | null): void => {
    handleStaticLinkUpdate(payload, staticLinkUUID);
  };

  const handleWhitelistIPsSubmit = (ipRanges: string): void => {
    handleUpdateSettings({ whitelistedIPs: ipRanges });
    setIsWhitelistIPsUpdate(false);
  };

  /// Submit handler for blacklisted IPs
  const handleBlacklistIPsSubmit = (ipRanges: string[]): void => {
    handleUpdateSettings({ blacklistedIPs: ipRanges });
    setBlacklistIPsUpdate(false);
  };

  const bindForm = (mode: string, values: any, errors: any): void => {
    subForms[mode].values = values;
    subForms[mode].errors = errors;
  };

  /// save on Accept
  const onAccept = (): void => {
    setShowConfirmation(false);
    if (isWhitelistIPsUpdate) {
      handleWhitelistIPsSubmit(selectedIPRanges);
    } else if (isBlacklistIPsUpdate) {
      handleBlacklistIPsSubmit(selectedBlacklistIPRanges);
    } else {
      isSMTPOrApplicationUpdate ? handleSMTPSubmit() : handleApplicationSubmit();
    }
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = (): JSX.Element => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  const hideEnvironmentVariablesDialog = (): void => {
    setShowEnvironmentVariable(false);
  };

  const getApplicationEnvironmentVariables = (): JSX.Element => {
    return (
      <EnvironmentVariablePopup
        environmentVariables={settingsState.environmentVariables}
        onClose={hideEnvironmentVariablesDialog}
      />
    );
  };

  const isAnyApplicationUpdatesExist: boolean =
    isApplicationUpdatesExist || isLogoUpdateExists || isFavouriteIconUpdateExists;
  const isAnySMTPUpdatesExist: boolean = isSMTPUpdatesExist;

  return (
    <Form>
      <Row>
        <ApplicationSettingsForm
          bindForm={bindForm}
          settingsState={settingsState}
          translation={t}
          revalidateErrors={revalidateErrors}
          revalidateUpdates={revalidateApplicationUpdates}
          CSSTemplates={templateState.CSSTemplates}
          showConfirmation={showConfirmation}
          isFormValid={isFormValid}
          isAnyUpdatesExist={isAnyApplicationUpdatesExist}
          saveButtonClick={saveApplicationButtonClick}
          uploadLogo={uploadLogo}
          deleteLogo={deleteLogo}
          uploadFavouriteIcon={uploadFavouriteIcon}
          deleteFavouriteIcon={deleteFavouriteIcon}
          displayCroppedLogo={displayCroppedLogo}
          displayCroppedFavouriteIcon={displayCroppedFavouriteIcon}
          errorHandler={errorHandler}
        />

        <Col xs={12} lg={6}>
          <SMTPSettingsForm
            bindForm={bindForm}
            settings={settingsState.applicationSettings}
            handleTestSMTP={handleTestSMTP}
            translation={t}
            isLoadingTest={settingsState.isLoadingTest}
            isLoading={!settingsState.applicationSettings}
            showConfirmation={showConfirmation}
            isFormValid={isSMTPFormValid}
            isAnyUpdatesExist={isAnySMTPUpdatesExist}
            saveButtonClick={saveSMTPButtonClick}
            revalidateErrors={revalidateErrors}
            revalidateUpdates={revalidateSMTPUpdates}
          />
          <IPListForm
            isLoading={!settingsState.applicationSettings}
            showConfirmation={showConfirmation}
            saveButtonClick={(event: any, selectedIPs: string[]) =>
              saveWhitelistIPsClick(event, selectedIPs)
            }
            ipRanges={
              settingsState.applicationSettings && settingsState.applicationSettings.whitelistedIPs
            }
            isWhitelistedIPs={true}
          />
          <IPListForm
            isLoading={!settingsState.applicationSettings}
            showConfirmation={showConfirmation}
            saveButtonClick={(event: any, selectedIPs: string[]) =>
              saveBlacklistIPsClick(event, selectedIPs)
            }
            ipRanges={
              settingsState.applicationSettings && settingsState.applicationSettings.blacklistedIPs
            }
            isWhitelistedIPs={false}
          />
          <StaticLinkForm
            tableData={staticLinksState.staticLinks}
            refreshSaticLinksList={refreshSaticLinksList}
            isLoading={staticLinksState.isStaticLinksLoading}
            staticLinksState={staticLinksState}
            deleteLinks={handleStaticLinkDelete}
            uploadStaticLinkIcon={uploadStaticLinkIcon}
            deleteStaticLinkIcon={deleteStaticLinkIcon}
            displayCroppedStaticLinkIcon={displayCroppedStaticLinkIcon}
            handleSubmit={handleStaticLinkSubmit}
            setCroppedStaticLinkIcon={setCroppedStaticLinkIcon}
          />
        </Col>

        <Col xs="12" className="py-0">
          <Button
            className="settingsForm float-right"
            onClick={() => {
              setShowEnvironmentVariable(!showEnvironmentVariable);
            }}>
            {showEnvironmentVariable ? (
              <span className="d-flex align-items-cente d-flex align-items-center justify-content-center">
                <i className="pi pi-eye-slash" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">{t('/admin/settings.hideEnvironmentVariables')}</span>
              </span>
            ) : (
              <span className="d-flex align-items-center d-flex align-items-center justify-content-center">
                <i className="pi pi-eye" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">{t('/admin/settings.showEnvironmentVariables')}</span>
              </span>
            )}
          </Button>
        </Col>
      </Row>

      <DialogWrapper
        isDialogVisible={showEnvironmentVariable}
        onHide={() => hideEnvironmentVariablesDialog()}
        headerTitle={t('I18N.settings.enviromentVariables')}>
        {getApplicationEnvironmentVariables()}
      </DialogWrapper>
      {getConfirmPopup()}
    </Form>
  );
};

export default SettingsForm;
