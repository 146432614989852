/*
 * UserTokenManagement.tsx (InstaLOD GmbH)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file UserTokenManagement.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { useDispatch, useSelector } from 'react-redux';
import {
  IUserTokenTransactionState,
  createUserTokenTransactionAction,
  getUserTokenTransactionListAction,
  getUserTokenState,
  userTokenActions
} from '../../../Store/UserTokenTransactionSlice';
import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import {
  IPageEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import UserTokenTransactionTable from './UserTokenTransactionTable';
import { IUserTokenTransaction } from '@abstract/abstractwebcommon-shared/interfaces/user/UserTokenTransaction';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { Col, Row } from 'react-bootstrap';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const UserTokenTransactionManagement = (): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const userTokenState: IUserTokenTransactionState = useSelector(getUserTokenState);
  const dispatch: Dispatch<any> = useDispatch();
  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      creationDate: 'DESC'
    },
    searchTerm: ''
  }); /**< Default Payload */

  useEffect(() => {
    dispatch(getUserTokenTransactionListAction(payload));

    // on unmount
    return () => {
      dispatch(userTokenActions.reset());
    };
  }, [dispatch]);

  /**
   * Handler for page update
   * @param event IPageEvent
   */
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(getUserTokenTransactionListAction(updatedPayload));
  };

  /**
   * Handler for sort update
   * @param payload: ITablePayload
   */
  const handleSortUpdate = (updatedPayload: ITablePayload): void => {
    setPayload(updatedPayload);
    dispatch(getUserTokenTransactionListAction(updatedPayload));
  };

  /**
   * Handler for filter update
   * @param event string
   */
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(getUserTokenTransactionListAction(updatedPayload));
  };

  const handleAddOrEditUserToken = async (
    createUserTokenPayload: Partial<IUserTokenTransaction>
  ): Promise<void> => {
    await asyncErrorHandler(
      dispatch(
        createUserTokenTransactionAction({
          createUserTokenPayload,
          getUserTokenListPayload: payload
        })
      )
    );
  };

  useEffect(() => {
    setDialogVisible(false);
    if (userTokenState.isCreated) {
      showToast({
        severity: 'success',
        summary: translation('I18N.success_messages.userToken_create_success'),
        detail: ''
      });
    }
  }, [userTokenState.isCreated, dispatch]);

  useEffect(() => {
    if (userTokenState.createError != null) {
      showToast({
        severity: 'error',
        summary: translation('I18N.error_messages.failed'),
        detail: userTokenState.createError?.message || userTokenState.createError
      });
    }
    if (userTokenState.fetchError != null) {
      showToast({
        severity: 'error',
        summary: translation('I18N.error_messages.failed'),
        detail: userTokenState.fetchError?.message || userTokenState.fetchError
      });
    }
  }, [userTokenState.createError, userTokenState.fetchError, dispatch]);

  return (
    <Row>
      <Col>
        <UserTokenTransactionTable
          tablePayload={payload}
          onPage={handlePageUpdate}
          onSort={handleSortUpdate}
          onFilter={handleFilterUpdate}
          userTokenList={userTokenState.userTokenList}
          isLoading={userTokenState.isLoading}
          handleAddOrEditUserToken={handleAddOrEditUserToken}
          isDialogVisible={isDialogVisible}
          setDialogVisible={setDialogVisible}
        />
      </Col>
    </Row>
  );
};

export default UserTokenTransactionManagement;
