/**
* DashboardPage.tsx (abstractuser) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2021 

* @file DashboardPage.tsx
* @author Etienne Daher
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDashboardStatisticsAction,
  fetchDashboardVersionAction,
  fetchFeedAction,
  fetchSystemWarningsAction
} from '../../../Store/DashboardSlice';
import {
  getDashboardState,
  dashboardActions,
  IDashboardState
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import { DashboardForm } from './DashboardForm';
import { getSafeAppSettingsAction } from '../../../Store/SettingsSlice';

export const DashboardPage = (): JSX.Element => {
  const dashboardState: IDashboardState = useSelector(getDashboardState);
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(getSafeAppSettingsAction());
    dispatch(fetchDashboardStatisticsAction());
    dispatch(fetchDashboardVersionAction());
    dispatch(fetchFeedAction());
    dispatch(fetchSystemWarningsAction());

    // on unmount
    return () => {
      dispatch(dashboardActions.reset());
    };
  }, []);

  const chartData: IDashboardState = dashboardState;

  return (
    <DashboardForm
      chartData={chartData}
      backendVersion={dashboardState.version}
      feedsData={dashboardState.feedsData}
      systemWarnings={dashboardState.systemWarnings}
    />
  );
};

export default DashboardPage;
