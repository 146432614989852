/**
* PlaceholderSlice.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file PlaceholderSlice.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { Reducer } from 'react';

export const placeholderfeatureKey: string = 'ph';

/**
 * Interface placeholderInitialState
 */
export interface IPlaceholderState {
  phUpdateError: any;
  placeholders: any[];
}

export const placeholderInitialState: IPlaceholderState = {
  phUpdateError: null,
  placeholders: []
};

export const phAdapter: EntityAdapter<IPlaceholderState> = createEntityAdapter();
export const initialPHState: EntityState<IPlaceholderState> & IPlaceholderState =
  phAdapter.getInitialState(placeholderInitialState);

/**
 * Creates Slice - All Placeholder related state will be stored here
 */
export const phSlice = createSlice({
  name: placeholderfeatureKey,
  initialState: initialPHState,
  reducers: {}
});

export const phReducer: Reducer<EntityState<IPlaceholderState> & IPlaceholderState, AnyAction> =
  phSlice.reducer;

export const phActions: any = phSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = phAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getPhState: any = (rootState: any) => rootState[placeholderfeatureKey];
export const selectAllPH: any = createSelector(getPhState, selectAll);
export const selectPhEntities: any = createSelector(getPhState, selectEntities);
