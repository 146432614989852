/*
 * ForgotPasswordWrapper.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file ForgotPasswordWrapper.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import "./index.css";

import { Steps } from "primereact/steps";
import FindAccount from "./FindAccount";
import VerifyAccount from "./VerifyAccount";
import PasswordResetForm from "./ForgotPasswordForm";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import { Card } from "primereact/card";

interface IForgotPasswordWrapperProperties {
  onCancel: () => void;
  onSubmit: (data?: any) => void;
  pageIndex: number;
  isLoading: boolean;
}

function ForgotPasswordWrapper({
  onCancel,
  onSubmit,
  pageIndex,
  isLoading,
}: IForgotPasswordWrapperProperties): ReactElement {
  const { t } = useTranslation();

  const stepperModel = [
    { label: t("awc:/.forgot_password.step.account") },
    { label: t("awc:/.forgot_password.step.verify") },
    { label: t("awc:/.forgot_password.step.reset_pass") },
  ];

  /// Show loader if loading is true else show specific component
  const getStepComponent = () => {
    if (isLoading) {
      return (
        <div className="mt-5">
          <Loader />
        </div>
      );
    } else {
      switch (pageIndex) {
        case 0:
          return (
            <FindAccount
              onCancel={onCancel}
              onFindAccount={onSubmit}
            />
          );
        case 1:
          return <VerifyAccount onCancel={onCancel} onVerify={onSubmit} />;
        case 2:
          return <PasswordResetForm onCancel={onCancel} onSubmit={onSubmit} />;
        default:
          return (
            <FindAccount
              onCancel={onCancel}
              onFindAccount={onSubmit}
            />
          );
      }
    }
  };

  return (
    <>
      <Steps model={stepperModel} activeIndex={pageIndex} readOnly />
      <div className="mt-5 login-pages-global-container">
        <Card title={t("awc:/.forgot_password.step.reset_pass")}>{getStepComponent()}</Card>
      </div>
    </>
  );
}

export default ForgotPasswordWrapper;
